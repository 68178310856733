import {
  DELETE_COMMUNITY,
  EDIT_COMMUNITY,
  GET_COMMUNITY,
  GET_COMMUNITY_FOLLOWERS,
  GET_COMMUNITY_LIST,
  GET_COMMUNITY_MODERATORS,
  GET_COMMUNITY_SURVEYS,
  MARK_DELETED_COMMUNITY,
  RESET_COMMUNITY_STATE,
  ADD_COMMUNITY,
  BLOCK_COMMUNITY,
  FOLLOW_COMMUNITY,
  GET_COMMUNITIES_SEARCH_LIST,
} from 'const';
import { Community, IActionFn, QueryParams } from 'interfaces';

export const getCommunityListAction: IActionFn<QueryParams> = (params) => ({
  type: GET_COMMUNITY_LIST.PENDING,
  payload: params,
});

export const getCommunityAction: IActionFn<number> = (id) => ({
  type: GET_COMMUNITY.PENDING,
  payload: id,
});

export const editCommunityAction: IActionFn<Community> = (
  community,
  redirect
) => ({
  type: EDIT_COMMUNITY.PENDING,
  payload: community,
  redirect,
});

export const blockCommunityAction: IActionFn<number> = (id) => ({
  type: BLOCK_COMMUNITY.PENDING,
  payload: id,
});

export const followCommunityAction: IActionFn<number> = (id) => ({
  type: FOLLOW_COMMUNITY.PENDING,
  payload: id,
});

export const markDeletedCommunityAction: IActionFn<number> = (id) => ({
  type: MARK_DELETED_COMMUNITY.PENDING,
  payload: id,
});

export const deletedCommunityAction: IActionFn<number> = (id, redirect) => ({
  type: DELETE_COMMUNITY.PENDING,
  payload: id,
  redirect,
});

export const getCommunitySurveysAction: IActionFn<QueryParams> = (params) => ({
  type: GET_COMMUNITY_SURVEYS.PENDING,
  payload: params,
});

export const getCommunityFollowersAction: IActionFn<QueryParams> = (
  params
) => ({
  type: GET_COMMUNITY_FOLLOWERS.PENDING,
  payload: params,
});

export const getCommunityModeratorsAction: IActionFn<QueryParams> = (
  params
) => ({
  type: GET_COMMUNITY_MODERATORS.PENDING,
  payload: params,
});

export const resetCommunityAction: IActionFn = () => ({
  type: RESET_COMMUNITY_STATE.SUCCESS,
  payload: null,
});

export const addCommunityAction: IActionFn<Community> = (
  community,
  redirect
) => ({
  type: ADD_COMMUNITY.PENDING,
  payload: community,
  redirect,
});

export const getCommunitiesBySearchAction: IActionFn<QueryParams> = (
  payload,
  redirect
) => ({
  payload,
  redirect,
  type: GET_COMMUNITIES_SEARCH_LIST.PENDING,
});
