import React from 'react';

import { UniversalCmpStyleType } from './universal-consts';
import { UniversalBtnType, UniversalButton, UniversalIcon } from '..';
import {
  Category,
  Community,
  IIconComponent,
  Location,
  Owner,
} from 'interfaces';
import { checkmarkIcon, closeIcon } from '../..';
import { formatFn } from 'utils';

export const makeBtn = (
  title: string,
  icon: IIconComponent,
  style: UniversalCmpStyleType,
  fn?: () => void,
  btnType?: UniversalBtnType
) => {
  return (
    <UniversalButton
      btnTitle={title}
      iconComp={icon}
      styleType={style}
      onClick={fn}
      btnType={btnType}
    />
  );
};

export const makeLink = (
  title: string,
  linkTo: string,
  icon?: IIconComponent,
  style?: UniversalCmpStyleType,
  fn?: () => void
) => {
  return (
    <UniversalButton
      btnType="link"
      btnTitle={title}
      linkTo={linkTo}
      iconComp={icon}
      styleType={style}
      onClick={fn}
    />
  );
};

export const categoryFn = (c: Category) => (c ? c.categoryName : '');
export const communityFn = (c: Community) => (c ? c.title : '');
export const ownerFn = (o: Owner) => (o ? o.username : '');
export const visibilityFn = (isPrivate: boolean) =>
  isPrivate ? 'Private' : 'Public';
export const locationFn = (l: Location) => (l ? l.name : '');
export const dateFn = (format: string) => (date: string) =>
  date ? formatFn(format)(date) : '';
export const booleanIconFn = (trueStyle: UniversalCmpStyleType = 'primary') => (
  isTrue: boolean
) => (
  <UniversalIcon
    iconComp={isTrue ? checkmarkIcon : closeIcon}
    styleType={isTrue ? trueStyle : 'hint'}
  />
);
