import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  UniversalButton,
  UniversalSkeleton,
  UniversalModal,
  makeBtn,
} from 'components/common/universal';
import { addIcon, checkIcon } from 'components/common';
import { PointsType, POINTS_LIMIT } from 'const';
import { Input } from 'antd';
import { givePointsToUserAction } from 'actions';
import { UserProfile, PermissionTypes, UserGroups } from 'interfaces';
import { formatPoints } from 'helpers';
import { customAbility } from 'containers/Can';

interface IProps {
  points: number;
  extraPoints: number;
  isLoading?: boolean;
  id: UserProfile['id'];
}

interface IState {
  isPrompt: boolean;
  amountPoints: {
    value: string;
    error: boolean;
  };
  pointType: PointsType | null;
}

const initialState: IState = {
  isPrompt: false,
  pointType: null,
  amountPoints: {
    value: '',
    error: false,
  },
};

export const ProfileStatistic: React.FC<IProps> = ({
  points,
  extraPoints,
  isLoading,
  id,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);

  const caUpdateUser = customAbility.can(
    PermissionTypes.UPDATE,
    UserGroups.USERS
  );

  const renderSkeleton = (val: number) =>
    isLoading ? <UniversalSkeleton type={'img'} height={55} width={60} /> : val;

  const showPrompt = (pointType: PointsType) => () => {
    setState({
      ...state,
      pointType,
      isPrompt: true,
    });
  };

  const closePrompt = () => {
    setState({
      ...state,
      isPrompt: false,
      pointType: null,
      amountPoints: { value: '', error: false },
    });
  };

  const getPoints = () => {
    const pointType = state.pointType;

    if (pointType === null) return;

    const { value } = state.amountPoints;
    if (!value) {
      return setState({ ...state, amountPoints: { value, error: true } });
    }
    dispatch(
      givePointsToUserAction({ pointType, id, amount: +value }, closePrompt)
    );
  };

  const handleChangePoits = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const preparedValud = formatPoints(value);
    setState({
      ...state,
      amountPoints: { value: preparedValud, error: false },
    });
  };

  return (
    <div className="card profile-statistic">
      <h4>POINTS INFO</h4>

      <h3 className="statistic-counter">{renderSkeleton(points)}</h3>
      <p className="points hint">Points</p>
      {caUpdateUser && (
        <UniversalButton
          btnType="link"
          iconComp={addIcon}
          btnTitle="Give Points"
          styleType="success"
          onClick={showPrompt(PointsType.POINT)}
        />
      )}

      <h3 className="statistic-counter">{renderSkeleton(extraPoints)}</h3>
      <p className="points hint">Extra Points</p>
      {caUpdateUser && (
        <UniversalButton
          btnType="link"
          iconComp={addIcon}
          btnTitle="Give Extra Points"
          styleType="success"
          onClick={showPrompt(PointsType.EXTRA)}
        />
      )}

      {/* MODAL */}
      <UniversalModal
        onCancel={closePrompt}
        visible={state.isPrompt}
        promptOkBtn={makeBtn('Get', checkIcon, 'success', getPoints)}
      >
        <Input
          className={`input ${state.amountPoints.error ? 'input--error' : ''}`}
          type="text"
          value={state.amountPoints.value}
          placeholder="Enter Points"
          onChange={handleChangePoits}
        />
      </UniversalModal>
    </div>
  );
};
