import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import {
  FormDatepicker,
  FormSelect,
  FormTextInput,
  FormUploadImage,
} from 'components/common/form';
import { UniversalButton } from 'components/common/universal';
import { checkIcon } from 'components/common';
import {
  genderOptions,
  locationOptions,
  visibilityOptions,
} from 'assets/static-data';
import { IImmutableMap, UserProfile } from 'interfaces';
import { humanizeRole, cutToSize, formatPhoneNumber } from 'helpers';
import {
  PROFILE_FORM,
  avaCropParams,
  LARGE_TITLE_LIMIT,
  EMAIL_LIMIT,
  ZIP_CODE_LIMIT,
} from 'const';
import {
  validateEmail,
  validateRequired,
  disabledUnderageDate,
  validateZipCode,
  validatePhoneNumber,
} from 'utils';

interface IProps extends InjectedFormProps {
  initialValues: IImmutableMap<UserProfile>;
}

const ProfileForm: any = (props: IProps) => {
  const { handleSubmit, initialValues } = props;
  const { role } = initialValues.toJS();

  return (
    <form onSubmit={handleSubmit} className="profile-form card">
      <div className="f-s-sb">
        <h4>POINTS INFO</h4>
        <div>
          <span className="hint">User Group: </span>
          <span className="label capitalize">{humanizeRole(role)}</span>
        </div>
      </div>
      <Field
        name="avatar"
        label="Your Avatar"
        listType="avatar"
        component={FormUploadImage}
        defaultCrop={avaCropParams}
      />
      <Field
        name="username"
        label="Full Name"
        placeholder="Enter Full Name"
        component={FormTextInput}
        validate={validateRequired}
        normalize={cutToSize(LARGE_TITLE_LIMIT)}
      />
      <Field
        name="phoneNumber"
        type="tel"
        label="Phone Number"
        placeholder="Enter Phone Number"
        component={FormTextInput}
        readOnly={true}
        validate={validatePhoneNumber}
        normalize={formatPhoneNumber}
      />

      <Field
        name="email"
        label="Email Address"
        placeholder="Enter Email Address"
        component={FormTextInput}
        validate={validateEmail}
        normalize={cutToSize(EMAIL_LIMIT)}
      />
      <Field
        name="gender"
        label="Gender"
        placeholder="Select Gender"
        options={genderOptions}
        component={FormSelect}
      />
      <Field
        name="yearOfBirth"
        label="Birth Date"
        placeholder="Select Birth Date"
        pickerType="month"
        dateFormat="YYYY"
        component={FormDatepicker}
        disabledDate={disabledUnderageDate}
      />
      <Field
        name="location"
        label="Location"
        placeholder="Select Location"
        options={locationOptions}
        validate={validateRequired}
        component={FormSelect}
      />
      <Field
        name="zipcode"
        type="tel"
        label="Postal/Zip code"
        placeholder="Enter Postal/Zip Code"
        component={FormTextInput}
        validate={validateZipCode}
        normalize={cutToSize(ZIP_CODE_LIMIT)}
      />
      <Field
        name="isPublic"
        label="Profile"
        placeholder="Select Profile Type"
        options={visibilityOptions}
        component={FormSelect}
      />
      <UniversalButton
        htmlType="submit"
        btnTitle="Save Changes"
        styleType="success"
        iconComp={checkIcon}
      />
    </form>
  );
};

export default reduxForm({
  form: PROFILE_FORM,
  enableReinitialize: true,
})(ProfileForm);
