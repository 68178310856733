import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Input } from 'antd';
import Icon from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { UniversalTooltip } from '../universal';
import { IReactChangeEvent } from 'interfaces';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { errorIcon } from 'components/common';

type InputTypes = 'text' | 'password' | 'tel' | 'textarea';

interface IProps extends WrappedFieldProps {
  type: InputTypes;
  placeholder: string;
  label: string;
  prefixIcon: IconComponentProps['component'];
  className: string;
  wrapperClassName: string;
  allowClear?: boolean;
  textareaAutoSize?: {
    minRows: number;
    maxRows: number;
  };
  readOnly?: boolean;
  max?: number;
}

export const FormTextInput = (props: IProps) => {
  const {
    input,
    input: { onChange },
    meta: { touched, error },
    placeholder,
    label,
    prefixIcon,
    type = 'text',
    allowClear,
    textareaAutoSize,
    readOnly = false,
    max,
  } = props;
  const invalid = touched && error;

  const handleChange = (ev: IReactChangeEvent) => {
    if (type === 'tel' && isNaN(+ev.target.value)) return;
    onChange(ev);
  };

  const renderPrefix = () => prefixIcon && <Icon component={prefixIcon} />;

  const renderSuffix = () =>
    invalid ? (
      <UniversalTooltip placement="right" title={error} styleType="error">
        <Icon
          component={errorIcon}
          className={`icon-${type} anticon-c-error`}
        />
      </UniversalTooltip>
    ) : (
      <span />
    );

  const renderField = () => {
    const inputProps = {
      ...input,
      type,
      placeholder,
      allowClear,
      readOnly,
      onChange: handleChange,
      className: `${type}-input`,
      prefix: renderPrefix(),
      suffix: renderSuffix(),
    };

    if (type === 'textarea') {
      return (
        <>
          {renderSuffix()}
          <TextArea
            {...input}
            className={`${type}-input`}
            placeholder={placeholder}
            autoSize={textareaAutoSize}
          />
        </>
      );
    }
    if (type === 'password') {
      return (
        <div className="password-field">
          {renderSuffix()}
          <Input.Password {...inputProps} />
        </div>
      );
    }
    return <Input {...inputProps} />;
  };

  return (
    <div
      className={`form-group ${invalid ? 'invalid' : ''} ${
        max ? 'form-group--counter' : ''
      }`}
    >
      <div className="form-control-wrapper">
        {label && (
          <label htmlFor={input.name} className="text-input-label">
            {label}
          </label>
        )}
        {renderField()}
        {max && (
          <span className="form-group__counter">
            {input.value.length}/{max}
          </span>
        )}
      </div>
    </div>
  );
};
