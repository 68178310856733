import { IActionFn, INotificationAll } from 'interfaces';
import { SEND_NOTIFICATION_ALL } from 'const';

export const sendNotificationAllAction: IActionFn<INotificationAll> = (
  params,
  redirect
) => ({
  redirect,
  payload: params,
  type: SEND_NOTIFICATION_ALL.PENDING,
});
