import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import { FormTextInput } from 'components/common/form';
import { UniversalButton } from 'components/common/universal';
import { checkIcon } from 'components/common';
import { CHANGE_PASS_FORM, PASSWORD_LIMIT } from 'const';
import {
  validatePassword,
  validateRecoveryPasswordFormCreator,
  makeSelector,
} from 'utils';
import { cutToSize } from 'helpers';
import { changeUserPasswordAction } from 'actions';
import { UserProfile } from 'interfaces';

interface IProps extends InjectedFormProps {}

const ChangePassForm = (props: any) => {
  const { handleSubmit } = props;
  const dispatch = useDispatch();

  const profile = useSelector<any, UserProfile>(
    makeSelector(['ownDataReducer', 'ownProfile'])
  );

  const { id } = profile;

  const onSubmit = (formData: any) => {
    const { newPassword, oldPassword } = formData.toJS();

    dispatch(
      changeUserPasswordAction({
        ...profile,
        id,
        oldPassword,
        password: newPassword,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="change-pass-form card">
      <Field
        name="oldPassword"
        type="password"
        label="Old Password"
        placeholder="Enter Password"
        component={FormTextInput}
        validate={validatePassword}
        normalize={cutToSize(PASSWORD_LIMIT)}
      />

      <Field
        name="newPassword"
        type="password"
        label="New Password"
        placeholder="Enter Password"
        component={FormTextInput}
        validate={validatePassword}
        normalize={cutToSize(PASSWORD_LIMIT)}
      />

      <Field
        name="confirmPassword"
        type="password"
        label="Confirm Password"
        placeholder="Enter Password"
        component={FormTextInput}
        validate={validatePassword}
        normalize={cutToSize(PASSWORD_LIMIT)}
      />

      <UniversalButton
        htmlType="submit"
        btnTitle="Change Password"
        styleType="success"
        iconComp={checkIcon}
      />
    </form>
  );
};

const validateForm = validateRecoveryPasswordFormCreator(
  'newPassword',
  'confirmPassword'
);

export default reduxForm({
  form: CHANGE_PASS_FORM,
  validate: validateForm,
})(ChangePassForm);
