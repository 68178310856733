import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound from 'components/not-found/NotFound';
import OffensiveWordsPage from 'components/OffensiveWords/OffensiveWordsPage';
import { OffensiveWordsRoute } from 'const';

const OffensiveWordsRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={OffensiveWordsRoute.ROOT}
        component={OffensiveWordsPage}
      />

      <Route component={NotFound} />
    </Switch>
  );
};

export default OffensiveWordsRouter;
