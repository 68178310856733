import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { CategoryHeader } from 'components/common/parts';
import { addIcon, closedIcon, closeIcon, deleteIcon } from 'components/common';
import {
  IUniversalModalProps,
  makeBtn,
  UniversalModal,
} from 'components/common/universal';
import {
  Community,
  IItem,
  PermissionTypes,
  UserGroups,
  RolesForPrivateTabs,
} from 'interfaces';
import {
  getMatchParam,
  prepareCategorySubTitle,
  SubTitleLinkTypes,
  prepareSubTitlelink,
} from 'helpers';
import {
  deletedCommunityAction,
  getCommunityAction,
  markDeletedCommunityAction,
  blockCommunityAction,
  followCommunityAction,
} from 'actions';
import { PromptPrefix, SurveysRoute } from 'const';
import { makeSelector, session, getCommunityCategoryTab } from 'utils';
import { useLoading } from 'hooks';
import { CommunityChildrenRouter } from 'routes/childRoutes/CommunityRouter';
import { customAbility, getRole } from 'containers/Can';

interface IProps extends RouteComponentProps {}

const CommunityDetailsPage: React.FC<IProps> = ({
  history,
  match,
  location,
}) => {
  const dispatch = useDispatch();

  const isLoading = useLoading(['communityReducer', 'isLoadingCommunity']);
  const community = useSelector<any, Community>(
    makeSelector(['communityReducer', 'community'])
  );

  const {
    title,
    owner,
    dateCreated,
    blocked,
    followed,
    bookmarked,
    oct,
  } = community;
  const idFromUrl = getMatchParam(match).id;
  const isOwnCommunity = owner.id === session.getOwnID();

  const role = getRole();
  const showPrivateTabs =
    isOwnCommunity || RolesForPrivateTabs.some((r) => r === role);
  const categoryTabs = getCommunityCategoryTab(idFromUrl, showPrivateTabs);

  const canDelete =
    customAbility.can(PermissionTypes.DELETE, UserGroups.REGULAR_COMMUNITYS) ||
    isOwnCommunity === true;

  const canBlock = customAbility.can(
    PermissionTypes.BAN,
    UserGroups.REGULAR_COMMUNITYS
  );

  const canFollow =
    customAbility.can(PermissionTypes.FOLLOW, UserGroups.REGULAR_COMMUNITYS) &&
    !isOwnCommunity;

  const showSuggestBtn = !showPrivateTabs;

  useEffect(() => {
    if (idFromUrl) {
      dispatch(getCommunityAction(idFromUrl));
    }
  }, []);

  // PROMPTS
  const [isPrompt, setPromptState] = useState(false);
  const [promptParams, setPromptParams] = useState<IUniversalModalProps | null>(
    null
  );

  const closePrompt = () => setPromptState(false);

  const showPromptWith = (params: IUniversalModalProps) => () => {
    setPromptParams(params);
    setPromptState(true);
  };

  const deleteCommunity = () => {
    closePrompt();
    dispatch(deletedCommunityAction(idFromUrl, history.goBack));
  };

  const markDeleted = () => {
    dispatch(markDeletedCommunityAction(idFromUrl));
    closePrompt();
  };

  const blockCommunity = () => {
    dispatch(blockCommunityAction(idFromUrl));
    closePrompt();
  };

  const follow = () => {
    dispatch(followCommunityAction(idFromUrl));
    closePrompt();
  };

  const suggestSurvey = () => {
    history.push(SurveysRoute.getSuggestRoute(idFromUrl));
  };

  // Each Prompt depends of status
  const prepareHeaderCtrlBtns = () => {
    const promptProps: IUniversalModalProps = {
      visible: true,
      onCancel: closePrompt,
    };

    // DELETE
    const deletePrompt = {
      ...promptProps,
      onOk: deleteCommunity,
    };

    // MARK DELETED
    const markWord = bookmarked ? 'unmark' : 'mark';
    const markIcon = bookmarked ? addIcon : closeIcon;
    const markDeletedPrompt = {
      ...promptProps,
      promptText: `${PromptPrefix.ARE_SURE} ${markWord} as deleted the community?`,
      promptOkBtn: makeBtn(
        `Yes, ${markWord} it!`,
        markIcon,
        'warning',
        markDeleted
      ),
    };

    // BLOCK
    const blockWord = blocked ? 'unblock' : 'block';
    const blockIcon = blocked ? addIcon : closedIcon;
    const blockStyle = blocked ? 'success' : 'error';
    const blockPrompt = {
      ...promptProps,
      promptText: `${PromptPrefix.ARE_SURE} ${blockWord} the community?`,
      promptOkBtn: makeBtn(
        `Yes, ${blockWord} it!`,
        blockIcon,
        blockStyle,
        blockCommunity
      ),
    };

    // FOLLOW
    const followWord = followed ? 'unfollow' : 'follow';
    const followIcon = followed ? closeIcon : addIcon;
    const followStyle = followed ? 'error' : 'success';

    return (
      <>
        {showSuggestBtn &&
          makeBtn('Create Survey', addIcon, 'success', suggestSurvey)}
        {canDelete &&
          makeBtn(
            'Delete Permanently',
            deleteIcon,
            'error',
            showPromptWith(deletePrompt)
          )}
        {canDelete &&
          makeBtn(
            `${markWord} Deleted`,
            markIcon,
            'warning',
            showPromptWith(markDeletedPrompt)
          )}
        {canBlock &&
          makeBtn(
            `${blockWord} Community`,
            blockIcon,
            blockStyle,
            showPromptWith(blockPrompt)
          )}
        {canFollow && makeBtn(followWord, followIcon, followStyle, follow)}
      </>
    );
  };

  // TABS
  const onSelectTab = (tab: IItem) => {
    history.replace(tab.id as string);
  };

  const setDefaultTab = () => {
    const currentTab = categoryTabs.find((t) => t.id === location.pathname);
    return currentTab ? currentTab : categoryTabs[0];
  };

  const userSubTitleLink = prepareSubTitlelink({
    type: SubTitleLinkTypes.USER,
    title: owner.username,
    id: owner.id,
  });

  return (
    <div className="f-column community-page">
      <CategoryHeader
        catTitle={title}
        catSubTitle={prepareCategorySubTitle(userSubTitleLink, '', dateCreated)}
        catTabs={categoryTabs}
        backBtn={{ showButton: true }}
        ctrlBtns={!oct && prepareHeaderCtrlBtns()}
        onSelectTab={onSelectTab}
        isLoading={isLoading}
        defaultTab={setDefaultTab()}
      />

      {/* CONTENT */}
      {CommunityChildrenRouter({ community, isOwnCommunity, isLoading })}

      <UniversalModal {...promptParams} visible={isPrompt} />
    </div>
  );
};

export default CommunityDetailsPage;
