import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import { FormCheckbox, FormTextInput } from 'components/common/form';
import { UniversalButton } from 'components/common/universal';
import { validateLoginField, validatePassword } from 'utils';
import { AuthRoute, SING_IN_FORM, PASSWORD_LIMIT } from 'const';
import { AuthContainer } from 'containers';
import { IAuthContainer } from 'interfaces';
import { cutToSize, formatLoginFields } from 'helpers';
import { lockIcon, userFilledIcon } from 'components/common';

interface IProps extends InjectedFormProps, IAuthContainer {}

const LoginForm: any = ({ handleSubmit, signIn }: IProps) => {
  const onSubmit = (formData: any) => {
    const { username, password, rememberMe } = formData.toJS();
    signIn({ username, password, rememberMe });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="login-form">
      <h2>Sign In</h2>

      <Field
        name="username"
        placeholder="Enter phone Number or Email"
        prefixIcon={userFilledIcon}
        component={FormTextInput}
        validate={validateLoginField}
        normalize={formatLoginFields}
      />
      <Field
        name="password"
        type="password"
        placeholder="Password"
        prefixIcon={lockIcon}
        component={FormTextInput}
        validate={validatePassword}
        normalize={cutToSize(PASSWORD_LIMIT)}
      />

      <div className="f-s-sb remember-me">
        <Field
          name="rememberMe"
          label="Remember me"
          type="checkbox"
          component={FormCheckbox}
        />

        <UniversalButton
          btnType="link"
          linkTo={AuthRoute.FORGOT_PASSWORD}
          btnTitle="Forgot Password?"
          styleType="hint"
        />
      </div>

      <UniversalButton block htmlType="submit" btnTitle="Sign in" />
    </form>
  );
};

export default AuthContainer(reduxForm({ form: SING_IN_FORM })(LoginForm));
