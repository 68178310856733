import { createCanBoundTo } from '@casl/react';
import { ability } from 'config';

const myAbility = ability();

export const customAbility = myAbility.ability;

export const getRole = myAbility.getRole;

export const Can: any = createCanBoundTo(customAbility);
