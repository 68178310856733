import React from 'react';
import history from 'config/history';

import { DetailsInfoCard, IDetailsInfoCard } from 'components/common/parts';
import { makeBtn, visibilityFn } from 'components/common/universal';
import { editIcon } from 'components/common';
import { CommunityRoute, SurveysRoute } from 'const';
import { Community, resetCategoryModel } from 'interfaces';
import { CssPrefix, humanizeBoolean } from 'helpers';

interface IProps {
  community: Community;
  isOwnCommunity: boolean;
  cssPrefix?: CssPrefix;
  isLoading: boolean;
}

const CommunityDetailsInfo: React.FC<IProps> = (props) => {
  const {
    community: {
      id,
      category = resetCategoryModel(),
      location,
      description,
      image,
      isPrivate,
      numberOfUsers,
      numberOfSurveys,
      blocked,
      surveyAutoReview,
      oct: isOct,
    },
    isOwnCommunity,
    isLoading,
  } = props;

  const toEditCommunity = () => {
    history.push(CommunityRoute.getEditRoute(id));
  };

  const suggestSurvey = () => {
    history.push(SurveysRoute.getSuggestRoute(id));
  };

  let successButton: JSX.Element | null = isOwnCommunity
    ? makeBtn('Edit Community', editIcon, 'success', toEditCommunity)
    : null;

  if (blocked === true) {
    successButton = null;
  }

  // tslint:disable-next-line: no-object-literal-type-assertion
  const params = {
    title: 'Community',
    avatar: image,
    details: [
      { title: 'Category', value: category.categoryName },
      { title: 'Visibility', value: visibilityFn(isPrivate) },
      !isOct
        ? {
            title: 'Automatic approval of surveys',
            value: humanizeBoolean(surveyAutoReview),
          }
        : null,
      { title: 'Location', value: location.name },
      { title: 'Description', value: description },
    ].filter(Boolean),
    statistics: [
      { title: 'Followers', value: numberOfUsers },
      { title: 'Active Surveys', value: numberOfSurveys },
    ],
    ctrlBtns: successButton,
  } as IDetailsInfoCard;

  return <DetailsInfoCard params={params} isLoading={isLoading} />;
};

export default CommunityDetailsInfo;
