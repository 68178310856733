export class Location {
  id: number = 1;
  name: string = 'USA';
  phoneCode: string = '+1';
}

export const resetLocationModel = (): Location => ({
  id: 1,
  name: '',
  phoneCode: '',
});
