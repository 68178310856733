import { Ability, AbilityBuilder } from '@casl/ability';
import { UserProfile, getPermissionsByRole } from 'interfaces';

import store from './store';

// Defines how to detect object's type
function subjectName(item: any) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}

export const ability = () => {
  const ability = new Ability([], { subjectName });

  let currentAuth: UserProfile | undefined;

  store.subscribe(() => {
    const prevAuth = currentAuth;
    const state = (store.getState() as any).toJS();

    currentAuth = state.ownDataReducer.ownProfile;

    if (prevAuth !== currentAuth && currentAuth) {
      ability.update(defineRulesFor(currentAuth));
    }
  });

  function defineRulesFor(auth: UserProfile) {
    const { can, rules, cannot } = new AbilityBuilder();
    const { role, id } = auth;

    const permissions: any = getPermissionsByRole(role);

    let key: keyof typeof permissions;
    for (key in permissions) {
      if (permissions.hasOwnProperty(key)) {
        can(permissions[key], key, { userId: id });
      }
    }

    return rules as any;
  }

  function getRole() {
    if (currentAuth) {
      return currentAuth.role;
    }
    return null;
  }

  return { ability, getRole };
};
