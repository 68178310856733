import React, { useEffect } from 'react';

import { humanizeQuestionType, QuestionList } from 'interfaces';
import { CssPrefix, cssPrefixCreator } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelector } from 'utils';
import { getSurveyQuestionsAction } from 'actions';
import SurveyDetailsQuestionsSkeleton from './surveyDetailsQuestionsSkeleton';

interface IProps {
  questionId: number;
  cssPrefix: CssPrefix;
  isLoading: boolean;
}

const SurveyDetailsQuestionsPreview: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const questions = useSelector<any, QuestionList>(
    makeSelector(['surveysReducer', 'questionList'])
  );
  const { questionId, isLoading } = props;

  useEffect(() => {
    if (questionId) {
      dispatch(getSurveyQuestionsAction(questionId));
    }
  }, []);

  const getLabel = ({
    qualified,
    optional,
  }: {
    qualified: boolean;
    optional: boolean;
  }) => {
    let label = null;
    if (qualified) {
      label = 'Qualified question';
    }
    if (optional) {
      label = 'Optional question';
    }
    return label;
  };

  const cssPrefix = cssPrefixCreator(props.cssPrefix('questions-preview-cmp'));
  return isLoading ? (
    <SurveyDetailsQuestionsSkeleton />
  ) : (
    <div className={cssPrefix(' card')}>
      <ol className={cssPrefix('list')}>
        {questions.map(
          ({
            questionNumber,
            question,
            type,
            optionList,
            qualified,
            optional,
          }) => {
            const label = getLabel({ optional, qualified });
            return (
              <li key={questionNumber}>
                <h3>{question} </h3>
                {label && (
                  <span className={props.cssPrefix('qualified-info-text')}>
                    {label}
                  </span>
                )}
                <p className="hint">Type: {humanizeQuestionType[type]} ()</p>
                <ul>
                  {optionList.map(({ text, id }) => (
                    <li key={id}>{text}</li>
                  ))}
                </ul>
              </li>
            );
          }
        )}
      </ol>
    </div>
  );
};

export default SurveyDetailsQuestionsPreview;
