import React, { useEffect, useRef, useState } from 'react';

import { Input } from 'antd';
import Icon from '@ant-design/icons';
import { searchIcon, addIcon } from '..';
import { UniversalButton } from '../universal';
import { IReactChangeEvent, QueryParams, IIconComponent } from 'interfaces';

interface IProps {
  btnTitle?: string;
  btnIco?: IIconComponent;
  onClickBtn?(): void;
  onChange?(queryParams?: QueryParams): void;
  initialQuery?: QueryParams;
}

let timerId = 0;

export const SearchBlock = (props: IProps) => {
  const {
    btnTitle,
    onClickBtn,
    onChange = () => {},
    initialQuery: { query = '' } = {},
    btnIco = addIcon,
  } = props;
  const didMountRef = useRef(false); // to prevent first request
  const [value, setValue] = useState('');

  useEffect(() => {
    if (didMountRef.current) {
      if (value.length) {
        handleCheck();
      }
    } else {
      didMountRef.current = true;
    }
    return () => clearTimeout(timerId);
  }, [value]);

  const onPressEnter = (ev: any) => {
    onChange({ query: ev.target.value });
  };

  const onTyping = ({ target: { value } }: IReactChangeEvent) => {
    if (!value.length) {
      onChange({ query: '' });
    }
    setValue(value);
  };

  const handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(timerId);
    timerId = window.setTimeout(() => {
      onChange({ query: value, offset: 0 });
    }, 500);
  };

  return (
    <div className="search-block f-c-s">
      <Input
        className="search"
        placeholder="Search..."
        onChange={onTyping}
        onPressEnter={onPressEnter}
        prefix={<Icon style={{ opacity: 0.2 }} component={searchIcon} />}
        allowClear={true}
        defaultValue={query}
        maxLength={30}
      />
      {btnTitle && (
        <UniversalButton
          btnTitle={btnTitle}
          iconComp={btnIco}
          styleType="success"
          onClick={onClickBtn}
        />
      )}
    </div>
  );
};
