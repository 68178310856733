import {
  EmailErrors,
  EmailHints,
  PasswordErrors,
  PasswordHints,
  PATTERN_EMAIL,
  PhoneHints,
  PATTERN_PASSWORD,
  ZIP_CODE_LIMIT,
  PhoneChars,
  PATTERN_URL,
  MAX_PASS_CODE,
  PasscodeHint,
  EmailOrPhoneHint,
} from 'const';

const Hints = {
  REQUIRED: 'Must be not empty',
  INVALID_URL: `The URL you've provided is invalid.`,
};

const isEmptyString = (value: any) =>
  typeof value === 'string' && !value.length;

export const validateRequired = (value: string | number | null = '') => {
  const valueWithoutSpaces =
    value && typeof value === 'string' ? value.trim() : value;

  if (valueWithoutSpaces === null || isEmptyString(valueWithoutSpaces)) {
    return Hints.REQUIRED;
  }
};

export const validateEmail = (value = '') => {
  const valueWithoutSpaces = value ? value.trim() : value;

  if (isEmptyString(valueWithoutSpaces)) {
    return EmailHints.SET_EMAIL;
  }
  if (!PATTERN_EMAIL.test(valueWithoutSpaces)) {
    return EmailErrors.INVALID_PATTERN;
  }
};

export const validatePassword = (value = '') => {
  if (isEmptyString(value)) {
    return PasswordHints.SET_PASSWORD;
  }

  if (!PATTERN_PASSWORD.test(value)) {
    return PasswordErrors.INCORRECT_SYMBOLS;
  }
};

export const validatePhone = (value = '') => {
  if (isEmptyString(value)) {
    return PhoneHints.SET_PHONE;
  }
};

export const validateRecoveryPasswordFormCreator = (
  newPassName: string,
  confirmPassName: string
) => (data: any) => {
  const values = data.toJS();
  const errors = {
    [newPassName]: '',
    [confirmPassName]: '',
  };

  if (!values[newPassName] || isEmptyString(values[newPassName])) {
    errors[newPassName] = PasswordHints.SET_NEW;
  }
  if (!PATTERN_PASSWORD.test(values[newPassName])) {
    errors[newPassName] = PasswordErrors.INCORRECT_SYMBOLS;
  }

  if (!values[confirmPassName] || isEmptyString(values[confirmPassName])) {
    errors[confirmPassName] = PasswordHints.REPLY_NEW;
  }
  if (!PATTERN_PASSWORD.test(values[confirmPassName])) {
    errors[confirmPassName] = PasswordErrors.INCORRECT_SYMBOLS;
  }

  if (values[newPassName] !== values[confirmPassName]) {
    errors[confirmPassName] = PasswordErrors.NEW_AND_CONFIRM_SHOULD_BE_EQUAL;
  }

  return errors;
};

export const validateZipCode = (value = '') => {
  const valueWithoutSpaces = value ? value.replace(/ /g, '') : value;
  const isEmptyError = validateRequired(valueWithoutSpaces);
  if (isEmptyError) return isEmptyError;

  if (valueWithoutSpaces.length < ZIP_CODE_LIMIT) return Hints.REQUIRED;
};

export const validatePhoneNumber = (value = '') => {
  const withPrefix = value.match(/\+/);
  const preffix = withPrefix
    ? PhoneChars.CODE_WITH_PLUS
    : PhoneChars.CODE_WITHOUT_PLUS;

  const maxLength = preffix + PhoneChars.NUMBER;

  const valueWithoutSpaces = value ? value.replace(/ /g, '') : value;
  const isEmptyError = validateRequired(valueWithoutSpaces);
  if (isEmptyError) return isEmptyError;

  if (valueWithoutSpaces.length < maxLength) return Hints.REQUIRED;
};

export const validateLoginField = (values = '') => {
  const valueWithoutSpaces = values ? values.replace(/ /g, '') : values;
  const isPhone = valueWithoutSpaces.match(/(\+.+)|[0-9]/);

  const isEmptyError = validateRequired(valueWithoutSpaces);
  if (isEmptyError) return EmailOrPhoneHint;

  if (isPhone) {
    return validatePhoneNumber(valueWithoutSpaces);
  }
  if (validateEmail(valueWithoutSpaces)) {
    return EmailOrPhoneHint;
  }
};

export const validateURL = (value = '') => {
  const isEmpty = validateRequired(value);
  if (isEmpty) return isEmpty;
  if (!PATTERN_URL.test(value)) {
    return Hints.INVALID_URL;
  }
};

export const validateSoftURL = (value = '') => {
  if (isEmptyString(value)) return;
  if (!PATTERN_URL.test(value)) {
    return Hints.INVALID_URL;
  }
};

export const validatePasscode = (value = '') => {
  const valueWithoutSpaces = value ? value.replace(/ /g, '') : value;
  const isEmptyError = validateRequired(valueWithoutSpaces);
  if (isEmptyError) return isEmptyError;

  if (valueWithoutSpaces.length < MAX_PASS_CODE)
    return PasscodeHint.INCORRECT_SYMBOLS;
};

export const validateFormWithPdfAndUrl = (url: string, pdf: string) => (
  data: any
) => {
  const values = data.toJS();

  const urlError = validateSoftURL(values[url]) || '';

  const errors = {
    [url]: urlError,
    [pdf]: '',
  };
  if (!values[url] && !values[pdf]) {
    return {
      [url]: urlError || Hints.REQUIRED,
      [pdf]: Hints.REQUIRED,
    };
  }

  return errors;
};
