import { PaginationWrapper } from 'interfaces';

export interface Category {
  id: number;
  categoryName: string;
  image: string;
  logo?: string;
  isDefault: boolean;
}

export interface CategoriesList extends PaginationWrapper {
  data: Category[];
}

export const resetCategoryModel = (): Category => ({
  id: 1,
  categoryName: '',
  image: '',
  logo: '',
  isDefault: false,
});

export const resetCategoryListModel = (): CategoriesList => ({
  data: [],
  recordsFiltered: 0,
  recordsTotal: 0,
});
