import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound from 'components/not-found/NotFound';
import CategoriesPage from 'components/Categories/CategoriesPage';
import { CategoriesRoute } from 'const';

const CategoriesRouter = () => {
  return (
    <Switch>
      <Route exact path={CategoriesRoute.ROOT} component={CategoriesPage} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default CategoriesRouter;
