import React, { useState, useEffect, ReactElement } from 'react';
import history from 'config/history';
import { Select, Empty } from 'antd';

import { UniversalButton } from 'components/common/universal';
import {
  IUniversalTableParams,
  QueryParams,
  UniversalTableItem,
} from 'interfaces';
import { orderAscIcon, orderDescIcon, sortIcon } from '../..';
import {
  QueryDef,
  QueryOrder,
  QueryLimitItems,
  MEDIA_TABLET_POINTS,
} from 'const';
import { classNamesHlp, findParentNode } from 'helpers';
import { useDelay, useMobile } from 'hooks';

import './universal-table.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const { Option } = Select;

const DESKTOP_MIN_WIDTH = 1000;

interface IProps extends IUniversalTableParams {
  children?: ReactElement;
  className?: string;
}

export const UniversalTable = (props: IProps) => {
  const {
    titles = [],
    data = [],
    total = data.length,
    routeConst,
    onChange = () => {},
    onRowClick,
    isLoading = false,
    initialQuery = new QueryParams(),
    className = '',
    queryLimitItems = QueryLimitItems,
    children,
  } = props;
  const {
    offset = QueryDef.OFFSET,
    sort = QueryDef.SORT,
    order = QueryDef.ORDER,
    query = '',
  } = initialQuery;

  const initialLimit = initialQuery.limit || QueryDef.LIMIT;
  const totalVisibleCount = data.length;

  const [currPageIndex, setPage] = useState(offset);
  const [limit, setLimit] = useState(initialLimit);
  const [visibleItems, setVisibleItems] = useState(initialLimit);
  const delayedSetVisibleItems = useDelay<number>(setVisibleItems);
  const delayedSetPage = useDelay<number>(setPage);
  const delayedOnChange = useDelay<QueryParams>(onChange);

  const isTablet = useMobile(MEDIA_TABLET_POINTS);

  const availablePages = Math.ceil(total / limit);

  const limitOptions = queryLimitItems.reduce(
    (acc: number[], curr, index, arr) => {
      if (total >= curr) {
        acc.push(curr);
        if (index === arr.length - 1 && total > curr) {
          acc.push(total);
        }
      } else if (total > arr[index - 1]) {
        acc.push(total);
      }
      return acc;
    },
    []
  );

  useEffect(() => {
    if (currPageIndex !== offset) {
      setPage(offset);
    }
  }, [offset]);

  useEffect(() => {
    const limitIndex = limitOptions.indexOf(initialLimit);
    if (limitOptions.length) {
      const lastOption =
        limitOptions[limitIndex] || limitOptions[limitOptions.length - 1];
      setLimit(lastOption);
      setVisibleItems(lastOption);
    }

    if (query === '' && total >= initialLimit) {
      setLimit(initialLimit);
      setVisibleItems(initialLimit);
    }
  }, [total]);

  const selectPage = (page: number) => {
    if (!currPageIndex && !total) return;
    setPage(page);
    onChange({ offset: page });
  };
  const nextPage = (page: number) => () => selectPage(page);

  const selectVisibleItms = (items: number) => {
    setLimit(items);
    delayedSetVisibleItems(items);
    delayedSetPage(QueryDef.OFFSET);
    delayedOnChange({ limit: items, offset: QueryDef.OFFSET });
  };

  const applySorts = (propName: string) => () => {
    if (!total) return;
    if (sort === propName) {
      const selectOrder =
        order === QueryOrder.ASC ? QueryOrder.DESC : QueryOrder.ASC;
      onChange({ order: selectOrder });
    } else {
      onChange({ sort: propName, order: QueryOrder.ASC });
    }
  };

  const isAvailableForClick = (
    id: UniversalTableItem['id'],
    type: 'click' | 'redirect'
  ) => {
    if (type === 'click') {
      return !isLoading && id && onRowClick;
    }
    return !isLoading && id && routeConst && routeConst.getDetailsRoute;
  };

  const goToDetails = ({ id }: UniversalTableItem) => (e: any) => {
    if (findParentNode(e.target, 'without-redirect')) return false;

    if (isAvailableForClick(id, 'redirect') && routeConst) {
      history.push(routeConst.getDetailsRoute(id));
    }
    if (isAvailableForClick(id, 'click') && onRowClick) {
      onRowClick(id);
    }
  };

  const renderDiapason = () => {
    if (total) {
      const from = currPageIndex * limit + 1;
      const to = (currPageIndex + 1) * limit;
      return `entries | Displaying ${from} to ${
        to < total ? to : total
      } of ${total} Items`;
    }
    return `entries | Displaying 0 Items`;
  };

  const visibleRows =
    visibleItems >= totalVisibleCount ? totalVisibleCount : visibleItems;

  const renderTable = () => {
    return (
      <table className={`${className} ${isTablet ? 'mobile-table' : ''}`}>
        {!isTablet && (
          <thead>
            <tr>
              {/* TITLES */}
              {titles.map((titleOpt, i) => {
                const {
                  title,
                  propName,
                  className,
                  isSortable = true,
                } = titleOpt;
                const icon =
                  sort === propName
                    ? order === QueryOrder.ASC
                      ? orderAscIcon
                      : orderDescIcon
                    : sortIcon;
                return (
                  <th key={i} className={className || propName}>
                    {title}
                    {isSortable && (
                      <UniversalButton
                        btnTitle=""
                        iconComp={icon}
                        styleType={sort === propName ? 'label' : 'hint'}
                        btnType="link"
                        onClick={applySorts(propName)}
                      />
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}

        <tbody>
          {/* ROWS */}
          {total || isLoading
            ? [...new Array(visibleRows)].map((r, i) => {
                const rowItem = data[i] || { id: 0 };
                return (
                  <tr
                    key={rowItem.id + 'row' + i}
                    className={classNamesHlp({ hoverable: !!rowItem.id })}
                    onClick={goToDetails(rowItem)}
                  >
                    {/* CELLS */}
                    {titles.map((title, j) => {
                      const {
                        propName,
                        formatFn,
                        formatPropName,
                        canRedirect = true,
                        title: headTitle,
                      } = title;

                      let value: any = <>&zwnj;</>;
                      if (rowItem.id) {
                        if (typeof formatFn === 'function') {
                          const name = formatPropName || propName;
                          value = formatFn(rowItem[name]);
                        } else if (rowItem[propName]) {
                          value = rowItem[propName];
                        } else {
                          value = '-';
                        }
                      }
                      const className = classNamesHlp('cell-ellipsis', {
                        'cell-loading': isLoading,
                      });
                      return (
                        <td
                          key={rowItem.id + propName + 'cell' + j}
                          className={`${className} ${
                            canRedirect === false ? 'without-redirect' : ''
                          }`}
                        >
                          {isTablet && (
                            <span className="mobile-table__title">
                              {headTitle}
                            </span>
                          )}
                          <div className="mobile-table__value">
                            {typeof value !== 'object' ? (
                              <span>{value || '-'}</span>
                            ) : (
                              value
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    );
  };

  return (
    <div className="universal-table card">
      <div className="universal-table-wrapper">
        {!children ? renderTable() : children}
        {/* NO DATA */}
        {!total && !isLoading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>

      {/* FOOTER */}
      <div className="universal-table-footer f-c-sb">
        {/* LIMIT */}
        <div className="f-c-c">
          <span>Show</span>
          <Select
            value={limit}
            onChange={selectVisibleItms}
            className="universal-table-limit-select"
            dropdownClassName="universal-table-page-select"
          >
            {availablePages
              ? limitOptions.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))
              : null}
          </Select>
          <span>{renderDiapason()}</span>
        </div>

        {/* PAGINATION */}
        <div className="universal-table-pagination f-c-c">
          <UniversalButton
            shape="circle"
            iconComp={LeftOutlined}
            disabled={currPageIndex === 0}
            onClick={nextPage(currPageIndex - 1)}
          />
          <span>Page</span>
          <Select
            value={currPageIndex}
            onChange={selectPage}
            dropdownClassName="universal-table-page-select"
          >
            {new Array(availablePages).fill(0).map((v, i) => (
              <Option value={i} key={i}>
                {i + 1}
              </Option>
            ))}
          </Select>
          <span>of {availablePages}</span>
          <UniversalButton
            shape="circle"
            iconComp={RightOutlined}
            disabled={
              currPageIndex === availablePages ||
              currPageIndex === availablePages - 1
            }
            onClick={nextPage(currPageIndex + 1)}
          />
        </div>
      </div>
    </div>
  );
};
