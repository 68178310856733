import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import { DELETE_BUZZ, GET_BUZZ, GET_BUZZ_LIST, RESET_BUZZ_STATE } from 'const';
import {
  Buzz,
  BuzzList,
  QueryParams,
  resetBuzzListModel,
  resetBuzzModel,
} from 'interfaces';
import { makeCombinedAction } from 'utils/app/reduxHelper';

interface BuzzReducerState {
  isLoading: boolean;
  buzzList: BuzzList;
  buzz: Buzz;
  queryParams: QueryParams;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = Immutable.fromJS({
  isLoading: false,
  buzzList: resetBuzzListModel(),
  buzz: resetBuzzModel(),
  queryParams: {},
} as BuzzReducerState);

const { PENDING, DONE } = makeCombinedAction(
  GET_BUZZ_LIST,
  GET_BUZZ,
  DELETE_BUZZ
);

const buzzReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [GET_BUZZ_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('buzzList', Immutable.fromJS(payload));
    },
    [GET_BUZZ_LIST.PENDING]: (state, { payload }) => {
      return state.set('queryParams', Immutable.fromJS(payload));
    },
    [GET_BUZZ.SUCCESS]: (state, { payload }) => {
      return state.set('buzz', Immutable.fromJS(payload));
    },
    [`${DELETE_BUZZ.SUCCESS}||${RESET_BUZZ_STATE.SUCCESS}`]: (state) => {
      return state.set('buzz', Immutable.fromJS(resetBuzzModel()));
      // .set('buzzList', state.get('buzzList').filter((buzz: any) => buzz.get('id') !== payload));
    },
  },
  initialState
);

export default buzzReducer;
