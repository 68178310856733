import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SearchBlock } from 'components/common/parts';
import { UniversalTable } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { getCommunityFollowersAction } from 'actions';
import { makeSelector } from 'utils';
import { FollowersList, QueryParams } from 'interfaces';
import { getMatchParam } from 'helpers';
import { usersTableParams } from 'assets/static-data';

interface IProps extends SearchableTable {}

const CommunityFollowers: React.FC<IProps> = ({
  match,
  getList,
  isLoading,
  queryParams,
}) => {
  const { data, recordsFiltered } = useSelector<any, FollowersList>(
    makeSelector(['communityReducer', 'followersList'])
  );

  const idFromUrl = getMatchParam(match).id;

  useEffect(() => {
    onGetList(queryParams);
  }, []);

  const onGetList = (params: QueryParams) => {
    getList({ ...params, id: idFromUrl });
  };

  return (
    <>
      <SearchBlock onChange={getList} initialQuery={queryParams} />
      <UniversalTable
        {...usersTableParams}
        data={data}
        onChange={onGetList}
        isLoading={isLoading}
        initialQuery={queryParams}
        total={recordsFiltered}
      />
    </>
  );
};

export default SearchableTableContainer({
  getListAction: getCommunityFollowersAction,
  isInitialized: false,
  reducerName: 'communityReducer',
  queryName: 'followersQueryParams',
  isEqualSearch: true,
})(CommunityFollowers);
