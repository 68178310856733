import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CategoryHeader } from 'components/common/parts';
import Transactions from './tabs-content/Transactions';
import ChangePassForm from './tabs-content/ChangePassForm';
import AccountProfile from './tabs-content/AccountProfile/AccountProfile';
import { IItem, UserProfile } from 'interfaces';
import { getOwnProfileAction } from 'actions';
import { makeSelector, session, getAccountCategoryTab } from 'utils';

import './account-page.scss';
import ChangePhone from './tabs-content/ChangePhone/ChangePhone';

const categoryTabs = getAccountCategoryTab();

enum Tab {
  PROFILE = 1,
  PASSWORD,
  PHONE,
  TRANSACTIONS,
}

const AccountPage = () => {
  const dispatch = useDispatch();
  const [currTab, setCurrTab] = useState<IItem>(categoryTabs[0]);

  const profile = useSelector<any, UserProfile>(
    makeSelector(['ownDataReducer', 'ownProfile'])
  );
  useEffect(() => {
    dispatch(getOwnProfileAction(session.getOwnID()));
  }, []);

  const onSelectTab = (tab: IItem) => setCurrTab(tab);

  const renderContent = () => {
    switch (currTab.id) {
      case Tab.PROFILE:
        return <AccountProfile profile={profile} />;
      case Tab.PASSWORD:
        return <ChangePassForm />;
      case Tab.PHONE:
        return <ChangePhone profile={profile} />;
      case Tab.TRANSACTIONS:
        return <Transactions />;
    }
  };

  return (
    <div className="f-column account-page-root">
      <CategoryHeader
        catTitle="Account"
        catTabs={categoryTabs}
        onSelectTab={onSelectTab}
      />

      {/* CONTENT */}
      {renderContent()}
    </div>
  );
};

export default AccountPage;
