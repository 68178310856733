// tslint:disable:prettier

import {
  CommunityRoute,
  communityDetailPath,
  UsersRoute,
  userDetailPath,
} from 'const';
import { IItem } from 'interfaces';

export const getCommunityCategoryTab = (
  idFromUrl: string,
  showPrivateTabs: boolean
): IItem[] => {
  const validTabs = [
    {
      id: `${CommunityRoute.getDetailsRoute(idFromUrl)}/${
        communityDetailPath.MAIN_INFO
      }`,
      title: 'Main Info',
    },
    {
      id: `${CommunityRoute.getDetailsRoute(idFromUrl)}/${
        communityDetailPath.FOLLOWERS
      }`,
      title: 'Followers',
    },
    {
      id: `${CommunityRoute.getDetailsRoute(idFromUrl)}/${
        communityDetailPath.MODERATORS
      }`,
      title: 'Moderators',
    },
    {
      id: `${CommunityRoute.getDetailsRoute(idFromUrl)}/${
        communityDetailPath.INACTIVE_NEWS
      }`,
      title: 'Inactive News',
    },
    {
      id: `${CommunityRoute.getDetailsRoute(idFromUrl)}/${
        communityDetailPath.ACTIVE_NEWS
      }`,
      title: 'Active News',
    },
  ];
  const privateTabs = [
    {
      id: `${CommunityRoute.getDetailsRoute(idFromUrl)}/${
        communityDetailPath.SURVEYS
      }`,
      title: 'Surveys',
    },
  ];

  return showPrivateTabs ? validTabs.concat(privateTabs) : validTabs;
};

export const getUserCategoryTab = (
  idFromUrl: string,
  showPrivateTabs: boolean
): IItem[] => {
  const validTabs = [
    {
      id: `${UsersRoute.getDetailsRoute(idFromUrl)}/${userDetailPath.ACCOUNT}`,
      title: 'Account info',
    },
    {
      id: `${UsersRoute.getDetailsRoute(idFromUrl)}/${
        userDetailPath.COMMUNITIES
      }`,
      title: 'Owned Communities',
    },
  ];
  const privateTabs = [
    {
      id: `${UsersRoute.getDetailsRoute(idFromUrl)}/${userDetailPath.SURVEYS}`,
      title: 'Surveys',
    },
  ];
  return showPrivateTabs ? validTabs.concat(privateTabs) : validTabs;
};

export const getAccountCategoryTab = (): IItem[] => {
  return [
    { id: 1, title: 'Profile' },
    { id: 2, title: 'Change Password' },
    { id: 3, title: 'Change Phone Number' },
    // { id: 4, title: 'Transaction History' },
  ];
};

export const getContactRequestCategoryTab = (): IItem[] => {
  return [{ id: 1, title: 'Requested' }, { id: 2, title: 'Solved' }];
};

export const getPaymentCategotyTab = (): IItem[] => {
  return [{ id: 1, title: 'Points' }, { id: 2, title: 'Extra Points' }];
};

export const getRolesCategoryTab = (): IItem[] => {
  return [
    { id: 1, title: 'User Groups & Permissions' },
    { id: 2, title: 'Users' },
  ];
};

export const getGetInTouchTab = (): IItem[] => {
  return [{ id: 1, title: 'Subscribed' }, { id: 2, title: 'Unsubscribed' }];
};
