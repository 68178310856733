import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import { FormTextInput } from 'components/common/form';
import { AuthRoute, FORGOT_PASS_FORM } from 'const';
import { IAuthContainer } from 'interfaces';
import { AuthContainer } from 'containers';
import { UniversalButton } from 'components/common/universal';
import { validatePhoneNumber } from 'utils';
import { formatPhoneNumber } from 'helpers';
import { userFilledIcon } from 'components/common';

interface IProps extends InjectedFormProps, IAuthContainer {}

const ForgotPassForm: any = ({ handleSubmit, forgotPass }: IProps) => {
  const onSubmit = (formData: any) => {
    const { phoneNumber } = formData.toJS();
    forgotPass({ phoneNumber });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="forgot-pass-form">
      <h2>Forgot your Password?</h2>
      <p>
        Enter your phone number and we’ll send you SMS with a passcode to reset
        your password
        <UniversalButton
          btnType="link"
          linkTo={AuthRoute.ROOT}
          btnTitle="Go to Login"
          styleType="hint"
        />
      </p>

      <Field
        name="phoneNumber"
        placeholder="Phone Number"
        prefixIcon={userFilledIcon}
        component={FormTextInput}
        validate={validatePhoneNumber}
        normalize={formatPhoneNumber}
      />

      <UniversalButton block htmlType="submit" btnTitle="Reset" />
    </form>
  );
};

export default AuthContainer(
  reduxForm({ form: FORGOT_PASS_FORM, initialValues: { phoneNumber: '+1' } })(
    ForgotPassForm
  )
);
