export const MESSAGE = {
  ERROR_DEFAULT: 'Something went wrong.',
  FORM_WARNING: 'Please, fill all required fields.',
  SESSION_EXPIRED: 'Session is over. Please, re-login.',
  NO_NETWORK: `Can't fetch data. Please, check your internet connection.`,
  NEEDS_RELOAD: 'Please, reload page.',
  INVALID_UPLOADED_TYPE: 'Please choose supported media file',
};

export const SURVEY_SUCCESS_MESSAGE = {
  PULSE: 'Your pulse was cuccessfully created!',
  SURVEY: 'Your survey was cuccessfully created!',
  DRAFT: 'Your survey was successfully saved as a draft!',
};
