import { IUniversalTableParams, Transaction } from 'interfaces';
import { FORMAT_TABLE_DATE } from 'const';
import { humanizeString } from 'helpers';
import { dateFn } from 'components/common/universal';

const pointsFn = (points: number) => `+ ${points} Points`;

export const transactionsTtableParams: IUniversalTableParams<Transaction> = {
  titles: [
    {
      title: 'Date',
      propName: 'date',
      formatFn: dateFn(FORMAT_TABLE_DATE),
    },
    {
      title: 'Action',
      propName: 'pointsEarned',
      formatFn: pointsFn,
    },
    {
      title: 'Description',
      propName: 'description',
      formatFn: humanizeString,
    },
    {
      title: 'Payment Details',
      propName: 'details',
    },
    {
      title: 'Points',
      propName: 'pointsCurrent',
    },
    {
      title: 'Extra Points',
      propName: 'extraPointsCurrent',
    },
  ],
  data: [],
};
