import React, { useState } from 'react';
import { ReactNodeLike } from 'prop-types';

import { CssPrefix, cssPrefixCreator, humanizeString } from 'helpers';

import './details-info-card.scss';
import {
  DetailsInfoPairs,
  IDetailsInfoArrItem,
} from './DetailsInfoPairs/DetailsInfoPairs';
import { UniversalModal, UniversalSkeleton } from '../../universal';

export interface IDetailsInfoCard {
  title: string;
  statistics: IDetailsInfoArrItem[];
  details: IDetailsInfoArrItem[];
  ctrlBtns?: ReactNodeLike;
  avatar?: string;
}

interface IProps {
  params: IDetailsInfoCard;
  wrapperClassName?: string;
  cssPrefix?: CssPrefix;
  isLoading?: boolean;
}

export const DetailsInfoCard: React.FC<IProps> = (props) => {
  const [isModal, setModalState] = useState(false);
  const toggleModal = () => setModalState(!isModal);

  const {
    params: { title, details, statistics, ctrlBtns, avatar },
    wrapperClassName = 'card',
    isLoading,
  } = props;
  // const isLoading = true;

  const cssPrefix = cssPrefixCreator(
    `${props.cssPrefix || ''} details-info-card-cmp`
  );

  return (
    <div className={cssPrefix(` ${wrapperClassName}`)}>
      <div className={cssPrefix('info-block')}>
        {/* HEADER */}
        <div className="f-s-sb">
          <h3 className="statistic-title f-c-sb">{title} Info</h3>
          {isLoading ? null : ctrlBtns}
        </div>

        <div className={cssPrefix('info-inner')}>
          {/* AVATAR */}
          {typeof avatar === 'string' &&
            (isLoading ? (
              <UniversalSkeleton type="avatar" width={120} height={120} />
            ) : (
              <>
                <img
                  src={avatar}
                  className={cssPrefix('avatar')}
                  alt="avatar"
                  onClick={toggleModal}
                />
                <UniversalModal
                  visible={isModal}
                  type={'media'}
                  imageSrc={avatar}
                  onCancel={toggleModal}
                />
              </>
            ))}

          {/* DETAILS */}
          <DetailsInfoPairs isLoading={isLoading} details={details} />
        </div>
      </div>

      <div className={cssPrefix('statistic-block')}>
        {/* STATISTIC */}
        <h3 className="statistic-title">{title} Statistics</h3>
        <div className={cssPrefix('counters flex')}>
          {statistics.map(({ title, value }, i) => {
            const val =
              typeof value === 'string' && value.includes('%') ? (
                <>
                  {value.split('%')[0]}
                  <span>%</span>
                </>
              ) : (
                value || 0
              );
            return (
              <div key={i}>
                <h3 className="statistic-counter">
                  {isLoading ? (
                    <UniversalSkeleton type={'img'} height={55} width={60} />
                  ) : (
                    val
                  )}
                </h3>
                <p className="hint">{title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
