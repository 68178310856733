interface CommunityCategory {
  category: string;
  counter: number;
}

interface Communities {
  counters: {
    private: number;
    public: number;
    total: number;
  };
  categories: CommunityCategory[];
}

interface Surveys {
  completed: number;
  live: number;
  total: number;
}

interface Pulses extends Surveys {}

interface Users {
  active: number;
  blocked: number;
  inactive: number;
  total: number;
}

interface Category {
  category: string;
  communities: number;
  surveys: number;
}

interface Requests {
  notSolved: number;
  solved: number;
  total: number;
}

export interface DashboardConfig {
  communities: Communities;
  surveys: Surveys;
  pulses: Pulses;
  users: Users;
  categories: Category[];
  requests: Requests;
}

export const resetDashboardConfigModel = (): DashboardConfig => ({
  communities: {
    counters: {
      private: 0,
      public: 0,
      total: 0,
    },
    categories: [],
  },
  pulses: {
    completed: 0,
    live: 0,
    total: 0,
  },
  surveys: {
    completed: 0,
    live: 0,
    total: 0,
  },
  users: {
    active: 0,
    blocked: 0,
    inactive: 0,
    total: 0,
  },
  categories: [],
  requests: {
    notSolved: 0,
    solved: 0,
    total: 0,
  },
});
