import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
  getCommunityNewsListAction,
  addCommunityNewsAction,
  getCommunityNewsAction,
  editCommunityNewsAction,
  deleteCommunityNewsAction,
  changeCommunityNewsStatusAction,
  getCommunityActiveNewsListAction,
  changeOrderCommunityNewsAction,
  updateOrderCommunityNewsAction,
} from 'actions';
import {
  GET_COMMUNITY_NEWS_LIST,
  ADD_COMMUNITY_NEWS,
  GET_COMMUNITY_NEWS,
  EDIT_COMMUNITY_NEWS,
  DELETE_COMMUNITY_NEWS,
  CHANGE_NEWS_STATUS,
  GET_COMMUNITY_ACTIVE_NEWS_LIST,
  CHANGE_ORDER_COMMUNITY_NEWS,
  UPDATE_ORDER_COMMUNITY_NEWS_STATE,
} from 'const';
import { Community } from 'interfaces';
import {
  encodeDataToUrl,
  httpApi,
  HttpResp,
  makeAction,
  showMessage,
  makeSelector,
} from 'utils';
import { INews, NewsList } from 'interfaces/community/news';

function* getCommunityListNews({
  payload,
}: ReturnType<typeof getCommunityNewsListAction>) {
  const { id = '', ...rest } = payload;
  try {
    const res: HttpResp<INews[]> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/news/${id}/page?${encodeDataToUrl(rest)}&active=false`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_COMMUNITY_NEWS_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_COMMUNITY_NEWS_LIST.FAILURE, res));
    }
  } catch (error) {}
}

function* getCommunityNews({
  payload,
}: ReturnType<typeof getCommunityNewsAction>) {
  const { communityId, newsId } = payload;
  try {
    const res: HttpResp<INews[]> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/news/${communityId}/${newsId}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_COMMUNITY_NEWS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_COMMUNITY_NEWS.FAILURE, res));
    }
  } catch (error) {}
}

function* addCommunityNews({
  payload,
  redirect,
}: ReturnType<typeof addCommunityNewsAction>) {
  try {
    const { communityId, news } = payload;
    const res: HttpResp<Community> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/news/${communityId}`,
      data: news,
    });
    if (res && !res.error) {
      showMessage.success('News created successfully.');
      if (redirect) {
        redirect();
      }
    } else {
      yield put(makeAction(ADD_COMMUNITY_NEWS.FAILURE, res));
    }
  } catch (error) {}
}

function* editCommunityNews({
  payload,
  redirect,
}: ReturnType<typeof editCommunityNewsAction>) {
  try {
    const {
      communityId,
      news,
      queryParams,
      news: { id },
    } = payload;
    const res: HttpResp<Community> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/news/${communityId}/${id}`,
      data: news,
    });
    if (res && !res.error) {
      showMessage.success('News updated successfully.');
      if (redirect) {
        redirect();
      }
      if (queryParams) {
        yield put(getCommunityNewsListAction(queryParams));
      }
    } else {
      yield put(makeAction(ADD_COMMUNITY_NEWS.FAILURE, res));
    }
  } catch (error) {}
}

function* changeNewsStatus({
  payload,
  redirect,
}: ReturnType<typeof changeCommunityNewsStatusAction>) {
  try {
    const {
      communityId,
      queryParams,
      news: { active, id },
    } = payload;
    const res: HttpResp<Community> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/news/${communityId}/${id}`,
    });
    if (res && !res.error) {
      showMessage.success('News updated successfully.');
      if (redirect) {
        redirect();
      }
      if (queryParams) {
        yield put(getCommunityNewsListAction(queryParams));
      } else {
        yield put(getCommunityActiveNewsListAction(communityId));
      }
    } else {
      yield put(makeAction(ADD_COMMUNITY_NEWS.FAILURE, res));
    }
  } catch (error) {}
}

function* deleteCommunityNews({
  payload,
  redirect,
}: ReturnType<typeof deleteCommunityNewsAction>) {
  try {
    const { communityId, newsId } = payload;
    const res: HttpResp<Community> = yield call(httpApi, {
      method: 'DElETE',
      partUrl: `/news/${communityId}/${newsId}`,
    });
    if (res && !res.error) {
      showMessage.success('News deleted successfully.');
      if (redirect) {
        redirect();
      }
    } else {
      yield put(makeAction(ADD_COMMUNITY_NEWS.FAILURE, res));
    }
  } catch (error) {}
}

function* getCommunityActiveListNews({
  payload,
}: ReturnType<typeof getCommunityActiveNewsListAction>) {
  const id = payload;
  try {
    const res: HttpResp<any> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/news/${id}/page?active=true`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_COMMUNITY_ACTIVE_NEWS_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_COMMUNITY_ACTIVE_NEWS_LIST.FAILURE, res));
    }
  } catch (error) {}
}

function* changeOrderCommunityNews({
  payload,
}: ReturnType<typeof changeOrderCommunityNewsAction>) {
  const { id, orders } = payload;
  try {
    const res: HttpResp<any> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/news/${id}`,
      data: orders,
    });

    if (res && !res.error) {
      yield put(updateOrderCommunityNewsAction(payload));
    } else {
      yield put(makeAction(CHANGE_ORDER_COMMUNITY_NEWS.FAILURE, res));
    }
  } catch (error) {}
}

function* changeOrderCommunityNewsSuccess({
  payload,
}: ReturnType<typeof changeOrderCommunityNewsAction>) {
  const { orders } = payload;
  const newsList: NewsList = yield select(
    makeSelector(['communityReducer', 'activeNewsList'])
  );
  const news = newsList.data.map((n) => {
    const order = orders[n.id];
    n.displayOrder = order;
    return n;
  });
  yield put(
    makeAction(GET_COMMUNITY_ACTIVE_NEWS_LIST.SUCCESS, {
      ...newsList,
      data: news,
    })
  );
}

export function* newsSaga() {
  yield takeLatest(GET_COMMUNITY_NEWS_LIST.PENDING, getCommunityListNews);
  yield takeLatest(ADD_COMMUNITY_NEWS.PENDING, addCommunityNews);
  yield takeLatest(GET_COMMUNITY_NEWS.PENDING, getCommunityNews);
  yield takeLatest(EDIT_COMMUNITY_NEWS.PENDING, editCommunityNews);
  yield takeLatest(DELETE_COMMUNITY_NEWS.PENDING, deleteCommunityNews);
  yield takeLatest(CHANGE_NEWS_STATUS.PENDING, changeNewsStatus);
  yield takeLatest(
    GET_COMMUNITY_ACTIVE_NEWS_LIST.PENDING,
    getCommunityActiveListNews
  );
  yield takeLatest(
    CHANGE_ORDER_COMMUNITY_NEWS.PENDING,
    changeOrderCommunityNews
  );
  yield takeLatest(
    UPDATE_ORDER_COMMUNITY_NEWS_STATE,
    changeOrderCommunityNewsSuccess
  );
}
