// tslint:disable: max-classes-per-file

import { UserGroups, SurveyStatus, PermissionTypes } from 'interfaces';
import { customAbility } from 'containers/Can';

interface ISurveyPermissionProps {
  isOCT: boolean;
  status: SurveyStatus;
  isOwnSurvey: boolean;
  isOwnCommunity: boolean;
  isPulse: boolean;
}

export const getSurveyPermission = ({
  isOCT,
  status,
  isOwnSurvey,
  isOwnCommunity,
  isPulse,
}: ISurveyPermissionProps) => {
  let canClone = customAbility.can(PermissionTypes.CLONE, UserGroups.SURVEYS);
  let canDelete = customAbility.can(PermissionTypes.DELETE, UserGroups.SURVEYS);
  let canComplete = customAbility.can(
    PermissionTypes.COMPLETE,
    UserGroups.SURVEYS
  );
  let canUpdate = customAbility.can(PermissionTypes.UPDATE, UserGroups.SURVEYS);

  if (isOCT) {
    canClone = customAbility.can(PermissionTypes.CLONE, UserGroups.OCT_SURVEYS);
    canDelete = customAbility.can(
      PermissionTypes.DELETE,
      UserGroups.OCT_SURVEYS
    );
    canComplete = customAbility.can(
      PermissionTypes.COMPLETE,
      UserGroups.OCT_SURVEYS
    );
    canUpdate = customAbility.can(
      PermissionTypes.UPDATE,
      UserGroups.OCT_SURVEYS
    );
  }

  const canApprove = SurveyStatus.MODERATION === status;
  const canReject = SurveyStatus.MODERATION === status;

  const canCloneAction =
    (canClone || isOwnCommunity || isOwnSurvey) && !isPulse;

  const canDeleteAction = canDelete || isOwnSurvey;
  const canCompleteAction = canComplete || isOwnSurvey;
  const canUpdateActions = canUpdate || isOwnSurvey;

  return {
    canReject,
    canApprove,
    canUpdate: canUpdateActions,
    canDelete: canDeleteAction,
    canComplete: canCompleteAction,
    canClone: canCloneAction,
  };
};
