import React from 'react';

import { UserProfile, humanizeGender } from 'interfaces';
import {
  DetailsInfoCard,
  IDetailsInfoCard,
  ProfileStatistic,
} from 'components/common/parts';
import logo from 'assets/images/svg/user_card.svg';
import { humanizeRole, humanizeVisibility } from 'helpers';

import './user-account-info.scss';
import { RouteComponentProps } from 'react-router';

interface IProps extends RouteComponentProps {
  userProfile: UserProfile;
  isLoading: boolean;
}

const UserAccountInfo: React.FC<IProps> = (props) => {
  const {
    avatar,
    phoneNumber,
    email,
    gender,
    yearOfBirth,
    location,
    zipcode,
    isPublic,
    role,
    points,
    extraPoints,
    numberOfSurveys,
    numberOfOwnedCommunities,
    id,
  } = props.userProfile;
  const { isLoading } = props;

  const controlButtons = (
    <div>
      <span className="hint">User Group: </span>
      <span className="label capitalize">{humanizeRole(role)}</span>
    </div>
  );

  const params: IDetailsInfoCard = {
    title: 'User',
    avatar: avatar || logo,
    details: [
      { title: 'Phone Number', value: phoneNumber },
      { title: 'Email Address', value: email },
      { title: 'Gender', value: humanizeGender[gender] },
      { title: 'Birthday Date', value: yearOfBirth },
      { title: 'Location', value: location.name },
      { title: 'Postal/Zip code', value: zipcode },
      { title: 'Profile', value: humanizeVisibility(isPublic) },
    ],
    statistics: [
      { title: 'Owned Communities', value: numberOfOwnedCommunities },
      { title: 'Surveys', value: numberOfSurveys },
    ],
    ctrlBtns: controlButtons,
  };

  return (
    <div className="f-s-c account-info-cmp">
      <DetailsInfoCard params={params} isLoading={isLoading} />
      <ProfileStatistic
        points={points}
        extraPoints={extraPoints}
        isLoading={isLoading}
        id={id}
      />
    </div>
  );
};

export default UserAccountInfo;
