import React, { useEffect, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { resetUploadFileStateAction, uploadFileAction } from 'actions';
import {
  makeLink,
  UniversalButton,
  UniversalSpinner,
  UniversalTooltip,
} from '../universal';
import { SentMedia } from 'reducers';
import { StatusCode } from 'const';
import { closeIcon, errorIcon, externalPdfIcon } from 'components/common';
import { classNamesHlp, prepareFileNameFromUrl } from 'helpers';
import { makeSelector } from 'utils';
import Icon from '@ant-design/icons';

interface IProps extends WrappedFieldProps {
  label: string;
  className: string;
  onChange: any;
  accept?: string; // 'application/pdf, image/jpeg'
}

export const FormUploadFile = (props: IProps) => {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const {
    input: { value, onChange, name },
    meta: { touched, error },
    className = '',
    label,
    accept,
  } = props;
  const invalid = touched && error;

  // result after upload on server
  const sentMedia = useSelector<any, SentMedia>(
    makeSelector(['appReducer', 'sentMediaMap', name])
  );

  useEffect(() => {
    if (sentMedia && sentMedia.status === StatusCode.OK) {
      onChange(sentMedia.url);
      dispatch(resetUploadFileStateAction(name));
    }
  }, [(sentMedia || { status: 0 }).status]);

  const onFileLoaded = (file: File) => {
    updateLoading(true);
    dispatch(
      uploadFileAction({ file, mapKey: name }, () => updateLoading(false))
    );
  };

  const removeFile = () => {
    onChange(null);
  };

  return (
    <div className={classNamesHlp('form-group', className, { invalid })}>
      <div className="form-control-wrapper">
        {label && <div className="text-input-label">{label}</div>}

        <div className="form-control-download">
          <UniversalButton
            accept={accept}
            btnTitle={value ? 'Update' : 'Choose file'}
            styleType="success"
            btnType="file"
            onFileLoaded={onFileLoaded}
          />

          {invalid && (
            <UniversalTooltip placement="right" title={error} styleType="error">
              <Icon component={errorIcon} />
            </UniversalTooltip>
          )}

          <UniversalSpinner show={isLoading} />

          {value && !isLoading && (
            <span className="upload-file-cmp upload-file-cmp-file">
              {makeLink(prepareFileNameFromUrl(value), value, externalPdfIcon)}
              <UniversalButton
                btnType="link"
                styleType="error"
                iconComp={closeIcon}
                onClick={removeFile}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
