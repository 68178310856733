import {
  ADD_COMMUNITY_NEWS,
  GET_COMMUNITY_NEWS_LIST,
  EDIT_COMMUNITY_NEWS,
  GET_COMMUNITY_NEWS,
  RESET_COMMUNITY_NEWS_STATE,
  DELETE_COMMUNITY_NEWS,
  CHANGE_NEWS_STATUS,
  GET_COMMUNITY_ACTIVE_NEWS_LIST,
  CHANGE_ORDER_COMMUNITY_NEWS,
  UPDATE_ORDER_COMMUNITY_NEWS_STATE,
} from 'const';
import { IActionFn, QueryParams } from 'interfaces';
import { INews } from 'interfaces/community/news';

export const getCommunityNewsListAction: IActionFn<QueryParams> = (params) => ({
  type: GET_COMMUNITY_NEWS_LIST.PENDING,
  payload: params,
});

export const getCommunityActiveNewsListAction: IActionFn<number> = (
  communityId
) => ({
  type: GET_COMMUNITY_ACTIVE_NEWS_LIST.PENDING,
  payload: communityId,
});

interface GetNewsActionType {
  communityId: number;
  newsId: number;
}
export const getCommunityNewsAction: IActionFn<GetNewsActionType> = (
  params
) => ({
  type: GET_COMMUNITY_NEWS.PENDING,
  payload: params,
});

interface NewsActionType {
  communityId: number;
  news: INews;
  queryParams?: QueryParams;
}
export const addCommunityNewsAction: IActionFn<NewsActionType> = (
  news,
  redirect
) => ({
  type: ADD_COMMUNITY_NEWS.PENDING,
  payload: news,
  redirect,
});

export const editCommunityNewsAction: IActionFn<NewsActionType> = (
  news,
  redirect
) => ({
  type: EDIT_COMMUNITY_NEWS.PENDING,
  payload: news,
  redirect,
});

export const changeCommunityNewsStatusAction: IActionFn<NewsActionType> = (
  news,
  redirect
) => ({
  type: CHANGE_NEWS_STATUS.PENDING,
  payload: news,
  redirect,
});

export const deleteCommunityNewsAction: IActionFn<GetNewsActionType> = (
  params,
  redirect
) => ({
  type: DELETE_COMMUNITY_NEWS.PENDING,
  payload: params,
  redirect,
});

export const resetCommunityNewsAction: IActionFn = () => ({
  type: RESET_COMMUNITY_NEWS_STATE.SUCCESS,
  payload: null,
});

interface ChangeOrderCommunityNewsAction {
  orders: {
    [key in string]: number;
  };
  id: number;
}

export const changeOrderCommunityNewsAction: IActionFn<ChangeOrderCommunityNewsAction> = (
  orders
) => ({
  type: CHANGE_ORDER_COMMUNITY_NEWS.PENDING,
  payload: orders,
});

export const updateOrderCommunityNewsAction: IActionFn<ChangeOrderCommunityNewsAction> = (
  orders
) => ({
  type: UPDATE_ORDER_COMMUNITY_NEWS_STATE,
  payload: orders,
});
