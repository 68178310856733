import { call, put, takeLatest } from 'redux-saga/effects';
import { reset } from 'redux-form';

import {
  banUserAction,
  deleteUserAction,
  getUserCommunitiesAction,
  getUserProfileAction,
  getUserSurveysAction,
  getUserTransactionsAction,
  changeUserPasswordAction,
  changeUserPhoneAction,
} from '../actions';
import {
  encodeDataToUrl,
  httpApi,
  HttpResp,
  makeAction,
  showMessage,
} from '../utils';
import { CommunityList, UserProfile, UsersList } from '../interfaces';
import {
  BAN_USER,
  DELETE_USER,
  GET_USER_COMMUNITIES,
  GET_USER_PROFILE,
  GET_USER_SURVEYS,
  GET_USER_TRANSACTIONS,
  GET_USERS_LIST,
  CHANGE_USER_PASSWORD,
  CHANGE_PASS_FORM,
  CHANGE_USER_PHONE,
} from '../const';
import { getUsersListAction } from 'actions';

function* getUsersList({ payload }: ReturnType<typeof getUsersListAction>) {
  try {
    const res: HttpResp<UsersList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/users/page?${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_USERS_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_USERS_LIST.FAILURE, res));
    }
  } catch (error) {}
}

function* getUserProfile({ payload }: ReturnType<typeof getUserProfileAction>) {
  try {
    const res: HttpResp<UserProfile> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/users/${payload}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_USER_PROFILE.SUCCESS, res));
    } else {
      yield put(makeAction(GET_USER_PROFILE.FAILURE, res));
    }
  } catch (error) {}
}

function* banUser({ payload }: ReturnType<typeof banUserAction>) {
  try {
    const res: HttpResp<UserProfile> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/users/${payload}/ban`,
    });
    if (res && !res.error) {
      yield put(makeAction(BAN_USER.SUCCESS, res));
      if (res.blocked === true) {
        showMessage.success('User banned successfully');
      } else {
        showMessage.success('User unbanned successfully');
      }
    } else {
      yield put(makeAction(BAN_USER.FAILURE, res));
    }
  } catch (error) {}
}

function* deleteUser({
  payload,
  redirect,
}: ReturnType<typeof deleteUserAction>) {
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'DELETE',
      partUrl: `/users/${payload}`,
    });
    if (res && !res.error) {
      yield put(makeAction(DELETE_USER.SUCCESS, res));
      showMessage.success('User deleted successfully');
      if (redirect) {
        redirect();
      }
    } else {
      yield put(makeAction(DELETE_USER.FAILURE, res));
    }
  } catch (error) {}
}

function* getUserCommunities({
  payload,
}: ReturnType<typeof getUserCommunitiesAction>) {
  const { id = '', ...rest } = payload;
  try {
    const res: HttpResp<CommunityList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/community/user/${id}?${encodeDataToUrl(rest)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_USER_COMMUNITIES.SUCCESS, res));
    } else {
      yield put(makeAction(GET_USER_COMMUNITIES.FAILURE, res));
    }
  } catch (error) {}
}

function* getUserSurveys({ payload }: ReturnType<typeof getUserSurveysAction>) {
  const { id = '', ...rest } = payload;
  try {
    const res: HttpResp<CommunityList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/surveys/user/${id}?${encodeDataToUrl(rest)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_USER_SURVEYS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_USER_SURVEYS.FAILURE, res));
    }
  } catch (error) {}
}

function* getUserTransactions({
  payload,
}: ReturnType<typeof getUserTransactionsAction>) {
  const { id = '', ...rest } = payload;
  try {
    const res: HttpResp<CommunityList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/transactions/${id}?${encodeDataToUrl(rest)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_USER_TRANSACTIONS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_USER_TRANSACTIONS.FAILURE, res));
    }
  } catch (error) {}
}

function* changeUserPassword({
  payload,
}: ReturnType<typeof changeUserPasswordAction>) {
  const { oldPassword, password, id, ...restProfile } = payload;
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/users/${id}`,
      data: {
        oldPassword,
        password,
        ...restProfile,
      },
    });
    if (res && !res.error) {
      showMessage.success('The password is changed');
      yield put(reset(CHANGE_PASS_FORM));
    }
  } catch (error) {}
}

function* changeUserPhone({
  payload,
  redirect,
}: ReturnType<typeof changeUserPhoneAction>) {
  const { id, phoneNumber, resendSMS, smsCode } = payload;
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/users/${id}/phone`,
      data: {
        phoneNumber,
        resendSMS,
        smsCode,
      },
    });
    if (res && !res.error) {
      if (resendSMS === false) {
        showMessage.success('The phone number is changed');
      }
      if (redirect) {
        redirect();
      }
      yield put(reset(CHANGE_PASS_FORM));
    }
  } catch (error) {}
}

export function* usersSaga() {
  yield takeLatest(GET_USERS_LIST.PENDING, getUsersList);
  yield takeLatest(GET_USER_PROFILE.PENDING, getUserProfile);
  yield takeLatest(BAN_USER.PENDING, banUser);
  yield takeLatest(DELETE_USER.PENDING, deleteUser);
  yield takeLatest(GET_USER_COMMUNITIES.PENDING, getUserCommunities);
  yield takeLatest(GET_USER_SURVEYS.PENDING, getUserSurveys);
  yield takeLatest(GET_USER_TRANSACTIONS.PENDING, getUserTransactions);
  yield takeLatest(CHANGE_USER_PASSWORD.PENDING, changeUserPassword);
  yield takeLatest(CHANGE_USER_PHONE.PENDING, changeUserPhone);
}
