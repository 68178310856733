import React from 'react';
import { ReactNodeLike } from 'prop-types';
import { UniversalSkeleton } from '../../../universal';

import './details-info-pairs.scss';

export interface IDetailsInfoArrItem {
  title: string;
  value: ReactNodeLike;
}

interface IProps {
  wrapperClassName?: string;
  details: IDetailsInfoArrItem[];
  isLoading?: boolean;
}

export const DetailsInfoPairs: React.FC<IProps> = (props) => {
  const { wrapperClassName, details, isLoading } = props;
  return (
    <ul className={`details-info-pairs-cmp ${wrapperClassName}`}>
      {details.map(({ title, value }, i) => {
        const val =
          typeof value === 'object' ? value : <span>{value || '-'}</span>;
        return (
          <li key={i}>
            <span className="label">{title}:</span>
            {isLoading ? (
              <UniversalSkeleton minWidth={'80%'} height={20} />
            ) : (
              val
            )}
          </li>
        );
      })}
    </ul>
  );
};
