import React, { useState } from 'react';

import {
  CategoryHeader,
  SearchBlock,
  IDetailsInfoArrItem,
  DetailsInfoPairs,
} from 'components/common/parts';

import { getGetInTouchTab, makeSelector } from 'utils';
import { IItem, IGetInTouchList, IGetInTouch } from 'interfaces';
import SubscribedList from './tab-content/SubscribedList';
import UnsubscribedList from './tab-content/UnsubscribedList';
import { useFormInModal } from 'hooks';
import { useSelector } from 'react-redux';
import { UniversalModal } from 'components/common/universal';

interface IProps {}

const getInTouchTabs = getGetInTouchTab();

enum Tab {
  SUBSCRIBED = 1,
  UNSUBSCRIBED,
}

const GetIntouchPage: React.FC<IProps> = ({}) => {
  const [currTab, setCurrTab] = useState(getInTouchTabs[0]);

  const onSelectTab = (tab: IItem) => {
    setCurrTab(tab);
  };

  const { data, recordsFiltered } = useSelector<any, IGetInTouchList>(
    makeSelector(['getInTouchReducer', 'getInTouchList'])
  );

  // PROMPTS
  const {
    isPrompt,
    closeEditor,
    openEditor,
    formInitialValues,
  } = useFormInModal<IGetInTouch>(data, '');

  const renderContent = () => {
    switch (currTab.id) {
      case Tab.SUBSCRIBED:
        return <SubscribedList openEditor={openEditor} />;
      case Tab.UNSUBSCRIBED:
        return <UnsubscribedList openEditor={openEditor} />;
      default:
        return null;
    }
  };

  const details: IDetailsInfoArrItem[] = [
    {
      title: 'Email',
      value: formInitialValues.email,
    },
    {
      title: 'First Name',
      value: formInitialValues.firstname,
    },
    {
      title: 'Last Name',
      value: formInitialValues.lastname,
    },
  ];

  return (
    <div className="f-column">
      <CategoryHeader
        catTitle="Stay In Touch Emails"
        catTabs={getInTouchTabs}
        onSelectTab={onSelectTab}
      />

      {renderContent()}

      <UniversalModal
        visible={isPrompt}
        title={'Get in Touch Email Info'}
        onCancel={closeEditor}
        maxWidth="600px"
        className="category-editor-modal"
        promptOkBtn={<></>}
      >
        <div className="form-group">
          <DetailsInfoPairs details={details} />
        </div>
      </UniversalModal>
    </div>
  );
};

export default GetIntouchPage;
