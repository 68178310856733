import React from 'react';

import { UniversalSkeleton } from 'components/common/universal';
// import { CssPrefix } from 'helpers';

interface IProps {
  // cssPrefix: CssPrefix
}

const SurveyDetailsQuestionsSkeleton: React.FC<IProps> = () => {
  return (
    <div className="card">
      <UniversalSkeleton type="paragraph" width={'60%'} rows={5} />
    </div>
  );
};

export default SurveyDetailsQuestionsSkeleton;
