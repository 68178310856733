import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_OWN_PROFILE,
  GET_OWN_SURVEYS,
  GET_OWN_TRANSACTIONS,
  UPD_OWN_PROFILE,
} from 'const';
import {
  encodeDataToUrl,
  httpApi,
  HttpResp,
  makeAction,
  session,
  showMessage,
} from 'utils';
import {
  getOwnProfileAction,
  getOwnSurveysAction,
  getOwnTransactionsAction,
  updOwnProfileAction,
} from 'actions';
import { SurveyItem, SurveysList, Transaction, UserProfile } from 'interfaces';

function* getOwnProfile({
  payload: { userId },
}: ReturnType<typeof getOwnProfileAction>) {
  try {
    const res: HttpResp<UserProfile> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/users/${userId}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_OWN_PROFILE.SUCCESS, res));
    } else {
      yield put(makeAction(GET_OWN_PROFILE.FAILURE, res));
    }
  } catch (error) {}
}

function* updOwnProfile({ payload }: ReturnType<typeof updOwnProfileAction>) {
  const { id } = payload;
  try {
    const res: HttpResp<UserProfile> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/users/${id}`,
      data: payload,
    });
    if (res && !res.error) {
      showMessage.success('Profile updated successfully');
      yield put(getOwnProfileAction(id));
    } else {
    }
  } catch (error) {}
}

function* getOwnTransactions({
  payload,
}: ReturnType<typeof getOwnTransactionsAction>) {
  const id = session.getOwnID();
  try {
    const res: HttpResp<Transaction[]> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/transactions/${id}?${encodeDataToUrl(payload)}`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_OWN_TRANSACTIONS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_OWN_TRANSACTIONS.FAILURE, res));
    }
  } catch (error) {}
}

function* getOwnSurveys({ payload }: ReturnType<typeof getOwnSurveysAction>) {
  // const id = session.getOwnID();
  try {
    const res: HttpResp<SurveysList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/surveys/oct/page?${encodeDataToUrl(payload)}`,
      // partUrl: `/surveys/user/${id}?isDraft=false&${encodeDataToUrl(payload)}`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_OWN_SURVEYS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_OWN_SURVEYS.FAILURE, res));
    }
  } catch (error) {}
}

export function* userSaga() {
  yield takeLatest(GET_OWN_PROFILE.PENDING, getOwnProfile);
  yield takeLatest(UPD_OWN_PROFILE.PENDING, updOwnProfile);
  yield takeLatest(GET_OWN_TRANSACTIONS.PENDING, getOwnTransactions);
  yield takeLatest(GET_OWN_SURVEYS.PENDING, getOwnSurveys);
}
