export interface PointsConfig {
  addModerator: number; //
  becameModerator: number; //
  comment: number; //
  commentDeleted: number; //
  commentDeletedSponsored: number; //
  commentSponsored: number; //
  createCommunity: number; //
  createCommunityExtra: number; //
  createSponsorSurvey: number;
  createSurvey: number; //
  dailyPoits: number; //
  followCommunity: number; //
  joinedYourCommunity: number; //
  octAdminActionsCost: number; //
  officialSurveyPointsCost: number;
  passedYourSurvey: number; //
  shareSurvey: number; //
  shareSurveySponsored: number; //
  sponsoredSurveyPointsCost: number;
  surveyPointsCost: number; //
  youModeratedSurvey: number;
}

export const resetPointsConfigModel = (): PointsConfig => ({
  addModerator: 0,
  becameModerator: 0,
  comment: 0,
  commentDeleted: 0,
  commentDeletedSponsored: 0,
  commentSponsored: 0,
  createCommunity: 0,
  createCommunityExtra: 0,
  createSponsorSurvey: 0,
  createSurvey: 0,
  dailyPoits: 0,
  followCommunity: 0,
  joinedYourCommunity: 0,
  octAdminActionsCost: 0,
  officialSurveyPointsCost: 0,
  passedYourSurvey: 0,
  shareSurvey: 0,
  shareSurveySponsored: 0,
  sponsoredSurveyPointsCost: 0,
  surveyPointsCost: 0,
  youModeratedSurvey: 0,
});
