import React from 'react';
import { useSelector } from 'react-redux';

import { SearchBlock } from 'components/common/parts';
import { dateFn, UniversalTable } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { getPulsesListAction } from 'actions';
import { makeSelector } from 'utils';
import { IUniversalTableParams, SurveyItem, SurveysList } from 'interfaces';
import { FORMAT_TABLE_DATE, SurveysRoute } from 'const';
import { amountFn, humanizeString } from 'helpers';

const tableParams: IUniversalTableParams<SurveyItem> = {
  titles: [
    {
      title: 'ID',
      propName: 'id',
    },
    {
      title: 'Title',
      propName: 'title',
    },
    {
      title: 'Date',
      propName: 'dateCreated',
      formatFn: dateFn(FORMAT_TABLE_DATE),
    },
    {
      title: 'Status',
      propName: 'status',
      formatFn: humanizeString,
    },
    {
      title: 'Passed times',
      propName: 'passedTimes',
      formatFn: amountFn,
      isSortable: false,
    },
    // {
    //   title: 'Comments',
    //   propName: 'comments',
    // },
  ],
  data: [],
  routeConst: SurveysRoute,
};

interface IProps extends SearchableTable {}

const Pulses: React.FC<IProps> = ({
  history,
  getList,
  isLoading,
  queryParams,
}) => {
  const { data, recordsFiltered } = useSelector<any, SurveysList>(
    makeSelector(['surveysReducer', 'pulsesList'])
  );

  const toEditPage = () => {
    history.push(`${SurveysRoute.ADD}/pulse`); // isPulse=true
  };

  return (
    <>
      <SearchBlock
        btnTitle="Add New Pulse"
        onChange={getList}
        onClickBtn={toEditPage}
        initialQuery={queryParams}
      />
      <UniversalTable
        {...tableParams}
        data={data}
        total={recordsFiltered}
        onChange={getList}
        isLoading={isLoading}
        initialQuery={queryParams}
      />
    </>
  );
};

export default SearchableTableContainer({
  getListAction: getPulsesListAction,
  reducerName: 'surveysReducer',
  queryName: 'pulsesQueryParams',
  isEqualSearch: true,
})(Pulses);
