import { Crop } from 'components/common';

export const FORMAT_TABLE_DATE = 'MM/DD/YYYY, hh:mm:ss A';

export enum QueryOrder {
  // ASC = 'asc',
  ASC = '',
  DESC = 'desc',
}

export const QueryLimitItems = [1, 25, 50, 100];
export const QueryLimitCategoriesItems = [1, 3, 9, 18, 36];

export enum QueryLimit {
  MIN = QueryLimitItems[0],
  DEF = QueryLimitItems[1],
  MAX = QueryLimitItems[QueryLimitItems.length - 1],
}

export const QueryDef = {
  LIMIT: QueryLimit.DEF,
  OFFSET: 0,
  SORT: 'id',
  ORDER: QueryOrder.ASC,
};

export enum PhoneChars {
  NUMBER = 10,
  CODE_WITH_PLUS = 2,
  CODE_WITHOUT_PLUS = 1,
}

export const avaCropParams: Crop = {
  aspect: 1,
  height: 90,
  width: 90,
  unit: '%',
  x: 10,
  y: 10,
};

export const ZIP_CODE_LIMIT = 5;
export const PASSWORD_LIMIT = 16;
export const BUZZ_TITLE_LIMIT = 70;
export const URL_LIMIT = 250;
export const BUZZ_DESCRIPTION_LIMIT = 280;
export const LARGE_TITLE_LIMIT = 45;
export const SMALL_TITLE_LIMIT = 30;
export const DESCRIPTION_LIMIT = 500;
export const QUESTION_LIMIT = 120;
export const EMAIL_LIMIT = 42;
export const NITIFICATION_LIMIT = 50;
