import { MEDIA_TABLET_POINTS } from 'const';
import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';

export const useMobile = (maxWidth: number = MEDIA_TABLET_POINTS) => {
  const { width } = useWindowSize();

  const [isMobile, toggleMobile] = useState(window.innerWidth <= maxWidth);

  useEffect(() => {
    if (window.innerWidth <= maxWidth && !isMobile) {
      toggleMobile(true);
    } else if (window.innerWidth > maxWidth && isMobile) {
      toggleMobile(false);
    }
  }, [width]);

  return isMobile;
};
