import React, { useState } from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';

import { CategoryHeader } from 'components/common/parts';
import { IItem } from 'interfaces';
import { SurveysChildrenRouter } from 'routes/childRoutes/SurveysRouter';
import { SurveysRoute } from 'const';

export const surveyCategoryTabs: IItem[] = [
  { id: SurveysRoute.ROOT.children.OFFICAL, title: 'Official Surveys' },
  { id: SurveysRoute.ROOT.children.USER, title: 'User Surveys' },
  { id: SurveysRoute.ROOT.children.PULSES, title: 'Pulses' },
];

interface IProps extends RouteComponentProps {}

const SurveysPage: React.FC<IProps> = ({ location, history }) => {
  const onSelectTab = (tab: IItem) => {
    history.replace(tab.id as string);
  };

  const setDefaultTab = () => {
    return surveyCategoryTabs.find((t) => t.id === location.pathname);
  };

  return (
    <div className="f-column surveys-page">
      <CategoryHeader
        catTitle="Surveys"
        catTabs={surveyCategoryTabs}
        onSelectTab={onSelectTab}
        defaultTab={setDefaultTab()}
      />

      {/* CONTENT */}
      {SurveysChildrenRouter()}
    </div>
  );
};

export default SurveysPage;
