import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import { FormTextInput } from 'components/common/form';
import { validateRequired } from 'utils';
import { REJECT_SURVEY_FORM, DESCRIPTION_LIMIT } from 'const';
import { cutToSize } from 'helpers';

interface IProps extends InjectedFormProps {}

const RejectSurveyForm: any = (props: IProps) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className="category-edit-form">
      <Field
        name="description"
        type="textarea"
        label="Please add a description of the rejection"
        placeholder="Enter a reason"
        component={FormTextInput}
        validate={validateRequired}
        normalize={cutToSize(DESCRIPTION_LIMIT)}
        max={DESCRIPTION_LIMIT}
      />
    </form>
  );
};

export default reduxForm({
  form: REJECT_SURVEY_FORM,
  enableReinitialize: true,
})(RejectSurveyForm);
