import React from 'react';

import './universal-skeleton.scss';

type SkeletonType =
  | 'string'
  | 'title'
  | 'paragraph'
  | 'img'
  | 'avatar'
  | 'button';

enum Type {
  STRING = 'string',
  TITLE = 'title',
  IMG = 'img',
  PARAGRAPH = 'paragraph',
  BUTTON = 'button',
}

interface IProps {
  type?: SkeletonType;
  className?: string;
  height?: number | string;
  width?: number | string;
  minWidth?: number | string;
  rows?: number;
}

export const UniversalSkeleton: React.FC<IProps> = (props) => {
  const {
    type = 'string',
    className = '',
    height,
    width,
    minWidth,
    rows,
  } = props;

  const classNameStr = `skeleton skeleton-${type} ${className}`;

  const renderDefault = () => (
    <div className={classNameStr} style={{ height, width, minWidth }}>
      &zwnj;
    </div>
  );

  switch (type) {
    case Type.TITLE:
      return (
        <h3 className={classNameStr} style={{ width }}>
          &zwnj;
        </h3>
      );
    case Type.PARAGRAPH:
      return rows ? (
        <div style={{ width }}>
          {[...new Array(rows)].map((it, i) => (
            <p key={i} className={classNameStr}>
              &zwnj;
            </p>
          ))}
        </div>
      ) : (
        renderDefault()
      );
    case Type.STRING:
      return (
        <span className={classNameStr} style={{ height, width, minWidth }}>
          &zwnj;
        </span>
      );
    case Type.IMG:
    case Type.BUTTON:
    default:
      return renderDefault();
  }
};
