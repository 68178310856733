import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchBlock, IDetailsInfoArrItem } from 'components/common/parts';
import { UniversalButton, UniversalTable } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { makeSelector } from 'utils';
import { getRequestedListAction, markRequestSolvedAction } from 'actions';
import {
  ContactRequested,
  RequestedList,
  ITitle,
  UniversalTableItem,
} from 'interfaces';
import { contactRequestTableParams } from './contact-request-table-params';

interface IProps {
  openEditor({ id }: { id: UniversalTableItem['id'] }): () => void;
}

const RequestedListCmp: React.FC<IProps & SearchableTable> = ({
  getList,
  isLoading,
  queryParams,
  openEditor,
}) => {
  const dispatch = useDispatch();
  const { data, recordsFiltered } = useSelector<any, RequestedList>(
    makeSelector(['contactRequestReducer', 'requestedList'])
  );

  const markSolved = (id: number) => () => {
    dispatch(markRequestSolvedAction({ id, queryParams }));
  };

  const actionFn = (id: number) => (
    <UniversalButton
      btnTitle="Mark Solved"
      styleType="success"
      onClick={markSolved(id)}
    />
  );

  const handleSelectText = (id: UniversalTableItem['id']) => {
    openEditor({ id })();
  };

  const tableCeil: ITitle<keyof ContactRequested> = {
    title: 'Action',
    propName: 'id',
    className: 'action',
    isSortable: false,
    formatFn: actionFn,
    canRedirect: false,
  };

  return (
    <>
      <SearchBlock onChange={getList} initialQuery={queryParams} />
      <UniversalTable
        titles={[...contactRequestTableParams.titles, tableCeil]}
        data={data}
        onChange={getList}
        total={recordsFiltered}
        isLoading={isLoading}
        initialQuery={queryParams}
        onRowClick={handleSelectText}
      />
    </>
  );
};

export default SearchableTableContainer<IProps>({
  getListAction: getRequestedListAction,
  reducerName: 'contactRequestReducer',
  queryName: 'queryParams',
  isEqualSearch: true,
})(RequestedListCmp);
