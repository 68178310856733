import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import {
  SIGN_IN,
  FORGOT_PASSWORD,
  UPDATE_RECOVERY_PASSWORD_ACTION,
} from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';

const { PENDING, DONE } = makeCombinedAction(SIGN_IN, FORGOT_PASSWORD);

const initialState = Immutable.fromJS({
  isLoading: false,
  recoveryPassword: {
    phoneNumber: null,
  },
});

const authReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [UPDATE_RECOVERY_PASSWORD_ACTION]: (state, { payload }) => {
      return state.set('recoveryPassword', Immutable.fromJS(payload));
    },
  },
  initialState
);

export default authReducer;
