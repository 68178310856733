import React from 'react';
import { useSelector } from 'react-redux';

import { CategoryHeader, SearchBlock } from 'components/common/parts';
import { dateFn, UniversalTable } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { BuzzList, IUniversalTableParams } from 'interfaces';
import { makeSelector } from 'utils';
import { BuzzRoute, FORMAT_TABLE_DATE } from 'const';
import { getBuzzListAction } from 'actions';

const tableParams: IUniversalTableParams = {
  titles: [
    {
      title: 'ID',
      propName: 'id',
    },
    {
      title: 'Title',
      propName: 'title',
    },
    {
      title: 'Date',
      propName: 'dateCreated',
      formatFn: dateFn(FORMAT_TABLE_DATE),
    },
    {
      title: 'URL',
      propName: 'webURL',
    },
    {
      title: 'Description',
      propName: 'description',
    },
  ],
  data: [],
  routeConst: BuzzRoute,
};

interface IProps extends SearchableTable {}

const BuzzPage: React.FC<IProps> = ({
  history,
  getList,
  isLoading,
  queryParams,
}) => {
  const { data, recordsFiltered } = useSelector<any, BuzzList>(
    makeSelector(['buzzReducer', 'buzzList'])
  );

  const toEditPage = () => {
    history.push(BuzzRoute.ADD);
  };

  return (
    <div className="f-column buzz-page">
      <CategoryHeader catTitle="The Buzz!" />

      <SearchBlock
        btnTitle="Add The Buzz!"
        onClickBtn={toEditPage}
        onChange={getList}
        initialQuery={queryParams}
      />

      <UniversalTable
        {...tableParams}
        data={data}
        total={recordsFiltered}
        onChange={getList}
        isLoading={isLoading}
        initialQuery={queryParams}
      />
    </div>
  );
};

export default SearchableTableContainer({
  getListAction: getBuzzListAction,
  reducerName: 'buzzReducer',
})(BuzzPage);
