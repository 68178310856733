import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SearchBlock } from 'components/common/parts';
import { UniversalTable, UniversalButton } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { makeSelector } from 'utils';
import { getUnsubscribedListAction, deleteSubscribeAction } from 'actions';
import {
  IGetInTouchList,
  ITitle,
  IGetInTouch,
  UniversalTableItem,
} from 'interfaces';
import { getInTouchTableParams } from 'assets/static-data';

interface IProps {
  openEditor({ id }: { id: UniversalTableItem['id'] }): () => void;
}

const UnsubscribedList: React.FC<IProps & SearchableTable> = ({
  getList,
  openEditor,
  isLoading,
  queryParams,
}) => {
  const dispatch = useDispatch();
  const { data, recordsFiltered } = useSelector<any, IGetInTouchList>(
    makeSelector(['getInTouchReducer', 'getInTouchList'])
  );

  const handleDelete = (id: number) => () => {
    dispatch(deleteSubscribeAction({ id, type: 'unsubscribe' }));
  };

  const actionFn = (id: number) => (
    <UniversalButton
      btnTitle="Delete"
      styleType="error"
      onClick={handleDelete(id)}
    />
  );

  const handleSelectText = (id: UniversalTableItem['id']) => {
    openEditor({ id })();
  };

  const tableCeil: ITitle<keyof IGetInTouch> = {
    title: 'Action',
    propName: 'id',
    className: 'action',
    isSortable: false,
    formatFn: actionFn,
    canRedirect: false,
  };

  return (
    <>
      <SearchBlock onChange={getList} initialQuery={queryParams} />

      <UniversalTable
        {...getInTouchTableParams}
        titles={[...getInTouchTableParams.titles, tableCeil]}
        data={data}
        total={recordsFiltered}
        onChange={getList}
        isLoading={isLoading}
        initialQuery={queryParams}
        onRowClick={handleSelectText}
      />
    </>
  );
};

export default SearchableTableContainer<IProps>({
  getListAction: getUnsubscribedListAction,
  reducerName: 'getInTouchReducer',
  queryName: 'getInTouchQueryParams',
})(UnsubscribedList);
