import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import store from 'config/store';
import AppRouter from 'routes/AppRouter';

import 'scss/main.scss';
import history from 'config/history';

const domNode = document.getElementById('root') as Element;
const root = createRoot(domNode);

root.render(
  <Provider store={store}>
    <Router history={history}>
      <AppRouter />
    </Router>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// for hot reloading
// if (module.hot) module.hot.accept();
