import React from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import './universal-tooltip.scss';
import { UniversalCmpStyleType } from '..';

interface IProps {
  children: React.ReactNode;
  title: React.ReactNode;
  styleType?: UniversalCmpStyleType;
  placement?: TooltipPlacement;
}

export const UniversalTooltip = (props: IProps) => {
  const { children, title, styleType = 'primary', placement } = props;

  return (
    <Tooltip
      placement={placement}
      title={title}
      overlayClassName={`c-tooltip-${styleType}`}
    >
      {children}
    </Tooltip>
  );
};
