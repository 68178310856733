import {
  GET_SUBSCRIBED_LIST_ACTION,
  GET_UNSUBSCRIBED_LIST_ACTION,
  UNSUBSCRIBE_ACTION,
  DELETE_UNSUBSCRIBED_ACTION,
} from 'const';
import { IActionFn, QueryParams } from 'interfaces';

export const getSubscribedListAction: IActionFn<QueryParams> = (payload) => ({
  payload,
  type: GET_SUBSCRIBED_LIST_ACTION.PENDING,
});

export const getUnsubscribedListAction: IActionFn<QueryParams> = (payload) => ({
  payload,
  type: GET_UNSUBSCRIBED_LIST_ACTION.PENDING,
});

export const unsubscribeAction: IActionFn<number> = (payload) => ({
  payload,
  type: UNSUBSCRIBE_ACTION.PENDING,
});

export const deleteSubscribeAction: IActionFn<{
  id: number;
  type: 'subscribe' | 'unsubscribe';
}> = (payload) => ({
  payload,
  type: DELETE_UNSUBSCRIBED_ACTION.PENDING,
});
