import { call, put, takeLatest } from 'redux-saga/effects';

import {
  addPaymentPlanAction,
  deletePaymentPlanAction,
  editPaymentPlanAction,
  getPaymentPlansAction,
} from 'actions';
import {
  ADD_PAYMENT_PLAN,
  DELETE_PAYMENT_PLAN,
  EDIT_PAYMENT_PLAN,
  GET_PAYMENT_PLANS,
} from 'const';
import { httpApi, HttpResp, makeAction, showMessage } from 'utils';
import { PaymentPlan, PaymentPlans } from 'interfaces';

function* getPaymentPlans({}: ReturnType<typeof getPaymentPlansAction>) {
  try {
    const res: HttpResp<PaymentPlans> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/plans`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_PAYMENT_PLANS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_PAYMENT_PLANS.FAILURE, res));
    }
  } catch (error) {}
}

function* editPaymentPlan({
  payload,
}: ReturnType<typeof editPaymentPlanAction>) {
  try {
    const res: HttpResp<PaymentPlan> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/plans`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(makeAction(EDIT_PAYMENT_PLAN.SUCCESS, res));
      showMessage.success('Updated successfully');
      yield put(getPaymentPlansAction(null));
    } else {
      yield put(makeAction(EDIT_PAYMENT_PLAN.FAILURE, res));
    }
  } catch (error) {}
}

function* addPaymentPlan({ payload }: ReturnType<typeof addPaymentPlanAction>) {
  try {
    const res: HttpResp<PaymentPlan> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/plans`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(makeAction(ADD_PAYMENT_PLAN.SUCCESS, res));
      showMessage.success('Added successfully');
      yield put(getPaymentPlansAction(null));
    } else {
      yield put(makeAction(ADD_PAYMENT_PLAN.FAILURE, res));
    }
  } catch (error) {}
}

function* deletePaymentPlan({
  payload,
}: ReturnType<typeof deletePaymentPlanAction>) {
  try {
    const res: HttpResp<PaymentPlan> = yield call(httpApi, {
      method: 'DELETE',
      partUrl: `/plans/${payload}`,
    });
    if (res && !res.error) {
      yield put(makeAction(DELETE_PAYMENT_PLAN.SUCCESS, res));
      showMessage.success('Deleted successfully');
      yield put(getPaymentPlansAction(null));
    } else {
      yield put(makeAction(DELETE_PAYMENT_PLAN.FAILURE, res));
    }
  } catch (error) {}
}

export function* paymentSaga() {
  yield takeLatest(GET_PAYMENT_PLANS.PENDING, getPaymentPlans);
  yield takeLatest(EDIT_PAYMENT_PLAN.PENDING, editPaymentPlan);
  yield takeLatest(ADD_PAYMENT_PLAN.PENDING, addPaymentPlan);
  yield takeLatest(DELETE_PAYMENT_PLAN.PENDING, deletePaymentPlan);
}
