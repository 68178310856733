import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound from 'components/not-found/NotFound';
import ContactRequestsPage from 'components/ContactRequests/ContactRequestsPage';
import { ContactRequestsRoute } from 'const';

const ContactRequestsRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={ContactRequestsRoute.ROOT}
        component={ContactRequestsPage}
      />

      <Route component={NotFound} />
    </Switch>
  );
};

export default ContactRequestsRouter;
