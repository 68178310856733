import { PaginationWrapper } from '../common';

export interface Buzz {
  dateCreated: number;
  description: string;
  id: number;
  image: string;
  title: string;
  webURL: string;
  pdf: string | null;
}

export interface BuzzList extends PaginationWrapper {
  data: Buzz[];
}

export const resetBuzzModel = (): Buzz => ({
  dateCreated: 0,
  description: '',
  id: 0,
  image: '',
  title: '',
  webURL: '',
  pdf: null,
});

export const resetBuzzListModel = (): BuzzList => ({
  data: [],
  recordsFiltered: 0,
  recordsTotal: 0,
});
