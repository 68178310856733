import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CategoryHeader, SearchBlock } from 'components/common/parts';
import {
  UniversalTable,
  UniversalModal,
  makeBtn,
} from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import {
  getUsersListAction,
  editCategoryAction,
  sendNotificationAllAction,
} from 'actions';
import { makeSelector } from 'utils';
import { UsersList, IImmutableMap, INotificationAll } from 'interfaces';
import { usersTableParams } from 'assets/static-data';
import { useFormInModal } from 'hooks';
import UserNotificationForm from './UserNotificationForm';
import { checkIcon, bellIcon } from 'components/common';
import { submit } from 'redux-form';
import { NOTIFICATION_ALL_FORM } from 'const';

interface IProps extends SearchableTable {}

const UsersPage: React.FC<IProps> = ({ getList, isLoading, queryParams }) => {
  const dispatch = useDispatch();

  const { data, recordsFiltered } = useSelector<any, UsersList>(
    makeSelector(['usersReducer', 'usersList'])
  );

  // PROMPTS
  const {
    promptTitle,
    btnTitle,
    formInitialValues,
    isPrompt,
    closeEditor,
    openEditor,
  } = useFormInModal([{ notification: '' }], 'Notification');

  const remoteSubmit = () => {
    dispatch(submit(NOTIFICATION_ALL_FORM));
  };

  const onSubmit = (formData: IImmutableMap<INotificationAll> | any) => {
    const message: INotificationAll = formData.toJS();

    dispatch(sendNotificationAllAction(message, closeEditor));
  };

  return (
    <div className="f-column">
      <CategoryHeader catTitle="Users" />

      <SearchBlock
        onChange={getList}
        initialQuery={queryParams}
        btnTitle="Notify everyone"
        btnIco={bellIcon}
        onClickBtn={openEditor()}
      />
      <UniversalTable
        {...usersTableParams}
        data={data}
        total={recordsFiltered}
        onChange={getList}
        isLoading={isLoading}
        initialQuery={queryParams}
      />

      <UniversalModal
        visible={isPrompt}
        title={promptTitle}
        onCancel={closeEditor}
        promptOkBtn={makeBtn(btnTitle, checkIcon, 'success', remoteSubmit)}
        maxWidth="600px"
        className="category-editor-modal"
      >
        <UserNotificationForm
          initialValues={formInitialValues}
          onSubmit={onSubmit}
        />
      </UniversalModal>
    </div>
  );
};

export default SearchableTableContainer({
  getListAction: getUsersListAction,
  reducerName: 'usersReducer',
  queryName: 'usersQueryParams',
})(UsersPage);
