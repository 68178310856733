import { ContactRequested, IUniversalTableParams } from 'interfaces';

export const contactRequestTableParams: IUniversalTableParams<ContactRequested> = {
  titles: [
    {
      title: 'ID',
      propName: 'id',
    },
    {
      title: 'Email',
      propName: 'email',
    },
    {
      title: 'Request',
      propName: 'description',
    },
  ],
  data: [],
};
