import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound from 'components/not-found/NotFound';
import PointsPage from 'components/Points/PointsPage';
import { PointsRoute } from 'const';

const PointsRouter = () => {
  return (
    <Switch>
      <Route exact path={PointsRoute.ROOT} component={PointsPage} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default PointsRouter;
