import React from 'react';
import { Route, Switch } from 'react-router';

import {
  ForgotPassForm,
  LoginForm,
  ForgotPassCongrats,
  ResetPassForm,
} from 'components/Auth/screens';
import { AuthRoute } from 'const';

const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path={AuthRoute.ROOT} component={LoginForm} />
      <Route
        exact
        path={AuthRoute.FORGOT_PASSWORD}
        component={ForgotPassForm}
      />
      <Route
        exact
        path={AuthRoute.FORGOT_PASSWORD_CONGRATS}
        component={ForgotPassCongrats}
      />
      <Route exact path={AuthRoute.RESET_PASSWORD} component={ResetPassForm} />
    </Switch>
  );
};

export default AuthRouter;
