import { storageSvc } from './local-storage';
import { jwt } from './jwt';
import { Local } from 'config';
import { ISignInResp } from 'interfaces';

class AuthSessionService {
  private _expiredDate = 0;

  constructor() {
    this.saveExpiredDate();
  }

  private saveExpiredDate(token?: string) {
    const access_token = token || storageSvc.getItem(Local.TOKEN);
    if (access_token) {
      const details = jwt.getDetails(access_token);
      this._expiredDate = details ? details.exp * 1000 : 0;
    }
  }

  isRegistered() {
    return !!this.getToken();
  }

  saveData(
    { access_token, refresh_token, user_name }: ISignInResp,
    rememberMe?: boolean
  ): void {
    const isStayLogged =
      rememberMe || storageSvc.getItem(Local.TOKEN, localStorage);
    this.clearData();

    const storage = isStayLogged ? localStorage : sessionStorage;
    storageSvc.setItem(Local.TOKEN, access_token, storage);
    storageSvc.setItem(Local.REFRESH_TOKEN, refresh_token, storage);
    storageSvc.setItem(Local.USER, user_name.username, storage); // user ID

    this.saveExpiredDate(access_token);
  }

  isExpired(): boolean {
    return (
      this.isRegistered() &&
      this._expiredDate > 0 &&
      this._expiredDate < +new Date()
    );
  }

  getToken(): string | null {
    return storageSvc.getItem(Local.TOKEN);
  }

  getRefreshToken(): string | null {
    return storageSvc.getItem(Local.REFRESH_TOKEN);
  }

  getOwnID(): string {
    return storageSvc.getItem(Local.USER) || '';
  }

  clearData() {
    storageSvc.removeItem(Local.TOKEN);
    storageSvc.removeItem(Local.REFRESH_TOKEN);
    storageSvc.removeItem(Local.USER);
  }
}

export const session = new AuthSessionService();
