import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { UserProfile } from 'interfaces';

import { submit, change } from 'redux-form';
import { CHANGE_PHONE_FORM } from 'const';
import ChangePhoneForm from './ChangePhoneForm';
import { changeUserPhoneAction } from 'actions';

interface IProps {
  profile: UserProfile;
}

const ChangePhone: React.FC<IProps> = ({ profile }) => {
  const dispatch = useDispatch();
  const [isPrompt, setPromptState] = useState(false);

  const { id } = profile;

  const remoteSubmit = () => {
    dispatch(submit(CHANGE_PHONE_FORM));
  };

  const onSubmit = (formData: any) => {
    const { newPhoneNumber: phoneNumber, smsCode } = formData.toJS();
    const req = {
      id,
      smsCode,
      phoneNumber,
      resendSMS: !smsCode ? true : false,
    };

    dispatch(changeUserPhoneAction(req, togglePrompt));
  };

  const togglePrompt = () => {
    dispatch(change(CHANGE_PHONE_FORM, 'smsCode', ''));
    setPromptState(!isPrompt);
  };

  return (
    <div className="change-pass-form">
      <ChangePhoneForm
        onSubmit={onSubmit}
        remoteSubmit={remoteSubmit}
        isPrompt={isPrompt}
        togglePrompt={togglePrompt}
      />
    </div>
  );
};

export default ChangePhone;
