import React from 'react';
import Chart from 'react-apexcharts';

import { Colors } from 'const';
import { cssPrefixCreator, formatAmount } from 'helpers';

import './circle-chart.scss';
import { IChartParams, IStatisticChartParams } from 'interfaces';

const defaultChartOptions: IChartParams['options'] = {
  labels: ['Apples', 'Oranges', 'Bananas'],
  // lines color
  fill: {
    colors: [Colors.SUCCESS, Colors.WARNING, Colors.ERROR],
  },
  // series colors(labels/tooltip)
  colors: [Colors.SUCCESS, Colors.WARNING, Colors.ERROR],
  legend: {
    show: false,
  },
  theme: {
    mode: 'light',
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: true,
    marker: {
      show: true,
    },
  },

  plotOptions: {
    radialBar: {
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
      },
      track: {
        show: true,
      },
    },
    pie: {
      expandOnClick: false,
      dataLabels: false, // percents
      // inner circle
      donut: {
        size: '85%',
        labels: {
          show: false, // inner total
        },
      },
    },
  },
};

const normalizeSeries = (series: number[]) => {
  return {
    normalizedSeries: series,
    tooltipTitleFormatter: (val: number, rest: any) => {
      const series = rest.series || rest.config.series;

      if (series === undefined) return 0;

      const sum = series.reduce((res: number, val: number) => res + val, 0);
      const sumPercents = 100;

      if (sum === 0) return 0;
      const percent = Math.round((val * sumPercents) / sum);
      return `${val} (${percent} %)`;
    },
  };
};

const CircleChart = (props: IStatisticChartParams) => {
  const { series, options, type, title } = props;

  // Note: Chart render values in percents.
  // So we should normalize series for part from 100%
  const { normalizedSeries, tooltipTitleFormatter } = normalizeSeries(series);

  const chartParams: IChartParams = {
    options: {
      ...defaultChartOptions,
      ...options,
      // tslint:disable-next-line:no-object-literal-type-assertion
      tooltip: {
        ...defaultChartOptions.tooltip,
        // because we use
        y: { formatter: tooltipTitleFormatter },
      } as IChartParams['options']['tooltip'],
    },
    series: normalizedSeries,
  };
  const {
    labels,
    fill: { colors },
  } = options;

  const total = formatAmount(series.reduce((acc, val) => acc + val, 0));

  const width = type === 'donut' ? '160' : '160';
  const height = type === 'donut' ? '233' : '250';

  const cssPrefix = cssPrefixCreator('circle-chart-cmp');
  return (
    <div className={cssPrefix(' f-c-s')} style={{ maxHeight: '160px' }}>
      {/* CHART */}

      <div className={cssPrefix('chart')}>
        <Chart {...chartParams} type={type} />
      </div>

      <div className={cssPrefix('details f-column')}>
        {/* TITLE */}
        {title ? (
          <div className={cssPrefix('title')}>
            <h3>{total}</h3>
            <span className="label">{title}</span>
          </div>
        ) : null}

        {/* LEGEND */}
        <div className={cssPrefix('legend f-c-s f-wrap')}>
          {series.map((val, i) => {
            return (
              <div className="f-c-c" key={i}>
                <span
                  className="legend-color"
                  style={{ background: colors[i] }}
                />
                <div className={cssPrefix('legend-values ')}>
                  <p>{formatAmount(val)}</p>
                  <span className="label">{labels[i]}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CircleChart;
