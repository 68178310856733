import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import { FormTextInput } from 'components/common/form';
import { UniversalButton } from 'components/common/universal';
import { RESET_PASS_FORM, PASSWORD_LIMIT } from 'const';
import { IAuthContainer } from 'interfaces';
import {
  validateRecoveryPasswordFormCreator,
  validatePassword,
  validatePhoneNumber,
  validatePasscode,
} from 'utils';
import { AuthContainer } from 'containers';
import { cutToSize, formatPhoneNumber, formatPasscode } from 'helpers';
import { lockIcon, userFilledIcon } from 'components/common';
import { MobileFilled } from '@ant-design/icons/lib/icons';

interface IProps extends InjectedFormProps, IAuthContainer {}

const ResetPassForm: any = (props: IProps) => {
  const {
    handleSubmit,
    resetPass,
    recoveryPssword: { phoneNumber },
  } = props;

  const hasPhoneNumber = Boolean(phoneNumber);

  const onSubmit = (formData: any) => {
    const {
      newPassword,
      oneTimePassword,
      phoneNumber: localNumber,
    } = formData.toJS();

    const phone = hasPhoneNumber ? phoneNumber : localNumber;

    resetPass({ newPassword, oneTimePassword, phoneNumber: phone });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="forgot-pass-form">
      <h2>Reset your Password</h2>

      {!hasPhoneNumber && (
        <Field
          name="phoneNumber"
          placeholder="Phone Number"
          prefixIcon={userFilledIcon}
          component={FormTextInput}
          validate={validatePhoneNumber}
          normalize={formatPhoneNumber}
        />
      )}

      <Field
        name="newPassword"
        type="password"
        placeholder="New Password"
        prefixIcon={lockIcon}
        component={FormTextInput}
        validate={validatePassword}
        normalize={cutToSize(PASSWORD_LIMIT)}
      />

      <Field
        name="confirmPassword"
        type="password"
        placeholder="Confirm Password"
        prefixIcon={lockIcon}
        component={FormTextInput}
        validate={validatePassword}
        normalize={cutToSize(PASSWORD_LIMIT)}
      />

      <Field
        name="oneTimePassword"
        type="text"
        placeholder="Passcode"
        prefixIcon={MobileFilled}
        theme="outlined"
        component={FormTextInput}
        validate={validatePasscode}
        normalize={formatPasscode}
      />

      <UniversalButton block htmlType="submit" btnTitle="Reset" />
    </form>
  );
};

const validateForm = validateRecoveryPasswordFormCreator(
  'newPassword',
  'confirmPassword'
);

export default AuthContainer(
  reduxForm({
    form: RESET_PASS_FORM,
    validate: validateForm,
  })(ResetPassForm)
);
