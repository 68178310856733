import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import {
  FormTextInput,
  FormUploadImage,
  FormUploadFile,
} from 'components/common/form';
import {
  BUZZ_EDIT_FORM,
  BUZZ_TITLE_LIMIT,
  URL_LIMIT,
  BUZZ_DESCRIPTION_LIMIT,
} from 'const';
import { validateRequired, validateFormWithPdfAndUrl } from 'utils';
import { cutToSize } from 'helpers';

interface IProps extends InjectedFormProps {}

const BuzzEditForm: any = (props: IProps) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className="buzz-edit-form">
      <div className="flex">
        <div className="buzz-edit-form-inputs">
          <Field
            name="title"
            label="Title"
            placeholder="Enter Title"
            component={FormTextInput}
            validate={validateRequired}
            normalize={cutToSize(BUZZ_TITLE_LIMIT)}
            max={BUZZ_TITLE_LIMIT}
          />

          <Field
            name="webURL"
            label="Web URL"
            placeholder="Enter URL"
            component={FormTextInput}
            normalize={cutToSize(URL_LIMIT)}
            max={URL_LIMIT}
          />
        </div>

        <div className="buzz-edit-form-description">
          <Field
            name="description"
            label="Description"
            type="textarea"
            placeholder="Enter Description"
            component={FormTextInput}
            validate={validateRequired}
            normalize={cutToSize(BUZZ_DESCRIPTION_LIMIT)}
            max={BUZZ_DESCRIPTION_LIMIT}
          />
        </div>
      </div>

      <Field
        name="pdf"
        label="Reference PDF"
        accept="application/pdf"
        component={FormUploadFile}
      />

      <Field
        name="image"
        label="Cover Image"
        component={FormUploadImage}
        className="buzz-edit-form-upload-file"
        validate={validateRequired}
      />
    </form>
  );
};

const validator = validateFormWithPdfAndUrl('webURL', 'pdf');

export default reduxForm({
  form: BUZZ_EDIT_FORM,
  enableReinitialize: true,
  validate: validator,
})(BuzzEditForm);
