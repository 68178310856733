import { put, takeLatest } from 'redux-saga/effects';
import {
  GET_SURVEY_LIST,
  GET_OWN_SURVEYS,
  GET_PULSES_LIST,
  UPDATE_SURVEY_PARAMS,
  GET_COMMUNITY_FOLLOWERS,
  GET_COMMUNITY_SURVEYS,
  GET_COMMUNITY_MODERATORS,
  UPDATE_COMMUNITY_PARAMS,
  UPDATE_USER_PARAMS,
  GET_USER_COMMUNITIES,
  GET_USER_SURVEYS,
  GET_USER_TRANSACTIONS,
  UPDATE_CONTACT_REQUESTS_PARAMS,
  GET_REQUESTED_LIST,
  GET_REQUESTED_SOLVED_LIST,
} from 'const';

import { makeAction } from 'utils';
import { QueryParams } from 'interfaces';

interface ISearchPayload {
  type: string;
  payload: QueryParams;
}

enum SearchTypes {
  SURVEY,
  COMMUNITY,
  USER,
  CONTACT_REQUESTS,
}

function updateSearch(type: SearchTypes) {
  return function* ({ payload }: ISearchPayload) {
    if (payload.searchName) {
      if (type === SearchTypes.SURVEY) {
        yield put(makeAction(UPDATE_SURVEY_PARAMS, payload));
      }
      if (type === SearchTypes.COMMUNITY) {
        yield put(makeAction(UPDATE_COMMUNITY_PARAMS, payload));
      }
      if (type === SearchTypes.USER) {
        yield put(makeAction(UPDATE_USER_PARAMS, payload));
      }
      if (type === SearchTypes.CONTACT_REQUESTS) {
        yield put(makeAction(UPDATE_CONTACT_REQUESTS_PARAMS, payload));
      }
    }
  };
}

export function* queryParamsSaga() {
  yield takeLatest(
    [GET_SURVEY_LIST.PENDING, GET_OWN_SURVEYS.PENDING, GET_PULSES_LIST.PENDING],
    updateSearch(SearchTypes.SURVEY)
  );
  yield takeLatest(
    [
      GET_COMMUNITY_SURVEYS.PENDING,
      GET_COMMUNITY_FOLLOWERS.PENDING,
      GET_COMMUNITY_MODERATORS.PENDING,
    ],
    updateSearch(SearchTypes.COMMUNITY)
  );
  yield takeLatest(
    [
      GET_USER_COMMUNITIES.PENDING,
      GET_USER_SURVEYS.PENDING,
      GET_USER_TRANSACTIONS.PENDING,
    ],
    updateSearch(SearchTypes.USER)
  );
  yield takeLatest(
    [GET_REQUESTED_LIST.PENDING, GET_REQUESTED_SOLVED_LIST.PENDING],
    updateSearch(SearchTypes.CONTACT_REQUESTS)
  );
}
