import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import { GET_PAYMENT_PLANS } from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';
import { PaymentPlans, resetPaymentPlansModel } from 'interfaces';

interface PaymentPlansState {
  isLoading: boolean;
  plans: PaymentPlans;
}

const initialState = Immutable.fromJS({
  isLoading: false,
  plans: resetPaymentPlansModel(),
} as PaymentPlansState);

const { PENDING, DONE } = makeCombinedAction(GET_PAYMENT_PLANS);

const paymentReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [GET_PAYMENT_PLANS.SUCCESS]: (state, { payload }) => {
      return state.set('plans', Immutable.fromJS(payload));
    },
  },
  initialState
);

export default paymentReducer;
