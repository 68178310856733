import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';

import authReducer from './authReducer';
import appReducer from './appReducer';
import ownDataReducer from './ownDataReducer';
import buzzReducer from './buzzReducer';
import surveysReducer from './surveysReducer';
import categoriesReducer from './categoriesReducer';
import communityReducer from './communityReducer';
import usersReducer from './usersReducer';
import contactRequestReducer from './contactRequestReducer';
import pointsReducer from './pointsReducer';
import offensiveWordsReducer from './offensiveWordsReducer';
import paymentReducer from './paymentReducer';
import dashboardReducer from './dashboardReducer';
import notificationReducer from './notificationReducer';
import getInTouchReducer from './getInTouchReducer';
import { SIGN_OUT } from 'const';

const mainAppReducer = combineReducers({
  form: formReducer,
  authReducer,
  appReducer,
  ownDataReducer,
  buzzReducer,
  surveysReducer,
  categoriesReducer,
  communityReducer,
  usersReducer,
  contactRequestReducer,
  pointsReducer,
  offensiveWordsReducer,
  paymentReducer,
  dashboardReducer,
  notificationReducer,
  getInTouchReducer,
});

const rootReducer = (state: any, action: any) => {
  // Clear all data in redux store to initial.
  if (action.type === SIGN_OUT.SUCCESS) state = undefined;

  return mainAppReducer(state, action);
};

export default rootReducer;

export * from './authReducer';
export * from './appReducer';
export * from './ownDataReducer';
export * from './buzzReducer';
export * from './surveysReducer';
export * from './categoriesReducer';
export * from './communityReducer';
export * from './usersReducer';
export * from './contactRequestReducer';
export * from './pointsReducer';
export * from './offensiveWordsReducer';
export * from './paymentReducer';
export * from './dashboardReducer';
export * from './notificationReducer';
export * from './getInTouchReducer';
