import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';

import { FormTextInput } from 'components/common/form';
import {
  UniversalButton,
  UniversalModal,
  makeBtn,
} from 'components/common/universal';
import { checkIcon } from 'components/common';
import { CHANGE_PHONE_FORM } from 'const';
import { validatePhoneNumber, validatePasscode } from 'utils';
import { formatPhoneNumber, formatPasscode } from 'helpers';
import { MobileOutlined } from '@ant-design/icons/lib/icons';

interface IProps {
  isPrompt: boolean;
  remoteSubmit(): void;
  togglePrompt(): void;
  onSubmit(formData: any): void;
}

const ChangePhoneForm = (props: IProps) => {
  const { onSubmit, remoteSubmit, isPrompt, togglePrompt } = props;

  return (
    <form onSubmit={onSubmit} className="card">
      <Field
        name="newPhoneNumber"
        type="tel"
        label="Phone Number"
        placeholder="Enter Phone Number"
        component={FormTextInput}
        validate={validatePhoneNumber}
        normalize={formatPhoneNumber}
      />

      <UniversalButton
        btnTitle="Change Phone Number"
        styleType="success"
        iconComp={checkIcon}
        onClick={remoteSubmit}
      />
      {isPrompt && (
        <UniversalModal
          visible={isPrompt}
          title="Title Modal"
          onCancel={togglePrompt}
          promptOkBtn={makeBtn('OK', checkIcon, 'success', remoteSubmit)}
          maxWidth="600px"
          className="category-editor-modal"
        >
          <p className="change-phone-text">
            SMS with a confirmation code has been sent.
            <br /> Use it to verify your phone number.
          </p>
          <Field
            name="smsCode"
            type="text"
            placeholder="Passcode"
            prefixIcon={MobileOutlined}
            component={FormTextInput}
            validate={validatePasscode}
            normalize={formatPasscode}
          />
        </UniversalModal>
      )}
    </form>
  );
};

export default reduxForm<{}, IProps>({
  form: CHANGE_PHONE_FORM,
  initialValues: { newPhoneNumber: '+1' },
})(ChangePhoneForm);
