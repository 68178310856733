interface IRoute {
  ROOT: string;
  DETAILS?: string;
}

type PrepareRouteFn = (id: number | string) => string;

interface IRootChildren<T> {
  pathname: string;
  children: {
    [key in keyof T]: string;
  };
}

export const baseURL = '';

export interface IComplexRoute {
  ROOT: string | IRootChildren<any>;
  DETAILS: string;
  EDIT: string;
  ADD: string;
  getEditRoute: PrepareRouteFn;
  getDetailsRoute: PrepareRouteFn;
}

const makeComplexRoute = (root: string): IComplexRoute => ({
  ROOT: root,
  DETAILS: `${root}/details/:id`,
  EDIT: `${root}/:id/edit`,
  ADD: `${root}/add`,
  getDetailsRoute: (id) => `${root}/details/${id}`,
  getEditRoute: (id) => `${root}/${id}/edit`,
});

// AUTH
const signIn = `${baseURL}/login`;
export const AuthRoute = {
  ROOT: signIn,
  FORGOT_PASSWORD: `${signIn}/forgot-password`,
  FORGOT_PASSWORD_CONGRATS: `${signIn}/forgot-password-congrats`,
  RESET_PASSWORD: `${signIn}/reset-password`,
};

// ACCOUNT
const account = `${baseURL}/account`;
export const AccountRoute: IRoute = {
  ROOT: account,
};

// DASHBOARD
const dashboard = `${baseURL}/dashboard`;
export const DashboardRoute: IRoute = {
  ROOT: dashboard,
  DETAILS: `${dashboard}/details`,
};

// BUZZ
const buzz = `${baseURL}/buzz`;
interface IBuzzRoute extends IComplexRoute {
  ROOT: string;
}
export const BuzzRoute: IBuzzRoute = {
  ROOT: buzz,
  DETAILS: `${buzz}/details/:id`,
  EDIT: `${buzz}/:id/edit`,
  ADD: `${buzz}/add`,
  getDetailsRoute: (id) => `${buzz}/details/${id}`,
  getEditRoute: (id) => `${buzz}/${id}/edit`,
};

// SURVEYS
const surveys = `${baseURL}/surveys`;

interface ISurveyChildRoutes {
  OFFICAL: string;
  USER: string;
  PULSES: string;
}

interface SurveyComplexRoute extends IComplexRoute {
  ROOT: IRootChildren<ISurveyChildRoutes>;
  SUGGEST: string;
  getSuggestRoute: PrepareRouteFn;
}
export const SurveysRoute: SurveyComplexRoute = {
  ROOT: {
    pathname: surveys,
    children: {
      OFFICAL: `${surveys}/offical`,
      USER: `${surveys}/user`,
      PULSES: `${surveys}/pulses`,
    },
  },
  DETAILS: `${surveys}/details/:id`,
  EDIT: `${surveys}/:id/edit`,
  ADD: `${surveys}/add`, // added additional parameter in SurveysRouter "/:isPulse?"
  SUGGEST: `${surveys}/suggest/:communityId`,
  getDetailsRoute: (id) => `${surveys}/details/${id}`,
  getEditRoute: (id) => `${surveys}/${id}/edit`,
  getSuggestRoute: (id) => `${surveys}/suggest/${id}`,
};

// COMMUNITY
interface ICommunityChildRoutes {
  MAIN_INFO: string;
  SURVEYS: string;
  FOLLOWERS: string;
  MODERATORS: string;
  NEWS: string;
  INACTIVE_NEWS: string;
  ACTIVE_NEWS: string;
  ADD_NEWS: string;
  EDIT_NEWS: string;
}
export enum communityDetailPath {
  MAIN_INFO = 'main-info',
  SURVEYS = 'surveys',
  FOLLOWERS = 'folowers',
  MODERATORS = 'moderators',
  NEWS = 'news',
  INACTIVE_NEWS = 'inactive-news',
  ACTIVE_NEWS = 'active-news',
  ADD_NEWS = 'news-add',
  EDIT_NEWS = 'news-edit',
}
interface ICommunityRoute extends IComplexRoute {
  ROOT: IRootChildren<ICommunityChildRoutes>;
}
const community = `${baseURL}/community`;
export const communityDetail = `${community}/details`;
const communityDetailFull = `${communityDetail}/:id`;
export const CommunityRoute: ICommunityRoute = {
  ROOT: {
    pathname: community,
    children: {
      MAIN_INFO: `${communityDetailFull}/${communityDetailPath.MAIN_INFO}`,
      SURVEYS: `${communityDetailFull}/${communityDetailPath.SURVEYS}`,
      FOLLOWERS: `${communityDetailFull}/${communityDetailPath.FOLLOWERS}`,
      MODERATORS: `${communityDetailFull}/${communityDetailPath.MODERATORS}`,
      NEWS: `${communityDetailFull}/${communityDetailPath.NEWS}`,
      INACTIVE_NEWS: `${communityDetailFull}/${communityDetailPath.INACTIVE_NEWS}`,
      ACTIVE_NEWS: `${communityDetailFull}/${communityDetailPath.ACTIVE_NEWS}`,
      ADD_NEWS: `${communityDetailFull}/${communityDetailPath.ADD_NEWS}`,
      EDIT_NEWS: `${communityDetailFull}/${communityDetailPath.EDIT_NEWS}/:idn`,
    },
  },
  DETAILS: communityDetailFull,
  EDIT: `${community}/:id/edit`,
  ADD: `${community}/add`,
  getDetailsRoute: (id) => `${community}/details/${id}`,
  getEditRoute: (id) => `${community}/${id}/edit`,
};

// USERS
interface IUserRChildRoutes {
  ACCOUNT: string;
  COMMUNITIES: string;
  SURVEYS: string;
}

interface IUserRoute extends IComplexRoute {
  ROOT: IRootChildren<IUserRChildRoutes>;
}
export enum userDetailPath {
  ACCOUNT = 'account',
  COMMUNITIES = 'communities',
  SURVEYS = 'surveys',
}
const users = `${baseURL}/users`;
const userDetailsPath = `${users}/details/:id`;
export const UsersRoute: IUserRoute = {
  ROOT: {
    pathname: users,
    children: {
      ACCOUNT: `${userDetailsPath}/${userDetailPath.ACCOUNT}`,
      COMMUNITIES: `${userDetailsPath}/${userDetailPath.COMMUNITIES}`,
      SURVEYS: `${userDetailsPath}/${userDetailPath.SURVEYS}`,
    },
  },
  DETAILS: `${userDetailsPath}`,
  EDIT: `${users}/:id/edit`,
  ADD: `${users}/add`,
  getDetailsRoute: (id) => `${users}/details/${id}`,
  getEditRoute: (id) => `${users}/${id}/edit`,
};

// CONTACT REQUEST
const contactRequests = `${baseURL}/contact-requests`;
export const ContactRequestsRoute: IRoute = {
  ROOT: contactRequests,
};

// GET IN TOUCH
const getInTouch = `${baseURL}/get-in-touch`;
export const GetInTouchRoute: IRoute = {
  ROOT: getInTouch,
};

// CATEGORIES
const categories = `${baseURL}/categories`;
export const CategoriesRoute: IRoute = {
  ROOT: categories,
};

// POINTS
const points = `${baseURL}/points`;
export const PointsRoute: IRoute = {
  ROOT: points,
};

// PAYMENT
const payment = `${baseURL}/payment`;
export const PaymentRoute: IRoute = {
  ROOT: payment,
};

// OFFENSIVE WORDS
const words = `${baseURL}/offensive-words`;
export const OffensiveWordsRoute: IRoute = {
  ROOT: words,
};

// ROLES AND PERMISSIONS
const roles = `${baseURL}/roles`;
export const RolesRoute: IRoute = {
  ROOT: roles,
};
