import React from 'react';
import { validateRequired } from 'utils';
import { cutToSize } from 'helpers';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';
import { FormTextInput } from 'components/common/form';
import { NITIFICATION_LIMIT, NOTIFICATION_ALL_FORM } from 'const';

interface IProps extends InjectedFormProps {}

function UserNotificationForm(props: IProps) {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className="category-edit-form">
      <Field
        name="message"
        label="Text of notification"
        type="textarea"
        placeholder="Enter text of notification"
        component={FormTextInput}
        validate={validateRequired}
        normalize={cutToSize(NITIFICATION_LIMIT)}
        max={NITIFICATION_LIMIT}
      />
    </form>
  );
}

export default reduxForm({
  form: NOTIFICATION_ALL_FORM,
  enableReinitialize: true,
})(UserNotificationForm);
