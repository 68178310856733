import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CategoryHeader, SearchBlock } from 'components/common/parts';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { makeSelector } from 'utils';
import { UniversalTable } from '../common/universal';
import { communityTableParams } from 'assets/static-data';
import { getCommunityListAction, resetCommunityAction } from 'actions';
import { CommunityList } from 'interfaces';
import { CommunityRoute } from 'const';

interface IProps extends SearchableTable {}

const CommunitiesPage: React.FC<IProps> = ({
  getList,
  isLoading,
  queryParams,
  history,
}) => {
  const dispatch = useDispatch();
  const { data, recordsFiltered } = useSelector<any, CommunityList>(
    makeSelector(['communityReducer', 'communityList'])
  );

  const toAddPage = () => {
    dispatch(resetCommunityAction(null));
    history.push(CommunityRoute.ADD);
  };

  return (
    <div className="f-column community-page">
      <CategoryHeader catTitle="Communities" />

      <SearchBlock
        onChange={getList}
        initialQuery={queryParams}
        btnTitle="Add community"
        onClickBtn={toAddPage}
      />
      <UniversalTable
        {...communityTableParams}
        data={data}
        total={recordsFiltered}
        onChange={getList}
        isLoading={isLoading}
        initialQuery={queryParams}
      />
    </div>
  );
};

export default SearchableTableContainer({
  getListAction: getCommunityListAction,
  reducerName: 'communityReducer',
  queryName: 'communityQueryParams',
})(CommunitiesPage);
