import { useState } from 'react';
import { ICategoryCardProps } from '../components/common/parts';

interface IInitualValues {
  [key: string]: any;
}

interface PromptState<T> {
  promptTitle: string;
  btnTitle: string;
  formInitialValues: T;
}

export function useFormInModal<T = IInitualValues>(arr: T[], title: string) {
  const promptInitialState: PromptState<T> = {
    promptTitle: `New ${title}`,
    btnTitle: `Create ${title}`,
    // tslint:disable-next-line: no-object-literal-type-assertion
    formInitialValues: {} as T,
  };

  const [isPrompt, setPromptState] = useState(false);
  const [
    { promptTitle, btnTitle, formInitialValues },
    setPromptParams,
  ] = useState(promptInitialState);

  const closeEditor = () => {
    setPromptState(false);
    setPromptParams(promptInitialState);
  };

  const openEditor = (cardItem?: IInitualValues) => () => {
    cardItem
      ? setPromptParams({
          promptTitle: `Edit ${title}`,
          btnTitle: `Edit ${title}`,
          formInitialValues:
            arr.find(({ id }: IInitualValues) => id === cardItem.id) ||
            // tslint:disable-next-line: no-object-literal-type-assertion
            ({} as T),
        })
      : setPromptParams(promptInitialState);
    setPromptState(true);
  };

  return {
    promptTitle,
    btnTitle,
    formInitialValues,
    isPrompt,
    closeEditor,
    openEditor,
  };
}
