import React, { useEffect } from 'react';
import { change, submit } from 'redux-form';
import { formValueSelector } from 'redux-form/immutable';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { useDispatch, useSelector, connect } from 'react-redux';
import { ReactNodeLike } from 'prop-types';

import { CategoryHeader } from 'components/common/parts';
import { makeBtn } from 'components/common/universal';
import { checkIcon, fileIcon } from 'components/common';
import SurveyEditForm from './SurveyEditForm';
import {
  addSurveyAction,
  editSurveyAction,
  getSurveyAction,
  getSurveyQuestionsAction,
  resetQuestionsStateAction,
  resetSurveyStateAction,
  getCommunityAction,
} from 'actions';
import {
  Category,
  IImmutableMap,
  QuestionList,
  resetQuestionModel,
  Survey,
  SurveyReq,
  SurveyStatus,
  AvailableCommunity,
  AvailableCommunityList,
  MANDATORY_QUESTION_COEFFICIENT,
  Question,
} from 'interfaces';
import { getMatchParam, getQueryParam } from 'helpers';
import { makeSelector, showMessage } from 'utils';
import { SURVEY_EDIT_FORM } from 'const';

interface IProp extends RouteComponentProps {
  selectedCommunity?: AvailableCommunity;
}

export type GetOptionalsQuestions = (
  questionDtoList: QuestionList
) => {
  optionalCount: number;
  optionalQuestions: Question[];
  isOptDisabled: boolean;
};

const SurveyEditPage = ({
  history,
  match,
  location,
  selectedCommunity,
}: IProp) => {
  const dispatch = useDispatch();

  const survey = useSelector<any, Survey>(
    makeSelector(['surveysReducer', 'survey'])
  );
  const questions = useSelector<any, QuestionList>(
    makeSelector(['surveysReducer', 'questionList'])
  );
  const categories = useSelector<any, Category[]>(
    makeSelector(['categoriesReducer', 'categoriesSearchList'])
  );

  const isPulse = survey.isPulse || !!getMatchParam(match).isPulse;
  const idFromUrl = getMatchParam(match).id;
  const isSelectedOCT = selectedCommunity ? selectedCommunity.oct : false;
  const isOfficial =
    getQueryParam(location, 'official') || isSelectedOCT || isPulse;
  const isEditMode = !!(idFromUrl || survey.id);
  const communityId = getMatchParam(match).communityId;
  const isSuggestMode = !!communityId;
  const isDraft = [SurveyStatus.NONE, SurveyStatus.DRAFT].includes(
    survey.status
  );

  useEffect(() => {
    if (idFromUrl) {
      dispatch(getSurveyAction(idFromUrl));
      dispatch(getSurveyQuestionsAction(idFromUrl));
    } else {
      dispatch(resetSurveyStateAction(null));
      dispatch(resetQuestionsStateAction(null));
    }
    if (communityId) {
      dispatch(getCommunityAction(communityId));
    }
  }, []);

  const remoteSubmitSurveyEditForm = () => {
    setTimeout(() => dispatch(submit(SURVEY_EDIT_FORM)), 300);
  };

  const saveSurveyAsDraft = () => {
    // required query params will add in saga
    dispatch(change(SURVEY_EDIT_FORM, 'status', SurveyStatus.NONE));
    remoteSubmitSurveyEditForm();
  };

  const createSurvey = () => {
    dispatch(change(SURVEY_EDIT_FORM, 'status', SurveyStatus.MODERATION));
    remoteSubmitSurveyEditForm();
  };

  const getOptionalCount = (questionDtoList: QuestionList) => {
    const totalQuestions = questionDtoList.filter((q) => !q.qualified);

    const optionalCount =
      totalQuestions.length -
      Math.floor(totalQuestions.length * MANDATORY_QUESTION_COEFFICIENT);
    return optionalCount;
  };

  const getOptionalsQuestions: GetOptionalsQuestions = (questionDtoList) => {
    const totalQuestions = questionDtoList.filter((q) => !q.qualified);
    const optionalQuestions = totalQuestions.filter((q) => q.optional);

    let isOptDisabled = true;
    let optionalCount = 0;

    if (totalQuestions.length > 3) {
      optionalCount = getOptionalCount(questionDtoList);
      isOptDisabled = optionalCount <= optionalQuestions.length;
    }

    return {
      optionalCount,
      optionalQuestions,
      isOptDisabled,
    };
  };

  const onSubmit = (formData: IImmutableMap<SurveyReq> | any) => {
    const formValues = formData.toJS();
    const {
      questionDtoList,
      status,
      community,
      category,
      isAbleToChangeAnswer,
    } = formValues;

    const { optionalCount, optionalQuestions } = getOptionalsQuestions(
      formValues.questionDtoList
    );

    if (optionalQuestions.length > optionalCount) {
      showMessage.error(
        'Your qoute is exceded, please create additional question or delete one optional one'
      );
      return;
    }

    // normalize questionNumber indexes
    (questionDtoList as QuestionList).forEach((q, i) => {
      const isQualified = q.qualified;

      q.questionNumber = i + 1;

      q.optionList.forEach((o, j) => {
        if (!isQualified) {
          o.qualified = false;
        }
        o.questionOptionNumber = j + 1;
      });
    });

    const isQualified = questionDtoList[0].qualified;

    const isUpdatedDraft = [SurveyStatus.NONE, SurveyStatus.DRAFT].includes(
      status
    );

    let communityUpdatedId = 1; // official community

    if (isSuggestMode) communityUpdatedId = communityId;
    if (isDraft) communityUpdatedId = community.id;

    const surveyReq: SurveyReq = {
      ...formValues,
      questionDtoList,
      community: { id: communityUpdatedId }, // required!
      category: { id: category },
      isDraft: isUpdatedDraft, // required;
      publish: !isUpdatedDraft, // required; queryParam
      isAbleToChangeAnswer: !isQualified ? isAbleToChangeAnswer : false,
    };

    if (!isOfficial) {
      delete surveyReq.url;
      delete surveyReq.pdf;
    }

    const action = isEditMode
      ? editSurveyAction(surveyReq, history.goBack)
      : addSurveyAction(surveyReq, history.goBack);
    dispatch(action);
  };

  const draftBtnTitle = `${isEditMode ? 'Update' : 'Save'} Draft`;
  let submitBtnTitle = '';

  if (isSuggestMode) {
    submitBtnTitle = 'Create Survey';
  } else {
    const startBtnTitle = isEditMode ? 'Update' : 'Create';
    const finishBtnTitle = isPulse ? 'Pulse' : 'Survey';
    submitBtnTitle = `${startBtnTitle} ${finishBtnTitle}`;
  }

  if (isDraft) {
    submitBtnTitle = 'Publish Draft';
  }

  const headerCtrlButton: ReactNodeLike = (
    <>
      {!isPulse &&
        makeBtn(draftBtnTitle, fileIcon, 'success', saveSurveyAsDraft)}
      {makeBtn(submitBtnTitle, checkIcon, 'success', createSurvey)}
    </>
  );

  const initialValues: SurveyReq & any = {
    ...survey,
    isPulse,
    category: survey.category ? survey.category.id || null : null,
    questionDtoList: questions.length
      ? questions
      : [resetQuestionModel(isPulse ? 'YESNO' : 'SINGLE')],
  };

  return (
    <div className="f-column">
      <CategoryHeader
        backBtn={{ showButton: true, showModal: true }}
        ctrlBtns={headerCtrlButton}
      />

      <SurveyEditForm
        isOfficial={isOfficial}
        initialValues={initialValues}
        getOptionalsQuestions={getOptionalsQuestions}
        onSubmit={onSubmit}
      />
    </div>
  );
};

const selector = formValueSelector(SURVEY_EDIT_FORM);

export default connect((state: any) => {
  const selectedCommunityId = selector(state, 'community.id');
  const selectCommunities: AvailableCommunityList['data'] = makeSelector([
    'communityReducer',
    'selectCommunityList',
  ])(state);

  const selectedCommunity = selectCommunities.find(
    (c) => Number(c.id) === Number(selectedCommunityId)
  );
  return {
    selectedCommunity,
  };
})(SurveyEditPage);
