import {
  IActionFn,
  QueryParams,
  UserProfile,
  IUserProfile,
} from '../interfaces';
import {
  BAN_USER,
  DELETE_USER,
  GET_USER_COMMUNITIES,
  GET_USER_PROFILE,
  GET_USER_SURVEYS,
  GET_USER_TRANSACTIONS,
  GET_USERS_LIST,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PHONE,
} from '../const';

export const getUsersListAction: IActionFn<QueryParams> = (params) => ({
  type: GET_USERS_LIST.PENDING,
  payload: params,
});

export const getUserProfileAction: IActionFn<UserProfile['id']> = (id) => ({
  type: GET_USER_PROFILE.PENDING,
  payload: id,
});

export const banUserAction: IActionFn<UserProfile['id']> = (id) => ({
  type: BAN_USER.PENDING,
  payload: id,
});

export const deleteUserAction: IActionFn<UserProfile['id']> = (
  id,
  redirect
) => ({
  type: DELETE_USER.PENDING,
  payload: id,
  redirect,
});

export const getUserCommunitiesAction: IActionFn<QueryParams> = (params) => ({
  type: GET_USER_COMMUNITIES.PENDING,
  payload: params,
});

export const getUserSurveysAction: IActionFn<QueryParams> = (params) => ({
  type: GET_USER_SURVEYS.PENDING,
  payload: params,
});

export const getUserTransactionsAction: IActionFn<QueryParams> = (params) => ({
  type: GET_USER_TRANSACTIONS.PENDING,
  payload: params,
});

export interface ChangeUserPasswordAction extends IUserProfile {
  password: string;
  oldPassword: string;
  phoneNumber: IUserProfile['phoneNumber'];
  id: IUserProfile['id'];
}

export const changeUserPasswordAction: IActionFn<ChangeUserPasswordAction> = (
  params
) => ({
  type: CHANGE_USER_PASSWORD.PENDING,
  payload: params,
});

interface ChangeUserPhoneAction {
  phoneNumber: string;
  id: string;
  resendSMS: boolean;
  smsCode?: string;
}

export const changeUserPhoneAction: IActionFn<ChangeUserPhoneAction> = (
  params,
  redirect
) => ({
  type: CHANGE_USER_PHONE.PENDING,
  payload: params,
  redirect,
});
