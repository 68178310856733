import React, { useState } from 'react';

import { UniversalButton, UniversalModal } from 'components/common/universal';
import { deleteIcon, editIcon } from 'components/common';

import './category-card.scss';

export interface ICategoryCardProps {
  id: number;
  title: string | number;
  image: string;
  isDefault: boolean;
  defaultMarkTitle: string;
  logo?: string;
  onEdit?(cardItem: ICategoryCardProps): void;
  onSubmitDelete?(cardItem: ICategoryCardProps): void;
}

export const CategoryCard = (cardItem: ICategoryCardProps) => {
  const [isPrompt, setPromptState] = useState(false);
  const togglePromptToDelete = () => setPromptState(!isPrompt);

  const {
    id,
    title,
    logo,
    image,
    isDefault,
    defaultMarkTitle = 'Default',
    onEdit = () => {},
    onSubmitDelete = () => {},
  } = cardItem;

  const onDeleteCard = () => {
    togglePromptToDelete();
    onSubmitDelete(cardItem);
  };

  return (
    <div className="category-card f-s-sb card">
      {/* MAIN IMAGE */}
      <img src={image} className="category-card-img" alt="category" />

      <div className="f-sb-s h100 w100">
        <div className="f-s-sb w100">
          {/* ICON */}
          {logo ? (
            <img src={logo} className="category-card-icon" alt="category" />
          ) : (
            <div />
          )}

          {/* CTRL BUTTONS */}
          <div className="category-card-buttons">
            <UniversalButton
              btnType="link"
              onClick={togglePromptToDelete}
              iconComp={deleteIcon}
              btnTitle="Delete"
              styleType="error"
            />
            <UniversalButton
              btnType="link"
              iconComp={editIcon}
              btnTitle="Edit"
              onClick={onEdit(cardItem)}
            />
          </div>
        </div>

        {/* DESCRIPTION */}
        <div className="category-card-description">
          <span className="label">ID:{id}</span>
          {isDefault && <span className="highlight">{defaultMarkTitle}</span>}
          <h3>{title}</h3>
        </div>
      </div>

      <UniversalModal
        visible={isPrompt}
        onCancel={togglePromptToDelete}
        onOk={onDeleteCard}
      />
    </div>
  );
};
