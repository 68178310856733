import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import {
  GET_REQUESTED_LIST,
  GET_REQUESTED_SOLVED_LIST,
  RESET_REQUESTED_LIST,
  UPDATE_CONTACT_REQUESTS_PARAMS,
} from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';
import {
  RequestedList,
  resetListPaginatedModel,
  QueryParams,
} from 'interfaces';

const { PENDING, DONE } = makeCombinedAction(GET_REQUESTED_LIST);

interface ContactRequestReducerState {
  isLoading: boolean;
  requestedList: RequestedList;
  queryParams: QueryParams;
  queryParamsSolved: QueryParams;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = Immutable.fromJS({
  isLoading: false,
  requestedList: resetListPaginatedModel(),
  queryParams: {},
  queryParamsSolved: {},
} as ContactRequestReducerState);

const contactRequestReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [GET_REQUESTED_LIST.PENDING]: (state, { payload }) => {
      return state.set('queryParams', Immutable.fromJS(payload));
    },
    [GET_REQUESTED_SOLVED_LIST.PENDING]: (state, { payload }) => {
      return state.set('queryParamsSolved', Immutable.fromJS(payload));
    },
    [RESET_REQUESTED_LIST]: (state) => {
      return state.set(
        'requestedList',
        Immutable.fromJS(resetListPaginatedModel())
      );
    },
    [GET_REQUESTED_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('requestedList', Immutable.fromJS(payload));
    },
    [UPDATE_CONTACT_REQUESTS_PARAMS]: (state, { payload }) => {
      const queryParams = state.toJS().queryParams;
      const queryParamsSolved = state.toJS().queryParamsSolved;

      state = state.set(
        'queryParams',
        Immutable.fromJS({ ...queryParams, query: payload.query })
      );
      state = state.set(
        'queryParamsSolved',
        Immutable.fromJS({ ...queryParamsSolved, query: payload.query })
      );
      return state;
    },
  },
  initialState
);

export default contactRequestReducer;
