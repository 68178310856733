import React from 'react';
import { useSelector } from 'react-redux';

import { SearchBlock } from 'components/common/parts';
import { UniversalTable } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { getOwnSurveysAction } from 'actions';
import { makeSelector } from 'utils';
import { SurveysList } from 'interfaces';
import { SurveysRoute } from 'const';
import { SurveysTableParams } from 'assets/static-data';

const { routeConst, titles } = SurveysTableParams;
const tableTitles = [...titles].map((opt) => {
  if (opt.propName === 'community') {
    return { ...opt, isSortable: false };
  }
  return opt;
});

interface IProps extends SearchableTable {}

const OfficialSurveys: React.FC<IProps> = ({
  history,
  getList,
  isLoading,
  queryParams,
}) => {
  const { data, recordsFiltered } = useSelector<any, SurveysList>(
    makeSelector(['ownDataReducer', 'ownSurveys'])
  );

  const toEditPage = () => {
    history.push(`${SurveysRoute.ADD}?official=true`);
  };

  return (
    <>
      <SearchBlock
        btnTitle="Add Official Survey"
        onChange={getList}
        onClickBtn={toEditPage}
        initialQuery={queryParams}
      />
      <UniversalTable
        titles={tableTitles}
        routeConst={routeConst}
        data={data}
        onChange={getList}
        total={recordsFiltered}
        isLoading={isLoading}
        initialQuery={queryParams}
      />
    </>
  );
};

export default SearchableTableContainer({
  getListAction: getOwnSurveysAction,
  reducerName: 'ownDataReducer',
  isEqualSearch: true,
})(OfficialSurveys);
