import history from 'config/history';
import { RouteComponentProps } from 'react-router';

export const getIdFromUrl = (routeBeforeId: string) => {
  return history.location.pathname.match(
    new RegExp(`[^${routeBeforeId}]+`)
  ) as string & number;
};

export const getMatchParam = (match: RouteComponentProps['match']) => {
  return match.params as { [key: string]: any };
};

export const getQueryParam = (
  location: RouteComponentProps['location'],
  name: string
) => {
  const search = location.search.replace('?', '');
  const params = search.split('&');
  const param = params.find((p) => p.split('=')[0] === name);
  if (param) {
    const result = param.split('=')[1];
    return JSON.parse(result);
  }
  return null;
};
