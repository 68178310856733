import { IUniversalTableParams, UserProfile } from 'interfaces';
import {
  booleanIconFn,
  locationFn,
} from 'components/common/universal/utils/universal-helper';
import { amountFn } from 'helpers';
import { UsersRoute, userDetailPath } from 'const';

const updatedUsersRoute = {
  ...UsersRoute,
  getDetailsRoute(id: string | number): string {
    const result = UsersRoute.getDetailsRoute(id);
    return `${result}/${userDetailPath.ACCOUNT}`;
  },
};

export const usersTableParams: IUniversalTableParams<UserProfile> = {
  titles: [
    {
      title: 'ID',
      propName: 'id',
    },
    {
      title: 'Name',
      propName: 'username',
    },
    {
      title: 'Phone number',
      propName: 'phoneNumber',
    },
    {
      title: 'Email',
      propName: 'email',
    },
    {
      title: 'Location',
      propName: 'location',
      formatFn: locationFn,
    },
    {
      title: 'Points',
      propName: 'points',
      formatFn: amountFn,
    },
    {
      title: 'Extra Points',
      propName: 'extraPoints',
      formatFn: amountFn,
    },
    {
      title: 'Date',
      propName: 'yearOfBirth',
    },
    {
      title: 'Active',
      propName: 'isActive',
      formatFn: booleanIconFn('primary'),
    },
    {
      title: 'Banned',
      propName: 'blocked',
      formatFn: booleanIconFn('error'),
    },
  ],
  data: [],
  routeConst: updatedUsersRoute,
};
