import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCategoriesBySearch, getCommunitiesBySearchAction } from 'actions';
import { makeSelectOptionItem } from 'helpers';
import {
  PaginationWrapper,
  AvailableCommunity,
  ISelectOption,
  QueryParams,
} from 'interfaces';

type SearchTypes = 'category' | 'community';

interface IUseSelectSearch {
  type: SearchTypes;
  title: string;
  value: string;
  page?: number;
  limit?: number;
}

export interface ISelectSearchResult {
  options: ISelectOption[];
  total: number;
}

export const MAX_ITEMS_PER_REQUEST = 10;

const useSelectSearch = (props: IUseSelectSearch) => {
  const { type, title, value: selectValue, page } = props;
  const dispatch = useDispatch();

  let action = getCategoriesBySearch;

  if (type === 'category') {
    action = getCategoriesBySearch;
  }

  if (type === 'community') {
    action = getCommunitiesBySearchAction;
  }

  const getOptions = ({
    query,
    offset,
    selected,
  }: QueryParams): Promise<ISelectSearchResult> => {
    return new Promise((resolve) => {
      dispatch(
        action(
          { query, offset, selected, limit: MAX_ITEMS_PER_REQUEST },
          ({
            data,
            recordsFiltered,
          }: PaginationWrapper<AvailableCommunity>) => {
            const options = data.map(makeSelectOptionItem(title, selectValue));
            if (type === 'community') {
              data.forEach((item, idx) => {
                if (item.oct) {
                  options[idx].label = `${options[idx].label} (OCT)`;
                }
              });
            }
            resolve({ options, total: recordsFiltered });
          }
        )
      );
    });
  };

  return getOptions;
};

export default useSelectSearch;
