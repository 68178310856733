import React, { useState } from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';

import { IIconComponent, IReactMouseEvent } from 'interfaces';
import { Link } from 'react-router-dom';

import './universal-button.scss';
import history from 'config/history';
import { UniversalCmpStyleType } from '../utils/universal-consts';

import FileType from 'file-type/browser';
import { showMessage } from 'utils';
import { MESSAGE } from 'const';

export type UniversalBtnType = 'default' | 'link' | 'file';

interface IUniversalButton {
  btnTitle?: string;
  block?: boolean;
  disabled?: boolean;
  htmlType?: 'button' | 'reset' | 'submit';
  btnType?: UniversalBtnType;
  linkTo?: string;
  styleType?: UniversalCmpStyleType;
  shape?: 'round' | 'circle' | 'circle-outline';
  className?: string;
  icon?: string;
  // events
  onClick?: any;
  isLoading?: boolean;
  onFileLoaded?: any;
  fileAs?: 'base64' | 'file' | 'blob';

  // Use icon as component because default ant btn doesn't support it
  iconComp?: IIconComponent;

  accept?: string; // 'application/pdf, image/jpeg'
}

export const UniversalButton = (props: IUniversalButton) => {
  const [inputField, setInputField] = useState<HTMLInputElement | null>(null);

  const {
    btnTitle,
    iconComp,
    linkTo,
    styleType = 'primary',
    btnType = 'default',
    onFileLoaded,
    fileAs = 'file',
    className = '',
    isLoading,
    accept,
    ...restProps
  } = props;
  const classNameStr = `${className} btn-${btnType} btn-${btnType}-${styleType}`;

  const onClickBtn = (e: IReactMouseEvent) => {
    if (typeof linkTo === 'string') {
      history.push(linkTo);
    }
    if (props.onClick !== undefined) {
      props.onClick(e);
    }
  };

  const onClickFileBtn = (e: IReactMouseEvent) => {
    if (inputField) {
      inputField.click();
    }

    e.stopPropagation();
  };

  const checkByCorrectFileType = async (file: File) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.addEventListener('load', async (event) => {
          const blob = new Blob(
            [new Uint8Array(event.target?.result as ArrayBuffer)],
            {
              type: file.type,
            }
          );

          const fileType = await FileType.fromBlob(blob);
          if (fileType?.mime === accept) {
            resolve(fileType);
          } else {
            // Invalid type
            showMessage.error(MESSAGE.INVALID_UPLOADED_TYPE);
            reject();
          }
        });
        reader.readAsArrayBuffer(file);
      } catch (err) {
        // Invalid type
        showMessage.error(MESSAGE.INVALID_UPLOADED_TYPE);
        reject();
      }
    });
  };

  const onSelectFile = async (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      switch (fileAs) {
        case 'file': {
          try {
            await checkByCorrectFileType(files[0]);
            onFileLoaded(files[0]);
          } catch (err) {}
          break;
        }
        case 'base64':
          const reader = new FileReader();
          reader.addEventListener('load', () => onFileLoaded(reader.result));
          reader.readAsDataURL(files[0]);
      }
      if (inputField) {
        inputField.value = '';
      }
    }
  };

  if (btnType === 'link' && linkTo) {
    return !/http/.test(linkTo) ? (
      <Link {...restProps} to={linkTo} className={classNameStr}>
        {iconComp ? <Icon component={iconComp} /> : null}
        <span>{btnTitle}</span>
      </Link>
    ) : (
      <a
        {...restProps}
        className={classNameStr}
        href={linkTo}
        target="_blank"
        rel="noopener noreferrer"
      >
        {iconComp ? <Icon component={iconComp} /> : null}
        <span>{btnTitle}</span>
      </a>
    );
  }

  return (
    <>
      <Button
        {...restProps}
        type={btnType !== 'file' ? btnType : 'default'}
        className={classNameStr}
        onClick={btnType !== 'file' ? onClickBtn : onClickFileBtn}
        loading={isLoading}
        data-testid="button-id"
      >
        {iconComp && <Icon component={iconComp} />}
        {btnTitle}
      </Button>
      {btnType === 'file' && (
        <input
          type="file"
          accept={accept}
          ref={setInputField}
          onChange={onSelectFile}
        />
      )}
    </>
  );
};
