import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import Icon from '@ant-design/icons';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { errorIcon, selectIcon } from 'components/common';
import { UniversalTooltip } from '../universal';
import { WrappedFieldProps } from 'redux-form';
import { ISelectOption, QueryParams } from 'interfaces';
import {
  ISelectSearchResult,
  MAX_ITEMS_PER_REQUEST,
} from 'hooks/useSelectSearch';
const { Option } = Select;

interface IAsyncSelectProps extends WrappedFieldProps {
  placeholder: string;
  label: string;
  className: string;
  wrapperClassName: string;
  disabled?: boolean;
  fetchOptions(props: QueryParams): Promise<ISelectSearchResult>;
  async?: boolean;
}

interface IAsyncSelectState {
  options: ISelectOption[];
  hasMore: boolean;
}

const intiialState: IAsyncSelectState = {
  options: [],
  hasMore: false,
};

const selectColourStyles = {
  option: (styles: any, { isFocused, isSelected }: any) => {
    let backgroundColor = '#fff';
    const color = 'rgba(0, 0, 0, 0.65)';
    if (isFocused) {
      backgroundColor = '#f5f7f7';
    }
    if (isSelected) {
      backgroundColor = '#fafafa';
    }
    return {
      ...styles,
      color,
      backgroundColor,
    };
  },
};

export const AsyncSelect = (props: IAsyncSelectProps) => {
  const [state, setState] = useState(intiialState);

  const {
    input,
    input: { value },
    meta: { touched, error },
    label,
    placeholder,
    disabled,
    async = true,
  } = props;
  const { options, hasMore } = state;

  const invalid = touched && error;
  const val = typeof value !== 'undefined' ? value.toString() : value;

  useEffect(() => {
    initialLoading();
  }, []);

  const defaultValue = options.find(
    (o) => String(o.value) === String(input.value)
  );

  const formatOptions = (options: ISelectOption[]): ISelectOption[] => {
    return options.filter((o) => Boolean(o.value));
  };

  const initialLoading = async () => {
    const { options } = await props.fetchOptions({
      query: '',
      offset: 0,
      selected: input.value,
    });

    setState({
      ...state,
      options: formatOptions(options),
      hasMore: hasMore || false,
    });
  };

  const renderSuffix = () => (
    <>
      <Icon component={selectIcon} />
      {invalid && (
        <UniversalTooltip placement="right" title={error} styleType="error">
          <Icon component={errorIcon} />
        </UniversalTooltip>
      )}
    </>
  );
  const selectProps = {
    filterOption: (inputValue: any, option: any) => {
      return (
        option.props.children
          .toUpperCase()
          .indexOf(inputValue.toUpperCase()) !== -1
      );
    },
  };

  const handleSearch = async (
    value: string,
    prevOptions: any
  ): Promise<any> => {
    const { options, total } = await props.fetchOptions({
      query: value,
      offset: Math.floor(prevOptions.length / MAX_ITEMS_PER_REQUEST),
      selected: input.value,
    });

    const result = prevOptions.length === total ? [] : options;
    const hasMore = result.length && total > prevOptions.length + result.length;

    setState({
      ...state,
      options: [...state.options, ...result],
      hasMore: hasMore || false,
    });
    return {
      hasMore,
      options: formatOptions(result),
    };
  };
  const onChange = (item: ISelectOption) => {
    const { input } = props;

    input.onChange(item.value);
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon component={selectIcon} />
      </components.DropdownIndicator>
    );
  };

  const renderAsyncSelect = () => {
    return (
      <div className="select-suffix">
        <AsyncPaginate
          placeholder={placeholder}
          components={{ DropdownIndicator }}
          defaultValue={defaultValue}
          defaultOptions={options}
          onChange={onChange}
          value={defaultValue}
          loadOptions={handleSearch}
          isDisabled={disabled}
          className={`select-input ${val ? 'has-value' : ''}`}
          styles={selectColourStyles}
        />
      </div>
    );
  };

  const renderDefaultSelect = () => {
    return (
      <Select
        {...input}
        {...selectProps}
        showSearch
        value={val}
        defaultActiveFirstOption={false}
        onFocus={input.onFocus as any}
        placeholder={placeholder}
        className={`select-input ${val ? 'has-value' : ''}`}
        suffixIcon={renderSuffix()}
        disabled={disabled}
        notFoundContent={null}
      >
        {options.map(({ value, label }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    );
  };

  return (
    <div className={`form-group`}>
      <div className="form-control-wrapper">
        {label && (
          <label htmlFor={input.name} className="text-input-label">
            {label}
          </label>
        )}
        {async ? renderAsyncSelect() : renderDefaultSelect()}
      </div>
    </div>
  );
};
