import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';
import { Collapse } from 'antd';

import { cssPrefixCreator, renderExpandIcon, formatPoints } from 'helpers';
import { FormTextInput } from '../common/form';
import { UniversalIcon, UniversalTooltip } from '../common/universal';
import { hintIcon } from '../common';
import { POINTS_EDIT_FORM, POINTS_LIMIT } from 'const';
import { validateRequired } from 'utils';

const { Panel } = Collapse;

interface IProps extends InjectedFormProps {}

const PointsEditForm: React.FC<IProps> = ({ handleSubmit }) => {
  const renderRow = (label: string, fieldName: string, isExtra?: boolean) => (
    <li className="f-c-sb">
      <span className="f-c-sb">
        {label}
        {
          <UniversalTooltip
            title={'Number of Points'}
            children={
              <span>
                <UniversalIcon iconComp={hintIcon} />
              </span>
            }
          />
        }
      </span>
      <span className={`f-c-c ${!isExtra ? 'points-regular-field' : ''}`}>
        {
          <Field
            name={fieldName}
            placeholder="Enter Points"
            type="tel"
            component={FormTextInput}
            validate={validateRequired}
            normalize={formatPoints}
          />
        }
        {isExtra ? 'extra points' : 'points'}
      </span>
    </li>
  );

  const cssPrefix = cssPrefixCreator('points-edit-form');

  return (
    <form onSubmit={handleSubmit}>
      <Collapse
        className={`${cssPrefix()} c-collapse`}
        defaultActiveKey={['1', '2']}
        expandIconPosition="right"
        expandIcon={renderExpandIcon}
      >
        {/* COMMUNITY */}
        <Panel header="Community Actions Cost" key="1">
          <div className="f-s-sb">
            <div className={cssPrefix('list-wrapper')}>
              <h3 className="statistic-title">INCOME</h3>
              <ul>
                {renderRow('Joined Your Community', 'joinedYourCommunity')}
                {renderRow('Became Moderator', 'becameModerator')}
              </ul>
            </div>

            <div className={cssPrefix('list-wrapper')}>
              <h3 className="statistic-title">COST</h3>
              <ul>
                {renderRow('Create Community', 'createCommunity')}
                {renderRow(
                  'Create Community Extra',
                  'createCommunityExtra',
                  true
                )}
                {renderRow('Follow Community', 'followCommunity')}
                {renderRow('Add Moderator', 'addModerator')}
              </ul>
            </div>
          </div>
        </Panel>

        {/* SURVEY */}
        <Panel header="Survey Actions Cost" key="2">
          <div className="f-s-sb">
            <div className={cssPrefix('list-wrapper')}>
              <h3 className="statistic-title">INCOME</h3>
              <ul>
                {renderRow('Passed Your Survey', 'passedYourSurvey')}
                {renderRow('You Moderated Survey', 'youModeratedSurvey')}
              </ul>
            </div>

            <div className={cssPrefix('list-wrapper')}>
              <h3 className="statistic-title">COST</h3>
              <ul>
                {renderRow('Create Survey', 'createSurvey')}
                {renderRow('Shared Survey', 'shareSurvey')}
                {renderRow('Survey Points Cost', 'surveyPointsCost')}
                {renderRow(
                  'Official Survey Points Cost',
                  'officialSurveyPointsCost'
                )}
                {renderRow('Create Sponsored Survey', 'createSponsorSurvey')}
                {renderRow('Shared Survey Sponsored', 'shareSurveySponsored')}
                {renderRow(
                  'Sponsored Survey Points Cost',
                  'sponsoredSurveyPointsCost'
                )}
              </ul>
            </div>
          </div>
        </Panel>

        {/* OTHER ACTIONS */}
        <Panel header="Other Actions Cost" key="3">
          <div className="f-s-sb">
            <div className={cssPrefix('list-wrapper')}>
              <h3 className="statistic-title">INCOME</h3>
              <ul>{renderRow('Daily Points', 'dailyPoits')}</ul>
            </div>

            <div className={cssPrefix('list-wrapper')}>
              <h3 className="statistic-title">COST</h3>
              <ul>
                {renderRow('OST Admin Actions Cost', 'octAdminActionsCost')}
              </ul>
            </div>
          </div>
        </Panel>
      </Collapse>
    </form>
  );
};

export default reduxForm({
  form: POINTS_EDIT_FORM,
  enableReinitialize: true,
})(PointsEditForm);
