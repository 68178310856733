import React from 'react';
import { UniversalModal } from 'components/common/universal';
import { IDetailsInfoArrItem, DetailsInfoPairs } from 'components/common/parts';

interface IProps {
  closeEditor(): void;
  isPrompt: boolean;
  details: IDetailsInfoArrItem[];
}

function RequestDescriptionModal(props: IProps) {
  const { isPrompt, closeEditor, details } = props;
  return (
    <UniversalModal
      visible={isPrompt}
      title={'Contact Request Info'}
      onCancel={closeEditor}
      maxWidth="600px"
      className="category-editor-modal"
      promptOkBtn={<></>}
    >
      <div className="form-group">
        <DetailsInfoPairs details={details} />
      </div>
    </UniversalModal>
  );
}

export default RequestDescriptionModal;
