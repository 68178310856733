import React from 'react';
import { Survey } from 'interfaces';

interface IProps {
  survey: Survey;
}

function SurveyRejectInfo(props: IProps) {
  const { rejectDescription } = props.survey;
  return (
    <div className="card card-rejected">
      <span className="card-rejected__title">Rejected by reason:</span>
      <span className="card-rejected__text">{rejectDescription}</span>
    </div>
  );
}

export default SurveyRejectInfo;
