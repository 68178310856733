export const SING_IN_FORM = 'signInForm';
export const PROFILE_FORM = 'profileForm';
export const FORGOT_PASS_FORM = 'forgotPassForm';
export const RESET_PASS_FORM = 'resetPassForm';
export const CHANGE_PASS_FORM = 'changePassForm';
export const CHANGE_PHONE_FORM = 'changePhoneForm';
export const BUZZ_EDIT_FORM = 'buzzEditForm';
export const SURVEY_EDIT_FORM = 'surveyEditForm';
export const COMMUNITY_EDIT_FORM = 'communityEditForm';
export const COMMUNITY_EDIT_NEWS_FORM = 'communityEditNewsForm';
export const CATEGORY_EDIT_FORM = 'categoryEditForm';
export const PAYMENT_EDIT_FORM = 'paymentEditForm';
export const POINTS_EDIT_FORM = 'pointsEditForm';
export const ROLE_EDIT_FORM = 'roleEditForm';
export const REJECT_SURVEY_FORM = 'rejectSurveyForm';
export const NOTIFICATION_ALL_FORM = 'notificationAllForm';

export const DEFAULT_ASPECT_RATIO = 290 / 250;
