import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from 'config/history';

import { SearchBlock } from 'components/common/parts';
import { UniversalTable } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { getCommunitySurveysAction } from 'actions';
import { makeSelector } from 'utils';
import { QueryParams, SurveysList, Community } from 'interfaces';
import { SurveysTableParams } from 'assets/static-data';
import { getMatchParam } from 'helpers';
import { SurveysRoute } from 'const';

interface IProps extends SearchableTable {}

const CommunitySurveys: React.FC<IProps> = ({
  match,
  getList,
  isLoading,
  queryParams,
}) => {
  const { data, recordsFiltered } = useSelector<any, SurveysList>(
    makeSelector(['communityReducer', 'surveysList'])
  );
  const community = useSelector<any, Community>(
    makeSelector(['communityReducer', 'community'])
  );

  const idFromUrl = getMatchParam(match).id;
  const { own, blocked } = community;

  useEffect(() => {
    onGetList(queryParams);
  }, []);

  const onGetList = (params: QueryParams) => {
    getList({ ...params, id: idFromUrl });
  };

  const suggestSurvey = () => {
    let path = idFromUrl;
    if (community.oct) {
      path = `${idFromUrl}?official=true`;
    }
    history.push(SurveysRoute.getSuggestRoute(path));
  };

  return (
    <>
      <SearchBlock
        onChange={getList}
        initialQuery={queryParams}
        btnTitle={!blocked ? 'Create Survey' : undefined}
        onClickBtn={suggestSurvey}
      />
      <UniversalTable
        {...SurveysTableParams}
        data={data}
        total={recordsFiltered}
        onChange={onGetList}
        isLoading={isLoading}
        initialQuery={queryParams}
      />
    </>
  );
};

export default SearchableTableContainer({
  getListAction: getCommunitySurveysAction,
  isInitialized: false,
  reducerName: 'communityReducer',
  queryName: 'surveysQueryParams',
  isEqualSearch: true,
})(CommunitySurveys);
