import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import Icon from '@ant-design/icons';

import logo from 'assets/images/logo.png';
import {
  buzzIcon,
  categoriesIcon,
  communityIcon,
  contactRequestsIcon,
  dashboardIcon,
  offensiveWordsIcon,
  pointsIcon,
  surveyIcon,
  usersIcon,
} from 'components/common';
import {
  BuzzRoute,
  CategoriesRoute,
  CommunityRoute,
  ContactRequestsRoute,
  DashboardRoute,
  OffensiveWordsRoute,
  PointsRoute,
  SurveysRoute,
  UsersRoute,
  GetInTouchRoute,
} from 'const';
import { IIconComponent, PermissionTypes, UserGroups } from 'interfaces';

import './main-wrapper.scss';
import MainHeader from './parts/MainHeader';
import { customAbility } from 'containers/Can';
import { withOwnUser } from 'containers';
import { MEDIA_TABLET_POINTS } from 'const/theme/variables';
import { useMobile } from 'hooks';

const { Sider, Content } = Layout;

interface IProps {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  children: any;
}

export const MainWrapper = (props: IProps) => {
  const {
    location: { pathname: currPath },
    history,
    children,
  } = props;

  const [isCollapsed, toggleCollapse] = useState(
    window.innerWidth <= MEDIA_TABLET_POINTS
  );
  const currRoute =
    (currPath.match(/^(\/[\w-]+)/) || [])[0] || DashboardRoute.ROOT;

  const isMobile = useMobile(MEDIA_TABLET_POINTS);

  useEffect(() => {
    toggleCollapse(window.innerWidth <= MEDIA_TABLET_POINTS);
  }, [isMobile]);

  const toggleMenu = () => {
    toggleCollapse(!isCollapsed);
  };
  const setRoute = (path: React.Key) => {
    if (path && path !== currRoute) {
      history.push(String(path));
    }
  };

  const onSelectMenuItem = ({
    key,
  }: {
    key: React.Key;
    keyPath: React.Key[];
    item: React.ReactInstance;
    domEvent: React.MouseEvent<HTMLElement>;
  }) => {
    setRoute(key);
  };

  const renderMenuItem = (
    title: string,
    path: string,
    icon: IIconComponent
  ) => {
    return (
      <Menu.Item key={path}>
        <Icon component={icon} />
        <span>{title}</span>
      </Menu.Item>
    );
  };

  const showContactRequestPage = customAbility.can(
    PermissionTypes.READ,
    UserGroups.CONTACT_REQUESTS
  );
  const showPointsPage = customAbility.can(
    PermissionTypes.READ,
    UserGroups.POINTS
  );
  const showUsersPage = customAbility.can(
    PermissionTypes.READ,
    UserGroups.USERS
  );
  const showCategoriesPage = customAbility.can(
    PermissionTypes.READ,
    UserGroups.CATEGORIES
  );
  const showOffensiveWordsPage = customAbility.can(
    PermissionTypes.READ,
    UserGroups.OFFENSIVE_WORDS
  );

  return (
    <Layout className={`main-wrapper ${!isCollapsed ? 'is-open-aside' : ''}`}>
      {/* ASIDE BLOCK */}
      <Sider
        collapsed={isCollapsed}
        theme="light"
        width="280"
        collapsedWidth={isMobile ? '60' : '80'}
      >
        {/* LOGO */}
        <div className="nav-logo-block f-c-s">
          <img
            src={logo}
            className="nav-logo"
            width="54"
            height="54"
            alt="logo"
          />
          <h1 className="logo-title">CrowdThinc</h1>
        </div>

        {/* NAVIGATION */}
        <Menu
          mode="inline"
          onSelect={onSelectMenuItem}
          selectedKeys={[currRoute]}
        >
          <div className="nav-separator">Content management</div>
          {renderMenuItem('Dashboard', DashboardRoute.ROOT, dashboardIcon)}
          {renderMenuItem('The Buzz', BuzzRoute.ROOT, buzzIcon)}
          {renderMenuItem(
            'Surveys',
            SurveysRoute.ROOT.children.OFFICAL,
            surveyIcon
          )}
          {renderMenuItem(
            'Communities',
            CommunityRoute.ROOT.pathname,
            communityIcon
          )}
          {showUsersPage &&
            renderMenuItem('Users', UsersRoute.ROOT.pathname, usersIcon)}
          {showContactRequestPage &&
            renderMenuItem(
              'Contact Requests',
              ContactRequestsRoute.ROOT,
              contactRequestsIcon
            )}

          {showContactRequestPage &&
            renderMenuItem(
              'Stay In Touch Emails',
              GetInTouchRoute.ROOT,
              contactRequestsIcon
            )}

          {(showPointsPage || showCategoriesPage || showOffensiveWordsPage) && (
            <div className="nav-separator">System Settings</div>
          )}
          {showCategoriesPage &&
            renderMenuItem('Categories', CategoriesRoute.ROOT, categoriesIcon)}
          {showPointsPage &&
            renderMenuItem('Points', PointsRoute.ROOT, pointsIcon)}
          {showOffensiveWordsPage &&
            renderMenuItem(
              'Offensive Words',
              OffensiveWordsRoute.ROOT,
              offensiveWordsIcon
            )}
        </Menu>

        <p className="copyrights">CrowdThinc ©. All Rights Reserved</p>
      </Sider>

      {/* TOP BLOCK */}
      <Layout>
        {/* HEADER */}
        <MainHeader
          isCollapsed={isCollapsed}
          toggleMenu={toggleMenu}
          setRoute={setRoute}
        />

        {/* CONTENT */}
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default withOwnUser(MainWrapper);
