import React from 'react';
import history from 'config/history';
import Icon from '@ant-design/icons';

import { IIconComponent } from 'interfaces';
import { formatAmount } from 'helpers';

const RenderTotalCard = (
  title: string,
  totalValue: number,
  icon: IIconComponent,
  toRoute: string,
  state?: any
) => {
  const total = formatAmount(totalValue);

  const setRoute = () => {
    history.push(toRoute, state);
  };

  return (
    <div className="total-card f-c-sb card" onClick={setRoute}>
      <Icon component={icon} />
      <div className="total-card-details">
        <h3>{total}</h3>
        <span>{title}</span>
      </div>
    </div>
  );
};

export default RenderTotalCard;
