import { RoleType } from 'interfaces';

export enum UserGroups {
  USERS = 'users',
  POINTS = 'points',
  CONTACT_REQUESTS = 'contact_requests',
  OCT_COMMUNITY = 'oct_community',
  REGULAR_COMMUNITYS = 'regular_community',
  CATEGORIES = 'categories',
  OFFENSIVE_WORDS = 'offensive_words',
  SURVEYS = 'surveys',
  OCT_SURVEYS = 'oct_surveys',
  NEWS = 'news',
}

export enum PermissionTypes {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  APPROVE = 'approve',
  BAN = 'ban',
  FOLLOW = 'follow',
  CLONE = 'clone',
  COMPLETE = 'complete',
}

export type IPermissions = {
  [key in UserGroups]: PermissionTypes[];
};

export interface IRole {
  id: number;
  title: string;
  permissions: any[];
}

export const OCT_ADMIN_PERMISSIONS: IPermissions = {
  [UserGroups.USERS]: [
    PermissionTypes.READ,
    PermissionTypes.DELETE,
    PermissionTypes.UPDATE,
    PermissionTypes.BAN,
  ],
  [UserGroups.OCT_COMMUNITY]: [PermissionTypes.READ, PermissionTypes.UPDATE],
  [UserGroups.REGULAR_COMMUNITYS]: [
    PermissionTypes.READ,
    PermissionTypes.DELETE,
    PermissionTypes.UPDATE,
    PermissionTypes.BAN,
  ],
  [UserGroups.POINTS]: [PermissionTypes.READ, PermissionTypes.UPDATE],
  [UserGroups.CONTACT_REQUESTS]: [PermissionTypes.READ],
  [UserGroups.CATEGORIES]: [PermissionTypes.READ],
  [UserGroups.OFFENSIVE_WORDS]: [PermissionTypes.READ],
  [UserGroups.SURVEYS]: [
    PermissionTypes.READ,
    PermissionTypes.CREATE,
    PermissionTypes.UPDATE,
    PermissionTypes.APPROVE,
    PermissionTypes.CLONE,
    PermissionTypes.DELETE,
    PermissionTypes.COMPLETE,
  ],
  [UserGroups.OCT_SURVEYS]: [
    PermissionTypes.READ,
    PermissionTypes.CREATE,
    PermissionTypes.UPDATE,
    PermissionTypes.APPROVE,
    PermissionTypes.CLONE,
    PermissionTypes.DELETE,
    PermissionTypes.COMPLETE,
  ],
  [UserGroups.NEWS]: [
    PermissionTypes.READ,
    PermissionTypes.CREATE,
    PermissionTypes.DELETE,
    PermissionTypes.UPDATE,
  ],
};

export const OCT_CONTENT_MANAGER_PERMISSIONS: IPermissions = {
  [UserGroups.USERS]: [],
  [UserGroups.OCT_COMMUNITY]: [PermissionTypes.READ, PermissionTypes.UPDATE],
  [UserGroups.REGULAR_COMMUNITYS]: [
    PermissionTypes.READ,
    PermissionTypes.FOLLOW,
  ],
  [UserGroups.POINTS]: [],
  [UserGroups.CONTACT_REQUESTS]: [],
  [UserGroups.CATEGORIES]: [],
  [UserGroups.OFFENSIVE_WORDS]: [],
  [UserGroups.SURVEYS]: [
    PermissionTypes.READ,
    PermissionTypes.APPROVE,
    PermissionTypes.CLONE,
  ],
  [UserGroups.OCT_SURVEYS]: [
    PermissionTypes.READ,
    PermissionTypes.CREATE,
    PermissionTypes.CLONE,
    PermissionTypes.DELETE,
    PermissionTypes.COMPLETE,
  ],
  [UserGroups.NEWS]: [
    PermissionTypes.READ,
    PermissionTypes.CREATE,
    PermissionTypes.DELETE,
    PermissionTypes.UPDATE,
  ],
};

export const resetPermissions = (): IPermissions => ({
  [UserGroups.USERS]: [],
  [UserGroups.OCT_COMMUNITY]: [],
  [UserGroups.REGULAR_COMMUNITYS]: [],
  [UserGroups.POINTS]: [],
  [UserGroups.CONTACT_REQUESTS]: [],
  [UserGroups.CATEGORIES]: [],
  [UserGroups.OFFENSIVE_WORDS]: [],
  [UserGroups.SURVEYS]: [],
  [UserGroups.OCT_SURVEYS]: [],
  [UserGroups.NEWS]: [],
});

export const getPermissionsByRole = (role: RoleType): IPermissions => {
  switch (role) {
    case RoleType.OCT_ADMIN:
      return OCT_ADMIN_PERMISSIONS;
    case RoleType.OCT_MODERATOR:
      return OCT_ADMIN_PERMISSIONS;
    case RoleType.OCT_CONTENT_MANAGER:
      return OCT_CONTENT_MANAGER_PERMISSIONS;
    default:
      return resetPermissions();
  }
};
