import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import {
  GET_SUBSCRIBED_LIST_ACTION,
  GET_UNSUBSCRIBED_LIST_ACTION,
} from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';
import { IGetInTouch, resetQueryParamsModel } from 'interfaces';

interface GetInTouchReducerState {
  isLoading: boolean;
  getInTouchList: IGetInTouch[];
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = Immutable.fromJS({
  isLoading: false,
  getInTouchList: [],
  getInTouchQueryParams: resetQueryParamsModel(),
} as GetInTouchReducerState);

const { PENDING, DONE, SUCCESS } = makeCombinedAction(
  GET_SUBSCRIBED_LIST_ACTION,
  GET_UNSUBSCRIBED_LIST_ACTION
);

const getinTouchReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [SUCCESS]: (state, { payload }) => {
      return state.set('getInTouchList', Immutable.fromJS(payload));
    },
    [PENDING]: (state, { payload }) => {
      return state.set('getInTouchQueryParams', Immutable.fromJS(payload));
    },
  },
  initialState
);

export default getinTouchReducer;
