import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import {
  BuzzRoute,
  DashboardRoute,
  SurveysRoute,
  CommunityRoute,
  ContactRequestsRoute,
  CategoriesRoute,
  PointsRoute,
  PaymentRoute,
  OffensiveWordsRoute,
  RolesRoute,
  AccountRoute,
  UsersRoute,
  GetInTouchRoute,
  AuthRoute,
} from 'const';

import NotFound from 'components/not-found/NotFound';
import DashboardRouter from './childRoutes/DashboardRouter';
import BuzzRouter from './childRoutes/BuzzRouter';
import SurveysRouter from './childRoutes/SurveysRouter';
import CommunityRouter from './childRoutes/CommunityRouter';
import ContactRequestsRouter from './childRoutes/ContactRequestsRouter';
import CategoriesRouter from './childRoutes/CategoriesRouter';
import PointsRouter from './childRoutes/PointsRouter';
import PaymentRouter from './childRoutes/PaymentRouter';
import OffensiveWordsRouter from './childRoutes/OffensiveWordsRouter';
import RolesRouter from './childRoutes/RolesRouter';
import AccountRouter from './childRoutes/AccountRouter';
import UsersRouter from './childRoutes/UsersRouter';
import { Can } from 'containers/Can';
import { PermissionTypes, UserGroups } from 'interfaces';
import GetInTouchRouter from './childRoutes/GetInTouchRouter';

const RootRouter = () => {
  return (
    <Switch>
      <Route path={DashboardRoute.ROOT} component={DashboardRouter} />
      <Route path={BuzzRoute.ROOT} component={BuzzRouter} />
      <Route path={SurveysRoute.ROOT.pathname} component={SurveysRouter} />
      <Route path={CommunityRoute.ROOT.pathname} component={CommunityRouter} />
      <Route path={UsersRoute.ROOT.pathname} component={UsersRouter} />
      <Route
        path={ContactRequestsRoute.ROOT}
        render={() => {
          return (
            <Can
              I={PermissionTypes.READ}
              a={UserGroups.CONTACT_REQUESTS}
              passThrough
            >
              {(can: boolean) => {
                if (can) return <ContactRequestsRouter />;
                return <NotFound />;
              }}
            </Can>
          );
        }}
      />
      <Route path={GetInTouchRoute.ROOT} component={GetInTouchRouter} />
      <Route
        path={CategoriesRoute.ROOT}
        render={() => {
          return (
            <Can I={PermissionTypes.READ} a={UserGroups.CATEGORIES} passThrough>
              {(can: boolean) => {
                if (can) return <CategoriesRouter />;
                return <NotFound />;
              }}
            </Can>
          );
        }}
      />
      <Route
        path={PointsRoute.ROOT}
        render={() => {
          return (
            <Can
              I={PermissionTypes.READ}
              a={UserGroups.OFFENSIVE_WORDS}
              passThrough
            >
              {(can: boolean) => {
                if (can) return <PointsRouter />;
                return <NotFound />;
              }}
            </Can>
          );
        }}
      />
      <Route
        path={OffensiveWordsRoute.ROOT}
        render={() => {
          return (
            <Can
              I={PermissionTypes.READ}
              a={UserGroups.OFFENSIVE_WORDS}
              passThrough
            >
              {(can: boolean) => {
                if (can) return <OffensiveWordsRouter />;
                return <NotFound />;
              }}
            </Can>
          );
        }}
      />

      <Route path={AccountRoute.ROOT} component={AccountRouter} />

      <Redirect exact from={AuthRoute.ROOT} to={DashboardRoute.ROOT} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default RootRouter;
