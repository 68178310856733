import { call, put, takeLatest } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { string } from 'prop-types';

import {
  encodeDataToUrl,
  httpApi,
  HttpResp,
  makeAction,
  session,
  showMessage,
} from 'utils';
import { forgotPassAction, signInAction, resetPassAction } from 'actions';
import {
  FORGOT_PASSWORD,
  SING_IN_FORM,
  SIGN_IN,
  FORGOT_PASS_FORM,
  INVALID_USER_MESSAGE,
  UPDATE_RECOVERY_PASSWORD_ACTION,
  RESET_PASS_FORM,
  RESET_PASSWORD,
  MESSAGE_SUCESS_PASSWORD_RECOVERY,
  SIGN_OUT,
} from 'const';
import { IForgotPassResp, ISignInResp } from 'interfaces';
import { checkPermission } from 'helpers';

const authParams = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: 'Basic Q1JPV0RUSElOQ19BUFA6cXdlcnR5',
  },
  method: 'POST',
  partUrl: '/oauth/token',
};

function* signIn({ payload, redirect }: ReturnType<typeof signInAction>) {
  const { password, username, rememberMe } = payload;
  try {
    const res: HttpResp<ISignInResp> = yield call(httpApi, {
      ...authParams,
      data: encodeDataToUrl({
        username,
        password,
        grant_type: 'password',
      }),
    });
    if (res && !res.error) {
      const authorities = res.user_name.authorities;
      const authorityRoles = authorities.map((a) => a.authority);
      const hasPermission = checkPermission(authorityRoles);

      if (!hasPermission) {
        showMessage.error(INVALID_USER_MESSAGE);
      } else {
        session.saveData(res, rememberMe);
        if (redirect) {
          redirect();
        }
      }
    } else {
      showMessage.error(res.description);
      yield put(stopSubmit(SING_IN_FORM, { username: res.description }));
      yield put(makeAction(SIGN_IN.FAILURE, res));
    }
  } catch (error) {
    yield put(makeAction(SIGN_IN.FAILURE, error));
  }
}

function* signOut() {
  yield put(makeAction(SIGN_OUT.SUCCESS));
  try {
    yield call(httpApi, {
      partUrl: '/leave',
    });
  } catch (error) {
    yield put(makeAction(SIGN_OUT.FAILURE, error));
  }
}

const refreshToken = () => ({ payload: string });
export function* refreshTokenSaga({
  payload,
}: ReturnType<typeof refreshToken>): any {
  try {
    const resp: HttpResp<ISignInResp> = yield call(httpApi, {
      ...authParams,
      data: encodeDataToUrl({
        grant_type: 'refresh_token',
        refresh_token: payload,
      }),
    });
    if (resp && !resp.error) {
      session.saveData(resp);
    }
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

function* forgotPass({
  payload,
  redirect,
}: ReturnType<typeof forgotPassAction>) {
  const { phoneNumber } = payload;
  try {
    const res: HttpResp<IForgotPassResp> = yield call(httpApi, {
      method: 'POST',
      partUrl: '/users',
      data: {
        phoneNumber,
        forgotPassword: true,
      },
    });

    if (res && !res.error) {
      if (redirect) {
        redirect();
      }
      yield put(makeAction(UPDATE_RECOVERY_PASSWORD_ACTION, { phoneNumber }));
    } else {
      showMessage.error(res.description);
      yield put(stopSubmit(FORGOT_PASS_FORM, { phoneNumber: res.description }));
    }
  } catch (error) {
    yield put(makeAction(SIGN_IN.FAILURE, error));
  }
}

function* resetPassword({
  payload,
  redirect,
}: ReturnType<typeof resetPassAction>) {
  const { newPassword, oneTimePassword, phoneNumber } = payload;
  try {
    const res: HttpResp<IForgotPassResp> = yield call(httpApi, {
      method: 'POST',
      partUrl: '/users',
      data: {
        newPassword,
        oneTimePassword,
        phoneNumber,
        forgotPassword: false,
      },
    });

    if (res && !res.error) {
      if (redirect) {
        redirect();
      }
      yield put(
        makeAction(UPDATE_RECOVERY_PASSWORD_ACTION, { phoneNumber: null })
      );
      showMessage.success(MESSAGE_SUCESS_PASSWORD_RECOVERY);
    } else {
      showMessage.error(res.description);
      yield put(stopSubmit(RESET_PASS_FORM));
    }
  } catch (error) {
    yield put(makeAction(SIGN_IN.FAILURE, error));
  }
}

export function* authSaga() {
  yield takeLatest(SIGN_IN.PENDING, signIn);
  // yield takeLatest(REFRESH_TOKEN, refreshTokenSaga); // Don't use it! (check httpApi)
  yield takeLatest(FORGOT_PASSWORD.PENDING, forgotPass);
  yield takeLatest(RESET_PASSWORD.PENDING, resetPassword);
  yield takeLatest(SIGN_OUT.PENDING, signOut);
}
