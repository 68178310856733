import { call, put, takeLatest } from 'redux-saga/effects';
import {
  encodeDataToUrl,
  httpApi,
  HttpResp,
  makeAction,
  showMessage,
} from 'utils';
import { UsersList } from 'interfaces';
import {
  GET_REQUESTED_LIST,
  MARK_REQUEST_SOLVED,
  GET_REQUESTED_SOLVED_LIST,
} from 'const';
import { getRequestedListAction, markRequestSolvedAction } from 'actions';

function* getRequestedList({
  payload,
}: ReturnType<typeof getRequestedListAction>) {
  try {
    const res: HttpResp<UsersList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/contactus/page?${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_REQUESTED_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_REQUESTED_LIST.FAILURE, res));
    }
  } catch (error) {}
}

function* markRequestSolved({
  payload,
}: ReturnType<typeof markRequestSolvedAction>) {
  try {
    const { id, queryParams } = payload;
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/contactus/mark/${id}`,
    });
    if (res && !res.error) {
      yield put(makeAction(MARK_REQUEST_SOLVED.SUCCESS, res));
      yield put(getRequestedListAction(queryParams));
      showMessage.success('Request marked as solved successfully.');
    } else {
      yield put(makeAction(MARK_REQUEST_SOLVED.FAILURE, res));
    }
  } catch (error) {}
}

export function* contactRequestSaga() {
  yield takeLatest(
    [GET_REQUESTED_LIST.PENDING, GET_REQUESTED_SOLVED_LIST.PENDING],
    getRequestedList
  );
  yield takeLatest(MARK_REQUEST_SOLVED.PENDING, markRequestSolved);
}
