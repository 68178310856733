import moment, { Moment } from 'moment';

export const formatFn = (format: string) => {
  return (date: number | string) =>
    moment(date ? +new Date(date) : undefined).format(format);
};

export const momentDate = (date?: number | string) => {
  return date
    ? moment(typeof date === 'string' ? +new Date(date) : date)
    : moment();
};

export const disabledUnderageDate = (current: Moment): boolean => {
  const maxDate = moment().subtract(21, 'y');
  const minDate = moment().subtract(100, 'y');

  return current && (current > maxDate || current < minDate);
};
