import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import {
  GET_USER_COMMUNITIES,
  GET_USER_PROFILE,
  GET_USER_SURVEYS,
  GET_USER_TRANSACTIONS,
  GET_USERS_LIST,
  BAN_USER,
  UPDATE_USER_PARAMS,
} from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';
import {
  CommunityList,
  QueryParams,
  resetListPaginatedModel,
  resetQueryParamsModel,
  resetUserProfileModel,
  SurveysList,
  TransactionsList,
  UserProfile,
  UsersList,
} from 'interfaces';

interface UsersReducerState {
  isLoading: boolean;
  usersQueryParams: QueryParams;
  usersList: UsersList;
  isLoadingUserProfile: boolean;
  userProfile: UserProfile;
  communityQueryParams: QueryParams;
  communityList: CommunityList;
  surveysQueryParams: QueryParams;
  surveysList: SurveysList;
  transactionsQueryParams: QueryParams;
  transactionsList: TransactionsList;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = Immutable.fromJS({
  isLoading: false,
  usersQueryParams: resetQueryParamsModel(),
  usersList: resetListPaginatedModel(),
  isLoadingUserProfile: false,
  userProfile: resetUserProfileModel(),
  communityQueryParams: resetQueryParamsModel(),
  communityList: resetListPaginatedModel(),
  surveysQueryParams: resetQueryParamsModel(),
  surveysList: resetListPaginatedModel(),
  transactionsQueryParams: resetQueryParamsModel(),
  transactionsList: [],
} as UsersReducerState);

const { PENDING, DONE } = makeCombinedAction(
  GET_USERS_LIST,
  GET_USER_COMMUNITIES,
  GET_USER_PROFILE,
  GET_USER_SURVEYS,
  GET_USER_TRANSACTIONS,
  BAN_USER
);

const usersReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [GET_USERS_LIST.PENDING]: (state, { payload }) => {
      return state.set('usersQueryParams', Immutable.fromJS(payload));
    },
    [GET_USERS_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('usersList', Immutable.fromJS(payload));
    },
    [GET_USER_PROFILE.PENDING]: (state) => {
      return state.set('isLoadingUserProfile', true);
    },
    [`${GET_USER_PROFILE.SUCCESS}||${BAN_USER.SUCCESS}`]: (
      state,
      { payload }
    ) => {
      return state.set('userProfile', Immutable.fromJS(payload));
    },
    [GET_USER_PROFILE.DONE]: (state) => {
      return state.set('isLoadingUserProfile', false);
    },
    [GET_USER_COMMUNITIES.PENDING]: (state, { payload }) => {
      return state.set('communityQueryParams', Immutable.fromJS(payload));
    },
    [GET_USER_COMMUNITIES.SUCCESS]: (state, { payload }) => {
      return state.set('communityList', Immutable.fromJS(payload));
    },
    [GET_USER_SURVEYS.PENDING]: (state, { payload }) => {
      return state.set('surveysQueryParams', Immutable.fromJS(payload));
    },
    [GET_USER_SURVEYS.SUCCESS]: (state, { payload }) => {
      return state.set('surveysList', Immutable.fromJS(payload));
    },
    [GET_USER_TRANSACTIONS.PENDING]: (state, { payload }) => {
      return state.set('transactionsQueryParams', Immutable.fromJS(payload));
    },
    [GET_USER_TRANSACTIONS.SUCCESS]: (state, { payload }) => {
      return state.set('transactionsList', Immutable.fromJS(payload));
    },
    [UPDATE_USER_PARAMS]: (state, { payload }) => {
      const communityQueryParams = state.toJS().communityQueryParams;
      const surveysQueryParams = state.toJS().surveysQueryParams;
      const transactionsQueryParams = state.toJS().transactionsQueryParams;

      state = state.set(
        'communityQueryParams',
        Immutable.fromJS({ ...communityQueryParams, query: payload.query })
      );
      state = state.set(
        'surveysQueryParams',
        Immutable.fromJS({ ...surveysQueryParams, query: payload.query })
      );
      state = state.set(
        'transactionsQueryParams',
        Immutable.fromJS({ ...transactionsQueryParams, query: payload.query })
      );

      return state;
    },
  },
  initialState
);

export default usersReducer;
