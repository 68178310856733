import { IActionFn, QueryParams } from 'interfaces';
import {
  GET_REQUESTED_LIST,
  MARK_REQUEST_SOLVED,
  GET_REQUESTED_SOLVED_LIST,
  RESET_REQUESTED_LIST,
} from 'const';

export const getRequestedListAction: IActionFn<QueryParams> = (params) => ({
  type: GET_REQUESTED_LIST.PENDING,
  payload: params,
});

export const getRequestedSolvedListAction: IActionFn<QueryParams> = (
  params
) => ({
  type: GET_REQUESTED_SOLVED_LIST.PENDING,
  payload: params,
});

export const resetRequestedListAction: IActionFn = () => ({
  type: RESET_REQUESTED_LIST,
  payload: null,
});

type MarkRequestSolvedType = {
  id: number;
  queryParams: QueryParams;
};
export const markRequestSolvedAction: IActionFn<MarkRequestSolvedType> = (
  payload
) => ({
  type: MARK_REQUEST_SOLVED.PENDING,
  payload,
});
