import React from 'react';
import checkImg from 'assets/images/svg/check_in_circle.svg';
import { AuthRoute } from 'const';
import { UniversalButton } from 'components/common/universal';

const ForgotPassCongrats = () => {
  return (
    <form className="f-column f-c-c forgot-pass-form">
      <img src={checkImg} alt="check_in_circle" />
      <h2>Notification</h2>
      <p>
        SMS with a confirmation code has been sent.
        <br /> Use it to verify your phone number.
      </p>

      <UniversalButton block linkTo={AuthRoute.RESET_PASSWORD} btnTitle="OK" />
    </form>
  );
};

export default ForgotPassCongrats;
