import React, { useEffect, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import 'react-image-crop/lib/ReactCrop.scss';
import { SentMedia } from 'reducers/appReducer';
import { closeIcon, Crop, Cropper } from 'components/common';
import {
  UniversalButton,
  UniversalModal,
  UniversalSpinner,
} from '../universal';
import { makeSelector } from 'utils';
import { StatusCode } from 'const';
import { resetUploadFileStateAction, uploadFileAction } from 'actions';
import { classNamesHlp } from 'helpers';

interface IProps extends WrappedFieldProps {
  label: string;
  className: string;
  onChange: any;
  listType?: 'picture' | 'avatar';
  defaultCrop?: Crop;
}

export const FormUploadImage: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const [isPreviewShown, togglePreviewVisible] = useState(false);

  const {
    input: { value, onChange, name },
    meta: { touched, error },
    className = '',
    label,
    listType = 'picture',
    defaultCrop,
  } = props;
  const invalid = touched && error;

  // result after upload on server
  const sentMedia = useSelector<any, SentMedia>(
    makeSelector(['appReducer', 'sentMediaMap', name])
  );

  useEffect(() => {
    if (sentMedia && sentMedia.status === StatusCode.OK) {
      onChange(sentMedia.url);
      dispatch(resetUploadFileStateAction(name));
    }
  }, [(sentMedia || { status: 0 }).status]);

  const togglePreview = () => {
    togglePreviewVisible(!isPreviewShown);
  };

  const onCropComplete = (file: Blob) => {
    updateLoading(true);
    dispatch(
      uploadFileAction({ file, mapKey: name }, () => updateLoading(false))
    );
  };

  const removeImage = () => {
    onChange(null);
  };

  const renderCropper = () => (
    <div className="cropper-block">
      {label && <div className="text-input-label">{label}</div>}
      <div className="form-control-download">
        <Cropper
          defaultCrop={defaultCrop}
          btnTitle={value ? 'Update' : 'Choose file'}
          onCropComplete={onCropComplete}
          invalidError={invalid && error}
        />
        <UniversalSpinner show={isLoading} />
      </div>
    </div>
  );

  const renderPreview = () => (
    <div className={`preview-block f-s-sb`}>
      <div className="w100" onClick={togglePreview}>
        <img src={value} alt="" />
      </div>
      <UniversalButton
        iconComp={closeIcon}
        btnType="link"
        onClick={removeImage}
      />
    </div>
  );

  const renderContent = () => {
    switch (listType) {
      case 'picture':
        return (
          <div className="upload-file-cmp upload-file-cmp-picture">
            {renderCropper()}
            {value && renderPreview()}
          </div>
        );
      case 'avatar':
        return (
          <div className="upload-file-cmp upload-file-cmp-avatar f-c-s">
            {value && renderPreview()}
            {renderCropper()}
          </div>
        );
    }
  };

  return (
    <div className={classNamesHlp('form-group', className, { invalid })}>
      <div className="form-control-wrapper">
        {renderContent()}

        <UniversalModal
          type="media"
          imageSrc={value}
          visible={isPreviewShown}
          onCancel={togglePreview}
        />
      </div>
    </div>
  );
};
