import React from 'react';
import { useSelector } from 'react-redux';

import { SearchBlock } from 'components/common/parts';
import { UniversalTable } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { makeSelector } from 'utils';
import { getRequestedSolvedListAction } from 'actions';
import { RequestedList, UniversalTableItem } from 'interfaces';
import { contactRequestTableParams } from './contact-request-table-params';

interface IProps {
  openEditor({ id }: { id: UniversalTableItem['id'] }): () => void;
}

const SolvedList: React.FC<IProps & SearchableTable> = ({
  getList,
  isLoading,
  queryParams,
  openEditor,
}) => {
  const { data, recordsFiltered } = useSelector<any, RequestedList>(
    makeSelector(['contactRequestReducer', 'requestedList'])
  );

  const handleSelectText = (id: UniversalTableItem['id']) => {
    openEditor({ id })();
  };

  return (
    <>
      <SearchBlock onChange={getList} initialQuery={queryParams} />
      <UniversalTable
        titles={[...contactRequestTableParams.titles]}
        data={data}
        onChange={getList}
        total={recordsFiltered}
        isLoading={isLoading}
        initialQuery={queryParams}
        onRowClick={handleSelectText}
      />
    </>
  );
};

export default SearchableTableContainer<IProps>({
  getListAction: getRequestedSolvedListAction,
  reducerName: 'contactRequestReducer',
  queryName: 'queryParamsSolved',
  solved: true,
  isEqualSearch: true,
})(SolvedList);
