import React, { useEffect, useState } from 'react';
import { submit } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { CategoryHeader } from 'components/common/parts';
import {
  makeBtn,
  UniversalModal,
  IUniversalModalProps,
} from 'components/common/universal';
import { checkIcon, closeIcon } from 'components/common';
import { cssPrefixCreator, getMatchParam } from 'helpers';
import { COMMUNITY_EDIT_NEWS_FORM } from 'const';
import {
  Community,
  IImmutableMap,
  PermissionTypes,
  UserGroups,
} from 'interfaces';
import { makeSelector } from 'utils';
import {
  editCommunityNewsAction,
  addCommunityNewsAction,
  getCommunityNewsAction,
  getCommunityAction,
  deleteCommunityNewsAction,
} from 'actions';

import CommunityEditNewsForm from './CommunityEditNewsForm';
import { INews } from 'interfaces/community/news';
import { customAbility } from 'containers/Can';

interface IProp extends RouteComponentProps {}

const CommunityNewsEditPage: React.FC<IProp> = ({ history, match }) => {
  const dispatch = useDispatch();
  const [isPrompt, setPromptState] = useState(false);
  const [modalParams, setModalParams] = useState<IUniversalModalProps | null>(
    null
  );

  const community = useSelector<any, Community>(
    makeSelector(['communityReducer', 'community'])
  );
  const news = useSelector<any, INews>(
    makeSelector(['communityReducer', 'news'])
  );

  const communityId = Number.parseInt(getMatchParam(match).id, 10);
  const newsId = Number.parseInt(getMatchParam(match).idn, 10);
  const isEditMode = Number.isNaN(newsId) !== true;

  useEffect(() => {
    if (communityId) {
      dispatch(getCommunityAction(communityId));
    }
    if (newsId) {
      dispatch(getCommunityNewsAction({ communityId, newsId }));
    }
  }, []);

  const closePrompt = () => {
    setPromptState(false);
  };

  const showPromptWith = (params: IUniversalModalProps) => () => {
    setModalParams(params);
    setPromptState(true);
  };

  const remoteSubmitEditForm = () => {
    dispatch(submit(COMMUNITY_EDIT_NEWS_FORM));
  };

  const onSubmit = (
    formData: IImmutableMap<Community> | any,
    dispatch: Dispatch<any>
  ) => {
    const formValues: INews = formData.toJS();

    const sendReq = {
      communityId,
      news: formValues,
    };

    const action = isEditMode
      ? editCommunityNewsAction(sendReq, history.goBack)
      : addCommunityNewsAction(sendReq, history.goBack);
    dispatch(action);
  };

  const deleteNews = () => {
    dispatch(
      deleteCommunityNewsAction({ communityId, newsId }, history.goBack)
    );
    closePrompt();
  };

  const initialValues = {
    ...news,
  };

  const headerCtrlButton = () => {
    const promptProps: IUniversalModalProps = {
      visible: true,
      onCancel: closePrompt,
    };

    const isOwn = community.own === true;

    const canCreate =
      customAbility.can(PermissionTypes.CREATE, UserGroups.NEWS) || isOwn;
    const canUpdate =
      customAbility.can(PermissionTypes.UPDATE, UserGroups.NEWS) || isOwn;
    const canDelete =
      customAbility.can(PermissionTypes.DELETE, UserGroups.NEWS) || isOwn;

    if (!isEditMode && canCreate) {
      return makeBtn('Create News', checkIcon, 'success', remoteSubmitEditForm);
    }
    if (canUpdate || canDelete) {
      const deletePrompt = {
        ...promptProps,
        onOk: deleteNews,
      };

      return (
        <>
          {canDelete &&
            makeBtn(
              'Delete News',
              closeIcon,
              'error',
              showPromptWith(deletePrompt)
            )}
          {canUpdate &&
            makeBtn('Save News', checkIcon, 'success', remoteSubmitEditForm)}
        </>
      );
    }
    return null;
  };

  const cssPrefix = cssPrefixCreator('community-edit-page');
  return (
    <div className={cssPrefix(' f-column')}>
      <CategoryHeader
        backBtn={{ showButton: true, showModal: true }}
        ctrlBtns={headerCtrlButton()}
      />

      <CommunityEditNewsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        isEditMode={isEditMode}
      />

      <UniversalModal {...modalParams} visible={isPrompt} />
    </div>
  );
};

export default CommunityNewsEditPage;
