import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { CategoryHeader } from 'components/common/parts';
import {
  IUniversalModalProps,
  makeBtn,
  UniversalModal,
} from 'components/common/universal';
import {
  checkIcon,
  closedIcon,
  deleteIcon,
  editIcon,
  refreshIcon,
} from 'components/common';
import SurveyDetailsQuestionsStatistic from './parts/SurveyDetailsQuestionsStatistic';
import SurveyDetailsInfo from './parts/SurveyDetailsInfo';
import SurveyDetailsQuestionsPreview from './parts/SurveyDetailsQuestionsPreview';
import {
  cssPrefixCreator,
  getMatchParam,
  prepareCategorySubTitle,
  prepareSurveyStatus,
  prepareSubTitlelink,
  SubTitleLinkTypes,
} from 'helpers';
import {
  deleteSurveyAction,
  getSurveyAction,
  resetSurveyStateAction,
  setSurveyStatusAction,
  editSurveyAction,
  getSurveyQuestionsAction,
} from 'actions';
import { makeSelector } from 'utils';
import { useLoading, useFormInModal } from 'hooks';
import {
  Survey,
  SurveyStatus,
  SurveyReq,
  QuestionList,
  SurveyStatusActions,
  IImmutableMap,
} from 'interfaces';
import { PromptPrefix, SurveysRoute, REJECT_SURVEY_FORM } from 'const';

import './survey-details-page.scss';
import SurveyDetailsQuestionsSkeleton from './parts/surveyDetailsQuestionsSkeleton';
import { withOwnUser, IWithOwnUser } from 'containers';
import RejectSurveyForm from './RejectSurveyForm';
import { submit } from 'redux-form';
import SurveyRejectInfo from './parts/SurveyRejectInfo';
import { getSurveyPermission } from 'helpers/permission/survey';

interface IProps extends RouteComponentProps, IWithOwnUser {}

const SurveyDetailsPage: React.FC<IProps> = ({ history, match, profile }) => {
  const dispatch = useDispatch();
  const [isPrompt, setPromptState] = useState(false);
  const [modalParams, setModalParams] = useState<IUniversalModalProps>();

  // PROMPTS
  const {
    formInitialValues,
    isPrompt: isPromptReject,
    closeEditor: closeRejectModal,
    openEditor: openRejectModal,
  } = useFormInModal([{ description: '' }], 'Description');

  const { id } = getMatchParam(match);
  const survey = useSelector<any, Survey>(
    makeSelector(['surveysReducer', 'survey'])
  );
  const questionList = useSelector<any, QuestionList>(
    makeSelector(['surveysReducer', 'questionList'])
  );
  const isLoading = useLoading('surveysReducer');
  const isLoadingQuestions = useLoading([
    'surveysReducer',
    'isLoadingQuestions',
  ]);

  const { title, owner, community, dateCreated, status, isPulse } = survey;
  const isDraft = [SurveyStatus.NONE, SurveyStatus.DRAFT].includes(status);
  const isOwnSurvey = profile.id === owner.id;
  const isOwnCommunity = community.owner
    ? profile.id === community.owner.id
    : false;
  const isOCT = community.oct;

  const {
    canApprove,
    canClone,
    canComplete,
    canDelete,
    canReject,
    canUpdate,
  } = getSurveyPermission({
    isOCT,
    isOwnCommunity,
    isOwnSurvey,
    isPulse,
    status,
  });

  useEffect(() => {
    if (id) {
      dispatch(getSurveyAction(id));
    }
    return () => {
      dispatch(resetSurveyStateAction(null));
    };
  }, []);

  useEffect(() => {
    if (id && isDraft) {
      dispatch(getSurveyQuestionsAction(id));
    }
  }, [isDraft]);

  const remoteSubmit = () => {
    dispatch(submit(REJECT_SURVEY_FORM));
  };

  const closePrompt = () => {
    setPromptState(false);
  };

  const showPromptWith = (params: IUniversalModalProps) => () => {
    setModalParams(params);
    setPromptState(true);
  };

  // delete
  const deleteSurvey = () => {
    dispatch(deleteSurveyAction(id, history.goBack));
    closePrompt();
  };

  const completeSurvey = () => {
    const status = SurveyStatusActions.COMPLETE;
    dispatch(setSurveyStatusAction({ id, status }));
    closePrompt();
  };

  const approveSurvey = () => {
    const status = SurveyStatusActions.ACCEPT;
    dispatch(setSurveyStatusAction({ id, status }));
    closePrompt();
  };

  const rejectSurvey = (
    formData: IImmutableMap<{ description: string }> | any
  ) => {
    const { description } = formData.toJS();
    const status = SurveyStatusActions.REJECT;
    dispatch(setSurveyStatusAction({ id, status, description }));
    closeRejectModal();
  };

  const activateSurvey = () => {
    const status = SurveyStatusActions.LIVE;
    dispatch(setSurveyStatusAction({ id, status }));
    closePrompt();
  };

  const cloneSurvey = () => {
    const status = SurveyStatusActions.CLONE;
    dispatch(setSurveyStatusAction({ id, status }));
    closePrompt();
  };

  const toEditDraft = () => {
    history.push(SurveysRoute.getEditRoute(id));
  };

  const createSurvey = () => {
    const isDraft = false;
    const surveyReq: SurveyReq = {
      ...survey,
      isDraft,
      publish: !isDraft,
      status: SurveyStatus.MODERATION,
      questionDtoList: questionList,
    };

    dispatch(editSurveyAction(surveyReq, history.goBack));
  };

  /* !!!Depends on survey status we should change:
   // - header control buttons,
   // - prompt modal parameters
   // - survey question view
   */

  // Here we can prepare header control buttons and set
  // prompt props by clicking them
  const prepareHeaderCtrlBtns = () => {
    const promptProps: IUniversalModalProps = {
      visible: true,
      onCancel: closePrompt,
    };

    switch (status) {
      case SurveyStatus.NONE:
      case SurveyStatus.DRAFT: {
        const deletePrompt = {
          ...promptProps,
          onOk: deleteSurvey,
        };
        return (
          <>
            {canDelete &&
              makeBtn(
                'Delete Draft',
                deleteIcon,
                'error',
                showPromptWith(deletePrompt)
              )}
            {canUpdate && (
              <>
                {makeBtn('Edit Draft', editIcon, 'success', toEditDraft)}
                {makeBtn('Publish Draft', checkIcon, 'success', createSurvey)}
              </>
            )}
          </>
        );
      }
      case SurveyStatus.LIVE: {
        const completePrompt = {
          ...promptProps,
          promptText: `${PromptPrefix.ARE_SURE} complete the survey?`,
          promptOkBtn: makeBtn(
            'Complete!',
            checkIcon,
            'success',
            completeSurvey
          ),
        };
        const btnName = isPulse ? 'Pulse' : 'Survey';
        return (
          canComplete && (
            <>
              {makeBtn(
                `Complete ${btnName}`,
                checkIcon,
                'success',
                showPromptWith(completePrompt)
              )}
              {/* {makeBtn('Export Statistic', fileIcon, 'success', exportStatistic)} */}
            </>
          )
        );
      }
      case SurveyStatus.MODERATION: {
        const approvePrompt = {
          ...promptProps,
          promptText: `${PromptPrefix.ARE_SURE} approve the survey?`,
          promptOkBtn: makeBtn('Approve!', checkIcon, 'success', approveSurvey),
        };
        return (
          <>
            {canApprove &&
              makeBtn(
                'Approve Survey',
                checkIcon,
                'success',
                showPromptWith(approvePrompt)
              )}
            {canReject &&
              makeBtn('Reject Survey', closedIcon, 'error', openRejectModal())}
          </>
        );
      }
      case SurveyStatus.COMPLETED: {
        const delPrompt = {
          ...promptProps,
          onOk: deleteSurvey,
        };
        const btnName = isPulse ? 'pulse' : 'survey';
        const reopenPrompt = {
          ...promptProps,
          promptText: `${PromptPrefix.ARE_SURE} re-open the ${btnName}?`,
          promptOkBtn: makeBtn(
            'Re-open!',
            checkIcon,
            'success',
            activateSurvey
          ),
        };
        const clonePrompt = {
          ...promptProps,
          promptText: `${PromptPrefix.ARE_SURE} clone the ${btnName}?`,
          promptOkBtn: makeBtn('Clone!', checkIcon, 'success', cloneSurvey),
        };

        return (
          <>
            {canDelete &&
              makeBtn(
                `Delete ${btnName}`,
                deleteIcon,
                'error',
                showPromptWith(delPrompt)
              )}
            {canUpdate &&
              makeBtn(
                'Back to Live',
                refreshIcon,
                'success',
                showPromptWith(reopenPrompt)
              )}
            {canClone &&
              makeBtn(
                `Clone ${btnName}`,
                refreshIcon,
                'success',
                showPromptWith(clonePrompt)
              )}
          </>
        );
      }
      case SurveyStatus.REJECTED: {
        const delPrompt = {
          ...promptProps,
          onOk: deleteSurvey,
        };
        const btnName = isPulse ? 'pulse' : 'survey';
        return (
          canDelete &&
          makeBtn(
            `Delete ${btnName}`,
            deleteIcon,
            'error',
            showPromptWith(delPrompt)
          )
        );
      }
      default:
        return [];
    }
  };

  const renderQuestionsView = () => {
    if (isLoading) {
      return <SurveyDetailsQuestionsSkeleton />;
    }
    switch (status) {
      case SurveyStatus.MODERATION:
      case SurveyStatus.DRAFT:
        return (
          <SurveyDetailsQuestionsPreview
            questionId={id}
            cssPrefix={cssPrefix}
            isLoading={isLoadingQuestions}
          />
        );
      case SurveyStatus.NONE:
      case SurveyStatus.LIVE:
      case SurveyStatus.BLOCKED:
      case SurveyStatus.COMPLETED:
        return (
          <SurveyDetailsQuestionsStatistic
            questionId={id}
            cssPrefix={cssPrefix}
            isLoading={isLoadingQuestions}
          />
        );
      case SurveyStatus.REJECTED:
        return <SurveyRejectInfo survey={survey} />;
      default:
        return (
          <div className="card">
            <i>no data</i>
          </div>
        );
    }
  };

  const cssPrefix = cssPrefixCreator('survey-details-page');

  const userSubTitleLink = prepareSubTitlelink({
    type: SubTitleLinkTypes.USER,
    title: owner.username,
    id: owner.id,
  });
  const communitySubTitleLink = prepareSubTitlelink({
    type: SubTitleLinkTypes.COMMUNITY,
    title: community.title,
    id: community.id,
  });

  return (
    <div className={cssPrefix()}>
      <CategoryHeader
        catTitle={title}
        catSubTitle={prepareCategorySubTitle(
          userSubTitleLink,
          communitySubTitleLink,
          dateCreated
        )}
        ctrlBtns={prepareHeaderCtrlBtns()}
        backBtn={{ showButton: true }}
        itemStatus={prepareSurveyStatus(status)}
        isLoading={isLoading}
      />

      <div className={cssPrefix('main flex')}>
        <SurveyDetailsInfo
          isLoading={isLoading}
          survey={survey}
          cssPrefix={cssPrefix}
        />

        {renderQuestionsView()}
      </div>

      <UniversalModal {...modalParams} visible={isPrompt} />
      <UniversalModal
        onCancel={closeRejectModal}
        promptOkBtn={makeBtn('Reject!', checkIcon, 'error', remoteSubmit)}
        visible={isPromptReject}
        title="Add Reason"
      >
        <RejectSurveyForm
          initialValues={formInitialValues}
          onSubmit={rejectSurvey}
        />
      </UniversalModal>
    </div>
  );
};

export default withOwnUser(SurveyDetailsPage);
