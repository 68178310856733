import { Community, IUniversalTableParams } from 'interfaces';
import { CommunityRoute, FORMAT_TABLE_DATE, communityDetailPath } from 'const';
import {
  booleanIconFn,
  categoryFn,
  dateFn,
  locationFn,
  ownerFn,
  visibilityFn,
} from 'components/common/universal/utils/universal-helper';
import { amountFn } from 'helpers';

const updatedCommunityRoute = {
  ...CommunityRoute,
  getDetailsRoute(id: string | number) {
    const result = CommunityRoute.getDetailsRoute(id);
    return `${result}/${communityDetailPath.MAIN_INFO}`;
  },
};

export const communityTableParams: IUniversalTableParams<Community> = {
  titles: [
    {
      title: 'ID',
      propName: 'id',
    },
    {
      title: 'Title',
      propName: 'title',
    },
    {
      title: 'Date',
      propName: 'dateCreated',
      formatFn: dateFn(FORMAT_TABLE_DATE),
    },
    {
      title: 'Category',
      propName: 'category',
      formatFn: categoryFn,
    },
    {
      title: 'Owner',
      propName: 'owner',
      formatFn: ownerFn,
    },
    {
      title: 'Visibility',
      propName: 'isPrivate',
      formatFn: visibilityFn,
    },
    {
      title: 'Followers',
      propName: 'numberOfUsers',
      formatFn: amountFn,
      isSortable: false,
    },
    {
      title: 'Active Surveys',
      propName: 'numberOfSurveys',
      formatFn: amountFn,
      isSortable: false,
    },
    {
      title: 'Location',
      propName: 'location',
      formatFn: locationFn,
    },
    {
      title: 'Blocked',
      propName: 'blocked',
      formatFn: booleanIconFn('error'),
    },
    {
      title: 'Deleted',
      propName: 'deleted',
      formatFn: booleanIconFn('error'),
    },
  ],
  data: [],
  routeConst: updatedCommunityRoute,
};
