import {
  GET_OWN_PROFILE,
  GET_OWN_SURVEYS,
  GET_OWN_TRANSACTIONS,
  UPD_OWN_PROFILE,
} from 'const';
import { IActionFn, QueryParams, UserProfile } from 'interfaces';

export const getOwnProfileAction: IActionFn<string, { userId: string }> = (
  userId
) => ({
  type: GET_OWN_PROFILE.PENDING,
  payload: { userId },
});

export const updOwnProfileAction: IActionFn<UserProfile> = (profile) => ({
  type: UPD_OWN_PROFILE.PENDING,
  payload: profile,
});

export const getOwnTransactionsAction: IActionFn<QueryParams> = (params) => ({
  type: GET_OWN_TRANSACTIONS.PENDING,
  payload: params,
});

export const getOwnSurveysAction: IActionFn<QueryParams> = (params) => ({
  type: GET_OWN_SURVEYS.PENDING,
  payload: params,
});
