import { makeActionType } from 'utils/app/reduxHelper';

// APP
export const UPLOAD_FILE = makeActionType('UPLOAD_FILE');
export const RESET_UPLOAD_FILE_STATE = makeActionType(
  'RESET_UPLOAD_FILE_STATE'
);

// AUTH
export const SIGN_IN = makeActionType('SIGN_IN');
export const SIGN_OUT = makeActionType('SIGN_OUT');

// forgot password
export const FORGOT_PASSWORD = makeActionType('FORGOT_PASSWORD');
export const UPDATE_RECOVERY_PASSWORD_ACTION =
  'UPDATE_RECOVERY_PASSWORD_ACTION';

// reset password
export const RESET_PASSWORD = makeActionType('RESET_PASSWORD');

// OWN PROFILE
export const GET_OWN_PROFILE = makeActionType('GET_OWN_PROFILE');
export const UPD_OWN_PROFILE = makeActionType('UPD_OWN_PROFILE');
export const GET_OWN_TRANSACTIONS = makeActionType('GET_OWN_TRANSACTIONS');
export const GET_OWN_SURVEYS = makeActionType('GET_OWN_SURVEYS');

// BUZZ
export const GET_BUZZ_LIST = makeActionType('GET_BUZZ_LIST');
export const GET_BUZZ = makeActionType('GET_BUZZ');
export const DELETE_BUZZ = makeActionType('DELETE_BUZZ');
export const EDIT_BUZZ = makeActionType('EDIT_BUZZ');
export const ADD_BUZZ = makeActionType('ADD_BUZZ');
export const RESET_BUZZ_STATE = makeActionType('RESET_BUZZ_STATE');

// SURVEYS/PULSES
export const START_SURVEY_ACTION = 'START_SURVEY_ACTION';
export const STOP_SURVEY_ACTION = 'STOP_SURVEY_ACTION';
export const GET_SURVEY_LIST = makeActionType('GET_SURVEY_LIST');
export const GET_PULSES_LIST = makeActionType('GET_PULSES_LIST');
export const GET_SURVEY = makeActionType('GET_SURVEY');
export const SET_SURVEY_STATUS = makeActionType('SET_SURVEY_STATUS');
export const GET_QUESTIONS = makeActionType('GET_QUESTIONS');
export const RESET_QUESTIONS_STATE = makeActionType('RESET_QUESTIONS_STATE');
export const GET_QUESTIONS_STATISTIC = makeActionType(
  'GET_QUESTIONS_STATISTIC'
);

export const DELETE_SURVEY = makeActionType('DELETE_SURVEY');
export const EDIT_SURVEY = makeActionType('EDIT_SURVEY');
export const ADD_SURVEY = makeActionType('ADD_SURVEY');
export const RESET_SURVEY_STATE = makeActionType('RESET_SURVEY_STATE');

// CATEGORIES
export const GET_CATEGORIES_LIST = makeActionType('GET_CATEGORIES_LIST');
export const GET_CATEGORIES_SEARCH_LIST = makeActionType(
  'GET_CATEGORIES_SEARCH_LIST'
);
export const EDIT_CATEGORY = makeActionType('EDIT_CATEGORY');
export const ADD_CATEGORY = makeActionType('ADD_CATEGORY');
export const DELETE_CATEGORY = makeActionType('DELETE_CATEGORY');

// COMMUNITIES
export const START_COMMUNITY_ACTION = 'START_COMMUNITY_ACTION';
export const STOP_COMMUNITY_ACTION = 'STOP_COMMUNITY_ACTION';
export const GET_COMMUNITY_LIST = makeActionType('GET_COMMUNITY_LIST');
export const GET_COMMUNITY = makeActionType('GET_COMMUNITY');
export const ADD_COMMUNITY = makeActionType('ADD_COMMUNITY');
export const EDIT_COMMUNITY = makeActionType('EDIT_COMMUNITY');
export const BLOCK_COMMUNITY = makeActionType('BLOCK_COMMUNITY');
export const FOLLOW_COMMUNITY = makeActionType('FOLLOW_COMMUNITY');
export const MARK_DELETED_COMMUNITY = makeActionType('MARK_DELETED_COMMUNITY');
export const DELETE_COMMUNITY = makeActionType('DELETE_COMMUNITY');
export const GET_COMMUNITY_SURVEYS = makeActionType('GET_COMMUNITY_SURVEYS');
export const GET_COMMUNITY_FOLLOWERS = makeActionType(
  'GET_COMMUNITY_FOLLOWERS'
);
export const GET_COMMUNITY_MODERATORS = makeActionType(
  'GET_COMMUNITY_MODERATORS'
);
export const RESET_COMMUNITY_STATE = makeActionType('RESET_COMMUNITY_STATE');
export const GET_COMMUNITY_NEWS_LIST = makeActionType(
  'GET_COMMUNITY_NEWS_LIST'
);
export const GET_COMMUNITY_ACTIVE_NEWS_LIST = makeActionType(
  'GET_COMMUNITY_ACTIVE_NEWS_LIST'
);
export const GET_COMMUNITY_NEWS = makeActionType('GET_COMMUNITY_NEWS');
export const ADD_COMMUNITY_NEWS = makeActionType('ADD_COMMUNITY_NEWS');
export const EDIT_COMMUNITY_NEWS = makeActionType('EDIT_COMMUNITY_NEWS');
export const CHANGE_NEWS_STATUS = makeActionType('CHANGE_NEWS_STATUS');
export const DELETE_COMMUNITY_NEWS = makeActionType('DELETE_COMMUNITY_NEWS');
export const RESET_COMMUNITY_NEWS_STATE = makeActionType(
  'RESET_COMMUNITY_NEWS_STATE'
);
export const CHANGE_ORDER_COMMUNITY_NEWS = makeActionType(
  'CHANGE_ORDER_COMMUNITY_NEWS'
);
export const GET_COMMUNITIES_SEARCH_LIST = makeActionType(
  'GET_COMMUNITIES_SEARCH_LIST'
);
export const UPDATE_ORDER_COMMUNITY_NEWS_STATE =
  'UPDATE_ORDER_COMMUNITY_NEWS_STATE';

// USERS
export const GET_USERS_LIST = makeActionType('GET_USERS_LIST');
export const GET_USER_PROFILE = makeActionType('GET_USER_PROFILE');
export const BAN_USER = makeActionType('BAN_USER');
export const DELETE_USER = makeActionType('DELETE_USER');
export const GET_USER_COMMUNITIES = makeActionType('GET_USER_COMMUNITIES');
export const GET_USER_SURVEYS = makeActionType('GET_USER_SURVEYS');
export const GET_USER_TRANSACTIONS = makeActionType('GET_USER_TRANSACTIONS');
export const CHANGE_USER_PASSWORD = makeActionType('CHANGE_USER_PASSWORD');
export const CHANGE_USER_PHONE = makeActionType('CHANGE_USER_PHONE');

// CONTACT REQUESTS
export const GET_REQUESTED_LIST = makeActionType('GET_REQUESTED_LIST');
export const GET_REQUESTED_SOLVED_LIST = makeActionType(
  'GET_REQUESTED_SOLVED_LIST'
);
export const RESET_REQUESTED_LIST = 'RESET_REQUESTED_LIST';
export const MARK_REQUEST_SOLVED = makeActionType('MARK_REQUEST_SOLVED');

// POINTS
export const GET_POINTS_CONFIG = makeActionType('GET_POINTS_CONFIG');
export const EDIT_POINTS_CONFIG = makeActionType('EDIT_POINTS_CONFIG');
export const GIVE_POINTS_TO_USER = makeActionType('GIVE_POINTS_TO_USER');

// POINTS
export const GET_PAYMENT_PLANS = makeActionType('GET_PAYMENT_PLANS');
export const EDIT_PAYMENT_PLAN = makeActionType('EDIT_PAYMENT_PLAN');
export const ADD_PAYMENT_PLAN = makeActionType('ADD_PAYMENT_PLAN');
export const DELETE_PAYMENT_PLAN = makeActionType('DELETE_PAYMENT_PLAN');

// OFFENSIVE WORDS
export const GET_OFFENSIVE_WORDS_LIST = makeActionType(
  'GET_OFFENSIVE_WORDS_LIST'
);
export const ADD_OFFENSIVE_WORD = makeActionType('ADD_OFFENSIVE_WORD');
export const DELETE_OFFENSIVE_WORD = makeActionType('DELETE_OFFENSIVE_WORD');

// DASHBOARD
export const GET_DASHBOARD_CONFIG = makeActionType('GET_DASHBOARD_CONFIG');

// EQUAL QUERY PARAMS

export const UPDATE_SURVEY_PARAMS = 'UPDATE_SURVEY_PARAMS';
export const UPDATE_COMMUNITY_PARAMS = 'UPDATE_COMMUNITY_PARAMS';
export const UPDATE_USER_PARAMS = 'UPDATE_USER_PARAMS';
export const UPDATE_CONTACT_REQUESTS_PARAMS = 'UPDATE_CONTACT_REQUESTS_PARAMS';

// NOTIFICATIONS
export const SEND_NOTIFICATION_ALL = makeActionType('SEND_NOTIFICATION_ALL');

// GET IN TOUCH
export const GET_SUBSCRIBED_LIST_ACTION = makeActionType(
  'GET_SUBSCRIBED_LIST_ACTION'
);
export const GET_UNSUBSCRIBED_LIST_ACTION = makeActionType(
  'GET_UNSUBSCRIBED_LIST_ACTION'
);
export const UNSUBSCRIBE_ACTION = makeActionType('UNSUBSCRIBE_ACTION');
export const DELETE_UNSUBSCRIBED_ACTION = makeActionType(
  'DELETE_UNSUBSCRIBED_ACTION'
);
