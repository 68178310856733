import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SearchBlock } from 'components/common/parts';
import { UniversalTable, UniversalButton } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import {
  getCommunityNewsListAction,
  resetCommunityNewsAction,
  changeCommunityNewsStatusAction,
} from 'actions';
import { makeSelector } from 'utils';
import {
  QueryParams,
  Community,
  ITitle,
  PermissionTypes,
  UserGroups,
} from 'interfaces';
import { getMatchParam } from 'helpers';
import { newsTableParams } from 'assets/static-data';
import { CommunityRoute, communityDetailPath } from 'const';
import { INews, NewsList } from 'interfaces/community/news';
import { customAbility } from 'containers/Can';

interface IProps extends SearchableTable {}

const CommunityInactiveNews: React.FC<IProps> = ({
  match,
  getList,
  isLoading,
  queryParams,
  history,
}) => {
  const dispatch = useDispatch();
  const { data, recordsFiltered } = useSelector<any, NewsList>(
    makeSelector(['communityReducer', 'newsList'])
  );

  const community = useSelector<any, Community>(
    makeSelector(['communityReducer', 'community'])
  );

  const idFromUrl = getMatchParam(match).id;
  const { id: communityId, own: isOwn } = community;

  useEffect(() => {
    onGetList(queryParams);
  }, []);

  const onGetList = (params: QueryParams) => {
    getList({ ...params, id: idFromUrl });
  };

  const addNews = () => {
    dispatch(resetCommunityNewsAction(null));
    const detailPath = CommunityRoute.getDetailsRoute(idFromUrl);
    history.push(`${detailPath}/${communityDetailPath.ADD_NEWS}`);
  };

  const onActiveNews = (id: number) => () => {
    const news = data.find((n) => n.id === id);
    if (!news) return;

    const reqData = {
      ...news,
      active: !news.active,
    };
    dispatch(
      changeCommunityNewsStatusAction({
        news: reqData,
        communityId,
        queryParams,
      })
    );
  };

  const actionFn = (id: number) => (
    <UniversalButton
      btnTitle="Active"
      styleType="success"
      onClick={onActiveNews(id)}
    />
  );

  const tableCell: ITitle<keyof INews> = {
    title: 'Active',
    propName: 'active',
    className: 'action',
    canRedirect: false,
    isSortable: false,
    formatPropName: 'id',
    formatFn: actionFn,
  };

  const titles = [...newsTableParams.titles, tableCell];

  const canCreate =
    customAbility.can(PermissionTypes.CREATE, UserGroups.NEWS) || isOwn;

  return (
    <>
      <SearchBlock
        onChange={getList}
        initialQuery={queryParams}
        btnTitle={canCreate ? 'Create News' : undefined}
        onClickBtn={addNews}
      />
      <UniversalTable
        {...newsTableParams}
        titles={titles}
        data={data}
        onChange={onGetList}
        isLoading={isLoading}
        total={recordsFiltered}
        initialQuery={queryParams}
        className={`fixed-column--${titles.length}`}
      />
    </>
  );
};

export default SearchableTableContainer({
  getListAction: getCommunityNewsListAction,
  isInitialized: false,
  reducerName: 'communityReducer',
  queryName: 'newsQueryParams',
})(CommunityInactiveNews);
