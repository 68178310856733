import React, { useEffect } from 'react';
import { submit } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNodeLike } from 'prop-types';

import { CategoryHeader } from 'components/common/parts';
import { UniversalButton } from 'components/common/universal';
import { checkIcon } from 'components/common';
import BuzzEditForm from './BuzzEditForm';
import {
  addBuzzAction,
  editBuzzAction,
  getBuzzAction,
  resetBuzzStateAction,
} from 'actions';
import { Buzz, IImmutableMap } from 'interfaces';
import { cssPrefixCreator, getMatchParam } from 'helpers';
import { BUZZ_EDIT_FORM, BuzzRoute } from 'const';

import './buzz-edit-page.scss';

interface IProp extends RouteComponentProps {}

const BuzzEditPage = ({ history, match }: IProp) => {
  const dispatch = useDispatch();
  const idFromUrl = getMatchParam(match).id;

  const buzz = useSelector<any, Buzz>((state) => {
    return state.getIn(['buzzReducer', 'buzz']).toJS();
  });

  useEffect(() => {
    if (idFromUrl) {
      if (!buzz.id) {
        dispatch(getBuzzAction(idFromUrl));
      }
    } else {
      dispatch(resetBuzzStateAction(null));
    }
  }, []);

  const remoteSubmitBuzzEditForm = () => {
    dispatch(submit(BUZZ_EDIT_FORM));
  };

  const onSubmit = (
    formData: IImmutableMap<Buzz> | any,
    dispatch: Dispatch<any>
  ) => {
    const buzz = formData.toJS();
    const action = buzz.id
      ? editBuzzAction(buzz, history.goBack)
      : addBuzzAction(buzz, () => history.push(BuzzRoute.ROOT));
    dispatch(action);
  };

  const headerCtrlButton: ReactNodeLike = (
    <UniversalButton
      btnTitle="Save The Buzz"
      iconComp={checkIcon}
      styleType="success"
      onClick={remoteSubmitBuzzEditForm}
    />
  );

  const cssPrefix = cssPrefixCreator('buzz-edit-page');
  return (
    <div className={cssPrefix(' f-column')}>
      <CategoryHeader
        backBtn={{ showButton: true, showModal: true }}
        ctrlBtns={headerCtrlButton}
      />

      <div className="card">
        <BuzzEditForm initialValues={buzz} onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default BuzzEditPage;
