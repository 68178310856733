import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
  deletedCommunityAction,
  editCommunityAction,
  getCommunityAction,
  getCommunityListAction,
  getCommunitySurveysAction,
  markDeletedCommunityAction,
  addCommunityAction,
  blockCommunityAction,
  followCommunityAction,
  getCommunitiesBySearchAction,
} from 'actions';
import {
  DELETE_COMMUNITY,
  EDIT_COMMUNITY,
  GET_COMMUNITY,
  GET_COMMUNITY_FOLLOWERS,
  GET_COMMUNITY_LIST,
  GET_COMMUNITY_MODERATORS,
  GET_COMMUNITY_SURVEYS,
  MARK_DELETED_COMMUNITY,
  ADD_COMMUNITY,
  BLOCK_COMMUNITY,
  FOLLOW_COMMUNITY,
  START_COMMUNITY_ACTION,
  STOP_COMMUNITY_ACTION,
  GET_COMMUNITIES_SEARCH_LIST,
} from 'const';
import {
  Community,
  FollowersList,
  SurveysList,
  CommunityList,
  AvailableCommunityList,
} from 'interfaces';
import {
  encodeDataToUrl,
  httpApi,
  HttpResp,
  makeAction,
  showMessage,
  makeSelector,
} from 'utils';

function* getCommunityList({
  payload,
}: ReturnType<typeof getCommunityListAction>) {
  try {
    const res: HttpResp<CommunityList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/community/page?${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_COMMUNITY_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_COMMUNITY_LIST.FAILURE, res));
    }
  } catch (error) {}
}

const setCommunityState = (res: Community) =>
  makeAction(GET_COMMUNITY.SUCCESS, res);

function* getCommunity({ payload }: ReturnType<typeof getCommunityAction>) {
  try {
    const res: HttpResp<Community> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/community/${payload}`,
    });
    if (res && !res.error) {
      yield put(setCommunityState(res));
    } else {
      yield put(makeAction(GET_COMMUNITY.FAILURE, res));
    }
  } catch (error) {}
}

function* editCommunity({
  payload,
  redirect,
}: ReturnType<typeof editCommunityAction>) {
  try {
    const res: HttpResp<Community> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/community/${payload.id}`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(setCommunityState(res));
      showMessage.success('Community saved successfully.');
      if (redirect) {
        redirect();
      }
    } else {
      yield put(makeAction(EDIT_COMMUNITY.FAILURE, res));
    }
  } catch (error) {}
}

function* blockCommunity({ payload }: ReturnType<typeof blockCommunityAction>) {
  yield put({ type: START_COMMUNITY_ACTION });
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/community/${payload}/block`,
    });
    if (res && !res.error) {
      yield put(getCommunityAction(payload));
      showMessage.success('Community updated successfully.');
    } else {
      yield put(makeAction(MARK_DELETED_COMMUNITY.FAILURE, res));
    }
  } catch (error) {}
}

function* followCommunity({
  payload,
}: ReturnType<typeof followCommunityAction>) {
  yield put({ type: START_COMMUNITY_ACTION });
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/community/${payload}/follow`,
    });
    if (res && !res.error) {
      yield put(getCommunityAction(payload));
      showMessage.success('Community updated successfully.');
    } else {
      yield put(makeAction(FOLLOW_COMMUNITY.FAILURE, res));
      yield put({ type: STOP_COMMUNITY_ACTION });
    }
  } catch (error) {
    yield put({ type: STOP_COMMUNITY_ACTION });
  }
}

function* markDeletedCommunity({
  payload,
}: ReturnType<typeof markDeletedCommunityAction>) {
  yield put({ type: START_COMMUNITY_ACTION });
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/community/${payload}/bookmark`,
    });
    if (res && !res.error) {
      yield put(getCommunityAction(payload));
      showMessage.success('Community updated successfully.');
    } else {
      yield put(makeAction(MARK_DELETED_COMMUNITY.FAILURE, res));
      yield put({ type: STOP_COMMUNITY_ACTION });
    }
  } catch (error) {
    yield put({ type: STOP_COMMUNITY_ACTION });
  }
}

function* deletedCommunity({
  payload,
  redirect,
}: ReturnType<typeof deletedCommunityAction>) {
  yield put({ type: START_COMMUNITY_ACTION });
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'DELETE',
      partUrl: `/community/${payload}`,
    });
    if (res && !res.error) {
      if (redirect) {
        redirect();
      }
      yield put({ type: STOP_COMMUNITY_ACTION });
      showMessage.success('Community deleted successfully.');
    } else {
      yield put(makeAction(DELETE_COMMUNITY.FAILURE, res));
      yield put({ type: STOP_COMMUNITY_ACTION });
    }
  } catch (error) {
    yield put({ type: STOP_COMMUNITY_ACTION });
  }
}

function* getCommunitySurveys({
  payload,
}: ReturnType<typeof getCommunitySurveysAction>) {
  const { id = '', ...rest } = payload;
  const params = {
    ...rest,
    community: id,
  };
  try {
    const res: HttpResp<SurveysList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/surveys/page?${encodeDataToUrl(params)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_COMMUNITY_SURVEYS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_COMMUNITY_SURVEYS.FAILURE, res));
    }
  } catch (error) {}
}

function* getCommunityFollowers({
  payload,
}: ReturnType<typeof getCommunitySurveysAction>) {
  const { id = '', ...rest } = payload;
  try {
    const res: HttpResp<FollowersList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/users/community/${id}/followers/page?${encodeDataToUrl(rest)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_COMMUNITY_FOLLOWERS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_COMMUNITY_FOLLOWERS.FAILURE, res));
    }
  } catch (error) {}
}

function* getCommunityModerators({
  payload,
}: ReturnType<typeof getCommunitySurveysAction>) {
  const { id = '', ...rest } = payload;
  try {
    const res: HttpResp<CommunityList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/users/community/${id}/moderators/page?${encodeDataToUrl(
        rest
      )}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_COMMUNITY_MODERATORS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_COMMUNITY_MODERATORS.FAILURE, res));
    }
  } catch (error) {}
}

function* addCommunity({
  payload,
  redirect,
}: ReturnType<typeof addCommunityAction>) {
  try {
    const res: HttpResp<Community> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/community`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(setCommunityState(res));
      showMessage.success('Community created successfully.');
      if (redirect) {
        redirect();
      }
    } else {
      yield put(makeAction(ADD_COMMUNITY.FAILURE, res));
    }
  } catch (error) {}
}

function* searchCommunitiesByQuery({
  payload,
  redirect,
}: ReturnType<typeof getCommunitiesBySearchAction>) {
  const prevCommunities: AvailableCommunityList['data'] = yield select(
    makeSelector(['communityReducer', 'selectCommunityList'])
  );
  try {
    const res: HttpResp<AvailableCommunityList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/community/available?${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      if (redirect) {
        redirect(res);
      }

      let newList = res.data;

      if (Number(payload.offset) >= 1 || payload.query) {
        newList = [...prevCommunities, ...newList];
      }

      yield put(makeAction(GET_COMMUNITIES_SEARCH_LIST.SUCCESS, newList));
    } else {
      yield put(makeAction(GET_COMMUNITIES_SEARCH_LIST.FAILURE, res));
    }
  } catch (error) {}
}

export function* communitySaga() {
  yield takeLatest(GET_COMMUNITY_LIST.PENDING, getCommunityList);
  yield takeLatest(GET_COMMUNITY.PENDING, getCommunity);
  yield takeLatest(EDIT_COMMUNITY.PENDING, editCommunity);
  yield takeLatest(BLOCK_COMMUNITY.PENDING, blockCommunity);
  yield takeLatest(FOLLOW_COMMUNITY.PENDING, followCommunity);
  yield takeLatest(MARK_DELETED_COMMUNITY.PENDING, markDeletedCommunity);
  yield takeLatest(DELETE_COMMUNITY.PENDING, deletedCommunity);
  yield takeLatest(GET_COMMUNITY_SURVEYS.PENDING, getCommunitySurveys);
  yield takeLatest(GET_COMMUNITY_FOLLOWERS.PENDING, getCommunityFollowers);
  yield takeLatest(GET_COMMUNITY_MODERATORS.PENDING, getCommunityModerators);
  yield takeLatest(ADD_COMMUNITY.PENDING, addCommunity);
  yield takeLatest(
    GET_COMMUNITIES_SEARCH_LIST.PENDING,
    searchCommunitiesByQuery
  );
}
