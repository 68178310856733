import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../config/history';

import {
  editPointsConfigAction,
  getPointsConfigAction,
  givePointsToUserAction,
  getUserProfileAction,
  getOwnProfileAction,
} from 'actions';
import {
  EDIT_POINTS_CONFIG,
  GET_POINTS_CONFIG,
  GIVE_POINTS_TO_USER,
  AccountRoute,
} from 'const';
import { httpApi, HttpResp, makeAction, showMessage } from 'utils';
import { PointsConfig } from 'interfaces';

function* getPointsConfig({}: ReturnType<typeof getPointsConfigAction>) {
  try {
    const res: HttpResp<PointsConfig> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/costs`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_POINTS_CONFIG.SUCCESS, res));
    } else {
      yield put(makeAction(GET_POINTS_CONFIG.FAILURE, res));
    }
  } catch (error) {}
}

function* editPointsConfig({
  payload,
}: ReturnType<typeof editPointsConfigAction>) {
  try {
    const res: HttpResp<PointsConfig> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/costs`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(makeAction(EDIT_POINTS_CONFIG.SUCCESS, res));
      showMessage.success('Updated successfully');
    } else {
      yield put(makeAction(EDIT_POINTS_CONFIG.FAILURE, res));
    }
  } catch (error) {}
}

function* givePointsToUser({
  payload,
  redirect,
}: ReturnType<typeof givePointsToUserAction>) {
  try {
    const { amount, pointType, id } = payload;
    const res: HttpResp<PointsConfig> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/users/${id}/earn?${pointType}=${amount}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GIVE_POINTS_TO_USER.SUCCESS, res));
      if (history.location.pathname === AccountRoute.ROOT) {
        yield put(getOwnProfileAction(id));
      } else {
        yield put(getUserProfileAction(id));
      }
      if (redirect) {
        redirect();
      }
    } else {
      yield put(makeAction(GIVE_POINTS_TO_USER.FAILURE, res));
    }
  } catch (error) {}
}

export function* pointsSaga() {
  yield takeLatest(GET_POINTS_CONFIG.PENDING, getPointsConfig);
  yield takeLatest(EDIT_POINTS_CONFIG.PENDING, editPointsConfig);
  yield takeLatest(GIVE_POINTS_TO_USER.PENDING, givePointsToUser);
}
