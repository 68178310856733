import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { momentDate } from 'utils';

const { MonthPicker } = DatePicker;

interface IProps extends WrappedFieldProps {
  label: string;
  defaultValue?: Moment;
  pickerType?: 'date' | 'month' | 'range' | 'week';
  dateFormat: string; // moment format
  disabledDate?: (current: Moment | null) => boolean;
}

export const FormDatepicker: React.FC<IProps> = (props) => {
  const {
    input,
    label,
    meta: { initial },
    pickerType = 'date',
    dateFormat,
    disabledDate,
  } = props;
  const { onChange, value, ...inp } = input;
  const initialValue = initial ? momentDate(initial) : undefined;

  const handleChange = (e: Moment | null) => {
    onChange(e);
  };

  const renderDumbPicker = () => <DatePicker disabledDate={disabledDate} />;

  const renderPicker = () => {
    switch (pickerType) {
      case 'date':
        return (
          <DatePicker
            {...inp}
            onChange={handleChange}
            format={dateFormat}
            disabledDate={disabledDate}
            // defaultValue={initialValue}
          />
        );
      case 'month':
        return (
          <MonthPicker
            {...inp}
            onChange={handleChange}
            format={dateFormat}
            defaultValue={initialValue}
            disabledDate={disabledDate}
          />
        );
    }
  };

  return (
    <div className="form-group">
      <div className="form-control-wrapper">
        {label && (
          <label htmlFor={input.name} className="text-input-label">
            {label}
          </label>
        )}

        {initial ? renderPicker() : renderDumbPicker()}
      </div>
    </div>
  );
};
