import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Icon from '@ant-design/icons';

import logo from '../../assets/images/logo.png';
import './auth-page.scss';

import { AuthRoute } from 'const';

export const AuthPageWrapper = (FormComponent: any) => (
  routeProps: RouteComponentProps
) => {
  const {
    match: { path },
  } = routeProps;

  const isBackLinkRendered = [
    AuthRoute.FORGOT_PASSWORD,
    AuthRoute.FORGOT_PASSWORD_CONGRATS,
  ].includes(path);

  return (
    <div className="f-c-c f-column auth-page">
      {/* LOGO */}
      <div className="f-column f-c-c">
        <img src={logo} width="80" height="80" alt="logo" />
        <h1 className="logo-title">CrowdThinc</h1>
      </div>

      {/* AUTH FORMS */}
      <div className="card">
        <FormComponent />
      </div>

      {/* BACK LINK */}
      {isBackLinkRendered && (
        <Link to={AuthRoute.ROOT} className="go-back-link">
          <Icon type="arrow-left" /> BACK TO LOGIN
        </Link>
      )}
    </div>
  );
};
