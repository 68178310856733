import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import { Empty } from 'antd';

import {
  CategoryCard,
  CategoryHeader,
  ICategoryCardProps,
  SearchBlock,
} from 'components/common/parts';
import { CategoriesList, Category, IImmutableMap } from 'interfaces';
import {
  addCategoryAction,
  deleteCategoryAction,
  editCategoryAction,
  getCategoriesListAction,
} from 'actions';
import { makeSelector } from 'utils';
import {
  makeBtn,
  UniversalModal,
  UniversalTable,
} from 'components/common/universal';
import { checkIcon } from '../common';
import CategoryEditForm from './CategoryEditForm';
import { CATEGORY_EDIT_FORM, QueryLimitCategoriesItems } from 'const';
import { useFormInModal } from 'hooks';

import './categories-page.scss';
import { SearchableTable, SearchableTableContainer } from 'containers';

interface IProps extends SearchableTable {}

const CategoriesPage: React.FC<IProps> = ({
  getList,
  queryParams,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const { data, recordsFiltered } = useSelector<any, CategoriesList>(
    makeSelector(['categoriesReducer', 'categoriesList'])
  );

  // PROMPTS
  const {
    promptTitle,
    btnTitle,
    formInitialValues,
    isPrompt,
    closeEditor,
    openEditor,
  } = useFormInModal(data, 'Category');

  const remoteSubmit = () => {
    dispatch(submit(CATEGORY_EDIT_FORM));
  };

  const onSubmit = (formData: IImmutableMap<Category> | any) => {
    const category: Category = formData.toJS();
    const action = category.id
      ? editCategoryAction({ category, params: queryParams }, closeEditor)
      : addCategoryAction({ category, params: queryParams }, closeEditor);
    dispatch(action);
  };

  const deleteCategory = (cardItem: ICategoryCardProps) => {
    dispatch(deleteCategoryAction({ id: cardItem.id, params: queryParams }));
  };

  return (
    <div className="f-column categories-page">
      <CategoryHeader catTitle="Categories" />

      <SearchBlock
        btnTitle="Add New Category"
        onChange={getList}
        onClickBtn={openEditor()}
        initialQuery={queryParams}
      />

      <UniversalTable
        titles={[]}
        data={data}
        total={recordsFiltered}
        onChange={getList}
        isLoading={isLoading}
        initialQuery={queryParams}
        queryLimitItems={QueryLimitCategoriesItems}
      >
        <div className="f-str-s f-wrap">
          {data.map((category: Category) => (
            <CategoryCard
              key={category.id}
              {...category}
              title={category.categoryName}
              defaultMarkTitle="Default Category"
              onEdit={openEditor}
              onSubmitDelete={deleteCategory}
            />
          ))}
        </div>
      </UniversalTable>

      <UniversalModal
        visible={isPrompt}
        title={promptTitle}
        onCancel={closeEditor}
        promptOkBtn={makeBtn(btnTitle, checkIcon, 'success', remoteSubmit)}
        maxWidth="600px"
        className="category-editor-modal"
      >
        <CategoryEditForm
          initialValues={formInitialValues}
          onSubmit={onSubmit}
        />
      </UniversalModal>
    </div>
  );
};

export default SearchableTableContainer({
  getListAction: getCategoriesListAction,
  reducerName: 'categoriesReducer',
})(CategoriesPage);
