import React from 'react';
import { useDispatch } from 'react-redux';

import { IImmutableMap, UserProfile } from 'interfaces';
import { locationsList } from 'assets/static-data';
import { ProfileStatistic } from 'components/common/parts';
import ProfileForm from './ProfileForm';
import { updOwnProfileAction } from 'actions';

interface IProps {
  profile: UserProfile;
}

const AccountProfile: React.FC<IProps> = ({ profile }) => {
  const dispatch = useDispatch();

  const location = profile.location.id;

  const onSubmit = (formData: IImmutableMap<UserProfile> | any) => {
    const profile = formData.toJS();

    const location = locationsList.find(
      ({ id }) => Number(profile.location) === id
    );

    dispatch(updOwnProfileAction({ ...profile, location }));
  };

  return (
    <div className="f-s-c account-profile">
      <ProfileForm
        initialValues={{ ...profile, location }}
        onSubmit={onSubmit}
      />
      <ProfileStatistic {...profile} />
    </div>
  );
};

export default AccountProfile;
