import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound from 'components/not-found/NotFound';
import GetInTouchPage from 'components/GetInTouch/GetInTouchPage';
import { GetInTouchRoute } from 'const';

const GetInTouchRouter = () => {
  return (
    <Switch>
      <Route exact path={GetInTouchRoute.ROOT} component={GetInTouchPage} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default GetInTouchRouter;
