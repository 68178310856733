import React from 'react';
import { ReactNodeLike } from 'prop-types';
import { Modal } from 'antd';
import Icon from '@ant-design/icons';

import './universal-prompt.scss';

import { closeIcon, deleteIcon } from '../..';
import { UniversalButton } from '../index';

type ModalType = 'media' | 'prompt';

export interface IUniversalModalProps {
  children?: React.ReactNode;
  visible: boolean;
  type?: ModalType;
  onOk?(): void;
  onCancel?(): void;
  title?: string;
  maxWidth?: string;
  className?: string;

  promptText?: string;
  promptOkBtn?: ReactNodeLike;
  // if type === 'media' imageSrc is required
  imageSrc?: string;
}

export const UniversalModal = (props: IUniversalModalProps) => {
  const {
    visible,
    type = 'prompt',
    maxWidth,
    title,
    className = '',
    onOk,
    onCancel,
    imageSrc,
    promptText,
    promptOkBtn,
    children,
  } = props;

  const closeIconCmp: any = <Icon component={closeIcon} />;

  // PROMPT
  const promptFooter: ReactNodeLike = (
    <>
      <UniversalButton
        btnTitle="Cancel"
        iconComp={closeIcon}
        styleType="error"
        onClick={onCancel}
      />
      {promptOkBtn || (
        <UniversalButton
          btnTitle="Yes, Delete it!"
          iconComp={deleteIcon}
          styleType="error"
          onClick={onOk}
        />
      )}
    </>
  );

  switch (type) {
    case 'prompt':
      return (
        <Modal
          visible={visible}
          centered
          title={title || 'Confirmation'}
          onCancel={onCancel}
          className={`c-prompt ${className}`}
          closeIcon={closeIconCmp}
          footer={promptFooter}
          style={{ maxWidth: maxWidth || '400px' }}
        >
          {(promptText && <>{promptText}</>) || (children && <>{children}</>) || (
            <>
              Are you sure you want to <b>delete</b> this item?
              <br />
              This process can't be undone.
            </>
          )}
        </Modal>
      );
    case 'media':
      return (
        <Modal
          visible={visible}
          centered
          onCancel={onCancel}
          closeIcon={' '}
          footer={null}
          style={{ maxWidth: maxWidth || '60%' }}
          className={`c-media ${className}`}
        >
          <img alt="example" style={{ width: '100%' }} src={imageSrc} />
        </Modal>
      );
    default:
      return null;
  }
};
