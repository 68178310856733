import {
  Category,
  Community,
  Owner,
  QuestionList,
  resetCategoryModel,
  resetCommunityModel,
  resetOwnerModel,
} from '..';
import { PaginationWrapper } from '../common';

export enum SurveyStatusActions {
  NONE = 'NONE',
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
  MODERATION = 'IN_MODERATION',
  COMPLETE = 'COMPLETE',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  BLOCKED = 'BLOCKED',
  CLONE = 'CLONE',
}

export const RedirectSurveyStatuses = [
  SurveyStatusActions.LIVE,
  SurveyStatusActions.CLONE,
];

export type SurveyStatusType =
  | SurveyStatus.NONE
  | SurveyStatus.DRAFT
  | SurveyStatus.LIVE
  | SurveyStatus.MODERATION
  | SurveyStatus.COMPLETED
  | SurveyStatus.BLOCKED // if community is blocked
  | SurveyStatus.REJECTED;

export enum SurveyStatus {
  NONE = 'NONE',
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
  MODERATION = 'IN_MODERATION',
  COMPLETED = 'COMPLETED',
  BLOCKED = 'BLOCKED',
  REJECTED = 'REJECTED',
}

export interface SurveyItem {
  id: number;
  category: Category;
  community: Community;
  dateCreated: number;
  description: string;
  earnedPoints: number;
  rating: number;
  isAbleToChangeAnswer: boolean;
  isPassed: boolean;
  isPulse: boolean;
  owner: Owner;
  passedTimes: number;
  status: SurveyStatusType;
  title: string;
  rejectDescription?: string;
}

export interface Survey extends SurveyItem {
  url?: string;
  pdf?: string;
}

export interface SurveyReq extends Survey {
  questionDtoList: QuestionList;
  isDraft: boolean;
  publish: boolean;
}

export interface SurveysList extends PaginationWrapper<SurveyItem> {}

export const resetSurveyModel = (): Survey => ({
  id: 0,
  category: resetCategoryModel(),
  community: resetCommunityModel(),
  dateCreated: 0,
  description: '',
  earnedPoints: 0,
  rating: 0,
  isAbleToChangeAnswer: true,
  isPassed: false,
  isPulse: false,
  owner: resetOwnerModel(),
  passedTimes: 0,
  status: '' as SurveyStatusType,
  title: '',
  url: '',
  pdf: '',
});

export interface SurveyStatusReq {
  id: number;
  status: SurveyStatusActions;
  description?: string;
  reason?: string;
}
