import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound from 'components/not-found/NotFound';
import UserDetailsPage from 'components/Users/UserDetails/UserDetailsPage';
import { UsersRoute } from 'const';
import UsersPage from '../../components/Users/UsersPage';
import UserAccountInfo from 'components/Users/UserDetails/tabs-content/UserAccountInfo/UserAccountInfo';
import UsersCommunities from 'components/Users/UserDetails/tabs-content/UsersCommunities';
import UsersSurveys from 'components/Users/UserDetails/tabs-content/UsersSurveys';
import { UserProfile, PermissionTypes, UserGroups } from 'interfaces';
import { Can, customAbility } from 'containers/Can';

const UsersRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={UsersRoute.ROOT.pathname}
        render={() => {
          return (
            <Can I={PermissionTypes.READ} a={UserGroups.CATEGORIES} passThrough>
              {(can: boolean) => {
                if (can) return <UsersPage />;
                return <NotFound />;
              }}
            </Can>
          );
        }}
      />
      <Route path={UsersRoute.DETAILS} component={UserDetailsPage} />

      <Route component={NotFound} />
    </Switch>
  );
};

export const UserChildrenRouter = ({
  user,
  isLoading,
  profile,
}: {
  user: UserProfile;
  isLoading: boolean;
  profile: UserProfile;
}) => {
  const isOwn = profile.id === user.id;
  const canRead =
    customAbility.can(PermissionTypes.READ, UserGroups.USERS) || isOwn;
  return (
    <Switch>
      <Route
        exact
        path={UsersRoute.ROOT.children.ACCOUNT}
        render={(props) => {
          return (
            <UserAccountInfo
              {...props}
              isLoading={isLoading}
              userProfile={user}
            />
          );
        }}
      />

      <Route
        exact
        path={UsersRoute.ROOT.children.COMMUNITIES}
        component={UsersCommunities}
      />
      {canRead && (
        <Route
          exact
          path={UsersRoute.ROOT.children.SURVEYS}
          component={UsersSurveys}
        />
      )}

      <Route component={NotFound} />
    </Switch>
  );
};

export default UsersRouter;
