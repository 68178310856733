export interface PaginationWrapper<T = any> {
  data: T[];
  recordsFiltered: number;
  recordsTotal: number;
  error?: any;
  draw?: any;
}

export const resetListPaginatedModel = <T>(): PaginationWrapper<T> => ({
  data: [],
  recordsFiltered: 0,
  recordsTotal: 0,
});
