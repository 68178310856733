import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CategoryHeader, IDetailsInfoArrItem } from '../common/parts';
import { IItem, ContactRequested, RequestedList } from 'interfaces';
import RequestedListCmp from './tabs-content/RequestedListCmp';
import SolvedList from './tabs-content/SolvedList';

import './contact-requests-page.scss';
import { getContactRequestCategoryTab, makeSelector } from 'utils';
import { resetRequestedListAction } from 'actions';
import { useFormInModal } from 'hooks';
import RequestDescriptionModal from './tabs-content/RequestDescriptionModal';

const categoryTabs = getContactRequestCategoryTab();

enum Tab {
  REQUESTED = 1,
  SOLVED,
}

const ContactRequestsPage = () => {
  const dispatch = useDispatch();
  const [currTab, setCurrTab] = useState(categoryTabs[0]);

  const { data, recordsFiltered } = useSelector<any, RequestedList>(
    makeSelector(['contactRequestReducer', 'requestedList'])
  );

  // PROMPTS
  const {
    isPrompt,
    closeEditor,
    openEditor,
    formInitialValues,
  } = useFormInModal<ContactRequested>(data, '');

  const onSelectTab = (tab: IItem) => {
    dispatch(resetRequestedListAction(null));
    setCurrTab(tab);
  };

  const renderContent = () => {
    switch (currTab.id) {
      case Tab.REQUESTED:
        return <RequestedListCmp openEditor={openEditor} />;
      case Tab.SOLVED:
        return <SolvedList openEditor={openEditor} />;
    }
  };

  const details: IDetailsInfoArrItem[] = [
    {
      title: 'Email',
      value: formInitialValues.email,
    },
    {
      title: 'Description',
      value: formInitialValues.description,
    },
  ];

  return (
    <div className="f-column contact-requests-page">
      <CategoryHeader
        catTitle="Contact Requests"
        catTabs={categoryTabs}
        onSelectTab={onSelectTab}
      />

      {/* CONTENT */}
      {renderContent()}

      <RequestDescriptionModal
        isPrompt={isPrompt}
        details={details}
        closeEditor={closeEditor}
      />
    </div>
  );
};

export default ContactRequestsPage;
