import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { UniversalTableItem, ITitle } from 'interfaces';
import { classNamesHlp, findParentNode } from 'helpers';
import { RouteComponentProps } from 'react-router';
import { IComplexRoute, MEDIA_TABLET_POINTS } from 'const';
import { Empty } from 'antd';
import { useMobile } from 'hooks';

interface IUniversalDnDTableProps<T = UniversalTableItem> {
  titles: ITitle<keyof T>[];
  data: T[];
  isLoading: boolean;
  routeConst?: IComplexRoute;
  history: RouteComponentProps['history'];
  onChange(data: T[]): void;
}

const reorder = (
  list: IUniversalDnDTableProps['data'],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const UniversalDnDTable = (props: IUniversalDnDTableProps) => {
  const { titles, isLoading, data, history, routeConst, onChange } = props;

  const isTablet = useMobile(MEDIA_TABLET_POINTS);

  const goToDetails = ({ id }: UniversalTableItem) => (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (findParentNode(e.target, 'without-redirect')) return false;
    if (!isLoading && id && routeConst && routeConst.getDetailsRoute) {
      history.push(routeConst.getDetailsRoute(id));
    }
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(data, result.source.index, result.destination.index);
    onChange(items);
  };

  const renderTable = () => (
    <table
      className={`drag-n-drop-table fixed-column--${titles.length} ${
        isTablet ? 'mobile-table' : ''
      }`}
    >
      {!isTablet && (
        <thead>
          <tr>
            {/* TITLES */}
            {titles.map((titleOpt, i) => {
              const { title, propName, className } = titleOpt;

              return (
                <th key={i} className={className || propName}>
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {/* ROWS */}
              {Boolean(data.length) || isLoading
                ? data.map((r, i) => {
                    const rowItem = data[i] || { id: 0 };
                    const id = `${rowItem.id}`;
                    return (
                      <Draggable key={id} draggableId={id} index={i}>
                        {(provided: any, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`
                              ${classNamesHlp({
                                hoverable: !!rowItem.id,
                              })} 
                              ${
                                snapshot.isDragging
                                  ? 'drag-n-drop-table__row-drag'
                                  : ''
                              }
                            `}
                            onClick={goToDetails(rowItem)}
                          >
                            {/* CELLS */}
                            {titles.map((title, j) => {
                              const {
                                propName,
                                formatFn,
                                formatPropName,
                                canRedirect = true,
                                title: headTitle,
                              } = title;

                              let value: any = <>&zwnj;</>;
                              if (rowItem.id) {
                                if (typeof formatFn === 'function') {
                                  const name = formatPropName || propName;
                                  value = formatFn(rowItem[name]);
                                } else if (rowItem[propName]) {
                                  value = rowItem[propName];
                                } else {
                                  value = '-';
                                }
                              }
                              const className = classNamesHlp('cell-ellipsis', {
                                'cell-loading': isLoading,
                              });
                              return (
                                <td
                                  key={rowItem.id + propName + 'cell' + j}
                                  className={`${className} ${
                                    canRedirect === false
                                      ? 'without-redirect'
                                      : ''
                                  }`}
                                >
                                  {isTablet && (
                                    <span className="mobile-table__title">
                                      {headTitle}
                                    </span>
                                  )}
                                  <div className="mobile-table__value">
                                    {typeof value !== 'object' ? (
                                      <span>{value || '-'}</span>
                                    ) : (
                                      value
                                    )}
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        )}
                      </Draggable>
                    );
                  })
                : null}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </table>
  );

  return (
    <div className="universal-table card">
      <div className="universal-table-wrapper">
        {renderTable()}
        {/* NO DATA */}
        {!data.length && !isLoading && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );
};
