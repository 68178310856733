import { all, fork } from 'redux-saga/effects';
import {
  appSaga,
  authSaga,
  buzzSaga,
  categoriesSaga,
  communitySaga,
  contactRequestSaga,
  offensiveWordsSaga,
  paymentSaga,
  pointsSaga,
  surveysSaga,
  userSaga,
  usersSaga,
  dashboardSaga,
  queryParamsSaga,
  newsSaga,
  notificationSaga,
  getInTouchSagas,
} from 'sagas';

export default function* root() {
  yield all([
    fork(authSaga),
    fork(appSaga),
    fork(userSaga),
    fork(buzzSaga),
    fork(surveysSaga),
    fork(categoriesSaga),
    fork(communitySaga),
    fork(usersSaga),
    fork(contactRequestSaga),
    fork(pointsSaga),
    fork(offensiveWordsSaga),
    fork(paymentSaga),
    fork(dashboardSaga),
    fork(queryParamsSaga),
    fork(newsSaga),
    fork(notificationSaga),
    fork(getInTouchSagas),
  ]);
}
