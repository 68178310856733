import React from 'react';
import { createPortal } from 'react-dom';
import { Spin } from 'antd';

import './universal-spinner.scss';

interface IUniverseSpinnerProps {
  show: boolean;
  domNode?: HTMLElement;
}

export const UniversalSpinner = (props: IUniverseSpinnerProps) => {
  const { show, domNode } = props;

  if (!show) return null;

  const renderContent = () => (
    <div className="universal-spinner" data-testid="test-spinner">
      <div className="universal-spinner__content">
        <Spin />
      </div>
    </div>
  );

  if (!domNode) return renderContent();

  return createPortal(renderContent(), domNode);
};
