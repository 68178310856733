import { FORGOT_PASSWORD, SIGN_OUT, RESET_PASSWORD, SIGN_IN } from 'const';
import {
  IActionFn,
  IForgotPassAction,
  IResetPassAction,
  ISignInAction,
} from 'interfaces';

export const signInAction: ISignInAction = (credentials, redirect) => ({
  type: SIGN_IN.PENDING,
  payload: credentials,
  redirect,
});

export const forgotPassAction: IForgotPassAction = (phoneNumber, redirect) => ({
  type: FORGOT_PASSWORD.PENDING,
  payload: phoneNumber,
  redirect,
});

export const resetPassAction: IResetPassAction = (password, redirect) => ({
  type: RESET_PASSWORD.PENDING,
  payload: password,
  redirect,
});

export const signOutAction: IActionFn<null> = () => ({
  type: SIGN_OUT.PENDING,
  payload: null,
});
