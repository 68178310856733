import React from 'react';
import { useSelector } from 'react-redux';

import { SearchBlock } from 'components/common/parts';
import { UniversalTable } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { getSurveyListAction } from 'actions';
import { makeSelector } from 'utils';
import { SurveysList } from 'interfaces';

import { SurveysTableParams } from 'assets/static-data';

interface IProps extends SearchableTable {}

const UserSurveys: React.FC<IProps> = ({ getList, isLoading, queryParams }) => {
  const { data, recordsFiltered } = useSelector<any, SurveysList>(
    makeSelector(['surveysReducer', 'surveysList'])
  );

  return (
    <>
      <SearchBlock onChange={getList} initialQuery={queryParams} />
      <UniversalTable
        {...SurveysTableParams}
        data={data}
        onChange={getList}
        total={recordsFiltered}
        isLoading={isLoading}
        initialQuery={queryParams}
      />
    </>
  );
};

export default SearchableTableContainer({
  getListAction: getSurveyListAction,
  reducerName: 'surveysReducer',
  queryName: 'surveysQueryParams',
  isEqualSearch: true,
})(UserSurveys);
