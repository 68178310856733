import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SearchBlock } from 'components/common/parts';
import { UniversalTable } from 'components/common/universal';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { getCommunityModeratorsAction } from 'actions';
import { makeSelector } from 'utils';
import { FollowersList, QueryParams, ValidModaratorStatuses } from 'interfaces';
import { getMatchParam } from 'helpers';
import { usersTableParams } from 'assets/static-data';

interface IProps extends SearchableTable {
  isOwnCommunity: boolean;
}

const CommunityModerators: React.FC<IProps> = ({
  match,
  getList,
  isLoading,
  queryParams,
  isOwnCommunity,
}) => {
  const { data, recordsFiltered } = useSelector<any, FollowersList>(
    makeSelector(['communityReducer', 'moderatorsList'])
  );

  const idFromUrl = getMatchParam(match).id;

  useEffect(() => {
    onGetList(queryParams);
  }, []);

  const onGetList = (params: QueryParams) => {
    getList({ ...params, id: idFromUrl });
  };

  const moderators = data.filter((moderator) => {
    if (isOwnCommunity) return true;

    return ValidModaratorStatuses.includes(moderator.moderatorStatus);
  });

  const titles = usersTableParams.titles.filter((title) => {
    const { propName } = title;
    return (
      propName !== 'isActive' &&
      propName !== 'blocked' &&
      propName !== 'location' &&
      propName !== 'yearOfBirth'
    );
  });

  if (isOwnCommunity) {
    titles.push({
      title: 'Status',
      propName: 'moderatorStatus',
      isSortable: false,
    });
  }

  return (
    <>
      <SearchBlock onChange={getList} initialQuery={queryParams} />
      <UniversalTable
        {...usersTableParams}
        titles={titles}
        data={moderators}
        onChange={onGetList}
        isLoading={isLoading}
        total={recordsFiltered}
        initialQuery={queryParams}
      />
    </>
  );
};

export default SearchableTableContainer({
  getListAction: getCommunityModeratorsAction,
  isInitialized: false,
  reducerName: 'communityReducer',
  queryName: 'moderatorsQueryParams',
  isEqualSearch: true,
})(CommunityModerators);
