import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import { FormTextInput, FormUploadImage } from 'components/common/form';
import {
  COMMUNITY_EDIT_NEWS_FORM,
  SMALL_TITLE_LIMIT,
  DESCRIPTION_LIMIT,
} from 'const';
import { validateRequired } from 'utils';
import { cutToSize } from 'helpers';

interface IProps {
  isEditMode: boolean;
}

const CommunityEditNewsForm: React.FC<
  IProps & InjectedFormProps<{}, IProps>
> = (props) => {
  const { handleSubmit, isEditMode } = props;

  return (
    <form onSubmit={handleSubmit} className="community-edit-form">
      <div className="card">
        <Field
          name="image"
          label="Cover Image"
          component={FormUploadImage}
          className="buzz-edit-form-upload-file"
          validate={validateRequired}
        />

        <Field
          name="title"
          label="Title"
          placeholder="Enter Title"
          component={FormTextInput}
          validate={validateRequired}
          normalize={cutToSize(SMALL_TITLE_LIMIT)}
          max={SMALL_TITLE_LIMIT}
        />
        <Field
          name="description"
          label="Description"
          type="textarea"
          placeholder="Enter Description"
          component={FormTextInput}
          validate={validateRequired}
          normalize={cutToSize(DESCRIPTION_LIMIT)}
          max={DESCRIPTION_LIMIT}
        />
      </div>
    </form>
  );
};

export default reduxForm<{}, IProps>({
  form: COMMUNITY_EDIT_NEWS_FORM,
  enableReinitialize: true,
})(CommunityEditNewsForm);
