import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound from 'components/not-found/NotFound';
import CommunitiesPage from 'components/Communities/CommunitiesPage';
import CommunityDetailsPage from 'components/Communities/CommunityDetails/CommunityDetailsPage';
import CommunityEditPage from 'components/Communities/CommunityEdit/CommunityEditPage';
import { CommunityRoute } from 'const';
import CommunityDetailsInfo from 'components/Communities/CommunityDetails/tabs-content/CommunityDetailsInfo';
import { Community, RoleType, RolesForPrivateTabs } from 'interfaces';
import CommunitySurveys from 'components/Communities/CommunityDetails/tabs-content/CommunitySurveys';
import CommunityFollowers from 'components/Communities/CommunityDetails/tabs-content/CommunityFollowers';
import CommunityModerators from 'components/Communities/CommunityDetails/tabs-content/CommunityModerators';
import CommunityNewsEditPage from 'components/Communities/CommunityNewsEdit/CommunityNewsEditPage';
import CommunityInactiveNews from 'components/Communities/CommunityDetails/tabs-content/CommunityInactiveNews';
import CommunityActiveNews from 'components/Communities/CommunityDetails/tabs-content/CommunityActiveNews';
import { getRole } from 'containers/Can';

const CommunityRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={CommunityRoute.ROOT.children.ADD_NEWS}
        component={CommunityNewsEditPage}
      />
      <Route
        exact
        path={CommunityRoute.ROOT.children.EDIT_NEWS}
        component={CommunityNewsEditPage}
      />
      <Route
        exact
        path={CommunityRoute.ROOT.pathname}
        component={CommunitiesPage}
      />
      <Route exact path={CommunityRoute.ADD} component={CommunityEditPage} />
      <Route exact path={CommunityRoute.EDIT} component={CommunityEditPage} />
      <Route path={CommunityRoute.DETAILS} component={CommunityDetailsPage} />

      <Route component={NotFound} />
    </Switch>
  );
};

export const CommunityChildrenRouter = ({
  community,
  isOwnCommunity,
  isLoading,
}: {
  community: Community;
  isOwnCommunity: boolean;
  isLoading: boolean;
}) => {
  const role = getRole();

  if (role === null) return;

  const showPrivateTabs =
    isOwnCommunity || RolesForPrivateTabs.some((r) => r === role);

  return (
    <Switch>
      <Route
        exact
        path={CommunityRoute.ROOT.children.MAIN_INFO}
        render={(props) => {
          return (
            <CommunityDetailsInfo
              {...props}
              community={community}
              isOwnCommunity={isOwnCommunity}
              isLoading={isLoading}
            />
          );
        }}
      />

      <Route
        exact
        path={CommunityRoute.ROOT.children.FOLLOWERS}
        component={CommunityFollowers}
      />

      <Route
        exact
        path={CommunityRoute.ROOT.children.MODERATORS}
        render={(props) => {
          const customProps = {
            isOwnCommunity,
            ...props,
          };
          return <CommunityModerators {...customProps} />;
        }}
      />
      <Route
        exact
        path={CommunityRoute.ROOT.children.INACTIVE_NEWS}
        component={CommunityInactiveNews}
      />
      <Route
        exact
        path={CommunityRoute.ROOT.children.ACTIVE_NEWS}
        component={CommunityActiveNews}
      />

      {showPrivateTabs && (
        <>
          <Route
            exact
            path={CommunityRoute.ROOT.children.SURVEYS}
            component={CommunitySurveys}
          />
        </>
      )}

      <Route component={NotFound} />
    </Switch>
  );
};

export default CommunityRouter;
