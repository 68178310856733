import {
  EDIT_POINTS_CONFIG,
  GET_POINTS_CONFIG,
  GIVE_POINTS_TO_USER,
  PointsType,
} from 'const';
import { IActionFn, PointsConfig, UserProfile } from 'interfaces';

export const getPointsConfigAction: IActionFn<null> = () => ({
  type: GET_POINTS_CONFIG.PENDING,
  payload: null,
});

export const editPointsConfigAction: IActionFn<PointsConfig> = (params) => ({
  type: EDIT_POINTS_CONFIG.PENDING,
  payload: params,
});

export type GivePointsToUserAction = IActionFn<{
  pointType: PointsType;
  amount: number;
  id: UserProfile['id'];
}>;
export const givePointsToUserAction: GivePointsToUserAction = (
  params,
  redirect
) => ({
  redirect,
  type: GIVE_POINTS_TO_USER.PENDING,
  payload: params,
});
