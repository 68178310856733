import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  surveyCardIcon,
  pulsesCardIcon,
  communityCardIcon,
  userCardIcon,
  contactCardIcon,
} from 'components/common';
import { CategoryHeader } from 'components/common/parts';
import {
  Colors,
  CommunityRoute,
  ContactRequestsRoute,
  SurveysRoute,
  UsersRoute,
} from 'const';
import { getDashboardConfigAction } from '../../actions';

import renderTotalCard from './parts/renderTotalCard';
import renderChartCard from './parts/renderChartCard';

import './dashboard-page.scss';
import { DashboardConfig, PermissionTypes, UserGroups } from 'interfaces';
import ColumnChart from './parts/ColumnChart/ColumnChart';
import {
  makeSelector,
  getUsersChartParams,
  getCommunitiesChartParams,
  getCategoriesChartParams,
  getSurveysChartParams,
  getPulsesChartParams,
} from 'utils';
import { customAbility } from 'containers/Can';

const DashboardPage = () => {
  const dispatch = useDispatch();

  const config = useSelector<any, DashboardConfig>(
    makeSelector(['dashboardReducer', 'config'])
  );

  const { users, communities, surveys, pulses, requests, categories } = config;

  const usersChartParams = getUsersChartParams(users);
  const communitiesChartParams = getCommunitiesChartParams(
    communities.counters
  );
  const categoriesChartParams = getCategoriesChartParams(
    communities.categories
  );
  const surveysChartParams = getSurveysChartParams(surveys);
  const pulsesChartParams = getPulsesChartParams(pulses);

  useEffect(() => {
    dispatch(getDashboardConfigAction(null));
  }, []);

  const showUserChart = customAbility.can(
    PermissionTypes.READ,
    UserGroups.USERS
  );

  const showContactRequestsChart = customAbility.can(
    PermissionTypes.READ,
    UserGroups.USERS
  );

  const showCateotiesChart = customAbility.can(
    PermissionTypes.READ,
    UserGroups.CATEGORIES
  );

  const isCutView =
    !showUserChart && !showContactRequestsChart && !showCateotiesChart;

  return (
    <div
      className={`f-column dashboard-page ${
        isCutView ? 'dashboard-page--cut' : ''
      }`}
    >
      <CategoryHeader catTitle="Dashboard" />

      <div className="f-str-c">
        {renderTotalCard(
          'Surveys',
          surveys.total,
          surveyCardIcon,
          SurveysRoute.ROOT.children.OFFICAL
        )}
        {renderTotalCard(
          'Pulses',
          pulses.total,
          pulsesCardIcon,
          SurveysRoute.ROOT.children.PULSES
        )}
        {renderTotalCard(
          'Communities',
          communities.counters.total,
          communityCardIcon,
          CommunityRoute.ROOT.pathname
        )}
        {showUserChart &&
          renderTotalCard(
            'Users',
            users.total,
            userCardIcon,
            UsersRoute.ROOT.pathname
          )}
        {showContactRequestsChart &&
          renderTotalCard(
            'Contact Requests',
            requests.notSolved,
            contactCardIcon,
            ContactRequestsRoute.ROOT
          )}
      </div>

      <div className="f-str-c">
        {showUserChart && (
          <div className="stat-block card">
            {renderChartCard('Users', usersChartParams, 'radialBar')}
          </div>
        )}
        <div className="stat-block f-e-sb card">
          {renderChartCard('Communities', communitiesChartParams, 'donut')}
          {renderChartCard('', categoriesChartParams, 'donut')}
        </div>
      </div>

      <div className="f-str-c">
        <div className="stat-block card f-column f-s-sb">
          {renderChartCard('Surveys', surveysChartParams, 'donut')}
          {renderChartCard('Pulses', pulsesChartParams, 'donut')}
        </div>

        {showCateotiesChart && (
          <div className="stat-block card">
            <ColumnChart categories={categories} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
