import React from 'react';

import { IIconComponent } from 'interfaces';
import { UniversalCmpStyleType } from '..';
import Icon from '@ant-design/icons';

import './universal-icon.scss';

interface IProps {
  type?: string;
  iconComp?: IIconComponent;
  styleType?: UniversalCmpStyleType;
  width?: number | string;
}

export const UniversalIcon: React.FC<IProps> = (props) => {
  const { type, iconComp, styleType = 'primary', width } = props;

  return (
    <Icon
      type={type}
      component={iconComp}
      className={`c-icon c-icon-${styleType}`}
      style={{ width }}
    />
  );
};
