import { put, takeLatest, call } from 'redux-saga/effects';

import {
  ADD_BUZZ,
  DELETE_BUZZ,
  EDIT_BUZZ,
  GET_BUZZ,
  GET_BUZZ_LIST,
} from 'const';
import {
  encodeDataToUrl,
  httpApi,
  HttpResp,
  makeAction,
  showMessage,
} from 'utils';
import { Buzz, BuzzList } from 'interfaces';
import {
  deleteBuzzAction,
  editBuzzAction,
  getBuzzAction,
  getBuzzListAction,
} from 'actions';

function* getBuzzList({ payload }: ReturnType<typeof getBuzzListAction>) {
  try {
    const res: HttpResp<BuzzList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/buzz/page?${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_BUZZ_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_BUZZ_LIST.FAILURE, res));
    }
  } catch (error) {}
}

function* getBuzz({ payload }: ReturnType<typeof getBuzzAction>) {
  try {
    const res: HttpResp<BuzzList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/buzz/${payload}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_BUZZ.SUCCESS, res));
    } else {
      yield put(makeAction(GET_BUZZ.FAILURE, res));
    }
  } catch (error) {}
}

function* deleteBuzz({
  payload,
  redirect,
}: ReturnType<typeof deleteBuzzAction>) {
  try {
    const res: HttpResp<BuzzList> = yield call(httpApi, {
      method: 'DELETE',
      partUrl: `/buzz/${payload}`,
    });
    if (res && !res.error) {
      yield put(makeAction(DELETE_BUZZ.SUCCESS, payload));
      redirect && redirect();
      showMessage.success('Buzz removed successfully');
    } else {
      yield put(makeAction(DELETE_BUZZ.FAILURE, res));
    }
  } catch (error) {}
}

function* addBuzz({ payload, redirect }: ReturnType<typeof editBuzzAction>) {
  try {
    const res: HttpResp<Buzz> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/buzz`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(makeAction(ADD_BUZZ.SUCCESS, payload));
      redirect && redirect();
      showMessage.success('Buzz created successfully');
    } else {
      yield put(makeAction(ADD_BUZZ.FAILURE, res));
    }
  } catch (error) {}
}

function* editBuzz({ payload, redirect }: ReturnType<typeof editBuzzAction>) {
  try {
    const res: HttpResp<Buzz> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/buzz/${payload.id}`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(makeAction(EDIT_BUZZ.SUCCESS, payload));
      redirect && redirect();
      showMessage.success('Buzz edited successfully');
    } else {
      yield put(makeAction(EDIT_BUZZ.FAILURE, res));
    }
  } catch (error) {}
}

export function* buzzSaga() {
  yield takeLatest(GET_BUZZ_LIST.PENDING, getBuzzList);
  yield takeLatest(GET_BUZZ.PENDING, getBuzz);
  yield takeLatest(DELETE_BUZZ.PENDING, deleteBuzz);
  yield takeLatest(ADD_BUZZ.PENDING, addBuzz);
  yield takeLatest(EDIT_BUZZ.PENDING, editBuzz);
}
