import React from 'react';
import { Route, Switch } from 'react-router';

import { BuzzRoute } from 'const';
import NotFound from 'components/not-found/NotFound';
import BuzzPage from 'components/Buzz/BuzzPage';
import BuzzDetailsPage from 'components/Buzz/BuzzDetails/BuzzDetailsPage';
import BuzzEditPage from 'components/Buzz/BuzzEdit/BuzzEditPage';

const BuzzRouter = () => {
  return (
    <Switch>
      <Route exact path={BuzzRoute.ROOT} component={BuzzPage} />
      <Route exact path={BuzzRoute.ADD} component={BuzzEditPage} />
      <Route exact path={BuzzRoute.EDIT} component={BuzzEditPage} />
      <Route exact path={BuzzRoute.DETAILS} component={BuzzDetailsPage} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default BuzzRouter;
