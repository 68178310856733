import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import { GET_CATEGORIES_LIST, GET_CATEGORIES_SEARCH_LIST } from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';
import {
  CategoriesList,
  QueryParams,
  resetCategoryListModel,
  Category,
} from 'interfaces';

interface CategoriesReducerState {
  isLoading: boolean;
  categoriesList: CategoriesList;
  queryParams: QueryParams;
  categoriesSearchList: Category[];
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = Immutable.fromJS({
  isLoading: false,
  categoriesList: resetCategoryListModel(),
  categoriesSearchList: [],
  queryParams: {
    limit: 9,
  },
} as CategoriesReducerState);

const { PENDING, DONE } = makeCombinedAction(GET_CATEGORIES_LIST);

const categoriesReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [GET_CATEGORIES_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('categoriesList', Immutable.fromJS(payload));
    },
    [GET_CATEGORIES_LIST.PENDING]: (state, { payload }) => {
      return state.set('queryParams', Immutable.fromJS(payload));
    },
    [GET_CATEGORIES_SEARCH_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('categoriesSearchList', Immutable.fromJS(payload));
    },
  },
  initialState
);

export default categoriesReducer;
