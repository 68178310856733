import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORIES_LIST,
  GET_CATEGORIES_SEARCH_LIST,
} from 'const';
import { Category, IActionFn, QueryParams } from 'interfaces';

export const getCategoriesListAction: IActionFn<QueryParams> = (
  params,
  redirect
) => ({
  type: GET_CATEGORIES_LIST.PENDING,
  payload: params,
  redirect,
});

export const getCategoriesBySearch: IActionFn<QueryParams> = (
  payload,
  redirect
) => ({
  payload,
  redirect,
  type: GET_CATEGORIES_SEARCH_LIST.PENDING,
});

export const editCategoryAction: IActionFn<{
  params: QueryParams;
  category: Category;
}> = (category, redirect) => ({
  redirect,
  type: EDIT_CATEGORY.PENDING,
  payload: category,
});

export const addCategoryAction: IActionFn<{
  params: QueryParams;
  category: Category;
}> = (category, redirect) => ({
  redirect,
  type: ADD_CATEGORY.PENDING,
  payload: category,
});

export const deleteCategoryAction: IActionFn<{
  id: number;
  params: QueryParams;
}> = (id) => ({
  type: DELETE_CATEGORY.PENDING,
  payload: id,
});
