import React, { useEffect, useState } from 'react';

import {
  humanizeQuestionType,
  QuestionOption,
  QuestionsStatistic,
  questionType,
} from 'interfaces';
import {
  CssPrefix,
  cssPrefixCreator,
  getImportantSurveyQuestionIco,
} from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelector } from 'utils';
import { getSurveyQuestionsStatisticAction } from 'actions';
import SurveyDetailsQuestionsSkeleton from './surveyDetailsQuestionsSkeleton';

type QuestionNumber = 1 | 2 | 3 | 4 | 5; // rank/rate

interface IProps {
  questionId: number;
  cssPrefix: CssPrefix;
  isLoading: boolean;
}

const SurveyDetailsQuestionsStatistic: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [isRendered, setIsRendered] = useState(false);
  const questions = useSelector<any, QuestionsStatistic[]>(
    makeSelector(['surveysReducer', 'questionStatistic'])
  );

  const { questionId, isLoading } = props;

  useEffect(() => {
    if (questionId) {
      dispatch(getSurveyQuestionsStatisticAction(questionId));
    }
    const tId = setTimeout(() => setIsRendered(true), 600);
    return () => clearTimeout(tId);
  }, [questionId]);

  const getLabel = ({
    qualified,
    optional,
  }: {
    qualified: boolean;
    optional: boolean;
  }) => {
    let label = null;
    if (qualified) {
      label = 'Qualified question';
    }
    if (optional) {
      label = 'Optional question';
    }
    return label;
  };

  const renderSubQuestion = ({
    resultsMap,
    votesMap,
    type,
    results,
    important,
  }: QuestionsStatistic) => {
    return ({ text, id, questionOptionNumber, votes }: QuestionOption) => {
      let colorStyle = 'primary';

      switch (type) {
        case questionType.RANK:
        case questionType.RATE:
          const qOptNumber = questionOptionNumber as QuestionNumber;
          return (
            <li key={id}>
              <h5 className="label">{text}</h5>
              <div className="f-c-sb">
                {resultsMap &&
                  Object.entries(resultsMap[qOptNumber]).map((pair) => {
                    const index = +pair[0] as QuestionNumber;
                    const percents = pair[1];
                    const votes = votesMap && votesMap[qOptNumber][index];

                    return (
                      <div key={index}>
                        <p>
                          <span>
                            {votes} | {`${percents}%`}
                          </span>
                        </p>
                        <div className="progress-bar">
                          <div
                            className={`progress-fill bg-${colorStyle}`}
                            style={{ width: `${isRendered ? percents : 0}%` }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </li>
          );
        default:
          const percents = results ? results[questionOptionNumber] : 0;
          if (type === questionType.YESNO) {
            colorStyle = percents >= 50 ? 'success' : 'error';
          }
          const defaultText =
            getImportantSurveyQuestionIco(important, questionOptionNumber) ||
            text;
          return (
            <li key={id} className="def-statistic-item">
              <p>
                <span>
                  {votes} | {`${percents}%`}
                </span>
                <span className="label">{defaultText}</span>
              </p>
              <div className="progress-bar">
                <div
                  className={`progress-fill bg-${colorStyle}`}
                  style={{ width: `${isRendered ? percents : 0}%` }}
                />
              </div>
            </li>
          );
      }
    };
  };

  const cssPrefix = cssPrefixCreator(
    props.cssPrefix('questions-statistic-cmp')
  );

  return isLoading ? (
    <SurveyDetailsQuestionsSkeleton />
  ) : (
    <div className={cssPrefix(' card')}>
      <ol className={cssPrefix('list')}>
        {questions.map((questionData) => {
          const {
            questionNumber,
            question,
            type,
            optionList,
            qualified,
            optional,
          } = questionData;
          const label = getLabel({ qualified, optional });
          return (
            <li key={questionNumber}>
              <h3>{question}</h3>
              {label && (
                <span className={props.cssPrefix('qualified-info-text')}>
                  {label}
                </span>
              )}
              <p className="hint">Type: {humanizeQuestionType[type]}</p>
              <ul>{optionList.map(renderSubQuestion(questionData))}</ul>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default SurveyDetailsQuestionsStatistic;
