export interface PaymentPlan {
  androidId: string;
  id: number;
  image: string;
  iosId: string;
  isBestPrice: boolean;
  pointsValue: number;
}

export interface PaymentPlans {
  points: {
    plans: PaymentPlan[];
  };
  extrapoints: {
    plans: PaymentPlan[];
  };
}

export const resetPaymentPlansModel = (): PaymentPlans => ({
  points: {
    plans: [],
  },
  extrapoints: {
    plans: [],
  },
});
