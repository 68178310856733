import React, { ReactNode, useEffect, useState, useRef } from 'react';
import history from 'config/history';

import { IItem, IItemStatus } from 'interfaces';
import {
  UniversalButton,
  IUniversalModalProps,
  UniversalModal,
  makeBtn,
} from 'components/common/universal';
import { arrowBackIcon, checkIcon } from 'components/common';
import { classNamesHlp, cssPrefixCreator } from 'helpers';

import './category-header.scss';
import CategoryHeaderSkeleton from './categoryHeaderSkeleton';

export interface CategoryHeaderProps {
  catTitle?: string;
  catSubTitle?: ReactNode | string;
  catTabs?: IItem[];
  defaultTab?: IItem;
  onSelectTab?(tab: IItem): any;
  backBtn?: {
    showButton: boolean;
    showModal?: boolean;
  };
  isLoading?: boolean;
  ctrlBtns?: ReactNode;
  itemStatus?: IItemStatus;
}

export const CategoryHeader: React.FC<CategoryHeaderProps> = (props) => {
  const [currTab, setCurrTab] = useState<IItem>({ id: 0, title: '' });

  const [isPrompt, setPromptState] = useState(false);
  const [modalParams, setModalParams] = useState<IUniversalModalProps | null>(
    null
  );

  const listRef = useRef<HTMLDivElement>(null);

  const {
    catTitle,
    catSubTitle,
    catTabs = [],
    defaultTab,
    onSelectTab,
    backBtn,
    isLoading,
    ctrlBtns,
    itemStatus,
  } = props;

  useEffect(() => {
    if (defaultTab || catTabs.length) {
      const tab = defaultTab || catTabs[0];
      selectTab(tab, false)();
      setCurrTab(tab);
    }
  }, []);

  useEffect(() => {
    const currIdx = catTabs.findIndex(({ id }) => id === currTab.id);
    if (listRef.current) {
      const childrenElements = listRef.current.children;
      const currElement = childrenElements[currIdx] as HTMLElement;

      if (currElement) {
        const parentOffset = listRef.current.getBoundingClientRect().left;
        const currTabOffset =
          currElement.getBoundingClientRect().left + listRef.current.scrollLeft;
        const offsetLeft = currTabOffset - parentOffset;

        listRef.current.scrollLeft = offsetLeft - 40;
      }
    }
  }, [currTab]);

  const showPromptWith = (params: IUniversalModalProps) => () => {
    setModalParams(params);
    setPromptState(true);
  };

  const closePrompt = () => {
    setPromptState(false);
  };

  const selectTab = (tab: IItem, isNeedSelect = true) => () => {
    if (currTab.title !== tab.title) {
      setCurrTab(tab);
      if (onSelectTab && isNeedSelect) {
        onSelectTab(tab);
      }
    }
  };

  const cssPrefix = cssPrefixCreator('category-header-cmp');

  const renderTabs = () =>
    catTabs.length ? (
      <div className={cssPrefix('tabs f-c-s')} ref={listRef}>
        {catTabs.map((tab) => (
          <h3
            key={tab.id}
            className={classNamesHlp('category-tab', {
              active: currTab.id === tab.id,
            })}
            onClick={selectTab(tab)}
          >
            {tab.title}
          </h3>
        ))}
      </div>
    ) : null;

  const renderControlButtons = () =>
    ctrlBtns && (
      <div className={cssPrefix('control-btns-block')}>{ctrlBtns}</div>
    );

  const promptProps: IUniversalModalProps = {
    visible: true,
    onCancel: closePrompt,
    promptText: `Your changes have not been saved. Do you want to continue?`,
    promptOkBtn: makeBtn('Ok', checkIcon, 'success', history.goBack),
  };

  return isLoading ? (
    <CategoryHeaderSkeleton
      {...props}
      cssPrefix={cssPrefix}
      tabsNode={renderTabs()}
    />
  ) : (
    <div className={cssPrefix(' card')}>
      {backBtn && (
        <div className={`f-c-sb ${cssPrefix('card-btns')}`}>
          {/* BACK BTN */}
          <UniversalButton
            btnType="link"
            btnTitle="BACK"
            onClick={
              backBtn.showModal ? showPromptWith(promptProps) : history.goBack
            }
            iconComp={arrowBackIcon}
            styleType="hint"
            className={cssPrefix('back-btn')}
          />
          {/* CTRL BTN */}
          {renderControlButtons()}
        </div>
      )}

      {/* TITLE */}
      {catTitle && (
        <div className="f-c-sb">
          <h2 className={cssPrefix('title')}>{catTitle}</h2>
          {!backBtn && renderControlButtons()}
        </div>
      )}
      {catSubTitle && <p className={cssPrefix('subtitle')}>{catSubTitle}</p>}

      {/* STATUS */}
      {itemStatus && (
        <div className={cssPrefix('status f-c-s')}>
          <span className={cssPrefix(`status-color bg-${itemStatus.color}`)} />
          <span className={`c-${itemStatus.color}`}>{itemStatus.title}</span>
        </div>
      )}

      {/* TABS */}
      {renderTabs()}

      {/* MODAL */}
      <UniversalModal {...modalParams} visible={isPrompt} />
    </div>
  );
};
