import { useEffect, useState } from 'react';

type propFn = <P>(p: P) => void;
// type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
type Delayed = <P>(fn: any) => (props: P) => void; // => void;

let tId = 0;
export const useDelay: Delayed = (fn) => {
  const [timerId, setTimerId] = useState(0);
  tId = timerId;
  // console.log(timerId);

  useEffect(() => {
    return () => {
      clearTimeout(tId);
      tId = 0;
    };
  }, []);

  return (props: any) => {
    clearTimeout(timerId);
    setTimerId(window.setTimeout(() => fn(props), 1000));
  };
};
