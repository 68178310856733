import React from 'react';

import { UniversalSkeleton } from '../../common/universal';
import { CssPrefix } from 'helpers';

interface IProps {
  cssPrefix: CssPrefix;
}

const buzzDetailsSkeleton: React.FC<IProps> = ({ cssPrefix }) => {
  return (
    <div className="card">
      <UniversalSkeleton
        type="img"
        className={cssPrefix('img rounded')}
        height={240}
      />
      <UniversalSkeleton type="paragraph" width={'60%'} rows={2} />
    </div>
  );
};

export default buzzDetailsSkeleton;
