import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from 'config/history';

import { signInAction, forgotPassAction, resetPassAction } from 'actions';
import { IAuthContainer, IRecoveryPassword } from 'interfaces';
import { AuthRoute, DashboardRoute } from 'const';
import { makeSelector } from 'utils';

export const AuthContainer = (ChildComponent: any) => {
  const Wrapper = () => {
    const dispatch = useDispatch();

    const recoveryPssword = useSelector<any, IRecoveryPassword>(
      makeSelector(['authReducer', 'recoveryPassword'])
    );

    const _redirect = (path: string) => () => history.push(path);

    // METHODS
    const signIn: IAuthContainer['signIn'] = (cred) => {
      dispatch(signInAction(cred, _redirect(DashboardRoute.ROOT)));
      // _redirect(DashboardRoute.ROOT)();
    };

    const forgotPass: IAuthContainer['forgotPass'] = (email) => {
      dispatch(
        forgotPassAction(email, _redirect(AuthRoute.FORGOT_PASSWORD_CONGRATS))
      );
      // _redirect(AuthRoute.FORGOT_PASSWORD_CONGRATS)();
    };

    const resetPass: IAuthContainer['resetPass'] = (password) => {
      dispatch(resetPassAction(password, _redirect(AuthRoute.ROOT)));
    };

    // Enhanced Component
    const childProps: IAuthContainer = {
      signIn,
      forgotPass,
      resetPass,
      recoveryPssword,
    };
    return <ChildComponent {...childProps} />;
  };

  return Wrapper;
};
