import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Checkbox } from 'antd';

interface IProps extends WrappedFieldProps {
  label: string;
}

export const FormCheckbox = (props: IProps) => {
  const { input, label } = props;

  /**
   *  !!! Add type="checkbox" to <Field> component props !!!
   */
  return (
    <div className={`form-group`}>
      <div className="form-control-wrapper">
        <Checkbox {...input}>{label}</Checkbox>
      </div>
    </div>
  );
};
