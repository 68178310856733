import { call, put, takeLatest } from 'redux-saga/effects';
import { sendNotificationAllAction } from 'actions';
import { reset } from 'redux-form';

import { SEND_NOTIFICATION_ALL, NOTIFICATION_ALL_FORM } from 'const';
import { httpApi, HttpResp, makeAction, showMessage } from 'utils';
import { DashboardConfig } from 'interfaces';

function* sendNotificationAll(
  action: ReturnType<typeof sendNotificationAllAction>
) {
  const { payload, redirect } = action;
  try {
    const res: HttpResp<DashboardConfig> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/users/notify?message=${payload.message}`,
    });
    if (res && !res.error) {
      yield put(makeAction(SEND_NOTIFICATION_ALL.SUCCESS, res));
      yield put(reset(NOTIFICATION_ALL_FORM));
      showMessage.success('Notification was sent successfully');
      if (redirect) {
        redirect();
      }
    } else {
      yield put(makeAction(SEND_NOTIFICATION_ALL.FAILURE, res));
    }
  } catch (error) {}
}

export function* notificationSaga() {
  yield takeLatest(SEND_NOTIFICATION_ALL.PENDING, sendNotificationAll);
}
