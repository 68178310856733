import React, { ReactNode } from 'react';

import { UniversalButton, UniversalSkeleton } from '../../universal';
import history from 'config/history';
import { arrowBackIcon } from '../..';
import { CssPrefix } from 'helpers';
import { CategoryHeaderProps } from './CategoryHeader';

interface IProps extends CategoryHeaderProps {
  cssPrefix: CssPrefix;
  tabsNode: ReactNode;
}

const CategoryHeaderSkeleton: React.FC<IProps> = (props) => {
  const {
    cssPrefix,
    catTitle,
    catSubTitle,
    backBtn,
    ctrlBtns,
    itemStatus,
    tabsNode,
  } = props;
  return (
    <div className={cssPrefix(' card')}>
      {backBtn && (
        <div className="f-c-sb">
          {/* BACK BTN */}
          <UniversalButton
            btnType="link"
            btnTitle="BACK"
            onClick={history.goBack}
            iconComp={arrowBackIcon}
            styleType="hint"
            className={cssPrefix('back-btn')}
          />
          {/* CTRL BTN */}
          {ctrlBtns && (
            <UniversalSkeleton
              type={'button'}
              className={cssPrefix('control-btns-block')}
            />
          )}
        </div>
      )}

      {/* TITLE */}
      {typeof catTitle !== 'undefined' && (
        <UniversalSkeleton type="title" className={cssPrefix('title f-c-sb')} />
      )}
      {typeof catSubTitle !== 'undefined' && (
        <UniversalSkeleton type="string" className={cssPrefix('subtitle')} />
      )}

      {/* STATUS */}
      {typeof itemStatus !== 'undefined' && (
        <div className={cssPrefix('status')}>
          <UniversalSkeleton type="string" width={'40px'} />
        </div>
      )}

      {/* TABS */}
      {tabsNode}
    </div>
  );
};

export default CategoryHeaderSkeleton;
