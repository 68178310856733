export enum AuthorityType {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_OCT_ADMIN = 'ROLE_OCT_ADMIN',
  ROLE_OCT_MODERATOR = 'ROLE_OCT_MODERATOR',
  ROLE_OCT_CONTENT_MANAGER = 'ROLE_OCT_CONTENT_MANAGER',
  ACTIVE_TRUE = 'ACTIVE_TRUE',
}

interface IAuthorityItem {
  authority: AuthorityType;
}

export interface IAuthUserData {
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  authorities: IAuthorityItem[];
  credentialsNonExpired: boolean;
  enabled: boolean;
  password: null;
  username: string; // user ID
}
