type ClassName = string | { [key: string]: boolean };

export const classNamesHlp = (...args: ClassName[]) => {
  return args
    .map((item) => {
      if (typeof item === 'string') return item;
      if (typeof item === 'object') {
        const clsName = Object.keys(item)[0];
        return item[clsName] ? clsName : '';
      }
      return '';
    })
    .join(' ');
};

export type CssPrefix = (
  clsName?: string,
  ...classNamesArgs: ClassName[]
) => string;
export const cssPrefixCreator = (prefix?: string): CssPrefix => {
  const fn = (clsName?: string, ...classNamesArgs: ClassName[]) => {
    let classNameStr = prefix;
    if (clsName) {
      classNameStr += clsName[0] !== ' ' ? `-${clsName}` : clsName;
    }
    if (classNamesArgs && classNamesArgs.length) {
      classNameStr += ' ' + classNamesHlp(...classNamesArgs);
    }
    return classNameStr;
  };
  fn.toString = () => '';
  return fn as CssPrefix;
};

export const findParentNode = (node: any, className: string): any => {
  if (node && node.classList.contains(className)) return node;
  if (!node.parentNode) return null;
  if (!node.parentNode.classList) return null;
  if (node.parentNode.classList.contains(className)) return node.parentNode;
  return findParentNode(node.parentNode, className);
};
