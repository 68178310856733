export enum Colors {
  PRIMARY = '#129793',
  PRIMARY_HOVER = '#25b6a9',
  SUCCESS = '#99cc00',
  WARNING = '#ecb75d',
  ERROR = '#e87171',
  HINT = '#b0b0b0',
  BACK = '#f5f7f7',
  BACK_HOVER = '#e8e8e8',
  TRANSPARENT = 'transparent',
}

export enum ColorsNames {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  HINT = 'hint',
  TRANSPARENT = '',
}
