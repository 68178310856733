import {
  Owner,
  resetOwnerModel,
  Location,
  resetLocationModel,
  Category,
  resetCategoryModel,
  PaginationWrapper,
} from '..';

export type CommunityActionType =
  | 'follow'
  | 'bookmark'
  | 'ban'
  | 'block_community'
  | 'approve'
  | 'decline'
  | 'moderate'
  | 'approve_moderate'
  | 'decline_moderate'
  | 'stop_moderate';

// * to ban user request body = {actions: 'ban', userId: [uuid]}
export enum CommunityAction {
  FOLLOW = 'follow',
  BOOKMARK = 'bookmark',
  BAN = 'ban',
  BLOCK = 'block_community',
  APPROVE = 'approve',
  DECLINE = 'decline',
  APPROVE_MODERATE = 'approve_moderate',
  DECLINE_MODERATE = 'decline_moderate',
  MODERATE = 'moderate',
  STOP_MODERATE = 'stop_moderate',
}

export enum ModetatorStatuses {
  NONE = 'NONE',
  MODERATION_REQUESTED = 'MODERATION_REQUESTED',
  MODERATION_SUGGESTED = 'MODERATION_SUGGESTED',
  MODERATOR = 'MODERATOR',
  OWNER = 'OWNER',
}

export const ValidModaratorStatuses = [
  ModetatorStatuses.MODERATOR,
  ModetatorStatuses.OWNER,
];

// temp
export interface CommunityUser {
  id: number;
  fullName: string;
  phone: string;
  email: string;
  gender: string;
  birthDate: string;
  location: string;
  postal: number;
  profile: string;

  ownedCommunities: number;
  surveys: number;
  comments: number;
}

export interface Community {
  // list item
  id: number;
  title: string;
  image: string;
  followed: boolean;
  isAllowToViewStatistic: boolean;
  isPrivate: boolean;
  moderated: boolean;
  owner: Owner;
  //
  category: Category;
  allowToViewStatistic: boolean;
  dateCreated: number;
  description: string;
  location: Location;
  numberOfSurveys: number;
  numberOfUsers: number; // followers
  oct: boolean;
  own: boolean;
  private: boolean;
  surveyAutoReview: boolean;
  bookmarked?: boolean;
  blocked?: boolean;
  deleted?: boolean;
}

export interface AvailableCommunity {
  id: number;
  title: string;
  oct: boolean;
}

export type Communities = Community[];
export interface CommunityList extends PaginationWrapper<Community> {}
export type AvailableCommunityList = PaginationWrapper<AvailableCommunity>;

export const resetCommunityModel = (id: number = 0): Community => ({
  // list item
  id,
  title: '',
  image: '',
  followed: false,
  isPrivate: false,
  moderated: false,
  isAllowToViewStatistic: false,
  owner: resetOwnerModel(),
  //
  category: resetCategoryModel(),
  allowToViewStatistic: false,
  dateCreated: 0,
  description: '',
  location: resetLocationModel(),
  numberOfSurveys: 0,
  numberOfUsers: 0,
  oct: false,
  own: false,
  private: false,
  surveyAutoReview: false,
  // blocked: false,
  // deleted: false
});

export interface Follower {
  id: number;
  avatar: string;
  username: string;
  moderatorStatus: ModetatorStatuses;
}

export interface FollowersList extends PaginationWrapper<Follower> {}
