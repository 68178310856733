import {
  ADD_PAYMENT_PLAN,
  DELETE_PAYMENT_PLAN,
  EDIT_PAYMENT_PLAN,
  GET_PAYMENT_PLANS,
} from 'const';
import { IActionFn, PaymentPlan } from 'interfaces';

export const getPaymentPlansAction: IActionFn<null> = () => ({
  type: GET_PAYMENT_PLANS.PENDING,
  payload: null,
});

export const editPaymentPlanAction: IActionFn<PaymentPlan> = (params) => ({
  type: EDIT_PAYMENT_PLAN.PENDING,
  payload: params,
});

export const addPaymentPlanAction: IActionFn<PaymentPlan> = (params) => ({
  type: ADD_PAYMENT_PLAN.PENDING,
  payload: params,
});

export const deletePaymentPlanAction: IActionFn<number> = (id) => ({
  type: DELETE_PAYMENT_PLAN.PENDING,
  payload: id,
});
