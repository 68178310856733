import React from 'react';

import { UniversalTable } from 'components/common/universal';
import { SearchBlock } from 'components/common/parts';
import { Transaction } from 'interfaces';
import { SearchableTable, SearchableTableContainer } from 'containers';
import { getOwnTransactionsAction } from 'actions';
import { useSelector } from 'react-redux';
import { makeSelector } from 'utils';
import { transactionsTtableParams } from 'assets/static-data';

interface IProps extends SearchableTable {}

const Transactions: React.FC<IProps> = ({ getList, isLoading }) => {
  const transactions = useSelector<any, Transaction[]>(
    makeSelector(['ownDataReducer', 'ownTransactions'])
  );

  return (
    <>
      <SearchBlock onChange={getList} />
      <UniversalTable
        {...transactionsTtableParams}
        data={transactions}
        onChange={getList}
        isLoading={isLoading}
      />
    </>
  );
};

export default SearchableTableContainer({
  getListAction: getOwnTransactionsAction,
  reducerName: 'ownDataReducer',
})(Transactions);
