import React from 'react';
import { useSelector } from 'react-redux';
import { UserProfile } from 'interfaces';
import { makeSelector } from 'utils';

export interface IWithOwnUser {
  profile: UserProfile;
}

export const withOwnUser = (ChildComponent: any) => {
  const Wrapper: React.FC<any> = (props) => {
    const profile = useSelector<any, UserProfile>(
      makeSelector(['ownDataReducer', 'ownProfile'])
    );
    return <ChildComponent {...props} profile={profile} />;
  };

  return Wrapper;
};
