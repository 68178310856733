import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Switch } from 'antd';

interface IProps extends WrappedFieldProps {
  label: string;
  disabled?: boolean;
}

export const FormSwitch = (props: IProps) => {
  const { input, label, ...res } = props;

  return (
    <div className={`form-group`}>
      <div className="form-control-wrapper form-control-wrapper--switch">
        <label>
          <Switch {...input} checked={input.value} {...res} />
          {label}
        </label>
      </div>
    </div>
  );
};
