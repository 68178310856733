import { IUniversalTableParams, IGetInTouch } from 'interfaces';

export const getInTouchTableParams: IUniversalTableParams<IGetInTouch> = {
  titles: [
    {
      title: 'ID',
      propName: 'id',
      isSortable: false,
    },
    {
      title: 'Email',
      propName: 'email',
      isSortable: false,
    },
    {
      title: 'First Name',
      propName: 'firstname',
      isSortable: false,
    },
    {
      title: 'Last Name',
      propName: 'lastname',
      isSortable: false,
    },
  ],
  data: [],
};
