import React from 'react';
import history from '../config/history';
import { formatFn } from 'utils';
import { HumanizeRole, ISelectOption, RoleType } from 'interfaces';
import {
  PhoneChars,
  CommunityRoute,
  UsersRoute,
  POINTS_LIMIT,
  MAX_PASS_CODE,
  userDetailPath,
  communityDetailPath,
} from 'const';
import { UniversalButton } from 'components/common/universal';
import { usersTableParams } from 'assets/static-data';

export const formatAmount = (val: number) => {
  return val >= 1000 ? (val / 1000).toFixed(0) + 'K' : val;
};

export enum SubTitleLinkTypes {
  USER,
  COMMUNITY,
}

interface IPrepareSubTitlelink {
  type: SubTitleLinkTypes;
  title: string;
  id: string | number;
  disabled?: boolean;
}

export const prepareSubTitlelink: React.FC<IPrepareSubTitlelink> = ({
  type,
  title,
  id,
  disabled = false,
}) => {
  let path = '';

  if (type === SubTitleLinkTypes.USER) {
    path = `${UsersRoute.getDetailsRoute(id)}/${userDetailPath.ACCOUNT}`;
  } else if (type === SubTitleLinkTypes.COMMUNITY) {
    path = `${CommunityRoute.getDetailsRoute(id)}/${
      communityDetailPath.MAIN_INFO
    }`;
  }

  return (
    <UniversalButton
      btnType="link"
      btnTitle={title}
      className="inline-link"
      onClick={() => history.push(path)}
      disabled={disabled}
    />
  );
};

export const prepareCategorySubTitle = (
  author: JSX.Element | string | null,
  section: JSX.Element | string | null | number = '',
  date: number
) => {
  return (
    <>
      {author && (
        <>
          Created by <b>{author}</b>{' '}
          {section && (
            <>
              in <b>{section}</b>
            </>
          )}{' '}
          |{' '}
        </>
      )}
      {formatFn('lll')(date)}
    </>
  );
};

export const humanizeBoolean = (bool: boolean) => (bool ? 'yes' : 'no');
export const humanizeString = (str: string) =>
  str ? str.split('_').join(' ').toLowerCase() : str;
export const humanizeVisibility = (isPublic: boolean) =>
  isPublic ? 'Public' : 'Private';
export const humanizeRole = (role: RoleType) =>
  HumanizeRole[role] || humanizeString(role);

export const makeSelectOptionItem = (
  titlePropName: string,
  valPropName: string
) => (item: any): ISelectOption => {
  return { label: item[titlePropName] + '', value: item[valPropName] + '' };
};

export const amountFn = (amount: string) => amount || '0';

export const prepareFileNameFromUrl = (url: string = '') =>
  (url.match(/\w+.\w+$/) || [url])[0];
export const prepareDomainNameFromUrl = (url: string = '') =>
  (url.match(/:\/\/(.[^/]+)/) || ['', url])[1];

export const cutToSize = (max: number) => (value: string): string => {
  let resultString: string = value;
  const isMax = max <= value.length;

  if (isMax) {
    resultString = value.slice(0, max);
  }
  return resultString;
};

export const formatPhoneNumber = (
  updatedValue: string,
  previousValue: string
): string => {
  const maxLength = PhoneChars.CODE_WITH_PLUS + PhoneChars.NUMBER;

  if (!updatedValue) return '+1';
  if (/^\+1$/.test(updatedValue)) return updatedValue;
  if (!/^\+1\d+$/.test(updatedValue)) return previousValue;

  return cutToSize(maxLength)(updatedValue);
};

export const formatLoginFields = (
  updatedValue: string,
  previousValue: string
): string => {
  const isPhone = updatedValue.match(/\+.+/);
  if (isPhone) {
    return formatPhoneNumber(updatedValue, previousValue);
  }
  return updatedValue;
};

export const formatIntegerNumbers = (value = ''): string => {
  const valueWithoutSpaces =
    value && typeof value === 'string' ? value.trim() : value;

  return valueWithoutSpaces.replace(/[^\d]|^0(?=\d)/g, '');
};

export const formatPoints = (value: string = ''): string => {
  const integerNumbers = formatIntegerNumbers(value);

  return cutToSize(POINTS_LIMIT)(integerNumbers);
};
export const formatPasscode = (value = '') => {
  const integerNumbers = formatIntegerNumbers(value);

  return cutToSize(MAX_PASS_CODE)(integerNumbers);
};

export const convertImageBase64toBlob = (b64: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.src = b64;
    img.onerror = reject;

    img.onload = function onload() {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }

      canvas.toBlob((blob) => {
        if (blob === null) return reject('Canvas is empty');
        resolve(blob);
      });
    };
  });
};
