import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Select } from 'antd';
import Icon from '@ant-design/icons';

import { ISelectOption } from 'interfaces';
import { errorIcon, selectIcon } from 'components/common';
import { ReactNodeLike } from 'prop-types';
import { UniversalTooltip } from '../universal';

const { Option } = Select;

interface IProps extends WrappedFieldProps {
  options: ISelectOption[];
  optionsCmp?: ReactNodeLike;
  placeholder: string;
  label: string;
  className: string;
  wrapperClassName: string;
  disabled?: boolean;
}
export const FormSelect = (props: IProps) => {
  const {
    input,
    input: { value },
    meta: { touched, error },
    options,
    label,
    placeholder,
    disabled,
  } = props;

  const invalid = touched && error;
  const val = typeof value !== 'undefined' ? value.toString() : value;

  const renderSuffix = () => (
    <>
      <Icon component={selectIcon} />
      {invalid && (
        <UniversalTooltip placement="right" title={error} styleType="error">
          <Icon component={errorIcon} />
        </UniversalTooltip>
      )}
    </>
  );

  const handleChange = (v: string) => {
    input.onChange(v);
  };

  return (
    <div className={`form-group`}>
      <div className="form-control-wrapper">
        {label && (
          <label htmlFor={input.name} className="text-input-label">
            {label}
          </label>
        )}
        <Select
          onChange={handleChange}
          value={val}
          onFocus={input.onFocus as any}
          placeholder={placeholder}
          className={`select-input ${val ? 'has-value' : ''}`}
          suffixIcon={renderSuffix()}
          disabled={disabled}
        >
          {options.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};
