import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import { SEND_NOTIFICATION_ALL } from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';

export interface NotificationReducerState {
  isLoading: boolean;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = Immutable.fromJS({
  isLoading: false,
} as NotificationReducerState);

const { PENDING, DONE } = makeCombinedAction(SEND_NOTIFICATION_ALL);

const notificationReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
  },
  initialState
);

export default notificationReducer;
