import { DashboardConfig, IChartParams } from 'interfaces';
import { Colors } from 'const';

const prepareFirstCategories = (
  categories: DashboardConfig['communities']['categories']
): { series: number[]; labels: string[] } => {
  const sortedCategories = categories.sort((a, b) => b.counter - a.counter);

  const firstCategories = sortedCategories.slice(0, 3);
  const otherCategories = sortedCategories.slice(3, sortedCategories.length);

  const firstCounters = firstCategories.map((c) => c.counter);
  const firstLables = firstCategories.map((c) => c.category);

  const summOfOtherCounters = otherCategories.reduce(
    (acc, curr) => acc + curr.counter,
    0
  );

  return {
    series: [...firstCounters, summOfOtherCounters],
    labels: [...firstLables, 'Others'],
  };
};

const getSurveysOptions = () => ({
  labels: ['Live', 'Completed'],
  fill: {
    colors: [Colors.SUCCESS, Colors.PRIMARY],
  },
  colors: [Colors.SUCCESS, Colors.PRIMARY],
});

export const getUsersChartParams = (
  config: DashboardConfig['users']
): IChartParams => {
  const { active = 0, inactive = 0, blocked = 0 } = config;
  return {
    series: [active, inactive, blocked],
    options: {
      labels: ['Active', 'Not Active', 'Banned'],
      fill: {
        colors: [Colors.SUCCESS, Colors.WARNING, Colors.ERROR],
      },
      colors: [Colors.SUCCESS, Colors.WARNING, Colors.ERROR],
    },
  };
};

export const getCommunitiesChartParams = (
  config: DashboardConfig['communities']['counters']
): IChartParams => {
  const { public: pub = 0, private: priv = 0 } = config;
  return {
    series: [pub, priv],
    options: {
      labels: ['Public', 'Private'],
      fill: {
        colors: [Colors.PRIMARY, Colors.SUCCESS],
      },
      colors: [Colors.PRIMARY, Colors.SUCCESS],
    },
  };
};

export const getCategoriesChartParams = (
  categories: DashboardConfig['communities']['categories']
): IChartParams => {
  const { series, labels } = prepareFirstCategories(categories);

  return {
    series,
    options: {
      labels,
      fill: {
        colors: ['#687ccc', Colors.SUCCESS, Colors.WARNING, Colors.HINT],
      },
      colors: ['#687ccc', Colors.SUCCESS, Colors.WARNING, Colors.HINT],
    },
  };
};

export const getSurveysChartParams = (
  config: DashboardConfig['surveys']
): IChartParams => {
  const { live = 0, completed = 0 } = config;
  return {
    series: [live, completed],
    options: getSurveysOptions(),
  };
};

export const getPulsesChartParams = (
  config: DashboardConfig['pulses']
): IChartParams => {
  const { live = 0, completed = 0 } = config;
  return {
    series: [live, completed],
    options: getSurveysOptions(),
  };
};
