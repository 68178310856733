export type QuestionType = 'SINGLE' | 'MULTI' | 'YESNO' | 'RANK' | 'RATE';

export const questionType = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI',
  YESNO: 'YESNO',
  RANK: 'RANK',
  RATE: 'RATE',
};

export const humanizeQuestionType = {
  [questionType.SINGLE]: 'Choose One',
  [questionType.MULTI]: 'Multiple choice',
  [questionType.YESNO]: 'Yes/No',
  [questionType.RANK]: 'Rank all items on a 1-5 scale',
  [questionType.RATE]: 'Rate each choice on 1-5 scale',
};

export enum QualifiedAnswer {
  YES = 'Yes',
  NO = 'No',
}

export const QUALIFIED_QUESTION_KEY = 'questionDtoList[0].type';
export const QUALIFIED_QUESTION_QUALIFIED = 'questionDtoList[0].qualified';
export const QUALIFIED_QUESTION_ANSWER_YES =
  'questionDtoList[0].optionList[0].text';
export const QUALIFIED_QUESTION_ANSWER_NO =
  'questionDtoList[0].optionList[1].text';
export const QUALIFIED_QUESTION_ANSWER_YES_TYPE =
  'questionDtoList[0].optionList[0].qualified';
export const QUALIFIED_QUESTION_ANSWER_NO_TYPE =
  'questionDtoList[0].optionList[1].qualified';

export interface QuestionOption {
  id: number;
  questionId: number;
  questionOptionNumber: number;
  text: string;
  votes: number;
  qualified: boolean;
}

export type QuestionOptionsList = QuestionOption[];

export interface Question {
  questionNumber: number;
  question: string;
  important: boolean;
  type: QuestionType;
  optionList: QuestionOptionsList;
  qualified: boolean;
  optional: boolean;
}

export type QuestionList = Question[];

interface resMap<T = number> {
  // rank/rate
  1: T;
  2: T;
  3: T;
  4: T;
  5: T;
}
export interface QuestionsStatistic extends Question {
  passedTimes: number;
  results: {
    [index: number]: number;
  } | null;
  resultsMap: resMap<resMap> | null;
  votesMap?: resMap<resMap> | null;
}

export const MANDATORY_QUESTION_COEFFICIENT = 0.7;

export const resetQuestionOptionModel = (
  qId: number = 0,
  qualified = false
): QuestionOption => ({
  id: 0,
  text: '',
  questionOptionNumber: qId,
  questionId: 0,
  votes: 0,
  qualified,
});

export const resetQuestionModel = (type: QuestionType = 'YESNO'): Question => ({
  type,
  question: '',
  questionNumber: 0,
  important: false,
  qualified: false,
  optionList: [resetQuestionOptionModel(0, true), resetQuestionOptionModel(1)],
  optional: false,
});
