import React from 'react';
import { Route, Switch } from 'react-router';

import { SurveysRoute } from 'const';
import NotFound from 'components/not-found/NotFound';
import SurveysPage from 'components/Surveys/SurveysPage';
import SurveyDetailsPage from 'components/Surveys/SurveyDetails/SurveyDetailsPage';
import SurveyEditPage from 'components/Surveys/SurveyEdit/SurveyEditPage';
import OfficialSurveys from 'components/Surveys/tabs-content/OfficialSurveys';
import UserSurveys from 'components/Surveys/tabs-content/UserSurveys';
import Pulses from 'components/Surveys/tabs-content/Pulses';

const SurveysRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${SurveysRoute.ADD}/:isPulse?`}
        component={SurveyEditPage}
      />
      <Route exact path={SurveysRoute.SUGGEST} component={SurveyEditPage} />
      <Route exact path={SurveysRoute.EDIT} component={SurveyEditPage} />
      <Route exact path={SurveysRoute.DETAILS} component={SurveyDetailsPage} />

      <Route path={SurveysRoute.ROOT.pathname} component={SurveysPage} />

      <Route component={NotFound} />
    </Switch>
  );
};

export const SurveysChildrenRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={SurveysRoute.ROOT.children.OFFICAL}
        component={OfficialSurveys}
      />
      <Route
        exact
        path={SurveysRoute.ROOT.children.USER}
        component={UserSurveys}
      />
      <Route
        exact
        path={SurveysRoute.ROOT.children.PULSES}
        component={Pulses}
      />
    </Switch>
  );
};

export default SurveysRouter;
