import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  UniversalDnDTable,
  UniversalButton,
} from 'components/common/universal';

import {
  getCommunityActiveNewsListAction,
  resetCommunityNewsAction,
  changeCommunityNewsStatusAction,
  changeOrderCommunityNewsAction,
} from 'actions';
import { makeSelector } from 'utils';
import { Community, ITitle, PermissionTypes, UserGroups } from 'interfaces';
import { getMatchParam } from 'helpers';
import { newsTableParams } from 'assets/static-data';
import { CommunityRoute, communityDetailPath } from 'const';
import { INews, NewsList } from 'interfaces/community/news';
import { RouteComponentProps } from 'react-router';
import { customAbility } from 'containers/Can';

interface IProps extends RouteComponentProps {}

interface IState {
  news: INews[];
}

const initialState: IState = {
  news: [],
};

const CommunityActiveNews: React.FC<IProps> = ({ match, history }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);

  const { data } = useSelector<any, NewsList>(
    makeSelector(['communityReducer', 'activeNewsList'])
  );
  const newsImmutable = useSelector<any, any>((state) =>
    state.getIn(['communityReducer', 'activeNewsList'])
  );
  const isLoading = useSelector<any, boolean>(
    makeSelector(['communityReducer', 'isLoading'])
  );

  const community = useSelector<any, Community>(
    makeSelector(['communityReducer', 'community'])
  );

  const idFromUrl = +getMatchParam(match).id;
  const { id: communityId, own: isOwn } = community;

  useEffect(() => {
    dispatch(getCommunityActiveNewsListAction(idFromUrl));
  }, []);

  useEffect(() => {
    const news = data.sort((a, b) => a.displayOrder - b.displayOrder);
    setState({
      ...state,
      news,
    });
  }, [newsImmutable]);

  const addNews = () => {
    dispatch(resetCommunityNewsAction(null));
    const detailPath = CommunityRoute.getDetailsRoute(idFromUrl);
    history.push(`${detailPath}/${communityDetailPath.ADD_NEWS}`);
  };

  const onActiveNews = (id: number) => () => {
    const news = data.find((n) => n.id === id);
    if (!news) return;

    const reqData = {
      ...news,
      active: !news.active,
    };
    dispatch(
      changeCommunityNewsStatusAction({
        news: reqData,
        communityId,
      })
    );
  };

  const actionFn = (id: number) => (
    <UniversalButton
      btnTitle="Inactive"
      styleType="error"
      onClick={onActiveNews(id)}
    />
  );

  const tableCell: ITitle<keyof INews> = {
    title: 'Active',
    propName: 'active',
    className: 'action',
    canRedirect: false,
    formatPropName: 'id',
    formatFn: actionFn,
  };

  const handleDrag = (news: INews[]) => {
    const orders = news.reduce((acc: any, curr, i) => {
      acc[curr.id] = i;
      return acc;
    }, {});
    dispatch(changeOrderCommunityNewsAction({ id: communityId, orders }));
    setState({
      ...state,
      news,
    });
  };

  const canCreate =
    customAbility.can(PermissionTypes.CREATE, UserGroups.NEWS) || isOwn;

  return (
    <>
      {canCreate && (
        <div className="search-block">
          <UniversalButton
            btnTitle="Create News"
            styleType="success"
            onClick={addNews}
          />
        </div>
      )}

      <UniversalDnDTable
        {...newsTableParams}
        titles={[...newsTableParams.titles, tableCell]}
        data={state.news}
        isLoading={isLoading}
        onChange={handleDrag}
        history={history}
      />
    </>
  );
};

export default CommunityActiveNews;
