export const PATTERN_EMAIL = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
// export const PATTERN_PASSWORD = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%\\^&*\\-_.]).{8,}$/;
export const PATTERN_PASSWORD = /^.{6,30}$/;
export const PATTERN_PHONE_NUMBER = /^\(\d{3}\)\d{3}\d{4}$/;
export const PATTERN_FULL_NAME = /^[A-Za-zа-яА-ЯёЁ]+$/;
export const PATTERN_CAPITALIZE = /[A-Z]/;
export const PATTERN_NUMBER = /[0-9]/;
export const PATTERN_SYMBOL = /[!@#\\$%\\^&*\\-_.]/;
export const PATTERN_BIRTH_DATE = /\w{2}\/\w{2}\/\w{4}/;
export const PATTERN_URL = /^http(s)?:\/\/.+/;
