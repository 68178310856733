import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import {
  FormSelect,
  FormTextInput,
  FormUploadImage,
  AsyncSelect,
  FormSwitch,
} from 'components/common/form';
import {
  COMMUNITY_EDIT_FORM,
  SMALL_TITLE_LIMIT,
  DESCRIPTION_LIMIT,
} from 'const';
import { locationOptions, visibilityReversedOptions } from 'assets/static-data';
import { validateRequired } from 'utils';
import { cutToSize } from 'helpers';
import useSelectSearch from 'hooks/useSelectSearch';

interface IProps extends InjectedFormProps {
  isOct: boolean;
}

const CommunityEditForm = (props: IProps) => {
  const { handleSubmit, isOct } = props;

  const onGetCategories = useSelectSearch({
    type: 'category',
    title: 'categoryName',
    value: 'id',
  });

  return (
    <form onSubmit={handleSubmit} className="community-edit-form">
      <div className="card">
        <Field
          name="image"
          label="Community image"
          listType="avatar"
          component={FormUploadImage}
          validate={validateRequired}
        />

        <Field
          name="title"
          label="Title"
          placeholder="Enter Title"
          component={FormTextInput}
          validate={validateRequired}
          normalize={cutToSize(SMALL_TITLE_LIMIT)}
          max={SMALL_TITLE_LIMIT}
        />

        {!isOct && (
          <Field
            name="category.id"
            label="Category"
            placeholder="Select category"
            component={AsyncSelect}
            fetchOptions={onGetCategories}
            validate={validateRequired}
            async={true}
          />
        )}

        <Field
          name="isPrivate"
          label="Visibility"
          placeholder="Select visibility Type"
          options={visibilityReversedOptions}
          component={FormSelect}
        />

        {!isOct && (
          <Field
            name={`surveyAutoReview`}
            label="Automatic approval of surveys"
            component={FormSwitch}
            parse={(val: string) => JSON.parse(val)}
          />
        )}

        <Field
          name="location.id"
          label="Location"
          placeholder="Select Location"
          options={locationOptions}
          component={FormSelect}
        />

        <Field
          name="description"
          label="Description"
          type="textarea"
          placeholder="Enter Description"
          component={FormTextInput}
          textareaAutoSize={{ minRows: 3, maxRows: 8 }}
          validate={validateRequired}
          normalize={cutToSize(DESCRIPTION_LIMIT)}
          max={DESCRIPTION_LIMIT}
        />
      </div>
    </form>
  );
};

export default reduxForm<any, any>({
  form: COMMUNITY_EDIT_FORM,
  enableReinitialize: true,
})(CommunityEditForm);
