import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound from 'components/not-found/NotFound';
import { DashboardRoute } from 'const';
import DashboardPage from 'components/Dashboard/DashboardPage';

const DashboardRouter = () => {
  return (
    <Switch>
      <Route exact path={DashboardRoute.ROOT} component={DashboardPage} />
      {/*<Route exact path={DashboardRoute.DETAILS} component={ForgotPassForm}/>*/}

      <Route component={NotFound} />
    </Switch>
  );
};

export default DashboardRouter;
