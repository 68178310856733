import { IItemStatus, SurveyStatusType, SurveyStatus } from 'interfaces';
import React, { ReactElement } from 'react';
import { ColorsNames } from '../const';

import likeIco from 'assets/images/svg/like.svg';
import dislikeIco from 'assets/images/svg/dislike.svg';

export const prepareSurveyStatus = (status: SurveyStatusType): IItemStatus => {
  switch (status) {
    case SurveyStatus.NONE:
    case SurveyStatus.DRAFT:
      return {
        title: 'Draft',
        color: ColorsNames.HINT,
      };
    case SurveyStatus.LIVE:
      return {
        title: 'Live',
        color: ColorsNames.SUCCESS,
      };
    case SurveyStatus.MODERATION:
      return {
        title: 'In Moderation',
        color: ColorsNames.WARNING,
      };
    case SurveyStatus.COMPLETED:
      return {
        title: 'Completed',
        color: ColorsNames.PRIMARY,
      };
    case SurveyStatus.BLOCKED:
      return {
        title: 'Blocked',
        color: ColorsNames.HINT,
      };
    case SurveyStatus.REJECTED:
      return {
        title: 'Rejected',
        color: ColorsNames.ERROR,
      };
    default:
      return {
        title: '',
        color: ColorsNames.TRANSPARENT,
      };
  }
};

export const getImportantSurveyQuestionIco = (
  important: boolean,
  questionOptionNumber: number
): ReactElement | null => {
  if (!important) return null;
  if (questionOptionNumber === 1) return <img src={likeIco} />;
  if (questionOptionNumber === 2) return <img src={dislikeIco} />;
  return null;
};
