import { PaginationWrapper } from 'interfaces';

export interface INews {
  id: number;
  dateCreated: number;
  description: string;
  image: string;
  title: string;
  active: boolean;
  displayOrder: number;
}

export const resetNewsModel = (): INews => ({
  dateCreated: 0,
  description: '',
  id: 0,
  image: '',
  title: '',
  active: false,
  displayOrder: 0,
});

export interface NewsList extends PaginationWrapper<INews> {
  available: number;
}
