export interface Owner {
  id: string;
  username: string;
  avatar: string;
  isActive: boolean;
}

export const resetOwnerModel = (): Owner => ({
  id: '',
  username: '',
  avatar: '',
  isActive: false,
});
