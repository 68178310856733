import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import {
  GET_OWN_PROFILE,
  GET_OWN_SURVEYS,
  GET_OWN_TRANSACTIONS,
  SIGN_OUT,
  UPD_OWN_PROFILE,
  UPDATE_SURVEY_PARAMS,
} from 'const';
import {
  resetUserProfileModel,
  resetListPaginatedModel,
  SurveysList,
  Transaction,
  UserProfile,
  QueryParams,
  AuthorityType,
  RoleType,
} from 'interfaces';
import { makeCombinedAction } from 'utils/app/reduxHelper';

interface OwnDataReducerState {
  isLoading: boolean;
  ownProfile: UserProfile;
  ownTransactions: Transaction[];
  ownSurveys: SurveysList;
  queryParams: QueryParams;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = Immutable.fromJS({
  isLoading: false,
  ownProfile: resetUserProfileModel(),
  ownTransactions: [],
  ownSurveys: resetListPaginatedModel(),
  queryParams: {},
} as OwnDataReducerState);

const { PENDING, DONE } = makeCombinedAction(
  GET_OWN_PROFILE,
  UPD_OWN_PROFILE,
  GET_OWN_SURVEYS
);

const ownDataReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [GET_OWN_PROFILE.SUCCESS]: (state, { payload }) => {
      return state.set('ownProfile', Immutable.fromJS(payload));
    },
    [GET_OWN_TRANSACTIONS.SUCCESS]: (state, { payload }) => {
      return state.set('ownTransactions', Immutable.fromJS(payload));
    },
    [GET_OWN_SURVEYS.PENDING]: (state, { payload }) => {
      return state.set('queryParams', Immutable.fromJS(payload));
    },
    [GET_OWN_SURVEYS.SUCCESS]: (state, { payload }) => {
      return state.set('ownSurveys', Immutable.fromJS(payload));
    },
    [SIGN_OUT.SUCCESS]: (state) => {
      return state.set('ownProfile', Immutable.fromJS(resetUserProfileModel()));
    },
    [UPDATE_SURVEY_PARAMS]: (state, { payload }) => {
      const queryParams = state.toJS().queryParams;
      return state.set(
        'queryParams',
        Immutable.fromJS({ ...queryParams, query: payload.query })
      );
    },
  },
  initialState
);

export default ownDataReducer;
