import React, { useEffect } from 'react';
import { submit } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { CategoryHeader } from 'components/common/parts';
import { makeBtn } from 'components/common/universal';
import { checkIcon } from 'components/common';
import { cssPrefixCreator, getMatchParam } from 'helpers';
import { COMMUNITY_EDIT_FORM } from 'const';
import { CategoriesList, Community, IImmutableMap } from 'interfaces';
import { makeSelector } from 'utils';
import {
  editCommunityAction,
  getCategoriesListAction,
  getCommunityAction,
  addCommunityAction,
} from 'actions';

import CommunityEditForm from './CommunityEditForm';

import './community-edit-page.scss';

interface IProp extends RouteComponentProps {}

const CommunityEditPage: React.FC<IProp> = ({ history, match }) => {
  const dispatch = useDispatch();
  const community = useSelector<any, Community>(
    makeSelector(['communityReducer', 'community'])
  );
  const { data: categories } = useSelector<any, CategoriesList>(
    makeSelector(['categoriesReducer', 'categoriesList'])
  );

  const idFromUrl = getMatchParam(match).id;
  const isEditMode = !!(idFromUrl || community.id);

  useEffect(() => {
    if (idFromUrl) {
      dispatch(getCommunityAction(idFromUrl));
    }
    if (!categories.length) {
      dispatch(getCategoriesListAction({}));
    }
  }, []);

  const remoteSubmitEditForm = () => {
    dispatch(submit(COMMUNITY_EDIT_FORM));
  };

  const onSubmit = (
    formData: IImmutableMap<Community> | any,
    dispatch: Dispatch<any>
  ) => {
    const formValues: Community = formData.toJS();

    const action = isEditMode
      ? editCommunityAction(formValues, history.goBack)
      : addCommunityAction(formValues, history.goBack);
    dispatch(action);
  };

  const initialValues = {
    ...community,
  };

  const headerCtrlButton = () => {
    if (!isEditMode) {
      return makeBtn(
        'Create Community',
        checkIcon,
        'success',
        remoteSubmitEditForm
      );
    }
    if (community.own === true) {
      return makeBtn(
        'Update Community',
        checkIcon,
        'success',
        remoteSubmitEditForm
      );
    }
    return null;
  };

  const cssPrefix = cssPrefixCreator('community-edit-page');
  return (
    <div className={cssPrefix(' f-column')}>
      <CategoryHeader
        backBtn={{ showButton: true, showModal: true }}
        ctrlBtns={headerCtrlButton()}
      />

      <CommunityEditForm
        isOct={community.oct}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default CommunityEditPage;
