import { call, put, takeLatest } from 'redux-saga/effects';

import {
  addCategoryAction,
  deleteCategoryAction,
  editCategoryAction,
  getCategoriesListAction,
} from 'actions';
import {
  ADD_CATEGORY,
  CATEGORY_EDIT_FORM,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORIES_LIST,
  GET_CATEGORIES_SEARCH_LIST,
} from 'const';
import {
  encodeDataToUrl,
  httpApi,
  HttpResp,
  makeAction,
  showMessage,
} from 'utils';
import { CategoriesList, Category, QueryParams } from 'interfaces';
import { reset } from 'redux-form';

function* searchCategoriesByQuery({
  payload,
  redirect,
}: ReturnType<typeof getCategoriesListAction>) {
  try {
    const res: HttpResp<CategoriesList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/categories/available?${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      if (redirect) {
        redirect(res);
      }
      yield put(makeAction(GET_CATEGORIES_SEARCH_LIST.SUCCESS, res.data));
    } else {
      yield put(makeAction(GET_CATEGORIES_SEARCH_LIST.FAILURE, res));
    }
  } catch (error) {}
}

function* getCategoriesList({
  payload,
}: ReturnType<typeof getCategoriesListAction>) {
  try {
    const res: HttpResp<CategoriesList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/categories/page?${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_CATEGORIES_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_CATEGORIES_LIST.FAILURE, res));
    }
  } catch (error) {}
}

function* editCategory({
  payload,
  redirect,
}: ReturnType<typeof editCategoryAction>) {
  const { category, params } = payload;
  try {
    const res: HttpResp<Category> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/categories/${category.id}`,
      data: category,
    });
    if (res && !res.error) {
      yield put(getCategoriesListAction(params));
      showMessage.success('Category updated successfully');
      yield put(reset(CATEGORY_EDIT_FORM));
      if (redirect) {
        redirect();
      }
    } else {
      yield put(makeAction(EDIT_CATEGORY.FAILURE, res));
    }
  } catch (error) {}
}

function* addCategory({
  payload,
  redirect,
}: ReturnType<typeof addCategoryAction>) {
  const { category, params } = payload;
  try {
    const res: HttpResp<Category> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/categories`,
      data: category,
    });
    if (res && !res.error) {
      yield put(getCategoriesListAction(params));
      showMessage.success('Category created successfully');
      yield put(reset(CATEGORY_EDIT_FORM));
      if (redirect) {
        redirect();
      }
    } else {
      yield put(makeAction(ADD_CATEGORY.FAILURE, res));
    }
  } catch (error) {}
}

function* deleteCategory({ payload }: ReturnType<typeof deleteCategoryAction>) {
  const { id, params } = payload;
  try {
    const res: HttpResp<Category> = yield call(httpApi, {
      method: 'DELETE',
      partUrl: `/categories/${id}`,
      data: id,
    });
    if (res && !res.error) {
      yield put(getCategoriesListAction(params));
      showMessage.success('Category deleted successfully');
    } else {
      yield put(makeAction(DELETE_CATEGORY.FAILURE, res));
    }
  } catch (error) {}
}

export function* categoriesSaga() {
  yield takeLatest(GET_CATEGORIES_SEARCH_LIST.PENDING, searchCategoriesByQuery);
  yield takeLatest(GET_CATEGORIES_LIST.PENDING, getCategoriesList);
  yield takeLatest(EDIT_CATEGORY.PENDING, editCategory);
  yield takeLatest(ADD_CATEGORY.PENDING, addCategory);
  yield takeLatest(DELETE_CATEGORY.PENDING, deleteCategory);
}
