import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound from 'components/not-found/NotFound';
import AccountPage from 'components/Account/AccountPage';
import { AccountRoute } from 'const';

const AccountRouter = () => {
  return (
    <Switch>
      <Route exact path={AccountRoute.ROOT} component={AccountPage} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default AccountRouter;
