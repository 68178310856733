import { call, put, takeLatest } from 'redux-saga/effects';

import {
  addOffensiveWordAction,
  deleteOffensiveWordAction,
  editPointsConfigAction,
  getOffensiveWordsListAction,
  getPointsConfigAction,
} from 'actions';
import {
  ADD_OFFENSIVE_WORD,
  DELETE_OFFENSIVE_WORD,
  EDIT_POINTS_CONFIG,
  GET_OFFENSIVE_WORDS_LIST,
  GET_POINTS_CONFIG,
} from 'const';
import { httpApi, HttpResp, makeAction, showMessage } from 'utils';
import { PointsConfig } from 'interfaces';

function* getOffensiveWordsList({}: ReturnType<
  typeof getOffensiveWordsListAction
>) {
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/ow/full`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_OFFENSIVE_WORDS_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_OFFENSIVE_WORDS_LIST.FAILURE, res));
    }
  } catch (error) {}
}

const newWordsArr: string[] = [];
function* addOffensiveWord({
  payload,
}: ReturnType<typeof addOffensiveWordAction>) {
  try {
    newWordsArr.push(payload);
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/ow?wording=${payload}`,
    });
    if (res && !res.error) {
      const msgPrefix =
        newWordsArr.length > 1
          ? `New unique words "${newWordsArr.join('", "')}" `
          : `New unique word "${payload}" `;
      showMessage.success(`${msgPrefix} added successfully`);
      yield put(getOffensiveWordsListAction(null));
      newWordsArr.length = 0;
    } else {
      yield put(makeAction(ADD_OFFENSIVE_WORD.FAILURE, res));
      newWordsArr.length = 0;
    }
  } catch (error) {}
}

function* deleteOffensiveWord({
  payload,
}: ReturnType<typeof deleteOffensiveWordAction>) {
  const { id, word } = payload;
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'DELETE',
      partUrl: `/ow/${id}`,
    });
    if (res && !res.error) {
      yield put(makeAction(DELETE_OFFENSIVE_WORD.SUCCESS, res));
      showMessage.success(`Word "${word}" removed successfully`);
      yield put(getOffensiveWordsListAction(null));
    } else {
      yield put(makeAction(DELETE_OFFENSIVE_WORD.FAILURE, res));
    }
  } catch (error) {}
}

export function* offensiveWordsSaga() {
  yield takeLatest(GET_OFFENSIVE_WORDS_LIST.PENDING, getOffensiveWordsList);
  yield takeLatest(ADD_OFFENSIVE_WORD.PENDING, addOffensiveWord);
  yield takeLatest(DELETE_OFFENSIVE_WORD.PENDING, deleteOffensiveWord);
}
