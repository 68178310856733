import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import { CategoryHeader } from '../common/parts';
import { makeSelector } from 'utils';
import { IImmutableMap, PointsConfig } from 'interfaces';
import { makeBtn } from '../common/universal';
import { checkIcon } from '../common';
import { POINTS_EDIT_FORM } from 'const';
import { editPointsConfigAction, getPointsConfigAction } from '../../actions';
import PointsEditForm from './PointsEditForm';

import './points-page.scss';

const PointsPage: React.FC = () => {
  const dispatch = useDispatch();

  const config = useSelector<any, PointsConfig>(
    makeSelector(['pointsReducer', 'config'])
  );

  useEffect(() => {
    dispatch(getPointsConfigAction(null));
  }, []);

  const remoteSubmit = () => {
    dispatch(submit(POINTS_EDIT_FORM));
  };

  const onSubmit = (formData: IImmutableMap<PointsConfig> | any) => {
    dispatch(editPointsConfigAction(formData.toJS()));
  };

  return (
    <div className="f-column points-page">
      <CategoryHeader
        catTitle="Points"
        ctrlBtns={makeBtn('Save changes', checkIcon, 'success', remoteSubmit)}
      />

      <PointsEditForm initialValues={config} onSubmit={onSubmit} />
    </div>
  );
};

export default PointsPage;
