import React, { useEffect } from 'react';
import {
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
} from 'redux-form/immutable';
import { InjectedFormProps, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { renderQuestionBlock } from './renderSurveyEditFormQuestion';
import {
  FormCheckbox,
  FormTextInput,
  FormUploadFile,
  AsyncSelect,
} from 'components/common/form';
import { cssPrefixCreator, cutToSize } from 'helpers';
import {
  IImmutableMap,
  QuestionList,
  SurveyReq,
  questionType,
  QualifiedAnswer,
  QUALIFIED_QUESTION_KEY,
  QUALIFIED_QUESTION_ANSWER_YES,
  QUALIFIED_QUESTION_ANSWER_NO,
  QUALIFIED_QUESTION_ANSWER_YES_TYPE,
  QUALIFIED_QUESTION_ANSWER_NO_TYPE,
  QUALIFIED_QUESTION_QUALIFIED,
  MANDATORY_QUESTION_COEFFICIENT,
} from 'interfaces';
import { SURVEY_EDIT_FORM, LARGE_TITLE_LIMIT, DESCRIPTION_LIMIT } from 'const';
import { showMessage, validateRequired, validateSoftURL } from 'utils';

import './survey-edit-form.scss';
import useSelectSearch from 'hooks/useSelectSearch';
import { GetOptionalsQuestions } from './SurveyEditPage';

interface InitialValues extends IImmutableMap<SurveyReq> {
  questionDtoList: QuestionList;
}

interface IProps extends InjectedFormProps {
  initialValues: InitialValues;
  isOfficial: boolean;
  getOptionalsQuestions: GetOptionalsQuestions;
}

const formSelector = formValueSelector(SURVEY_EDIT_FORM);
const cssPrefix = cssPrefixCreator('survey-edit-form');

const SurveyEditForm: any = ({
  handleSubmit,
  isOfficial,
  getOptionalsQuestions,
}: IProps) => {
  const dispatch = useDispatch();
  const { questionDtoList, isPulse, isDraft, isQualified } = useSelector<
    any,
    {
      questionDtoList: QuestionList;
      isPulse: boolean;
      isDraft: boolean;
      isQualified: boolean;
    }
  >((state: any) => ({
    questionDtoList: formSelector(state, 'questionDtoList').toJS(),
    isPulse: formSelector(state, 'isPulse'),
    isDraft: formSelector(state, 'isDraft'),
    isQualified: formSelector(state, QUALIFIED_QUESTION_QUALIFIED),
  }));

  const onGetCategories = useSelectSearch({
    type: 'category',
    title: 'categoryName',
    value: 'id',
  });

  const onGetCommunities = useSelectSearch({
    type: 'community',
    title: 'title',
    value: 'id',
  });
  useEffect(() => {
    if (questionDtoList[0].qualified) {
      dispatch(
        change(SURVEY_EDIT_FORM, QUALIFIED_QUESTION_KEY, questionType.YESNO)
      );
      dispatch(
        change(
          SURVEY_EDIT_FORM,
          QUALIFIED_QUESTION_ANSWER_YES,
          QualifiedAnswer.YES
        )
      );
      dispatch(
        change(
          SURVEY_EDIT_FORM,
          QUALIFIED_QUESTION_ANSWER_NO,
          QualifiedAnswer.NO
        )
      );
    }
  }, [questionDtoList[0].qualified, dispatch]);

  const handleChange = (e: any) => {
    const { name, checked } = e.target;
    if (
      name === QUALIFIED_QUESTION_ANSWER_YES_TYPE ||
      name === QUALIFIED_QUESTION_ANSWER_NO_TYPE
    ) {
      const uncheckedType =
        name === QUALIFIED_QUESTION_ANSWER_YES_TYPE
          ? QUALIFIED_QUESTION_ANSWER_NO_TYPE
          : QUALIFIED_QUESTION_ANSWER_YES_TYPE;

      if (checked) {
        dispatch(change(SURVEY_EDIT_FORM, uncheckedType, false));
        return;
      }
      dispatch(change(SURVEY_EDIT_FORM, name, true));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      onChange={handleChange}
      className={`
      ${cssPrefix(' flex')}
      ${isPulse ? `${cssPrefix('-pulse')}` : ''}
    `}
    >
      {/* LEFT SIDE */}
      <div className={`card`}>
        {isDraft && (
          <Field
            name="community.id"
            label="Community"
            placeholder="Select Community"
            component={AsyncSelect}
            fetchOptions={onGetCommunities}
            async={true}
            validate={validateRequired}
          />
        )}

        <Field
          name="title"
          label="Title"
          placeholder="Enter Title"
          component={FormTextInput}
          validate={validateRequired}
          normalize={cutToSize(LARGE_TITLE_LIMIT)}
          max={LARGE_TITLE_LIMIT}
        />

        <Field
          name="category"
          label="Category"
          placeholder="Select Category"
          component={AsyncSelect}
          fetchOptions={onGetCategories}
          async={true}
          validate={validateRequired}
        />

        {(isOfficial || isPulse) && (
          <Field
            name="url"
            label="Reference URL"
            placeholder="Enter URL"
            validate={validateSoftURL}
            component={FormTextInput}
          />
        )}

        <Field
          name="description"
          label="Description"
          type="textarea"
          placeholder="Enter Description"
          component={FormTextInput}
          textareaAutoSize={{ minRows: 3, maxRows: 8 }}
          className={cssPrefix('description')}
          validate={validateRequired}
          normalize={cutToSize(DESCRIPTION_LIMIT)}
          max={DESCRIPTION_LIMIT}
        />
        {(isOfficial || isPulse) && (
          <Field
            name="pdf"
            label="Reference PDF"
            accept="application/pdf"
            component={FormUploadFile}
          />
        )}

        {!isPulse && !isQualified && (
          <Field
            name="isAbleToChangeAnswer"
            label="Allow answer to be changed until survey is closed"
            type="checkbox"
            component={FormCheckbox}
          />
        )}
      </div>

      {/* RIGHT SIDE */}
      <div className={cssPrefix('right-side card')}>
        <FieldArray
          name="questionDtoList"
          component={renderQuestionBlock}
          props={{
            questionDtoList,
            isPulse,
            optionalQuestion: getOptionalsQuestions(questionDtoList),
          }}
        />
      </div>
    </form>
  );
};

export default reduxForm<any, any>({
  form: SURVEY_EDIT_FORM,
  enableReinitialize: true,
})(SurveyEditForm);
