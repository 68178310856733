import {
  IUniversalTableParams,
  SurveyItem,
  SurveyStatusType,
} from 'interfaces';
import { FORMAT_TABLE_DATE, SurveysRoute } from 'const';
import { amountFn, prepareSurveyStatus } from 'helpers';
import {
  categoryFn,
  communityFn,
  dateFn,
  ownerFn,
} from 'components/common/universal/utils/universal-helper';

export const SurveysTableParams: IUniversalTableParams<SurveyItem> = {
  titles: [
    {
      title: 'ID',
      propName: 'id',
    },
    {
      title: 'Title',
      propName: 'title',
    },
    {
      title: 'Date',
      propName: 'dateCreated',
      formatFn: dateFn(FORMAT_TABLE_DATE),
    },
    {
      title: 'Category',
      propName: 'category',
      formatFn: categoryFn,
    },
    {
      title: 'Community',
      propName: 'community',
      formatFn: communityFn,
    },
    {
      title: 'Owner',
      propName: 'owner',
      formatFn: ownerFn,
    },
    {
      title: 'Status',
      propName: 'status',
      formatFn: (status: SurveyStatusType) => prepareSurveyStatus(status).title,
    },
    {
      title: 'Passed times',
      propName: 'passedTimes',
      formatFn: amountFn,
      isSortable: false,
    },
    {
      title: 'Rating',
      propName: 'rating',
      formatFn: amountFn,
    },
  ],
  data: [],
  routeConst: SurveysRoute,
};
