import { IUniversalTableParams } from 'interfaces';
import {
  CommunityRoute,
  FORMAT_TABLE_DATE,
  communityDetailPath,
  communityDetail,
} from 'const';
import { dateFn } from 'components/common/universal/utils/universal-helper';
import { INews } from 'interfaces/community/news';
import history from 'config/history';

const updatedCommunityNewsRoute = {
  ...CommunityRoute,
  getDetailsRoute(id: string | number) {
    const url = history.location.pathname;
    const searchString = `${communityDetail}/(.+)/(${communityDetailPath.INACTIVE_NEWS}|${communityDetailPath.ACTIVE_NEWS})`;
    const preparedString = searchString.replace(/\//g, '[/]');
    const regexp = new RegExp(preparedString);

    const match = url.match(regexp);

    if (!match) return url;

    const communityId = match[1];

    const result = CommunityRoute.getDetailsRoute(communityId);
    return `${result}/${communityDetailPath.EDIT_NEWS}/${id}`;
  },
};

export const newsTableParams: IUniversalTableParams<INews> = {
  titles: [
    {
      title: 'ID',
      propName: 'id',
    },
    {
      title: 'Title',
      propName: 'title',
    },
    {
      title: 'Date',
      propName: 'dateCreated',
      formatFn: dateFn(FORMAT_TABLE_DATE),
    },
  ],
  data: [],
  routeConst: updatedCommunityNewsRoute,
};
