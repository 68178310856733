import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { IActionFn, QueryParams } from 'interfaces';
import { useLoading } from 'hooks';
import { makeSelector } from '../utils';

// tslint:disable-next-line:interface-name
export interface SearchableTable extends RouteComponentProps {
  getList(evQueryParams: QueryParams): void;
  isLoading: boolean;
  queryParams: QueryParams;
}

interface IContainerProps {
  getListAction: IActionFn<QueryParams>;
  reducerName: string;
  isInitialized?: boolean; // disable first init
  queryName?: string; // specific queryParams name
  solved?: boolean; // specific for contact request page
  isEqualSearch?: boolean;
}

export const SearchableTableContainer = <T extends {}>({
  getListAction,
  reducerName,
  isInitialized = true,
  queryName = 'queryParams',
  solved = false,
  isEqualSearch,
}: IContainerProps) => (ChildComponent: any) => {
  const Wrapper = (props: RouteComponentProps & T) => {
    const dispatch = useDispatch();
    const [queryParams, setQueryParams] = useState<QueryParams>(
      new QueryParams()
    );

    const queryParamsR = useSelector(makeSelector([reducerName, queryName]));
    const isLoading = useLoading(reducerName);

    useEffect(() => {
      if (isInitialized) {
        getList(queryParamsR);
      }
    }, []);

    const getList = (evQueryParams?: QueryParams) => {
      let searchReducer: QueryParams['searchName'];
      if (isEqualSearch) {
        searchReducer = {
          reducerName,
          fieldName: queryName,
        };
      }
      const params: QueryParams = {
        ...queryParams,
        ...evQueryParams,
        solved,
        searchName: searchReducer,
      };
      if (!solved) {
        delete params.solved;
      }
      if (!isEqualSearch) {
        delete params.searchName;
      }
      dispatch(getListAction(params));
      setQueryParams(params);
    };

    return (
      <ChildComponent
        {...props}
        getList={getList}
        isLoading={isLoading}
        queryParams={queryParamsR || queryParams}
      />
    );
  };

  return withRouter(Wrapper);
};
