import {
  ADD_BUZZ,
  DELETE_BUZZ,
  EDIT_BUZZ,
  GET_BUZZ,
  GET_BUZZ_LIST,
  RESET_BUZZ_STATE,
} from 'const';
import { Buzz, IActionFn, QueryParams } from 'interfaces';

export const getBuzzListAction: IActionFn<QueryParams> = (params) => ({
  type: GET_BUZZ_LIST.PENDING,
  payload: params,
});

export const getBuzzAction: IActionFn<number> = (id) => ({
  type: GET_BUZZ.PENDING,
  payload: id,
});

export const deleteBuzzAction: IActionFn<number> = (id, redirect) => ({
  type: DELETE_BUZZ.PENDING,
  payload: id,
  redirect,
});

export const addBuzzAction: IActionFn<Buzz> = (buzz, redirect) => ({
  type: ADD_BUZZ.PENDING,
  payload: buzz,
  redirect,
});

export const editBuzzAction: IActionFn<Buzz> = (buzz, redirect) => ({
  type: EDIT_BUZZ.PENDING,
  payload: buzz,
  redirect,
});

export const resetBuzzStateAction: IActionFn = () => ({
  type: RESET_BUZZ_STATE.SUCCESS,
  payload: null,
});
