import React, { memo } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { AuthRoute, DashboardRoute } from 'const';
import AuthRouter from './childRoutes/AuthRouter';
import MainWrapper from 'components/MainWrapper/MainWrapper';
import { AuthPageWrapper } from '../components/Auth/AuthPage';
import RootRouter from './RootRouter';
import { session } from 'utils';

const PrivateRoutes = ({ hasToken }: { hasToken: boolean }) => {
  if (!hasToken) return <Redirect to={AuthRoute.ROOT} />;
  return (
    <Route
      path="/"
      render={(props) => {
        return (
          <>
            <MainWrapper {...props}>
              <Switch>
                <Redirect exact from="/" to={DashboardRoute.ROOT} />
                <Route component={RootRouter} />
              </Switch>
            </MainWrapper>
          </>
        );
      }}
    />
  );
};

const MemoPrivateRoutes = memo(PrivateRoutes);

const AppRouter = () => {
  const hasToken = Boolean(session.getToken());

  return (
    <Switch>
      {/* AUTHORIZATION */}
      <Route path={AuthRoute.ROOT} component={AuthPageWrapper(AuthRouter)} />

      <MemoPrivateRoutes hasToken={hasToken} />
    </Switch>
  );
};

export default withRouter(AppRouter);
