import { QueryDef } from 'const';

export class QueryParams {
  query?: string = '';
  offset?: number = 0; // def 0
  limit?: number = QueryDef.LIMIT; // def 20
  sort?: string = QueryDef.SORT; // def "id"
  order?: string = QueryDef.ORDER; // def "asc"

  // additional specific value
  id?: number;
  solved?: boolean;
  searchName?: {
    reducerName: string;
    fieldName: string;
  };
  selected?: number;
}

// for immutable
export const resetQueryParamsModel = () => ({
  limit: QueryDef.LIMIT,
  sort: QueryDef.SORT,
  order: QueryDef.ORDER,
});
