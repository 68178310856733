import {
  ADD_SURVEY,
  DELETE_SURVEY,
  EDIT_SURVEY,
  GET_PULSES_LIST,
  GET_QUESTIONS,
  GET_QUESTIONS_STATISTIC,
  GET_SURVEY,
  GET_SURVEY_LIST,
  RESET_QUESTIONS_STATE,
  RESET_SURVEY_STATE,
  SET_SURVEY_STATUS,
} from 'const';
import {
  IActionFn,
  QueryParams,
  SurveyReq,
  SurveyStatusReq,
} from 'interfaces';

export const getSurveyListAction: IActionFn<QueryParams> = (params) => ({
  type: GET_SURVEY_LIST.PENDING,
  payload: params,
});

export const getPulsesListAction: IActionFn<QueryParams> = (params) => ({
  type: GET_PULSES_LIST.PENDING,
  payload: params,
});

export const getSurveyAction: IActionFn<number> = (id) => ({
  type: GET_SURVEY.PENDING,
  payload: id,
});

export const getSurveyQuestionsAction: IActionFn<number> = (id) => ({
  type: GET_QUESTIONS.PENDING,
  payload: id,
});

export const getSurveyQuestionsStatisticAction: IActionFn<number> = (id) => ({
  type: GET_QUESTIONS_STATISTIC.PENDING,
  payload: id,
});

export const setSurveyStatusAction: IActionFn<SurveyStatusReq> = (params) => ({
  type: SET_SURVEY_STATUS.PENDING,
  payload: params,
});

export const deleteSurveyAction: IActionFn<number> = (id, redirect) => ({
  type: DELETE_SURVEY.PENDING,
  payload: id,
  redirect,
});

export const addSurveyAction: IActionFn<SurveyReq> = (survey, redirect) => ({
  type: ADD_SURVEY.PENDING,
  payload: survey,
  redirect,
});

export const editSurveyAction: IActionFn<SurveyReq> = (survey, redirect) => ({
  type: EDIT_SURVEY.PENDING,
  payload: survey,
  redirect,
});

export const resetSurveyStateAction: IActionFn = () => ({
  type: RESET_SURVEY_STATE.SUCCESS,
  payload: null,
});

export const resetQuestionsStateAction: IActionFn = () => ({
  type: RESET_QUESTIONS_STATE.SUCCESS,
  payload: null,
});
