import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import {
  DELETE_SURVEY,
  GET_PULSES_LIST,
  GET_QUESTIONS,
  GET_QUESTIONS_STATISTIC,
  GET_SURVEY,
  GET_SURVEY_LIST,
  RESET_QUESTIONS_STATE,
  RESET_SURVEY_STATE,
  UPDATE_SURVEY_PARAMS,
  START_SURVEY_ACTION,
  STOP_SURVEY_ACTION,
} from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';
import {
  QueryParams,
  QuestionList,
  resetSurveyModel,
  Survey,
  SurveyItem,
  SurveysList,
} from 'interfaces';
import { resetListPaginatedModel } from '../interfaces/common';

interface SurveysReducerState {
  isLoading: boolean;
  isLoadingQuestions: boolean;
  surveysQueryParams: QueryParams;
  surveysList: SurveysList;
  survey: Survey;
  pulsesQueryParams: QueryParams;
  pulsesList: SurveysList;
  pulse: SurveyItem;
  questionList: QuestionList;
  questionStatistic: QuestionList;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = Immutable.fromJS({
  isLoading: false,
  isLoadingQuestions: false,
  surveysQueryParams: {},
  surveysList: resetListPaginatedModel<SurveyItem>(),
  survey: resetSurveyModel(),
  pulsesQueryParams: {},
  pulsesList: resetListPaginatedModel<SurveyItem>(),
  pulse: resetSurveyModel(),
  questionList: [],
  questionStatistic: [],
} as SurveysReducerState);

const { PENDING, DONE } = makeCombinedAction(
  GET_SURVEY_LIST,
  GET_SURVEY,
  GET_PULSES_LIST
);
const QUESTIONS = makeCombinedAction(GET_QUESTIONS, GET_QUESTIONS_STATISTIC);

const surveysReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [QUESTIONS.PENDING]: (state) => {
      return state.set('isLoadingQuestions', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [QUESTIONS.DONE]: (state) => {
      return state.set('isLoadingQuestions', false);
    },
    [GET_SURVEY_LIST.PENDING]: (state, { payload }) => {
      return state.set('surveysQueryParams', Immutable.fromJS(payload));
    },
    [GET_SURVEY_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('surveysList', Immutable.fromJS(payload));
    },
    [GET_PULSES_LIST.PENDING]: (state, { payload }) => {
      return state.set('pulsesQueryParams', Immutable.fromJS(payload));
    },
    [GET_PULSES_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('pulsesList', Immutable.fromJS(payload));
    },
    [GET_SURVEY.SUCCESS]: (state, { payload }) => {
      return state.set('survey', Immutable.fromJS(payload));
    },
    [GET_QUESTIONS.SUCCESS]: (state, { payload }) => {
      return state.set('questionList', Immutable.fromJS(payload));
    },
    [GET_QUESTIONS_STATISTIC.SUCCESS]: (state, { payload }) => {
      return state.set('questionStatistic', Immutable.fromJS(payload));
    },
    [`${DELETE_SURVEY.SUCCESS}||${RESET_SURVEY_STATE.SUCCESS}`]: (state) => {
      return state.set('survey', Immutable.fromJS(resetSurveyModel()));
    },
    [RESET_QUESTIONS_STATE.SUCCESS]: (state) => {
      return state.set('questionList', Immutable.fromJS([]));
    },
    [UPDATE_SURVEY_PARAMS]: (state, { payload }) => {
      const surveysQueryParams = state.toJS().surveysQueryParams;
      const pulsesQueryParams = state.toJS().pulsesQueryParams;

      state = state.set(
        'pulsesQueryParams',
        Immutable.fromJS({ ...pulsesQueryParams, query: payload.query })
      );

      state = state.set(
        'surveysQueryParams',
        Immutable.fromJS({ ...surveysQueryParams, query: payload.query })
      );

      return state;
    },
    [START_SURVEY_ACTION]: (state) => {
      return state.set('isLoading', true);
    },
    [STOP_SURVEY_ACTION]: (state) => {
      return state.set('isLoading', false);
    },
  },
  initialState
);

export default surveysReducer;
