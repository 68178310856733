import {
  // IImmutableMap,
  Interest,
  Location,
  PaginationWrapper,
  ModetatorStatuses,
} from '..';
import { Chat } from './chat.interface';

// interface Deserializable {
//   serialize(input: any): this
// }

export type GenderType = 'NONE' | 'MALE' | 'FEMALE';
export enum humanizeGender {
  NONE = '-',
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum RoleType {
  USER = 'USER',
  OCT_ADMIN = 'OCT_ADMIN',
  OCT_MODERATOR = 'OCT_MODERATOR',
  OCT_CONTENT_MANAGER = 'OCT_CONTENT_MANAGER',
}
export enum HumanizeRole {
  USER = 'User',
  OCT_ADMIN = 'OCT Admin',
  OCT_MODERATOR = 'OCT Moderator',
  OCT_CONTENT_MANAGER = 'OCT Content Manager',
}

export const OCT_ROLE_TYPES = [
  RoleType.OCT_ADMIN,
  RoleType.OCT_CONTENT_MANAGER,
  RoleType.OCT_MODERATOR,
];

export const RolesForPrivateTabs = [RoleType.OCT_ADMIN, RoleType.OCT_MODERATOR];

export interface IUserProfile {
  allowEmailNotifications: number;
  allowPushNotifications: number;
  blacklistedByMe: boolean;
  blacklistedMe: boolean;
  blocked: boolean;
  chat: Chat | null;
  extraPoints: number;
  gender: GenderType;
  id: string;
  interests: Interest[];
  isPublic: boolean;
  location: Location;
  phoneNumber: string;
  isActive: boolean;
  points: number;
  role: RoleType;
  unreadMessages: number;
  unreadNotifications: number;
  unreadNotificationsDate: number;
  username: string;
  yearOfBirth: string;
  email: string;
  avatar: string;
  zipcode: number;
  numberOfOwnedCommunities: number;
  numberOfSurveys: number;
  moderatorStatus: ModetatorStatuses | null;
}

export class UserProfile implements IUserProfile {
  allowEmailNotifications = 0;
  allowPushNotifications = 0;
  blacklistedByMe = false;
  blacklistedMe = false;
  blocked = false;
  chat = null;
  extraPoints = 0;
  gender = '' as GenderType;
  id = '';
  interests = [];
  isPublic = false;
  location = new Location();
  phoneNumber = '';
  isActive = false;
  points = 0;
  role = '' as RoleType;
  unreadMessages = 0;
  unreadNotifications = 0;
  unreadNotificationsDate = 0;
  username = '';
  yearOfBirth = '';
  email = '';
  avatar = '';
  zipcode = 0;
  numberOfOwnedCommunities = 0;
  numberOfSurveys = 0;
  moderatorStatus = null;

  // serialize(input: IUserProfile & IImmutableMap<IUserProfile>) {
  //   input.toJS
  //     ? Object.assign(this, input.toJS())
  //     : Object.assign(this, input);
  //   return this;
  // }
}

export const resetUserProfileModel = (): UserProfile => ({
  allowEmailNotifications: 0,
  allowPushNotifications: 0,
  blacklistedByMe: false,
  blacklistedMe: false,
  blocked: false,
  chat: null,
  extraPoints: 0,
  gender: '' as GenderType,
  id: '',
  interests: [],
  isPublic: false,
  location: new Location(),
  phoneNumber: '',
  isActive: false,
  points: 0,
  role: '' as RoleType,
  unreadMessages: 0,
  unreadNotifications: 0,
  unreadNotificationsDate: 0,
  username: '',
  yearOfBirth: '',
  email: '',
  avatar: '',
  zipcode: 0,
  numberOfOwnedCommunities: 0,
  numberOfSurveys: 0,
  moderatorStatus: null,
});

export interface UsersList extends PaginationWrapper<UserProfile> {}
