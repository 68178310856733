import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { CategoryHeader } from 'components/common/parts';
import {
  IUniversalModalProps,
  makeBtn,
  UniversalModal,
} from 'components/common/universal';
import {
  IItem,
  UserProfile,
  PermissionTypes,
  UserGroups,
  OCT_ROLE_TYPES,
} from 'interfaces';
import { closedIcon, deleteIcon, checkIcon } from 'components/common';
import { PromptPrefix } from 'const';
import { makeSelector, getUserCategoryTab } from 'utils';
import { getMatchParam } from 'helpers';
import { deleteUserAction, getUserProfileAction, banUserAction } from 'actions';
import { useLoading } from 'hooks';
import { UserChildrenRouter } from 'routes/childRoutes/UsersRouter';
import { customAbility } from 'containers/Can';
import { withOwnUser, IWithOwnUser } from 'containers';

interface IProps extends RouteComponentProps, IWithOwnUser {}

const UserDetailsPage: React.FC<IProps> = ({
  history,
  match,
  location,
  profile,
}) => {
  const dispatch = useDispatch();
  const user = useSelector<any, UserProfile>(
    makeSelector(['usersReducer', 'userProfile'])
  );
  const isLoading = useLoading(['usersReducer', 'isLoadingUserProfile']);

  const idFromUrl = getMatchParam(match).id;
  const isOwn = profile.id === user.id;
  const canRead =
    customAbility.can(PermissionTypes.READ, UserGroups.USERS) || isOwn;
  const categoryTabs = getUserCategoryTab(idFromUrl, canRead);

  const caUpdateUser = customAbility.can(
    PermissionTypes.UPDATE,
    UserGroups.USERS
  );

  const canBanUser = caUpdateUser && !OCT_ROLE_TYPES.includes(user.role);

  useEffect(() => {
    if (idFromUrl) {
      dispatch(getUserProfileAction(idFromUrl));
    }
  }, []);

  // TABS
  const onSelectTab = (tab: IItem) => {
    history.replace(tab.id as string);
  };

  const setDefaultTab = () => {
    const currentTab = categoryTabs.find((t) => t.id === location.pathname);
    return currentTab ? currentTab : categoryTabs[0];
  };

  // PROMPTS
  const [isPrompt, setPromptState] = useState(false);
  const [modalParams, setModalParams] = useState<IUniversalModalProps | null>(
    null
  );

  const closePrompt = () => setPromptState(false);

  const showPromptWith = (params: IUniversalModalProps) => () => {
    setModalParams(params);
    setPromptState(true);
  };

  const deleteUser = () => {
    closePrompt();
    dispatch(deleteUserAction(idFromUrl, history.goBack));
  };

  const banUser = () => {
    closePrompt();
    dispatch(banUserAction(idFromUrl));
  };

  // Each Prompt depends of status
  const prepareHeaderCtrlBtns = () => {
    const promptProps: IUniversalModalProps = {
      visible: true,
      onCancel: closePrompt,
    };

    // Delete User
    const deletePrompt = {
      ...promptProps,
      onOk: deleteUser,
    };

    // Ban User
    if (!user.blocked) {
      const banPrompt = {
        ...promptProps,
        promptText: `${PromptPrefix.ARE_SURE} ban this user?`,
        promptOkBtn: makeBtn('Ban', closedIcon, 'error', banUser),
      };
      if (!caUpdateUser) return null;
      return (
        <>
          {makeBtn(
            'Delete User',
            deleteIcon,
            'error',
            showPromptWith(deletePrompt)
          )}
          {canBanUser &&
            makeBtn('Ban User', closedIcon, 'error', showPromptWith(banPrompt))}
        </>
      );
    }

    // Unban User
    const unbanPrompt = {
      ...promptProps,
      promptText: `${PromptPrefix.ARE_SURE} unban this user?`,
      promptOkBtn: makeBtn('Unban', checkIcon, 'success', banUser),
    };
    return (
      caUpdateUser && (
        <>
          {makeBtn(
            'Delete User',
            deleteIcon,
            'error',
            showPromptWith(deletePrompt)
          )}
          {makeBtn(
            'Unban User',
            checkIcon,
            'success',
            showPromptWith(unbanPrompt)
          )}
        </>
      )
    );
  };

  return (
    <div className="f-column community-page">
      <CategoryHeader
        catTitle={user.username}
        catTabs={categoryTabs}
        backBtn={{ showButton: true }}
        ctrlBtns={prepareHeaderCtrlBtns()}
        onSelectTab={onSelectTab}
        isLoading={isLoading}
        defaultTab={setDefaultTab()}
      />

      {/* CONTENT */}
      {UserChildrenRouter({ user, isLoading, profile })}

      <UniversalModal {...modalParams} visible={isPrompt} />
    </div>
  );
};

export default withOwnUser(UserDetailsPage);
