import React from 'react';

import CircleChart from './CircleChart/CircleChart';
import { IChartParams, IStatisticChartParams } from 'interfaces';

const renderChartCard = (
  categoryName: string,
  chartParams: IChartParams,
  type: IStatisticChartParams['type']
) => {
  return (
    <div className="w100">
      {categoryName ? (
        <h3 className="stat-block-title">{categoryName} Statistic</h3>
      ) : null}
      <CircleChart
        {...chartParams}
        title={categoryName ? `${categoryName} Total` : ''}
        type={type}
      />
    </div>
  );
};

export default renderChartCard;
