import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';

import { FormCheckbox, FormTextInput, FormUploadImage } from '../common/form';
import { validateRequired } from 'utils';
import { CATEGORY_EDIT_FORM, LARGE_TITLE_LIMIT } from 'const';
import { cutToSize } from 'helpers';

interface IProps extends InjectedFormProps {}

const CategoryEditForm: any = (props: IProps) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className="category-edit-form">
      <Field
        name="categoryName"
        label="Title"
        placeholder="Enter Title"
        component={FormTextInput}
        validate={validateRequired}
        normalize={cutToSize(LARGE_TITLE_LIMIT)}
        max={LARGE_TITLE_LIMIT}
      />

      <Field
        name="image"
        label={
          <>
            <b>Cover Image </b>
            <span className="hint">(recommended size 290x250 px)</span>
          </>
        }
        component={FormUploadImage}
        validate={validateRequired}
      />

      <Field
        name="logo"
        label={
          <>
            <b>Category Icon </b>
            <span className="hint">(recommended size 290x250 px)</span>
          </>
        }
        // listType="avatar"
        component={FormUploadImage}
        validate={validateRequired}
      />

      <Field
        name="isDefault"
        label={<b>Default Category</b>}
        type="checkbox"
        component={FormCheckbox}
      />
    </form>
  );
};

export default reduxForm({
  form: CATEGORY_EDIT_FORM,
  enableReinitialize: true,
})(CategoryEditForm);
