import React, { useState } from 'react';
import {
  makeBtn,
  UniversalButton,
  UniversalIcon,
  UniversalModal,
  UniversalTooltip,
} from 'components/common/universal';
// @ts-ignore
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { checkIcon, errorIcon } from 'components/common';

import './cropper.scss';
import Icon from '@ant-design/icons';
import { convertImageBase64toBlob } from 'helpers';
import { showMessage } from 'utils';
import { MESSAGE } from 'const';

export interface Crop {
  aspect: number;
  unit: 'px' | '%';
  height: number;
  width: number;
  x: number;
  y: number;
}

const initialCropParams: Crop = {
  aspect: 16 / 9,
  unit: '%',
  width: 90,
  height: 80,
  x: 5,
  y: 10,
};

interface IProps {
  btnTitle?: string;
  onCropComplete(file: Blob): void;
  defaultCrop?: Crop;
  invalidError?: string;
}

const Cropper: React.FC<IProps> = (props) => {
  const {
    btnTitle = 'Choose file',
    onCropComplete,
    defaultCrop = initialCropParams,
    invalidError,
  } = props;
  const [isModal, setModalState] = useState(false);

  const [crop, setCropState] = useState<Crop>(defaultCrop);
  const [base64, setBase64State] = useState('');
  const [imageElem, setImageElemState] = useState<HTMLElement>();

  const toggleModal = () => {
    setModalState(!isModal);
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image: any) => {
    setImageElemState(image);
  };

  const onCropChange = (crop: Crop) => {
    setCropState(crop);
  };

  const makeClientCrop = async () => {
    if (imageElem && crop.width && crop.height) {
      const blob = await getCroppedImg(imageElem, crop, 'newFile.jpeg');
      onCropComplete(blob);
      toggleModal();
    }
  };

  const getCroppedImg = (image: any, crop: any, fileName: any) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d') as any;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise<Blob>((resolve, reject) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.filename = fileName;
        blob.name = fileName;

        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const onFileLoaded = async (base64: string) => {
    try {
      // Checked image type
      await convertImageBase64toBlob(base64);
      setBase64State(base64);
      toggleModal();
    } catch (err) {
      // Invalid type
      showMessage.error(MESSAGE.INVALID_UPLOADED_TYPE);
      console.error(err);
    }
  };

  const submit = () => makeClientCrop();

  return (
    <div className="cropper-cmp">
      <UniversalButton
        btnTitle={btnTitle}
        styleType="success"
        btnType="file"
        fileAs="base64"
        onFileLoaded={onFileLoaded}
        accept=".jpg,.jpeg,.png"
      />
      {invalidError && (
        <UniversalTooltip
          placement="right"
          title={invalidError}
          styleType="error"
        >
          <Icon component={errorIcon} />
        </UniversalTooltip>
      )}

      <UniversalModal
        title="Crop image"
        promptOkBtn={makeBtn('Crop', checkIcon, 'success', submit)}
        maxWidth={'60%'}
        onCancel={toggleModal}
        visible={isModal}
      >
        <ReactCrop
          src={base64}
          crop={crop}
          // ruleOfThirds
          onImageLoaded={onImageLoaded}
          // onComplete={onCropComplete}
          onChange={onCropChange}
        />
      </UniversalModal>
    </div>
  );
};

export { Cropper };
