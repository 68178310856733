import React from 'react';
import { Field, FieldArray } from 'redux-form/immutable';

import { makeBtn, UniversalButton } from 'components/common/universal';
import { addIcon, closeIcon, deleteIcon, refreshIcon } from 'components/common';
import {
  FormSelect,
  FormTextInput,
  FormSwitch,
  FormCheckbox,
} from 'components/common/form';
import {
  Question,
  QuestionList,
  questionType,
  resetQuestionModel,
  resetQuestionOptionModel,
} from 'interfaces';

import { questionTypeOptions } from 'assets/static-data';
import { validateRequired } from 'utils';
import { cutToSize } from 'helpers';
import { QUESTION_LIMIT } from 'const';

// QUESTION BLOCK (FormArray Item render)
// -------------------------------
// !!! Should be outside of form component to prevent infinity re-render
export const renderQuestionBlock = (props: {
  questionDtoList: QuestionList;
  isPulse: boolean;
  optionalQuestion: {
    optionalCount: number;
    optionalQuestions: Question[];
    isOptDisabled: boolean;
  };
  [key: string]: any;
}) => {
  const {
    questionDtoList,
    isPulse,
    fields,
    fields: { length },
    optionalQuestion,
  } = props;

  const prepareQuestion = () => {
    const question: Question = {
      ...resetQuestionModel(),
      // needs to normalize range before request (onSubmit fn)
      questionNumber: length,
    };
    // to prevent error optionList generates in child FieldArray
    return {
      ...question,
      optionList: undefined,
    };
  };

  const addQuestion = () => {
    fields.push(prepareQuestion());
  };

  const removeQuestion = (fieldIndex: number) => () => {
    fields.remove(fieldIndex);
  };

  const resetValues = (fieldIndex: number) => () => {
    removeQuestion(fieldIndex)();
    fields.insert(fieldIndex, prepareQuestion());
  };

  return (
    <>
      <ol className="">
        {fields.map((fieldPath: string, i: number) => {
          const isQualified = questionDtoList[i].qualified;
          const isShowQualified = i === 0 && !isPulse;
          const isOptional = questionDtoList[i].optional;
          const isCanBeOptional = !isQualified && !isPulse;
          const {
            isOptDisabled,
            optionalCount,
            optionalQuestions,
          } = optionalQuestion;

          return (
            <li key={fieldPath}>
              <h3 className="f-c-sb">
                {i + 1}.
                <span>
                  {length === 1
                    ? makeBtn('', refreshIcon, 'hint', resetValues(i), 'link')
                    : makeBtn(
                        '',
                        deleteIcon,
                        'error',
                        removeQuestion(i),
                        'link'
                      )}
                </span>
              </h3>

              {isShowQualified && !isOptional && (
                <Field
                  name={`${fieldPath}.qualified`}
                  label="Qualifier"
                  component={FormSwitch}
                  parse={(val: string) => JSON.parse(val)}
                />
              )}

              {isQualified && (
                <span>
                  Indicate which of the two choices must be selected b the user
                  to qualify the survey
                </span>
              )}

              {isCanBeOptional && (
                <Field
                  name={`${fieldPath}.optional`}
                  label={`Optional (${optionalQuestions.length}/${optionalCount})`}
                  component={FormSwitch}
                  parse={(val: string) => JSON.parse(val)}
                  disabled={isOptDisabled && !isOptional}
                />
              )}

              <Field
                name={`${fieldPath}.question`}
                label="Question Text"
                placeholder="Enter Text"
                component={FormTextInput}
                validate={validateRequired}
                normalize={cutToSize(QUESTION_LIMIT)}
                max={QUESTION_LIMIT}
              />

              <Field
                name={`${fieldPath}.type`}
                label="Answer Type"
                placeholder="Select Answer Type"
                options={questionTypeOptions}
                component={FormSelect}
                disabled={isPulse || isQualified}
              />

              {/* OPTIONS */}
              <FieldArray
                name={`${fieldPath}.optionList`}
                component={renderOptionList}
                props={{ question: questionDtoList[i], isQualified }}
              />
            </li>
          );
        })}
      </ol>
      {!isPulse && (
        <UniversalButton
          block
          btnType="link"
          iconComp={addIcon}
          btnTitle="Add New Question"
          onClick={addQuestion}
        />
      )}
    </>
  );
};

enum MIN {
  DEF = 2, // default min options amount
  YESNO = 2, // yes/no min options amount
  RANKR = 5, // Rank or Rate min options amount
}

enum MAX {
  DEF = 5, // default max options amount
}

// OPTIONS LIST
const renderOptionList = (props: {
  question: Question;
  isQualified: boolean;
  [key: string]: any;
}) => {
  const {
    fields,
    question,
    fields: { length },
    question: { type },
    isQualified,
  } = props;

  const isYesNo = type === questionType.YESNO;
  const isRankRate = type === questionType.RANK; // || type === questionType.RATE;
  const isDefault = !isYesNo && !isRankRate;

  const isRemovable = !isDefault || length > MIN.DEF;

  const addOption = () => {
    if (fields.length < MAX.DEF) {
      fields.push(resetQuestionOptionModel(length));
    }
  };

  const removeOption = (index: number) => () => {
    if (isRemovable) {
      fields.remove(index);
    }
  };

  const needsAmount = isRankRate
    ? length < MIN.RANKR && MIN.RANKR - length
    : length < MIN.DEF && MIN.DEF - length;

  if (needsAmount) {
    [...new Array(+needsAmount)].forEach(addOption);
  }

  return (
    <>
      <label className="text-input-label">Answer options list:</label>
      <ul>
        {fields.map((fieldPath: string, i: number) => {
          if (isYesNo && i >= MIN.YESNO) {
            removeOption(i)();
          }
          return (
            <li key={fieldPath} className="f-c-sb">
              <Field
                name={`${fieldPath}.text`}
                placeholder="Enter Text"
                component={FormTextInput}
                validate={validateRequired}
                normalize={cutToSize(QUESTION_LIMIT)}
                max={QUESTION_LIMIT}
              />
              {isQualified && (
                <div className="qualified-checkbox">
                  <Field
                    name={`${fieldPath}.qualified`}
                    component={FormCheckbox}
                    type="checkbox"
                  />
                </div>
              )}
              {isDefault && (
                <UniversalButton
                  iconComp={closeIcon}
                  btnType="link"
                  styleType={isRemovable ? 'error' : 'hint'}
                  disabled={!isRemovable}
                  onClick={removeOption(i)}
                />
              )}
            </li>
          );
        })}
      </ul>
      {isDefault && fields.length < MAX.DEF && (
        <UniversalButton
          btnType="link"
          iconComp={addIcon}
          btnTitle="Add Option"
          styleType="hint"
          onClick={addOption}
        />
      )}
    </>
  );
};
