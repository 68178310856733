import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { CategoryHeader, DetailsInfoPairs } from 'components/common/parts';
import { deleteIcon, editIcon, externalLinkIcon } from 'components/common';
import { makeBtn, makeLink, UniversalModal } from 'components/common/universal';
import {
  cssPrefixCreator,
  getMatchParam,
  prepareCategorySubTitle,
  prepareDomainNameFromUrl,
} from 'helpers';
import BuzzDetailsSkeleton from './buzzDetailsSkeleton';
import { Buzz } from 'interfaces';
import { BuzzRoute } from 'const';
import { deleteBuzzAction, getBuzzAction } from 'actions';
import { makeSelector } from 'utils';
import { useLoading } from 'hooks';

import './buzz-details-page.scss';

interface IProps extends RouteComponentProps {}

const BuzzDetailsPage: React.FC<IProps> = ({ history, match }) => {
  const dispatch = useDispatch();
  const [isPrompt, setPromptState] = useState(false);
  const [isMediaModal, setMediaModalState] = useState(false);

  const idFromUrl = Number.parseInt(getMatchParam(match).id, 10);
  const buzz = useSelector<any, Buzz>(makeSelector(['buzzReducer', 'buzz']));
  const isLoading = useLoading('buzzReducer', [idFromUrl === buzz.id]);

  const { id, title, description, image, dateCreated, webURL, pdf } = buzz;

  useEffect(() => {
    if (idFromUrl) {
      dispatch(getBuzzAction(idFromUrl));
    }
  }, []);

  const togglePromptToDelete = () => setPromptState(!isPrompt);
  const toggleMediaModal = () => setMediaModalState(!isMediaModal);

  const onDeleteBuzz = () => {
    togglePromptToDelete();
    dispatch(deleteBuzzAction(id, history.goBack));
  };

  const toEditPage = () => {
    history.push(BuzzRoute.getEditRoute(id), buzz);
  };

  const cssPrefix = cssPrefixCreator('buzz-details-page');
  return (
    <div className={cssPrefix(' f-column')}>
      <CategoryHeader
        catTitle={title}
        catSubTitle={prepareCategorySubTitle('', '', dateCreated)}
        backBtn={{ showButton: true }}
        isLoading={isLoading}
        ctrlBtns={
          <>
            {makeBtn(
              'Delete The Buzz',
              deleteIcon,
              'error',
              togglePromptToDelete
            )}
            {makeBtn('Edit The Buzz', editIcon, 'success', toEditPage)}
          </>
        }
      />

      {isLoading ? (
        <BuzzDetailsSkeleton cssPrefix={cssPrefix} />
      ) : (
        <div className="card">
          <img
            src={image}
            className={cssPrefix('img rounded')}
            alt="preview"
            onClick={toggleMediaModal}
          />
          <DetailsInfoPairs
            details={[
              {
                title: 'URL',
                value:
                  webURL &&
                  makeLink(
                    prepareDomainNameFromUrl(webURL),
                    webURL,
                    externalLinkIcon
                  ),
              },
              {
                title: 'PDF',
                value: pdf
                  ? makeLink(
                      prepareDomainNameFromUrl(pdf),
                      pdf,
                      externalLinkIcon
                    )
                  : '',
              },
              { title: 'Description', value: description },
            ]}
          />
        </div>
      )}

      <UniversalModal
        visible={isPrompt}
        onCancel={togglePromptToDelete}
        onOk={onDeleteBuzz}
      />

      <UniversalModal
        type="media"
        imageSrc={image}
        visible={isMediaModal}
        onCancel={toggleMediaModal}
      />
    </div>
  );
};

export default BuzzDetailsPage;
