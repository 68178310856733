import { AuthorityType } from 'interfaces';

export const SESSION_ERROR_TEXT = 'Время сессии истекло';

export const PASSWORD_MIN_LENGTH = 8;
export const BAD_CREDENTIALS =
  'The phone number you’ve entered doesn’t match any account. ' +
  'Try again or use «Forgot Password?» form.';

// PASSWORD
export const PasswordErrors = {
  // INVALID_PASSWORD: 'Пароль не верен.',
  // NEW_EQUAL_TO_OLD: 'Новый пароль не может совпадать со старым.',
  NEW_AND_CONFIRM_SHOULD_BE_EQUAL:
    'New Password and Confirm Password should be equal.',
  INCORRECT_SYMBOLS: 'Password should be contain min 6 max 16 chars.',
};

export const PasswordSuccess = {
  CHANGE_PASSWORD: 'Пароль успешно изменен!',
};

export const PasswordHints = {
  SET_PASSWORD: 'Enter your password.',
  SET_NEW: 'Enter new password.',
  REPLY_NEW: 'Reply new password.',
};

export const PasswordRequire = {
  START_STR: 'Пароль должен содержать как минимум',
  MIN_LENGTH: `${PASSWORD_MIN_LENGTH} символов`,
  CAPITALIZE: 'одну заглавную букву',
  NUMBER: 'одну цифру',
  SYMBOL: 'один спецсимвол',
};

// EMAIL
export const EmailErrors = {
  INVALID_PATTERN: 'Invalid email',
  // INVALID_EMAIL: 'Ваш e-mail не прошел валидацию!',
};

export const EmailSuccess = {
  // CHANGE_PASSWORD: 'Пароль успешно изменен!'
};

export const EmailHints = {
  SET_EMAIL: 'Set your email.',
};

// PHONE
export const PhoneHints = {
  SET_PHONE: 'Enter your phone number.',
};

export const PasscodeHint = {
  INCORRECT_SYMBOLS: 'Passcode should be contain 6 digits',
};

export const INVALID_USER_MESSAGE = 'There is no such user in the system';

export const EmailOrPhoneHint = `${EmailHints.SET_EMAIL} Or enter your phone number.`;

export const VALID_ROLES = [
  AuthorityType.ROLE_OCT_ADMIN,
  AuthorityType.ROLE_OCT_MODERATOR,
  AuthorityType.ROLE_OCT_CONTENT_MANAGER,
];
