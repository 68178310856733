import { call, put, takeLatest } from 'redux-saga/effects';
import { getDashboardConfigAction } from 'actions';

import { GET_DASHBOARD_CONFIG } from 'const';
import { httpApi, HttpResp, makeAction, showMessage } from 'utils';
import { DashboardConfig } from 'interfaces';

function* getDashboardConfig({}: ReturnType<typeof getDashboardConfigAction>) {
  try {
    const res: HttpResp<DashboardConfig> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/dashboard`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_DASHBOARD_CONFIG.SUCCESS, res));
    } else {
      yield put(makeAction(GET_DASHBOARD_CONFIG.FAILURE, res));
    }
  } catch (error) {}
}

export function* dashboardSaga() {
  yield takeLatest(GET_DASHBOARD_CONFIG.PENDING, getDashboardConfig);
}
