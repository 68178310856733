import {
  ADD_OFFENSIVE_WORD,
  DELETE_OFFENSIVE_WORD,
  GET_OFFENSIVE_WORDS_LIST,
} from 'const';
import { IActionFn, OffensiveWord } from 'interfaces';

export const getOffensiveWordsListAction: IActionFn<null> = () => ({
  type: GET_OFFENSIVE_WORDS_LIST.PENDING,
  payload: null,
});

export const addOffensiveWordAction: IActionFn<string> = (word) => ({
  type: ADD_OFFENSIVE_WORD.PENDING,
  payload: word,
});

export const deleteOffensiveWordAction: IActionFn<OffensiveWord> = (word) => ({
  type: DELETE_OFFENSIVE_WORD.PENDING,
  payload: word,
});
