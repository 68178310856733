import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import Icon from '@ant-design/icons';

import {
  logoutIcon,
  menuCollapsedIcon,
  menuIcon,
  userIcon,
} from 'components/common';
import { UniversalButton, UniversalModal } from 'components/common/universal';
import { getOwnProfileAction, signOutAction } from 'actions';
import { AccountRoute, AuthRoute, MEDIA_TABLET_POINTS } from 'const';
import { UserProfile } from 'interfaces';
import { makeSelector, session } from 'utils';
import { useMobile } from 'hooks';

const { Header } = Layout;

interface IProps {
  isCollapsed: boolean;
  toggleMenu(): void;
  setRoute(path: string): void;
}

const MainHeader: React.FC<IProps> = (props) => {
  const { isCollapsed, toggleMenu, setRoute } = props;
  const dispatch = useDispatch();
  const [isPrompt, togglePrompt] = useState(false);
  const userProfile = useSelector<any, UserProfile>(
    makeSelector(['ownDataReducer', 'ownProfile'])
  );

  useEffect(() => {
    if (!userProfile.id) {
      dispatch(getOwnProfileAction(session.getOwnID()));
    }
  }, [userProfile.id]);

  const toggleLogoutPrompt = () => {
    togglePrompt(!isPrompt);
  };

  const signOut = () => {
    dispatch(signOutAction(null));
    setRoute(AuthRoute.ROOT);
    session.clearData();
  };

  const isMobile = useMobile(MEDIA_TABLET_POINTS);

  return (
    <Header className="f-c-sb">
      {!isMobile && (
        <Icon
          className="trigger"
          component={isCollapsed ? menuCollapsedIcon : menuIcon}
          onClick={toggleMenu}
        />
      )}

      {/* USER CTRL */}
      <div>
        <UniversalButton
          btnType="link"
          linkTo={AccountRoute.ROOT}
          btnTitle={userProfile.username}
          iconComp={userIcon}
        />
        <UniversalButton
          btnType="link"
          btnTitle="Sign Out"
          iconComp={logoutIcon}
          className="logout-btn"
          onClick={toggleLogoutPrompt}
        />
      </div>

      {/* SIGN OUT PROMPT */}
      <UniversalModal
        visible={isPrompt}
        title="Sign Out"
        promptText="You will be returned to the login screen."
        onCancel={toggleLogoutPrompt}
        promptOkBtn={
          <UniversalButton
            btnTitle="Sign Out"
            iconComp={logoutIcon}
            styleType="error"
            onClick={signOut}
          />
        }
      />
    </Header>
  );
};

export default MainHeader;
