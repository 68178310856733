import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import {
  GET_COMMUNITY,
  GET_COMMUNITY_FOLLOWERS,
  GET_COMMUNITY_LIST,
  GET_COMMUNITY_MODERATORS,
  GET_COMMUNITY_SURVEYS,
  UPDATE_COMMUNITY_PARAMS,
  RESET_COMMUNITY_STATE,
  GET_COMMUNITY_NEWS_LIST,
  GET_COMMUNITY_NEWS,
  RESET_COMMUNITY_NEWS_STATE,
  GET_COMMUNITY_ACTIVE_NEWS_LIST,
  START_COMMUNITY_ACTION,
  STOP_COMMUNITY_ACTION,
  GET_COMMUNITIES_SEARCH_LIST,
} from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';
import {
  Community,
  FollowersList,
  resetCommunityModel,
  resetListPaginatedModel,
  SurveysList,
  CommunityList,
  QueryParams,
  resetQueryParamsModel,
  AvailableCommunityList,
} from 'interfaces';
import { resetNewsModel, NewsList } from 'interfaces/community/news';

interface CommunityReducerState {
  isLoading: boolean;
  isLoadingCommunity: boolean;
  communityQueryParams: QueryParams;
  communityList: CommunityList;
  community: Community;
  // depends of community
  surveysQueryParams: QueryParams;
  surveysList: SurveysList;
  followersQueryParams: QueryParams;
  followersList: FollowersList;
  moderatorsQueryParams: QueryParams;
  moderatorsList: FollowersList;
  newsQueryParams: QueryParams;
  newsList: NewsList;
  activeNewsList: NewsList;
  selectCommunityList: AvailableCommunityList;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = Immutable.fromJS(({
  isLoading: false,
  isLoadingCommunity: false,
  communityQueryParams: resetQueryParamsModel(),
  communityList: resetListPaginatedModel(),
  community: resetCommunityModel(),
  surveysQueryParams: resetQueryParamsModel(),
  surveysList: resetListPaginatedModel(),
  followersQueryParams: resetQueryParamsModel(),
  followersList: resetListPaginatedModel(),
  moderatorsQueryParams: resetQueryParamsModel(),
  moderatorsList: resetListPaginatedModel(),
  newsQueryParams: resetQueryParamsModel(),
  newsList: resetListPaginatedModel(),
  activeNewsList: resetListPaginatedModel(),
  news: resetNewsModel(),
  selectCommunityList: [],
} as unknown) as CommunityReducerState);

const { PENDING, DONE } = makeCombinedAction(
  GET_COMMUNITY_LIST,
  GET_COMMUNITY_SURVEYS,
  GET_COMMUNITY_FOLLOWERS,
  GET_COMMUNITY_MODERATORS
);

const communityReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [GET_COMMUNITY_LIST.PENDING]: (state, { payload }) => {
      return state.set('communityQueryParams', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('communityList', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY.PENDING]: (state) => {
      return state.set('isLoadingCommunity', true);
    },
    [GET_COMMUNITY.SUCCESS]: (state, { payload }) => {
      return state.set('community', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY.DONE]: (state) => {
      return state.set('isLoadingCommunity', false);
    },
    [GET_COMMUNITY_SURVEYS.PENDING]: (state, { payload }) => {
      return state.set('surveysQueryParams', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY_SURVEYS.SUCCESS]: (state, { payload }) => {
      return state.set('surveysList', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY_FOLLOWERS.PENDING]: (state, { payload }) => {
      return state.set('followersQueryParams', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY_FOLLOWERS.SUCCESS]: (state, { payload }) => {
      return state.set('followersList', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY_MODERATORS.PENDING]: (state, { payload }) => {
      return state.set('moderatorsQueryParams', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY_MODERATORS.SUCCESS]: (state, { payload }) => {
      return state.set('moderatorsList', Immutable.fromJS(payload));
    },
    [RESET_COMMUNITY_STATE.SUCCESS]: (state) => {
      return state.set('community', Immutable.fromJS(resetCommunityModel()));
    },
    [GET_COMMUNITY_NEWS_LIST.PENDING]: (state, { payload }) => {
      return state.set('newsQueryParams', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY_NEWS_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('newsList', Immutable.fromJS(payload));
    },
    [GET_COMMUNITY_NEWS.SUCCESS]: (state, { payload }) => {
      return state.set('news', Immutable.fromJS(payload));
    },
    [RESET_COMMUNITY_NEWS_STATE.SUCCESS]: (state) => {
      return state.set('news', Immutable.fromJS(resetNewsModel()));
    },
    [GET_COMMUNITY_ACTIVE_NEWS_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('activeNewsList', Immutable.fromJS(payload));
    },
    [UPDATE_COMMUNITY_PARAMS]: (state, { payload }) => {
      const surveysQueryParams = state.toJS().surveysQueryParams;
      const followersQueryParams = state.toJS().followersQueryParams;
      const moderatorsQueryParams = state.toJS().moderatorsQueryParams;

      state = state.set(
        'surveysQueryParams',
        Immutable.fromJS({ ...surveysQueryParams, query: payload.query })
      );
      state = state.set(
        'followersQueryParams',
        Immutable.fromJS({ ...followersQueryParams, query: payload.query })
      );
      state = state.set(
        'moderatorsQueryParams',
        Immutable.fromJS({ ...moderatorsQueryParams, query: payload.query })
      );
      return state;
    },
    [START_COMMUNITY_ACTION]: (state) => {
      return state.set('isLoadingCommunity', true);
    },
    [STOP_COMMUNITY_ACTION]: (state) => {
      return state.set('isLoadingCommunity', false);
    },
    [GET_COMMUNITIES_SEARCH_LIST.SUCCESS]: (state, { payload }) => {
      return state.set('selectCommunityList', payload);
    },
  },
  initialState
);

export default communityReducer;
