import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
  GET_SUBSCRIBED_LIST_ACTION,
  GET_UNSUBSCRIBED_LIST_ACTION,
  UNSUBSCRIBE_ACTION,
  DELETE_UNSUBSCRIBED_ACTION,
} from 'const';
import {
  httpApi,
  HttpResp,
  makeAction,
  encodeDataToUrl,
  showMessage,
} from 'utils';
import {
  getSubscribedListAction,
  getUnsubscribedListAction,
  unsubscribeAction,
  deleteSubscribeAction,
} from 'actions';

function* getSubscribeList({
  payload,
}: ReturnType<typeof getSubscribedListAction>) {
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/subscribe?${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_SUBSCRIBED_LIST_ACTION.SUCCESS, res));
    } else {
      yield put(makeAction(GET_SUBSCRIBED_LIST_ACTION.FAILURE, res));
    }
  } catch (error) {}
}

function* getUnsubscribeList({
  payload,
}: ReturnType<typeof getUnsubscribedListAction>) {
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/subscribe?subscribed=false&${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_UNSUBSCRIBED_LIST_ACTION.SUCCESS, res));
    } else {
      yield put(makeAction(GET_UNSUBSCRIBED_LIST_ACTION.FAILURE, res));
    }
  } catch (error) {}
}

function* unsubscribe({ payload }: ReturnType<typeof unsubscribeAction>) {
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/subscribe/${payload}`,
    });
    if (res && !res.error) {
      yield put(makeAction(UNSUBSCRIBE_ACTION.SUCCESS));
    } else {
      yield put(makeAction(UNSUBSCRIBE_ACTION.FAILURE, res));
    }
  } catch (error) {}
}

function* deleteUnsubscribed({
  payload,
}: ReturnType<typeof deleteSubscribeAction>) {
  const store = yield select();
  const state = store.toJS().getInTouchReducer;
  const params = state.getInTouchQueryParams;

  const { id, type } = payload;
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'DELETE',
      partUrl: `/subscribe/${id}`,
    });
    if (res && !res.error) {
      yield put(makeAction(DELETE_UNSUBSCRIBED_ACTION.SUCCESS));
      if (type === 'subscribe') {
        yield put(getSubscribedListAction(params));
      } else if (type === 'unsubscribe') {
        yield put(getUnsubscribedListAction(params));
      }
      showMessage.success('User was deleted');
    } else {
      yield put(makeAction(DELETE_UNSUBSCRIBED_ACTION.FAILURE, res));
    }
  } catch (error) {}
}

export function* getInTouchSagas() {
  yield takeLatest(GET_SUBSCRIBED_LIST_ACTION.PENDING, getSubscribeList);
  yield takeLatest(GET_UNSUBSCRIBED_LIST_ACTION.PENDING, getUnsubscribeList);
  yield takeLatest(UNSUBSCRIBE_ACTION.PENDING, unsubscribe);
  yield takeLatest(DELETE_UNSUBSCRIBED_ACTION.PENDING, deleteUnsubscribed);
}
