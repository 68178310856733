import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Tag } from 'antd';

import { CategoryHeader } from 'components/common/parts';

import './offensive-words-page.scss';
import {
  IReactChangeEvent,
  OffensiveWord,
  OffensiveWordsList,
} from 'interfaces';
import {
  addOffensiveWordAction,
  deleteOffensiveWordAction,
  getOffensiveWordsListAction,
} from 'actions';
import { makeSelector, showMessage } from 'utils';
import { checkIcon } from 'components/common';
import { makeBtn } from 'components/common/universal';
import { cutToSize } from 'helpers';

const MAX_CHARS_OFFENSIVE_WORD = 60;

const OffensiveWordsPage: React.FC = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');

  const words = useSelector<any, OffensiveWordsList>(
    makeSelector(['offensiveWordsReducer', 'wordsList'])
  );

  useEffect(() => {
    dispatch(getOffensiveWordsListAction(null));
  }, []);

  const removeWord = (word: OffensiveWord) => () => {
    dispatch(deleteOffensiveWordAction(word));
  };

  const addWord = () => {
    if (inputValue) {
      const fromServer = words.map(({ word }) => word);
      const inputWords = inputValue.match(/(\w+)/g) || [];
      const uniqueInputWords = inputWords.filter(
        (w, i, arr) => arr.indexOf(w) === i
      );
      const uniqueWords = uniqueInputWords.filter(
        (w) => !fromServer.includes(w)
      );
      const sameWords = inputWords.filter(
        (w) => !uniqueWords.some((u) => u === w)
      );
      uniqueWords.forEach((word) => dispatch(addOffensiveWordAction(word)));

      // show errors if word is exist
      if (sameWords.length) {
        const msg = sameWords.join(', ');
        showMessage.error(`"${msg}" is alredy exist`);
      }

      setInputValue('');
    }
  };

  const onChange = (e: IReactChangeEvent) => {
    const val = cutToSize(MAX_CHARS_OFFENSIVE_WORD)(e.target.value);
    setInputValue(val);
  };

  return (
    <div className="f-column offensive-words-page">
      <CategoryHeader catTitle="Offensive Words" />

      <div className="card">
        <h3>Add Offensive Words and press Enter</h3>
        <div className="offensive-words-page__field">
          <Input
            value={inputValue}
            placeholder="Type Here..."
            onChange={onChange}
            onPressEnter={addWord}
          />
          {makeBtn('Add Offensive Word', checkIcon, 'success', addWord)}
        </div>

        <p className="hint">separate words with commas or spaces</p>
        <div>
          {words.map(({ id, word }) => (
            <Tag
              className="c-tag"
              key={id}
              closable
              onClose={removeWord({ id, word })}
            >
              {word}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OffensiveWordsPage;
