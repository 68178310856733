import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../config/history';

import {
  ADD_SURVEY,
  DELETE_SURVEY,
  EDIT_SURVEY,
  GET_PULSES_LIST,
  GET_QUESTIONS,
  GET_QUESTIONS_STATISTIC,
  GET_SURVEY,
  GET_SURVEY_LIST,
  SET_SURVEY_STATUS,
  SURVEY_SUCCESS_MESSAGE,
  SurveysRoute,
  START_SURVEY_ACTION,
  STOP_SURVEY_ACTION,
} from 'const';
import {
  encodeDataToUrl,
  httpApi,
  HttpResp,
  makeAction,
  showMessage,
} from 'utils';
import {
  QuestionList,
  Survey,
  SurveysList,
  SurveyStatus,
  SurveyStatusActions,
  RedirectSurveyStatuses,
} from 'interfaces';
import {
  addSurveyAction,
  deleteSurveyAction,
  editSurveyAction,
  getPulsesListAction,
  getSurveyAction,
  getSurveyListAction,
  getSurveyQuestionsAction,
  getSurveyQuestionsStatisticAction,
  setSurveyStatusAction,
} from 'actions';

function* getSurveysList({ payload }: ReturnType<typeof getSurveyListAction>) {
  try {
    const res: HttpResp<SurveysList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/surveys/page?${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_SURVEY_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_SURVEY_LIST.FAILURE, res));
    }
  } catch (error) {}
}

function* getPulsesList({ payload }: ReturnType<typeof getPulsesListAction>) {
  try {
    const res: HttpResp<SurveysList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/surveys/page?isPulse=true&${encodeDataToUrl(payload)}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_PULSES_LIST.SUCCESS, res));
    } else {
      yield put(makeAction(GET_PULSES_LIST.FAILURE, res));
    }
  } catch (error) {}
}

function* getSurvey({ payload }: ReturnType<typeof getSurveyAction>) {
  try {
    const res: HttpResp<Survey> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/surveys/${payload}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_SURVEY.SUCCESS, res));
    } else {
      yield put(makeAction(GET_SURVEY.FAILURE, res));
    }
  } catch (error) {}
}

function* getSurveyQuestions({
  payload,
}: ReturnType<typeof getSurveyQuestionsAction>) {
  try {
    const res: HttpResp<QuestionList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/surveys/${payload}/questions`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_QUESTIONS.SUCCESS, res));
    } else {
      yield put(makeAction(GET_QUESTIONS.FAILURE, res));
    }
  } catch (error) {}
}

function* getSurveyQuestionsStatistic({
  payload,
}: ReturnType<typeof getSurveyQuestionsStatisticAction>) {
  try {
    const res: HttpResp<QuestionList> = yield call(httpApi, {
      method: 'GET',
      partUrl: `/statistic/survey/${payload}`,
    });
    if (res && !res.error) {
      yield put(makeAction(GET_QUESTIONS_STATISTIC.SUCCESS, res));
    } else {
      yield put(makeAction(GET_QUESTIONS_STATISTIC.FAILURE, res));
    }
  } catch (error) {}
}

function* setSurveyStatus({
  payload,
}: ReturnType<typeof setSurveyStatusAction>) {
  const { id, ...params } = payload;

  const description = params.description
    ? `&description=${params.description}`
    : '';

  yield put({ type: START_SURVEY_ACTION });

  try {
    const res: HttpResp<Survey> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/surveys/${id}/status?action=${params.status}${description}`,
      data: params,
    });
    if (res && !res.error) {
      showMessage.success('Status updated successfully');
      if (RedirectSurveyStatuses.includes(params.status)) {
        yield put(getSurveyAction(res.id));
        history.replace(`${SurveysRoute.getDetailsRoute(res.id)}`);
      } else {
        yield put(getSurveyAction(id));
      }
    }
  } catch (error) {
    yield put({ type: STOP_SURVEY_ACTION });
  }
}

function* deleteSurvey({
  payload,
  redirect,
}: ReturnType<typeof deleteSurveyAction>) {
  try {
    const res: HttpResp<{}> = yield call(httpApi, {
      method: 'DELETE',
      partUrl: `/surveys/${payload}`,
    });
    if (res && !res.error) {
      yield put(makeAction(DELETE_SURVEY.SUCCESS));
      if (redirect) {
        redirect();
      }
      showMessage.success('Survey removed successfully');
    }
  } catch (error) {}
}

function* addSurvey({ payload, redirect }: ReturnType<typeof addSurveyAction>) {
  const { isPulse, isDraft, publish } = payload;
  const urlParams = { isPulse, isDraft, publish };
  let successMessage = SURVEY_SUCCESS_MESSAGE.SURVEY;

  if (isDraft) {
    successMessage = SURVEY_SUCCESS_MESSAGE.DRAFT;
  }
  if (isPulse) {
    successMessage = SURVEY_SUCCESS_MESSAGE.PULSE;
  }

  try {
    const res: HttpResp<Survey> = yield call(httpApi, {
      method: 'POST',
      partUrl: `/surveys?${encodeDataToUrl(urlParams)}`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(makeAction(ADD_SURVEY.SUCCESS, payload));
      if (redirect) {
        redirect();
      }
      showMessage.success(successMessage);
    }
  } catch (error) {}
}

function* editSurvey({
  payload,
  redirect,
}: ReturnType<typeof editSurveyAction>) {
  const { publish } = payload;
  try {
    const res: HttpResp<Survey> = yield call(httpApi, {
      method: 'PUT',
      partUrl: `/surveys/${payload.id}?publish=${publish}`,
      data: payload,
    });
    if (res && !res.error) {
      yield put(makeAction(EDIT_SURVEY.SUCCESS, payload));
      if (redirect) {
        redirect();
      }
      showMessage.success('Survey edited successfully');
    }
  } catch (error) {}
}

export function* surveysSaga() {
  yield takeLatest(GET_SURVEY_LIST.PENDING, getSurveysList);
  yield takeLatest(GET_SURVEY.PENDING, getSurvey);
  yield takeLatest(ADD_SURVEY.PENDING, addSurvey);
  yield takeLatest(EDIT_SURVEY.PENDING, editSurvey);
  yield takeLatest(SET_SURVEY_STATUS.PENDING, setSurveyStatus);
  yield takeLatest(DELETE_SURVEY.PENDING, deleteSurvey);

  yield takeLatest(GET_PULSES_LIST.PENDING, getPulsesList);
  yield takeLatest(GET_QUESTIONS.PENDING, getSurveyQuestions);
  yield takeLatest(
    GET_QUESTIONS_STATISTIC.PENDING,
    getSurveyQuestionsStatistic
  );
}
