import React from 'react';
import { useSelector } from 'react-redux';

import { makeLink } from 'components/common/universal';
import { DetailsInfoCard, IDetailsInfoCard } from 'components/common/parts';
import { externalLinkIcon, externalPdfIcon } from 'components/common';
import {
  humanizeBoolean,
  CssPrefix,
  prepareDomainNameFromUrl,
  prepareFileNameFromUrl,
} from 'helpers';
import { makeSelector } from 'utils';
import { resetCategoryModel, Survey, UserProfile } from 'interfaces';

interface IProps {
  survey: Survey;
  cssPrefix: CssPrefix;
  isLoading: boolean;
}

const SurveyDetailsInfo: React.FC<IProps> = (props) => {
  // TODO user's location
  const { location } = useSelector<any, UserProfile>(
    makeSelector(['ownDataReducer', 'ownProfile'])
  );

  const {
    survey: {
      category = resetCategoryModel(),
      url,
      description,
      isAbleToChangeAnswer,
      pdf,
      passedTimes,
      rating = 0,
      isPulse,
    },
    cssPrefix,
    isLoading,
  } = props;

  const params: IDetailsInfoCard = {
    title: isPulse ? 'Pulse' : 'Survey',
    statistics: [
      { title: 'Passed Times', value: passedTimes },
      { title: 'Rating', value: rating + '%' },
    ],
    details: [
      { title: 'Category', value: category.categoryName },
      { title: 'Location', value: location.name },
      // { title: 'Sponsored', value: humanizeBoolean(isSponsored) },
      {
        title: 'Reference URL',
        value:
          url && makeLink(prepareDomainNameFromUrl(url), url, externalLinkIcon),
      },
      { title: 'Description', value: description },
      {
        title: 'Allow answers to be changed',
        value: humanizeBoolean(isAbleToChangeAnswer),
      },
      {
        title: 'Reference PDF',
        value:
          pdf && makeLink(prepareFileNameFromUrl(pdf), pdf, externalPdfIcon),
      },
    ],
  };

  return (
    <DetailsInfoCard
      params={params}
      isLoading={isLoading}
      cssPrefix={cssPrefix}
    />
  );
};

export default SurveyDetailsInfo;
