import {
  ISelectOption,
  humanizeQuestionType,
  questionType,
  GenderType,
} from 'interfaces';

export const genderOptions: ISelectOption<GenderType>[] = [
  {
    value: 'MALE',
    label: 'Male',
  },
  {
    value: 'FEMALE',
    label: 'Female',
  },
];

export const locationOptions: ISelectOption[] = [
  {
    value: '1',
    label: 'USA',
  },
];

export const visibilityOptions: ISelectOption[] = [
  {
    value: 'true',
    label: 'Public',
  },
  {
    value: 'false',
    label: 'Private',
  },
];

export const visibilityReversedOptions: ISelectOption[] = [
  {
    value: 'false',
    label: 'Public',
  },
  {
    value: 'true',
    label: 'Private',
  },
];

export const questionTypeOptions: ISelectOption[] = [
  {
    value: questionType.SINGLE,
    label: humanizeQuestionType[questionType.SINGLE],
  },
  {
    value: questionType.YESNO,
    label: humanizeQuestionType[questionType.YESNO],
  },
  {
    value: questionType.MULTI,
    label: humanizeQuestionType[questionType.MULTI],
  },
  {
    value: questionType.RANK,
    label: humanizeQuestionType[questionType.RANK],
  },
  {
    value: questionType.RATE,
    label: humanizeQuestionType[questionType.RATE],
  },
];
