import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import history from 'config/history';

import { AppConfig } from 'config/appConfig';
import { AuthRoute, MESSAGE, StatusCode } from 'const';
import { signOutAction } from 'actions';
import { refreshTokenSaga } from 'sagas';
import store from 'config/store';
import { session } from './session';
import { showMessage } from 'utils/notifications';

interface IPayload extends IRequestData {
  partUrl: string;
}

interface HttpError {
  error: boolean;
  status: any;
  description: string;
}

export type HttpResp<T> = T & HttpError;

interface IHeaders {
  'Content-Type':
    | 'application/json'
    | 'application/x-www-form-urlencoded'
    | 'multipart/form-data';
  Authorization?: string;
}

interface IRequestData {
  data: any | null;
  headers: IHeaders;
  method: string;
}

export const getHeaders = (existed?: IHeaders): IHeaders => {
  let headers: IHeaders;

  if (existed) {
    // just upd authorization if it needs
    headers = existed;
    if (headers.Authorization && /(Bearer)/.test(headers.Authorization)) {
      headers.Authorization = `Bearer ${session.getToken()}`;
    }
  } else {
    // create as default
    headers = { 'Content-Type': 'application/json' };
    if (session.getToken()) {
      headers.Authorization = `Bearer ${session.getToken()}`;
    }
  }
  return headers;
};

const prepareRequestData = (payload: IPayload): IRequestData | any => {
  const { headers, method = 'GET', data = {} } = payload;
  return {
    data,
    method,
    headers: getHeaders(headers),
  };
};

let isRefreshing = false;
let pendingPromises: any[] = [];
const continueStream = () => {
  return new Promise((resolve) => {
    pendingPromises.forEach((promise) => {
      promise();
    });
    pendingPromises = [];
    resolve();
  });
};

const signOut = () => {
  store.dispatch(signOutAction(null));
  history.push(AuthRoute.ROOT);
  showMessage.error(MESSAGE.SESSION_EXPIRED);
};

const updateToken = async (partUrl: string, payload: IPayload | any) => {
  if (isRefreshing) {
    const promise = new Promise((resolve) => pendingPromises.push(resolve));
    return promise;
  }

  // REFRESH TOKEN PROCESS
  if (session.isExpired() && (!isRefreshing || /token/.test(partUrl))) {
    // don't use as an action because we should await for refreshed token
    // before send rest requests
    if (!/token/.test(partUrl)) {
      isRefreshing = true;
      try {
        store.dispatch(
          refreshTokenSaga({ payload: session.getRefreshToken() } as any)
        );
      } catch (err) {
        signOut();
      }
    } else {
      const { status, data } = await axios(
        `${AppConfig.API_URL}${partUrl}`,
        prepareRequestData(payload)
      );
      console.log('REFRESH request', status);
      isRefreshing = false;
      continueStream();
      return status >= StatusCode.OK && status < StatusCode.MULTIPLY_CHOICE
        ? data
        : {};
    }
  }
};

export async function httpApi(payload: IPayload | any) {
  const { partUrl } = payload;
  try {
    // REFRESH TOKEN PROCESS
    await updateToken(partUrl, payload);

    // REQUEST
    const { status, data }: any = await axios(
      `${AppConfig.API_URL}${partUrl}`,
      prepareRequestData(payload)
    );
    return status >= StatusCode.OK && status < StatusCode.MULTIPLY_CHOICE
      ? data || {}
      : {};
  } catch ({ response = {} }) {
    isRefreshing = false;

    const { status = StatusCode.OFFLINE, data = {} } = response;

    const err: HttpError = {
      status,
      error: true,
      description: data.error_description || MESSAGE.ERROR_DEFAULT,
    };

    if (status === StatusCode.OFFLINE && !window.navigator.onLine) {
      err.description = MESSAGE.NO_NETWORK;
      // showMessage.error(MESSAGE.NO_NETWORK);
    }

    if (status === StatusCode.UNAUTHORIZED) {
      session.clearData();

      const path = window.location.pathname;
      if (path !== '/' && path !== AuthRoute.ROOT) {
        signOut();
      }
    } else {
      showMessage.error(err.description);
    }

    console.error('API ERROR ==>', { err, response });
    return err;
  }
}

// Encode data to x-www-form-urlencoded type
export const encodeDataToUrl = (
  params: { [key: string]: any },
  isSkipNull: boolean = true
) =>
  Object.keys(params)
    .filter((key) => !isSkipNull || (isSkipNull && params[key]))
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');
