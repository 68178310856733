import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import { GET_DASHBOARD_CONFIG } from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';
import { DashboardConfig, resetDashboardConfigModel } from 'interfaces';

export interface DashboardReducerState {
  isLoading: boolean;
  config: DashboardConfig;
}

const initialState = Immutable.fromJS({
  isLoading: false,
  config: resetDashboardConfigModel(),
} as DashboardReducerState);

const { PENDING, DONE } = makeCombinedAction(GET_DASHBOARD_CONFIG);

const dashboardReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [`${GET_DASHBOARD_CONFIG.SUCCESS}`]: (state, { payload }) => {
      return state.set('config', Immutable.fromJS(payload));
    },
  },
  initialState
);

export default dashboardReducer;
