import React, { useEffect, useState } from 'react';

import './column-chart.scss';
import { UniversalTooltip } from 'components/common/universal';
import { DashboardConfig } from 'interfaces';
import { useWindowSize } from 'hooks';

const ColChart = {
  COL_HEIGHT: 250, // Also duplicate same value to css! (as $col-height)
  MAX_ITEMS_COUNT: 10,
  MIN_ITEMS_COUNT: 6,
};

interface IProps {
  categories: DashboardConfig['categories'];
}

interface IState {
  max: number;
  statDividers: number[];
  sortedCategories: DashboardConfig['categories'];
}

const initialState: IState = {
  max: 0,
  sortedCategories: [],
  statDividers: [],
};

function ColumnChart({ categories }: IProps) {
  const [state, setState] = useState(initialState);
  const size = useWindowSize();

  const TOP_ITEMS_COUNT =
    size.width < 1500 ? ColChart.MIN_ITEMS_COUNT : ColChart.MAX_ITEMS_COUNT;

  useEffect(() => {
    let maxCommunities = 0;
    let maxSurveys = 0;

    categories.forEach((c) => {
      maxCommunities =
        c.communities > maxCommunities ? c.communities : maxCommunities;
      maxSurveys = c.surveys > maxSurveys ? c.surveys : maxSurveys;
    });

    // max of one criterion and round. For example 86 -> 120; 153 -> 200
    const maxVal = Math.max(maxCommunities, maxSurveys) || 200;
    const max = Math.ceil(maxVal / 100) * 100;

    // Divider needs to separate statistic into 5 sections, like 0-50-100-150-200
    const divider = Math.ceil(Math.round(max / 4) / 10) * 10;
    const statDividers = Array(5)
      .fill(divider)
      .map((val, i) => val * i)
      .reverse();

    const sortedCategories = categories.sort((a, b) => {
      return b.surveys + b.communities - (a.surveys + a.communities);
    });

    setState({
      ...state,
      sortedCategories,
      max,
      statDividers,
    });
  }, [categories]);

  const renderChatColumnItem = ({
    category,
    surveys,
    communities,
  }: DashboardConfig['categories'][0]) => {
    const calcHeight = (val: number) =>
      Math.round((((val * 100) / state.max) * ColChart.COL_HEIGHT) / 100);

    const surveysHeight = calcHeight(surveys);
    const communitiesHeight = calcHeight(communities);

    return (
      <div className="column-chart-item f-column" key={category}>
        <div className="flex">
          <span className="column-chart-col flex">
            <UniversalTooltip title={`surveys: ${surveys}`} styleType="success">
              <span style={{ height: `${surveysHeight}px` }} />
            </UniversalTooltip>
          </span>
          <span className="column-chart-col flex">
            <UniversalTooltip
              title={`communities: ${communities}`}
              styleType="primary"
            >
              <span style={{ height: `${communitiesHeight}px` }} />
            </UniversalTooltip>
          </span>
        </div>
        <span className="label">{category}</span>
      </div>
    );
  };

  const renderOtherItem = ({
    category,
    surveys,
    communities,
  }: DashboardConfig['categories'][0]) => {
    return (
      <div className="column-chart-other f-column f-s-sb" key={category}>
        <h5 className="label">{category}</h5>
        <div className="column-chart-other-counters">
          <UniversalTooltip title={`surveys: ${surveys}`} styleType="success">
            <span className="c-success">{surveys}</span>
          </UniversalTooltip>
          <span className="label">/</span>
          <UniversalTooltip
            title={`communities: ${communities}`}
            styleType="primary"
          >
            <span className="c-primary">{communities}</span>
          </UniversalTooltip>
        </div>
      </div>
    );
  };

  return (
    <div className="column-chart-cmp f-column">
      {/* HEADER */}
      <div className="f-c-sb">
        <h3 className="stat-block-title f-c-sb">Categories Statistic</h3>
        <div className="f-c-c">
          <div className="f-c-c">
            <span className="legend-color bg-success" />
            <span className="label">Surveys</span>
          </div>
          <div className="f-c-c">
            <span className="legend-color bg-primary" />
            <span className="label">Communities</span>
          </div>
        </div>
      </div>

      {/* STATISTIC */}
      <div className="flex">
        <div className="f-column f-c-sb column-chart-dividers">
          {state.statDividers.map((val, i) => {
            return <span key={i}>{val}</span>;
          })}
        </div>
        <div className="column-chart-block f-s-sb w100">
          {state.sortedCategories.map(
            (cat, i) => i < TOP_ITEMS_COUNT && renderChatColumnItem(cat)
          )}
        </div>
      </div>

      {/* OTHERS */}
      <div className="column-chart-cmp-others">
        <h3 className="">Others</h3>

        <div className="flex f-wrap">
          {state.sortedCategories.map(
            (cat, i) => i >= TOP_ITEMS_COUNT && renderOtherItem(cat)
          )}
        </div>
      </div>
    </div>
  );
}

export default ColumnChart;
