import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import { GET_POINTS_CONFIG } from 'const';
import { makeCombinedAction } from 'utils/app/reduxHelper';
import { PointsConfig, resetPointsConfigModel } from 'interfaces';

interface PointsReducerState {
  isLoading: boolean;
  config: PointsConfig;
}

const initialState = Immutable.fromJS({
  isLoading: false,
  config: resetPointsConfigModel(),
} as PointsReducerState);

const { PENDING, DONE } = makeCombinedAction(GET_POINTS_CONFIG);

const pointsReducer = handleActions(
  {
    [PENDING]: (state) => {
      return state.set('isLoading', true);
    },
    [DONE]: (state) => {
      return state.set('isLoading', false);
    },
    [GET_POINTS_CONFIG.SUCCESS]: (state, { payload }) => {
      return state.set('config', Immutable.fromJS(payload));
    },
  },
  initialState
);

export default pointsReducer;
